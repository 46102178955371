import React from 'react';
import { authSelector } from '@yonder-mind/ui-core';
import { FilterConfigSelector } from '../FilterConfigSelector/FilterConfigSelector';
import { DateSelector } from '../FilterConfigSelector/DateSelector';
import { DocumentsTable } from '../DocumentsTable/DocumentsTable';
import { PresetHeader } from '../Preset/PresetHeader';
import { Preset } from '../domain/types';
import dayjs from 'dayjs';
import { useSelectorUiWeb } from '../../../store';
import { useSelector } from 'react-redux';

export const PastCompliance: React.FC<{
    printRef: React.MutableRefObject<any>;
    currentPreset?: Preset;
}> = ({ printRef, currentPreset }) => {
    const hasComplianceViewRole = useSelector(authSelector.hasComplianceViewRole);

    const { documents, isLoadingDocuments } = useSelectorUiWeb((state) => {
        return {
            documents: state.complianceReports.documents,
            isLoadingDocuments: state.complianceReports.isLoadingDocuments,
        };
    });

    const defaultPastDate = currentPreset?.date ? currentPreset.date : dayjs().subtract(1, 'day').toISOString();

    return (
        <>
            {hasComplianceViewRole && (
                <div style={{ width: '100%' }}>
                    <PresetHeader currentPreset={currentPreset} />
                    <DateSelector defaultDate={defaultPastDate} />
                    <FilterConfigSelector />
                    <DocumentsTable printRef={printRef} documentsList={documents} isLoading={isLoadingDocuments} />
                </div>
            )}
        </>
    );
};

export default PastCompliance;
