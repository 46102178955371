import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, TextField, Typography } from '@material-ui/core';
import { YonderDialog } from '@yonder-mind/ui-core';

interface IProps {
    isOpen: boolean;
    isUploading: boolean;
    hasError: boolean;
    fileNamesInCR: string[];

    onUpload: (avatar: File) => any;
    onClose: () => any;
}

export const UploadAttachment: React.FC<IProps> = (props) => {
    const { t } = useTranslation();
    const ref = React.useRef<HTMLInputElement>(null);
    const [attachment, setAttachment] = React.useState<File | undefined>(undefined);
    const [filename, setFilename] = React.useState<string>('');
    const [showError, setShowError] = React.useState<boolean>(props.hasError);

    const defaultErrorText = t('workflow.tools.attachment.uploadAttachment.error');
    const fileNameExistsErrorText = t('workflow.tools.attachment.uploadAttachment.fileExistsError');
    const [errorText, setErrorText] = React.useState<string>(defaultErrorText);

    useEffect(() => {
        setShowError(props.hasError);
    }, [props.hasError]);

    const fileNameAlreadyPresent = (file: File) =>
        props.fileNamesInCR.filter((fileName) => fileName === file.name).length > 0;

    const handleFileSelection = (e: React.ChangeEvent<any>) => {
        const { files } = e.target;
        if (files && files.length !== 0) {
            const file = files[0];
            setAttachment(file);
            setFilename(file.name);
            if (fileNameAlreadyPresent(file)) {
                setErrorText(fileNameExistsErrorText);
                setShowError(true);
            } else {
                setErrorText(defaultErrorText);
                setShowError(false);
            }
        }
    };

    const handleInputClick = () => {
        if (ref && ref.current) {
            ref.current.click();
        }
    };

    const handleInputChange = (e: React.KeyboardEvent<any>) => e.preventDefault();

    const upload = () => {
        if (attachment) {
            setErrorText(defaultErrorText);
            props.onUpload(attachment);
        }
    };

    const clear = () => {
        if (ref && ref.current) {
            ref.current.value = '';
        }
        setAttachment(undefined);
        setFilename('');
        setErrorText(defaultErrorText);
        setShowError(false);
    };

    useEffect(() => {
        if (!props.isOpen) {
            clear();
        }
    }, [props.isOpen]);

    const close = () => {
        clear();
        props.onClose();
    };

    return (
        <YonderDialog
            isOpen={props.isOpen}
            className="workflow-tool attachment"
            title={<Typography variant="h6">{t('workflow.tools.attachment.uploadAttachment.title')}</Typography>}
            content={
                <Grid container={true}>
                    <Grid item={true} className="attachment-upload-dialog__input-container">
                        <input ref={ref} type="file" accept="*" hidden={true} onChange={handleFileSelection} />
                        <TextField
                            value={filename}
                            variant="outlined"
                            className="yonder-upload-avatar__input"
                            label={t('workflow.tools.attachment.uploadAttachment.input')}
                            fullWidth={true}
                            onClick={handleInputClick}
                            onKeyDown={handleInputChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={showError}
                            helperText={showError ? errorText : null}
                        />
                    </Grid>
                </Grid>
            }
            primaryAction={t('workflow.tools.attachment.uploadAttachment.upload')}
            isPrimaryActionDisabled={showError}
            isRunning={props.isUploading}
            secondaryAction={t('workflow.tools.attachment.uploadAttachment.clear')}
            isSecondaryActionsDisabled={filename === ''}
            onConfirm={upload}
            onCancel={clear}
            onClose={close}
        />
    );
};
