import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';
import { IWorkflowFormField, CloseButton, YonderButton } from '@yonder-mind/ui-core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { IFormValues } from '../../interfaces';
import { Form } from './Form';
import { FormFields } from './FormFields';

interface IProps {
    open?: boolean;
    title: string;

    fields: IWorkflowFormField[];

    injectedFields?: IFormValues;

    onSubmit: (values: IFormValues) => any;
    onCancel: () => any;

    size?: 'sm' | 'md' | 'lg';
}

export const WorkflowForm: React.FC<IProps> = (props) => {
    const { t } = useTranslation();

    const [injectedValues] = React.useState<IFormValues>({
        ...(props.injectedFields || {}),
    });

    const handleSubmit = (values: IFormValues) => {
        props.onSubmit(values);
    };

    const cancel = () => {
        props.onCancel();
    };

    return (
        <Dialog open={!!props.open} maxWidth={props.size || 'sm'} fullWidth={true} role="modal" data-testid="modal">
            <Form fields={props.fields} injectedValues={injectedValues} onSubmit={handleSubmit}>
                {(form, submitProps) => (
                    <>
                        <DialogTitle disableTypography={true} data-testid="modalHeader">
                            <Grid container={true} alignItems="center">
                                <Typography variant="h6">{props.title}</Typography>
                                <div className="grow" />
                                <CloseButton onClick={cancel} size="small" data-testid="modalCloseButton" />
                            </Grid>
                        </DialogTitle>
                        <DialogContent data-testid="modalContent">
                            <FormFields {...form} />
                        </DialogContent>
                        <DialogActions data-testid="modalActions">
                            <YonderButton variant="text" onClick={cancel} data-testid="modalCancelButton">
                                {t('form.actions.cancel')}
                            </YonderButton>

                            <YonderButton {...submitProps} variant="contained" data-testid="modalConfirmationButton">
                                {t('form.actions.submit')}
                            </YonderButton>
                        </DialogActions>
                    </>
                )}
            </Form>
        </Dialog>
    );
};
