import { Checkbox, TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowUpward } from '@material-ui/icons';

interface IProps {
    handleToggleSelectAllItems: () => void;
    allItemsAreSelected: boolean;
    sortingConfiguration: SortingConfiguration;
    setSortingConfiguration: (sortingConfiguration: SortingConfiguration) => void;
    disableMultiSelect?: boolean;
    withUploadDate?: boolean;
}

type ColumnKey = 'name' | 'startDate' | 'endDate' | 'uploadDate';
type Order = 'asc' | 'desc';

export interface SortingConfiguration {
    order: Order;
    orderBy: ColumnKey;
}

export const FileDropTableHead: React.FC<IProps> = (props) => {
    const {
        handleToggleSelectAllItems,
        allItemsAreSelected,
        sortingConfiguration,
        setSortingConfiguration,
        disableMultiSelect,
        withUploadDate,
    } = props;
    const { t } = useTranslation();

    const handleSortClick = (columnKey: ColumnKey) => {
        if (sortingConfiguration.orderBy === columnKey) {
            setSortingConfiguration({
                order: sortingConfiguration.order === 'asc' ? 'desc' : 'asc',
                orderBy: columnKey,
            });
        } else {
            setSortingConfiguration({
                order: 'asc',
                orderBy: columnKey,
            });
        }
    };

    const getColumnDirection = (columnKey: ColumnKey): Order => {
        if (sortingConfiguration.orderBy === columnKey) {
            return sortingConfiguration.order;
        }
        return 'asc';
    };

    const renderSortableHeaderCell = (columnKey: ColumnKey, label: string, className: string) => {
        const isActive = (columKey: ColumnKey) => sortingConfiguration.orderBy === columKey;
        const getClassName = `${className ? className : ''}${isActive(columnKey) ? ' table-head-active' : ''}`;

        return (
            <TableCell className={getClassName}>
                <TableSortLabel
                    onClick={() => handleSortClick(columnKey)}
                    direction={getColumnDirection(columnKey)}
                    active={isActive(columnKey)}
                    IconComponent={ArrowUpward}
                >
                    {label}
                </TableSortLabel>
            </TableCell>
        );
    };

    return (
        <TableHead>
            <TableRow>
                {!disableMultiSelect && (
                    <TableCell className="checkbox-column-header">
                        <Checkbox onClick={handleToggleSelectAllItems} checked={allItemsAreSelected} color="primary" />
                    </TableCell>
                )}
                <TableCell className="type-column-header">{t('fileDrop.file.type')}</TableCell>
                {renderSortableHeaderCell('name', t('fileDrop.file.name'), 'name-column-header')}
                {renderSortableHeaderCell('startDate', t('fileDrop.file.startDate'), 'start-date-column-header')}
                {renderSortableHeaderCell('endDate', t('fileDrop.file.endDate'), 'end-date-column-header')}
                {withUploadDate &&
                    renderSortableHeaderCell('uploadDate', t('fileDrop.file.uploadDate'), 'upload-date-column-header')}
                <TableCell />
            </TableRow>
        </TableHead>
    );
};
