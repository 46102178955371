import { ApiConstants } from '../../utils/ApiConstants';

export class FlashCardConstants extends ApiConstants {
    static getFlashcards(moduleOid: string): URL {
        return ApiConstants.relativeUrl(`/flash-cards/${moduleOid}`);
    }

    static deleteFlashcard(moduleOid: string, flashcardOid: string): URL {
        return ApiConstants.relativeUrl(`/flash-cards/${moduleOid}/${flashcardOid}`);
    }

    static getFlashcardSets(): URL {
        return ApiConstants.relativeUrl('/flash-cards/sets');
    }

    static saveFlashcardSet(): URL {
        return ApiConstants.relativeUrl('/flash-cards/set');
    }

    static deleteFlashcardSet(flashcardSetOid: string): URL {
        return ApiConstants.relativeUrl(`/flash-cards/set/${flashcardSetOid}`);
    }

    static getPlayFlashcardSet(flashcardSetOid: string): URL {
        return ApiConstants.relativeUrl(`/flash-cards/play/from-set/${flashcardSetOid}`);
    }

    static answerFlashcard(): URL {
        return ApiConstants.relativeUrl('/flash-cards/play/answer');
    }
}
