import React from 'react';
import { YonderButton } from '@yonder-mind/ui-core';
import { DropzoneInputProps } from 'react-dropzone';

export const FileDropHeader: React.FC<{
    openFileSelect: () => void;
    title: string;
    getInputProps: <T extends DropzoneInputProps>(props?: T) => T;
}> = ({ openFileSelect, title, getInputProps }) => {
    return (
        <div className="file-drop-header">
            <input {...getInputProps()} />
            <label htmlFor="import-pdf-button" data-testid="importPdfButton">
                <YonderButton onClick={openFileSelect} variant="outlined">
                    {title}
                </YonderButton>
            </label>
        </div>
    );
};
