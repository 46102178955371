import { createAction } from 'typesafe-actions';
import {
    ComplianceTableItem,
    ChangesTableItem,
    UsersTableItem,
    Preset,
} from '../../container/ComplianceReports/domain/types';
import { DropdownType, ApiComplianceReportsFilterConfig, ComplianceReportsFilterConfig } from '../../interfaces';
import { TagDomain, ListItem } from '@yonder-mind/ui-core';

export const setSelectedDropdown = createAction('SET_SELECTED_DROPDOWN', (selectedDropdown: DropdownType) => ({
    selectedDropdown: selectedDropdown,
}))();

export const clearFilterConfig = createAction('CLEAR_FILTER_CONFIG', (status: string) => status)();
export const clearChangesFilterConfig = createAction('CLEAR_CHANGES_FILTER_CONFIG', (status: string) => status)();

export const updateFilterConfigStatus = createAction('UPDATE_FILTER_CONFIG_STATUS', (payload: string) => payload)();

export const updateFilterConfigSelectedDocumentIds = createAction(
    'UPDATE_FILTER_CONFIG_SELECTED_DOCUMENT_IDS',
    (selectedDocumentIds: string[]) => ({ selectedDocumentIds })
)();

export const updateFilterConfigSelectedUserIds = createAction(
    'UPDATE_FILTER_CONFIG_SELECTED_USER_IDS',
    (selectedUserIds: string[]) => ({ selectedUserIds })
)();

export const loadCompliancePreset = createAction(
    'LOAD PRESET',
    (config: ComplianceReportsFilterConfig, date: string) => {
        return { config, date };
    }
)();

export const updateFilterConfigSelectedRoleIds = createAction(
    'UPDATE_FILTER_CONFIG_SELECTED_ROLE_IDS',
    (selectedRoleIds: string[]) => ({ selectedRoleIds })
)();

export const updateFilterConfigSelectedTagIds = createAction(
    'UPDATE_FILTER_CONFIG_SELECTED_TAG_IDS',
    (selectedTagIds: string[]) => ({ selectedTagIds })
)();

export const complianceReportsTagsRequested = createAction('COMPLIANCE_REPORTS_TAGS_REQUESTED')();

export const complianceReportsTagsReceived = createAction(
    'COMPLIANCE_REPORTS_TAGS_RECEIVED',
    (tagDomains: TagDomain[]) => ({ tagDomains })
)();

export const complianceReportsTagsError = createAction(
    'COMPLIANCE_REPORTS_TAGS_RECEIVED_ERROR',
    (error: any) => error
)();

export const complianceReportsRolesRequested = createAction('COMPLIANCE_REPORTS_ROLES_REQUESTED')();

export const complianceReportsRolesReceived = createAction(
    'COMPLIANCE_REPORTS_ROLES_RECEIVED',
    (roles: string[]) => roles
)();

export const complianceReportsRolesError = createAction(
    'COMPLIANCE_REPORTS_ROLES_RECEIVED_ERROR',
    (error: any) => error
)();

export const complianceReportsSelectableDocumentsRequested = createAction(
    'COMPLIANCE_REPORTS_SELECTABLE_DOCUMENTS_REQUESTED',
    (status: string, effectiveAt?: string) => ({ status, effectiveAt })
)();

export const complianceReportsSelectableDocumentsReceived = createAction(
    'COMPLIANCE_REPORTS_SELECTABLE_DOCUMENTS_RECEIVED',
    (documents: ListItem[]) => documents
)();

export const complianceReportsSelectableDocumentsError = createAction(
    'COMPLIANCE_REPORTS_SELECTABLE_DOCUMENTS_RECEIVED_ERROR',
    (error: any) => error
)();

export const complianceDocumentsRequested = createAction(
    'COMPLIANCE DOCUMENTS REQUESTED',
    (config: ApiComplianceReportsFilterConfig) => config
)();

export const complianceDocumentsReceived = createAction(
    'COMPLIANCE DOCUMENTS RECEIVED',
    (documents: ComplianceTableItem[], config: ComplianceReportsFilterConfig, date: string) => {
        return { documents, config, date };
    }
)();

export const complianceDocumentsError = createAction('COMPLIANCE DOCUMENTS ERROR', (error: any) => error)();

export const clearComplianceDocuments = createAction('CLEAR_COMPLIANCE_DOCUMENTS')();

export const complianceChangesRequested = createAction(
    'COMPLIANCE CHANGES REQUESTED',
    (contextVersionOid: string, config: ApiComplianceReportsFilterConfig) => ({ contextVersionOid, config })
)();

export const complianceChangesReceived = createAction(
    'COMPLIANCE CHANGES RECEIVED',
    (changes: ChangesTableItem[], contextVersionOid: string) => {
        return { changes, contextVersionOid };
    }
)();

export const complianceChangesError = createAction('COMPLIANCE CHANGES ERROR', (error: any) => error)();

export const complianceUsersRequested = createAction(
    'COMPLIANCE USERS REQUESTED',
    (payload: { changeOid: string; role: string; tagOids?: string[]; users?: string[] }) => payload
)();

export const complianceUsersReceived = createAction(
    'COMPLIANCE USERS RECEIVED',
    (users: UsersTableItem[], oid: string) => {
        return { users, oid };
    }
)();

export const complianceUsersError = createAction('COMPLIANCE USERS ERROR', (error: any) => error)();

export const complianceFileUserStatusesRequested = createAction(
    'COMPLIANCE FILE USER STATUSES REQUESTED',
    (payload: { fileRevisionOid: string; role?: string; tagOids?: string[]; users?: string[] }) => payload
)();

export const setDateToGetComplianceFilterDocuments = createAction(
    'SET DATE TO GET COMPLIANCE FILTER DOCUMENTS',
    (effectiveAt: string, allEffectiveInFuture: boolean) => ({ effectiveAt, allEffectiveInFuture })
)();

export const compliancePresetsRequested = createAction('COMPLIANCE PRESETS REQUESTED', (userOid: string) => userOid)();

export const compliancePresetsReceived = createAction('COMPLIANCE PRESETS RECEIVED', (presets: Preset[]) => presets)();

export const compliancePresetsError = createAction('COMPLIANCE PRESETS ERROR', (error: any) => error)();

export const setActiveCompliancePreset = createAction('SET ACTIVE COMPLIANCE PRESET', (name: string) => name)();

export const saveCompliancePresetsRequested = createAction(
    'SAVE COMPLIANCE PRESETS REQUESTED',
    (presets: Preset, successAction: (presetOid: string) => void, errorAction: () => void) => ({
        presets,
        successAction,
        errorAction,
    })
)();

export const saveCompliancePresetsSuccess = createAction(
    'SAVE COMPLIANCE PRESETS SUCCESS',
    (presets: Preset) => presets
)();

export const saveCompliancePresetsError = createAction(
    'SAVE COMPLIANCE PRESETS ERROR',
    (presets: Preset[]) => presets
)();

export const deleteCompliancePresetRequested = createAction(
    'DELETE COMPLIANCE PRESET REQUESTED',
    (oid: string, successAction: () => void) => ({ oid, successAction })
)();

export const deleteCompliancePresetsSuccess = createAction('DELETE COMPLIANCE PRESET SUCCESS', (oid: string) => oid)();

export const exportComplianceCsvRequested = createAction(
    'EXPORT COMPLIANCE CSV REQUESTED',
    (payload: {
        contextVersionOids: string[];
        status: string;
        role: string[];
        date?: string;
        tagOids?: string[];
        users?: string[];
        errorAction: () => void;
    }) => payload
)();

export const exportComplianceCsvSuccess = createAction('EXPORT COMPLIANCE CSV SUCCESS')();

export const exportComplianceCsvError = createAction('EXPORT COMPLIANCE CSV ERROR')();
