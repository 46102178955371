import { ActionType, getType } from 'typesafe-actions';
import { IChangeRequestStore } from '../../../interfaces/store/workflow';
import * as actions from './cr-actions';

export type ChangeRequestActions = ActionType<typeof actions>;

const initialState: IChangeRequestStore = {
    isLoading: true,
    isLoadingImportJobHasChanges: false,
    userCR: [],
    editCR: {},
    apiErrors: [],
    draftSaveError: false,
    draftErrorSnackVisible: false,
    draftSaved: false,
    editDraftSuccess: false,
    draftContentError: null,
    finishedLoadingDraftContent: false,
    importJobHasChanges: false,
    finishedCRs: [],
    activeCRs: [],
    isLoadingActiveCRs: false,
    isLoadingFinishedCRs: false,
    currentCR: null,
    isLoadingModuleCRs: false,
    isLoadingSaveCr: false,
    moduleCRs: [],
    calculatedDiff: null,
};

export default function changeRequestReducer(
    state: IChangeRequestStore = initialState,
    action: ChangeRequestActions
): IChangeRequestStore {
    switch (action.type) {
        case getType(actions.allChangeRequestsOfUserRequested):
            return {
                ...state,
                isLoading: true,
            };
        case getType(actions.allChangeRequestsOfUserReceived):
            return {
                ...state,
                isLoading: false,
                userCR: action.payload.changeRequests,
            };

        case getType(actions.addChangeRequestCompleted):
            return {
                ...state,
                isLoading: false,
            };

        case getType(actions.contextVersionImportJobHasChangesRequested):
            return {
                ...state,
                importJobHasChanges: false,
                isLoadingImportJobHasChanges: true,
            };
        case getType(actions.contextVersionImportJobHasChangesReceived):
            return {
                ...state,
                importJobHasChanges: action.payload.hasChanges,
                isLoadingImportJobHasChanges: false,
            };

        case getType(actions.changeRequestUpdatesReceived): {
            if (state.currentCR.processInstanceId === action.payload.processInstanceId) {
                return {
                    ...state,
                    currentCR: action.payload.activity,
                };
            }
            return state;
        }

        case getType(actions.changeRequestVariableUpdatesReceived): {
            if (state.currentCR.processInstanceId === action.payload.processInstanceId) {
                return {
                    ...state,
                    currentCR: {
                        ...state.currentCR,
                        variables: { ...state.currentCR.variables, ...action.payload.updates },
                    },
                };
            }
            return state;
        }

        case getType(actions.newChangeRequestFormFieldsReceived):
            return {
                ...state,
                startingFormFields: {
                    ...state.startingFormFields,
                    [action.payload.workflowId]: action.payload.fields,
                },
            };

        case getType(actions.editChangeRequestContentReceived):
            return {
                ...state,
                finishedLoadingDraftContent: true,
                draftContentError: null,
                editCR: {
                    ...state.editCR,
                    [action.payload.processInstanceId]: {
                        ...(state.editCR[action.payload.processInstanceId] || {}),
                        content: action.payload.content,
                        importJobLock: undefined,
                    },
                },
                isLoadingSaveCr: false,
            };

        case getType(actions.editChangeRequestContentError):
            return {
                ...state,
                finishedLoadingDraftContent: true,
                draftContentError: action.payload,
            };

        case getType(actions.peekEditingChangeRequestTitleReceived):
            return {
                ...state,
                editCR: {
                    ...state.editCR,
                    [action.payload.processInstanceId]: {
                        ...(state.editCR[action.payload.processInstanceId] || {}),
                        title: action.payload.title,
                        importJobLock: undefined,
                    },
                },
            };

        case getType(actions.editChangeRequestTitleReceived):
            return {
                ...state,
                editCR: {
                    ...state.editCR,
                    [action.payload.processInstanceId]: {
                        ...(state.editCR[action.payload.processInstanceId] || {}),
                        title: action.payload.title,
                        importJobLock: undefined,
                    },
                },
            };

        // Not used anymore
        case getType(actions.endEditingChangeRequest):
            return {
                ...state,
                editCR: {
                    ...state.editCR,
                    [action.payload.processInstanceId]: {
                        ...(state.editCR[action.payload.processInstanceId] || {}),
                        importJobLock: undefined,
                    },
                },
            };

        case getType(actions.cancelEditCRContentReceived):
            return {
                ...state,
                editCR: {
                    ...state.editCR,
                    [action.payload.processInstanceId]: {
                        ...(state.editCR[action.payload.processInstanceId] || {}),
                        importJobLock: undefined,
                    },
                },
            };

        case getType(actions.cancelEditCRTitleReceived):
            return {
                ...state,
                editCR: {
                    ...state.editCR,
                    [action.payload.processInstanceId]: {
                        ...(state.editCR[action.payload.processInstanceId] || {}),
                        importJobLock: undefined,
                    },
                },
            };

        case getType(actions.changeRequestEditLockReceived):
            return {
                ...state,
                editCR: {
                    ...state.editCR,
                    [action.payload.processInstanceId]: {
                        ...(state.editCR[action.payload.processInstanceId] || {}),
                        importJobLock: action.payload.importJobLock,
                    },
                },
            };
        case getType(actions.apiErrorReceived):
            return { ...state, apiErrors: [...state.apiErrors, action.payload.error], isLoading: false };
        case getType(actions.apiErrorReset):
            return { ...state, apiErrors: action.payload.errors, isLoading: false };
        case getType(actions.saveDraftErrorReceived):
            return {
                ...state,
                draftSaveError: action.payload.error,
                draftErrorSnackVisible: action.payload.error,
                draftSaved: false,
                isLoadingSaveCr: false,
            };
        case getType(actions.closeDraftErrorSnack):
            return {
                ...state,
                draftErrorSnackVisible: action.payload.error,
                draftSaved: false,
                editDraftSuccess: false,
            };
        case getType(actions.crContentSaved):
            return {
                ...state,
                draftSaveError: !action.payload,
                draftErrorSnackVisible: !action.payload,
                draftSaved: action.payload,
            };
        case getType(actions.resetDraftErrors):
            return {
                ...state,
                draftSaveError: false,
                draftErrorSnackVisible: false,
                draftSaved: false,
                editDraftSuccess: false,
            };
        case getType(actions.editDraftSuccessRequested):
            return {
                ...state,
                editDraftSuccess: action.payload,
            };

        case getType(actions.getChangeRequestsForDraftDoc): {
            return {
                ...state,
                isLoadingActiveCRs: true,
                activeCRs: [],
            };
        }

        case getType(actions.getChangeRequestsForReleasedDoc): {
            return {
                ...state,
                isLoadingActiveCRs: true,
                activeCRs: [],
            };
        }

        case getType(actions.getChangeRequestsSuccess): {
            return {
                ...state,
                isLoadingActiveCRs: false,
                activeCRs: action.payload,
            };
        }

        case getType(actions.getFinishedChangeRequests): {
            return {
                ...state,
                isLoadingFinishedCRs: true,
                finishedCRs: [],
            };
        }

        case getType(actions.getFinishedChangeRequestsSuccess): {
            return {
                ...state,
                isLoadingFinishedCRs: false,
                finishedCRs: action.payload,
            };
        }

        case getType(actions.getFinishedChangeRequestsError): {
            return {
                ...state,
                isLoadingFinishedCRs: false,
            };
        }

        case getType(actions.getChangeRequestsError): {
            return {
                ...state,
                isLoadingActiveCRs: false,
            };
        }

        case getType(actions.getChangeRequest): {
            return {
                ...state,
                isLoading: true,
                currentCR: null,
            };
        }

        case getType(actions.getChangeRequestSuccess): {
            return {
                ...state,
                isLoading: false,
                currentCR: action.payload,
            };
        }

        case getType(actions.getChangeRequestError): {
            return {
                ...state,
                isLoading: false,
            };
        }

        case getType(actions.getChangeRequestsForModule): {
            return {
                ...state,
                isLoadingModuleCRs: true,
                moduleCRs: [],
            };
        }

        case getType(actions.getChangeRequestsForModuleSuccess): {
            return {
                ...state,
                isLoadingModuleCRs: false,
                moduleCRs: action.payload,
            };
        }

        case getType(actions.getChangeRequestsForModuleError): {
            return {
                ...state,
                isLoadingModuleCRs: false,
            };
        }
        case getType(actions.calculatedDiffRequested): {
            return {
                ...state,
                calculatedDiff: null,
            };
        }
        case getType(actions.calculatedDiffClear): {
            return {
                ...state,
                calculatedDiff: null,
            };
        }
        case getType(actions.calculatedDiffReceived): {
            return {
                ...state,
                calculatedDiff: action.payload.contentDiff,
            };
        }

        case getType(actions.saveChangeRequestContent): {
            return {
                ...state,
                isLoadingSaveCr: true,
            };
        }

        default:
            return state;
    }
}
