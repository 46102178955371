import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SnackbarKey, useSnackbar } from 'notistack';
import { formActions, FormSubmissionTableItem, SnackBarCloseButton, Spinner, useHistory } from '@yonder-mind/ui-core';
import { IWebApplicationStore } from '../../interfaces';
import { SubmissionsTable } from '../../components/Form/SubmissionsTable';
import { SubmissionsSearchBar } from '../../components/Form/SubmissionsTable/SubmissionsSearchBar';
import { mapFormSubmissionList } from '../../components/Form/utils/FormUtils';

export const MySubmissionsBoard: React.FC = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [formSubmissionList, setFormSubmissionList] = useState<FormSubmissionTableItem[]>([]);
    const dispatch = useDispatch();
    const { pushUrl } = useHistory();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        dispatch(
            formActions.allFormSubmissionsRequested(() =>
                enqueueSnackbar(t('form.messages.error.generalError'), {
                    action: (key: SnackbarKey) => <SnackBarCloseButton snackbarKey={key} />,
                    variant: 'error',
                    persist: true,
                    preventDuplicate: true,
                })
            )
        );
    }, []);

    const { formSubmissions, isLoading } = useSelector((state: IWebApplicationStore) => {
        return {
            ...state.form,
        };
    });

    useEffect(() => {
        if (formSubmissions) {
            setFormSubmissionList(mapFormSubmissionList(formSubmissions, null));
        }
    }, [formSubmissions]);

    const filteredSubmissionsList = useMemo(
        () =>
            formSubmissionList.filter(
                (formSubmission) =>
                    formSubmission.formVersionTitle?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    formSubmission.summary?.toLowerCase().includes(searchTerm.toLowerCase())
            ),
        [formSubmissionList, searchTerm]
    );

    const openSubmission = (oid: string, formVersionOid: string) => {
        pushUrl(`/form/${formVersionOid}/${oid}`, [{ key: 'sidebar-tool', value: 'form-navigation' }]);
    };

    const onSearch = (searchValue: string) => {
        if (searchValue !== searchTerm) {
            setSearchTerm(searchValue);
        }
    };

    const onSearchCleared = () => {
        setSearchTerm('');
    };

    const isSubmissionsListEmpty = useMemo(() => {
        return filteredSubmissionsList.length === 0;
    }, [filteredSubmissionsList]);

    return (
        <div className="submissions-table-wrapper">
            {isLoading ? (
                <div className="submissions-filter-no-items-wrapper">
                    <div className="submissions-no-filter-applied">
                        <Spinner />
                    </div>
                </div>
            ) : (
                <>
                    <SubmissionsSearchBar
                        isResultsListEmpty={isSubmissionsListEmpty}
                        onSearchCleared={onSearchCleared}
                        onInputChanged={onSearch}
                        label={t('form.search.label')}
                        focus
                    />
                    {filteredSubmissionsList.length === 0 ? (
                        <div className="submissions-filter-no-items-wrapper">
                            <p>{t('form.submissionTable.noResults')}</p>
                        </div>
                    ) : (
                        <SubmissionsTable
                            formSubmissionsList={filteredSubmissionsList}
                            openSubmission={openSubmission}
                        />
                    )}
                </>
            )}
        </div>
    );
};
