import { List, ListItem, ListItemText } from '@material-ui/core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner, Divider, DateUtils, combineStrings, toCapitalCase } from '@yonder-mind/ui-core';
import { IVariableGroup } from '../../../interfaces';
import { WorkflowTool } from './WorkflowTool';
import { useWorkflow } from '../../../context';

interface IGroupedVarsListProps {
    type: 'revision' | 'change-request';
    processInstanceId?: string;
    hiddenVars?: string[];
}

export const GroupedVarsList: React.FC<IGroupedVarsListProps> = ({ type, processInstanceId, hiddenVars = [] }) => {
    const { actions: processActions, variablesGrouped: processVariablesGroupedByProcessId } = useWorkflow('process');
    const { t } = useTranslation();

    const variables =
        processInstanceId &&
        processVariablesGroupedByProcessId &&
        processVariablesGroupedByProcessId[processInstanceId];

    React.useEffect(() => {
        if (processInstanceId && variables === undefined) {
            processActions.requestProcessVariablesGrouped(processInstanceId);
        }
    }, [processInstanceId, variables]);

    if (!processInstanceId || !variables) {
        return <Spinner />;
    }

    const getTypeName = () => {
        switch (type) {
            case 'change-request':
                return 'changeRequest';
            case 'revision':
                return 'revision';
            default:
                return type;
        }
    };

    const getFieldName = (field: string) => {
        return combineStrings(
            field
                .toLowerCase()
                .split('_')
                .map((key) => toCapitalCase(key))
        );
    };

    return (
        <WorkflowTool tool={`overview`}>
            <List disablePadding={true}>
                {variables.tasks.map((variableTask: IVariableGroup, index) => {
                    const displayedTaskNames = ['Title Change Request', 'Delete Module Change Request'];
                    if (displayedTaskNames.includes(variableTask.taskName) && variableTask.vars.length === 0) {
                        variableTask.vars.push({
                            name: 'TEXT_PROPOSAL',
                            value: variables.allVars.TEXT_PROPOSAL,
                        });
                    }
                    const vars = variableTask.vars.filter((item) => !hiddenVars.includes(item.name));

                    return vars.length === 0 ? null : (
                        <React.Fragment key={index}>
                            <ListItem className={`overview-variable`}>
                                <h2>{variableTask.taskName}</h2>
                            </ListItem>
                            <Divider className="overview-variable-divider" />
                            <List disablePadding={true}>
                                {vars.map((item, indexd) => (
                                    <React.Fragment key={indexd}>
                                        <ListItem className={`overview-variable`}>
                                            <ListItemText
                                                className="overview-variable-name"
                                                children={t([
                                                    `workflow.${getTypeName()}.variables.${item.name.toUpperCase()}`,
                                                    getFieldName(item.name),
                                                ])}
                                            />
                                            <ListItemText
                                                className="overview-variable-value"
                                                children={DateUtils.parseToDate(item.value) as string}
                                            />
                                        </ListItem>
                                        <Divider className="overview-variable-divider" />
                                    </React.Fragment>
                                ))}
                            </List>
                        </React.Fragment>
                    );
                })}
            </List>
        </WorkflowTool>
    );
};
