import { IApiTagDomain, TagDomain, ListItem } from '@yonder-mind/ui-core';
import { call, put, takeLeading } from 'redux-saga/effects';
import { getType, ActionType } from 'typesafe-actions';
import * as actions from './compliance-reports-actions';
import { mapToTagDomain } from '../../utils/ResultMapper';
import { RestComplianceReportsApi } from '../../api/rest-compliance-reports-api';
import {
    ComplianceTableItem,
    ChangesTableItem,
    UsersTableItem,
    Preset,
} from '../../container/ComplianceReports/domain/types';

function* complianceReportsTagsRequested(api: RestComplianceReportsApi) {
    try {
        const apiTagDomains: IApiTagDomain[] = yield call(() => api.getAllTagDomainsForComplianceReports());
        const tagDomains: TagDomain[] = apiTagDomains.map(mapToTagDomain);
        yield put(actions.complianceReportsTagsReceived(tagDomains));
    } catch (error) {
        yield put(actions.complianceReportsTagsError(error));
    }
}

function* complianceReportsRolesRequested(api: RestComplianceReportsApi) {
    try {
        const roles: string[] = yield call(() => api.getAllRolesForComplianceReports());
        yield put(actions.complianceReportsRolesReceived(roles));
    } catch (error) {
        yield put(actions.complianceReportsRolesError(error));
    }
}

function* complianceReportsDocumentsRequested(
    api: RestComplianceReportsApi,
    action: ActionType<typeof actions.complianceDocumentsRequested>
) {
    try {
        const documents: ComplianceTableItem[] = yield call(() => api.getComplianceDocuments(action.payload));

        const { date, selectedDocumentIds, selectedRoleIds, selectedTagIds, selectedUserIds, status } = action.payload;

        yield put(
            actions.complianceDocumentsReceived(
                documents,
                { selectedDocumentIds, selectedRoleIds, selectedTagIds, selectedUserIds, status },
                date
            )
        );
    } catch (error) {
        yield put(actions.complianceDocumentsError(error));
    }
}

function* complianceReportsChangesRequested(
    api: RestComplianceReportsApi,
    action: ActionType<typeof actions.complianceChangesRequested>
) {
    try {
        const { contextVersionOid, config } = action.payload;
        const changes: ChangesTableItem[] = yield call(() => api.getComplianceChanges(contextVersionOid, config));

        yield put(actions.complianceChangesReceived(changes, contextVersionOid));
    } catch (error) {
        console.log('error', error);
        yield put(actions.complianceChangesError(error));
    }
}

function* complianceReportsUsersRequested(
    api: RestComplianceReportsApi,
    action: ActionType<typeof actions.complianceUsersRequested>
) {
    try {
        const users: UsersTableItem[] = yield call(() =>
            api.getComplianceUsers(
                action.payload.changeOid,
                action.payload.role,
                action.payload.tagOids,
                action.payload.users
            )
        );

        yield put(actions.complianceUsersReceived(users, action.payload.changeOid + action.payload.role));
    } catch (error) {
        console.log('error', error);
        yield put(actions.complianceUsersError(error));
    }
}

function* complianceReportsFileUserStatusesRequested(
    api: RestComplianceReportsApi,
    action: ActionType<typeof actions.complianceFileUserStatusesRequested>
) {
    try {
        const users: UsersTableItem[] = yield call(() =>
            api.getComplianceFileUserStatuses(
                action.payload.fileRevisionOid,
                action.payload.role,
                action.payload.tagOids,
                action.payload.users
            )
        );

        yield put(actions.complianceUsersReceived(users, `${action.payload.fileRevisionOid}${action.payload.role}`));
    } catch (error) {
        yield put(actions.complianceUsersError(error));
    }
}

function* complianceReportsSelectableDocumentsRequested(
    api: RestComplianceReportsApi,
    action: ActionType<typeof actions.complianceReportsSelectableDocumentsRequested>
) {
    const { status, effectiveAt } = action.payload;
    try {
        const documents: ListItem[] = yield call(() => api.getFilterDocuments(status, effectiveAt));
        yield put(actions.complianceReportsSelectableDocumentsReceived(documents));
    } catch (error) {
        yield put(actions.complianceReportsSelectableDocumentsError(error));
    }
}

function* complianceReportsPresetsRequested(
    api: RestComplianceReportsApi,
    action: ActionType<typeof actions.compliancePresetsRequested>
) {
    const userOid = action.payload;
    try {
        const presets: Preset[] = yield call(() => api.getComplianceReportsPresets(userOid));

        yield put(actions.compliancePresetsReceived(presets));
    } catch (error) {
        console.log('error', error);
        yield put(actions.compliancePresetsError(error));
    }
}

function* complianceReportsPresetsLoad(
    api: RestComplianceReportsApi,
    action: ActionType<typeof actions.loadCompliancePreset>
) {
    try {
        const documents: ComplianceTableItem[] = yield call(() =>
            api.getComplianceDocuments({ ...action.payload.config, date: action.payload.date })
        );

        yield put(actions.complianceDocumentsReceived(documents, action.payload.config, action.payload.date));
    } catch (error) {
        console.log('error', error);
        yield put(actions.compliancePresetsError(error));
    }
}

function* saveComplianceReportsPreset(
    api: RestComplianceReportsApi,
    action: ActionType<typeof actions.saveCompliancePresetsRequested>
) {
    try {
        const savedPreset: Preset = yield call(() => api.saveComplianceReportsPreset(action.payload.presets));

        yield put(actions.saveCompliancePresetsSuccess(savedPreset));
        action.payload.successAction(savedPreset.oid);
    } catch (error) {
        console.log('error', error);
        action.payload.errorAction();
        yield put(actions.compliancePresetsError(error));
    }
}

function* deleteComplianceReportsPreset(
    api: RestComplianceReportsApi,
    action: ActionType<typeof actions.deleteCompliancePresetRequested>
) {
    try {
        yield call(() => api.deleteComplianceReportsPreset(action.payload.oid));
        yield put(actions.deleteCompliancePresetsSuccess(action.payload.oid));
        action.payload.successAction();
    } catch (error) {
        console.log('error', error);
        yield put(actions.compliancePresetsError(error));
    }
}

function* exportComplianceCsvRequested(
    api: RestComplianceReportsApi,
    action: ActionType<typeof actions.exportComplianceCsvRequested>
) {
    const { contextVersionOids, status, role, date, tagOids, users, errorAction } = action.payload;
    try {
        yield call(() => api.exportComplianceCsv(contextVersionOids, status, role, date, tagOids, users));
        yield put(actions.exportComplianceCsvSuccess());
    } catch (error) {
        errorAction();
        yield put(actions.exportComplianceCsvError());
        console.log('error', error);
    }
}

export default function* complianceReportsSagas(api: RestComplianceReportsApi) {
    yield takeLeading(getType(actions.complianceReportsTagsRequested), complianceReportsTagsRequested, api);
    yield takeLeading(getType(actions.complianceReportsRolesRequested), complianceReportsRolesRequested, api);
    yield takeLeading(getType(actions.complianceDocumentsRequested), complianceReportsDocumentsRequested, api);
    yield takeLeading(getType(actions.complianceChangesRequested), complianceReportsChangesRequested, api);
    yield takeLeading(getType(actions.complianceUsersRequested), complianceReportsUsersRequested, api);
    yield takeLeading(
        getType(actions.complianceFileUserStatusesRequested),
        complianceReportsFileUserStatusesRequested,
        api
    );

    yield takeLeading(
        getType(actions.complianceReportsSelectableDocumentsRequested),
        complianceReportsSelectableDocumentsRequested,
        api
    );

    yield takeLeading(getType(actions.compliancePresetsRequested), complianceReportsPresetsRequested, api);
    yield takeLeading(getType(actions.loadCompliancePreset), complianceReportsPresetsLoad, api);
    yield takeLeading(getType(actions.saveCompliancePresetsRequested), saveComplianceReportsPreset, api);
    yield takeLeading(getType(actions.deleteCompliancePresetRequested), deleteComplianceReportsPreset, api);

    yield takeLeading(getType(actions.exportComplianceCsvRequested), exportComplianceCsvRequested, api);
}
