import { History } from 'history';
import * as React from 'react';
import { Provider } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import { Store } from 'redux';
import {
    AuthenticationLayer,
    ConnectedSidebarWrapperProvider,
    Memo,
    MuiProvider,
    NotificationSnackBar,
    ScreenOrientationUtils,
} from '@yonder-mind/ui-core';
import 'array-flat-polyfill';
import { Dashboard } from './Dashboard/Dashboard';
import DocumentDetail from './DocumentDetail/DocumentDetail';
import PlayFlashcardSet from './PlayFlashcardSet/PlayFlashcardSet';
import Search from './Search/Search';
import SearchResult from './SearchResult/SearchResult';
import PrintRevision from './PrintRevision/PrintRevision';
import { ChangeRequest } from './Workflow/ChangeRequest';
import { DocumentRevision } from './Workflow/DocumentRevision';
import { Form } from './Form';
import { FileDrop } from './FileDrop/FileDrop';
import { ConnectedRouter } from 'connected-react-router';
import { SnackbarProvider } from 'notistack';
import { Persistor } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { ComplianceReports } from './ComplianceReports/ComplianceReports';
import { DocumentChanges } from './ComplianceReports/DocumentChanges';
import ModuleDiffing from './ModuleDiffing/ModuleDiffing';
import { Storybooklet } from '../storybooklet/Storybooklet';
import { TransferHighlights } from './TransferHighlights/TransferHighlights';
import { MySubmissions } from './MySubmissions/MySubmissions';
import { ManageSubmissionsTable } from './ManageSubmissionsTable/ManageSubmissionsTable';
import { ManageSubmission } from './ManageSubmission';
import { CompareRevisionsLayout } from './CompareRevisions/CompareRevisionsLayout';
import { GlobalHistoryLogLayout } from './GlobalHistoryLog/GlobalHistoryLogLayout';

interface IRootContainerProps {
    store: Store<any>;
    history: History;
    persistor: Persistor;
}

const AUTO_HIDE_DURATION_IN_MILLIS = 5000;
const MAXIMUM_NUMBERS_OF_SNACKS = 3;

export const App = (props: IRootContainerProps): React.ReactElement => {
    ScreenOrientationUtils.handleScreenOrientation();

    return (
        <Provider store={props.store}>
            <PersistGate loading={null} persistor={props.persistor}>
                <MuiProvider>
                    <AuthenticationLayer>
                        <SnackbarProvider
                            autoHideDuration={AUTO_HIDE_DURATION_IN_MILLIS}
                            maxSnack={MAXIMUM_NUMBERS_OF_SNACKS}
                            className="notistack-snackbar-container"
                            anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
                        >
                            <NotificationSnackBar />
                                <ConnectedSidebarWrapperProvider>
                                    <ConnectedRouter history={props.history}>
                                        <Switch>
                                            <Route path={'/'} exact component={Dashboard} />
                                            <Route path={'/search/result'} component={SearchResult} />
                                            <Route path={'/search'} component={Search} />
                                            <Route path={'/doc/:documentOid'} component={DocumentDetail} />
                                            <Route path={'/form/:documentOid/:formSubmissionOid?'} component={Form} />
                                            <Route
                                                path={'/transfer-highlights/:contextVersionOid'}
                                                component={TransferHighlights}
                                            />
                                            <Route path={'/play/:flashcardSetOid'} component={PlayFlashcardSet} />
                                            <Route
                                                path={'/pdfexport/revision/:documentOid'}
                                                component={PrintRevision}
                                            />
                                            <Route
                                                path={'/workflow/revision/:documentOid'}
                                                component={DocumentRevision}
                                            />
                                            <Route path={'/workflow/cr/:documentOid'} component={ChangeRequest} />
                                            <Route path={'/file-drop'} component={FileDrop} />
                                            <Route path={'/memo/:memoOid'} component={Memo} />
                                            <Route path={'/my-submissions'} component={MySubmissions} />
                                            <Route path={'/manage-submissions'} component={ManageSubmissionsTable} />
                                            <Route
                                                path={'/manage-submission/:documentOid/:formSubmissionOid?'}
                                                component={ManageSubmission}
                                            />
                                            <Route
                                                path={'/compliance-reports/doc/:contextVersionOid'}
                                                component={DocumentChanges}
                                            />
                                            <Route path={'/compliance-reports/:status'} component={ComplianceReports} />
                                            <Redirect
                                                path={'/compliance-reports'}
                                                to={'/compliance-reports/present'}
                                                exact={true}
                                            />
                                            <Route path={'/compare-revisions'} component={CompareRevisionsLayout} />
                                            <Route path={'/history-log'} component={GlobalHistoryLogLayout} />
                                            <Route
                                                path={'/module-diffing/:contextVersionOid'}
                                                component={ModuleDiffing}
                                            />

                                        {window.location.hostname === 'localhost' && (
                                            <Route path={'/storybooklet'} component={Storybooklet} />
                                        )}
                                    </Switch>
                                </ConnectedRouter>
                            </ConnectedSidebarWrapperProvider>
                        </SnackbarProvider>
                    </AuthenticationLayer>
                </MuiProvider>
            </PersistGate>
        </Provider>
    );
};
