import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import { useTranslation } from 'react-i18next';
import { CloseButton, type ComplianceInfo, YonderButton, ArrayUtils } from '@yonder-mind/ui-core';

import * as S from './ComplianceInfoModal.style';

interface Props {
    open: boolean;
    onClose: () => void;
    complianceInfo: ComplianceInfo[];
}

export const ComplianceInfoModal: React.FC<Props> = ({ complianceInfo, open, onClose }) => {
    const { t } = useTranslation();

    if (complianceInfo === null) {
        return null;
    }

    const onCloseModal = () => {
        onClose();
    };

    const groups = ArrayUtils.groupBy<ComplianceInfo>(complianceInfo, (info: ComplianceInfo) => info.heading);

    return (
        <Dialog
            open={open}
            onClose={() => onCloseModal()}
            fullWidth={true}
            className="modal-input"
            role="modal"
            data-testid="modal"
        >
            <DialogTitle className="modal-input-title" disableTypography={true} data-testid="modalHeader">
                <Typography variant="h6">{t('workflow.changeRequest.complianceInfo.modalTitle')}</Typography>
                <CloseButton onClick={() => onCloseModal()} size="small" />
            </DialogTitle>
            <DialogContent className="modal-input-content" data-testid="modalContent">
                <S.ContentWrapper>
                    {Object.keys(groups).map((infoGroupKey: string) => (
                        <Box key={infoGroupKey} mb={3}>
                            <S.BorderBox>
                                <S.GroupTitle variant="subtitle1">{infoGroupKey}</S.GroupTitle>
                            </S.BorderBox>
                            {groups[infoGroupKey].map((info: ComplianceInfo) => (
                                <S.BorderBox key={info.id} paddingTop={0.5} paddingBottom={0.5}>
                                    <Box>{info.libraryName}</Box>
                                    <Box>{info.requirementNumber}</Box>
                                </S.BorderBox>
                            ))}
                        </Box>
                    ))}
                </S.ContentWrapper>
            </DialogContent>
            <DialogActions className="modal-input-actions" data-testid="modalActions">
                <YonderButton onClick={() => onCloseModal()} variant="text" data-testid="modalCancelButton">
                    {t('workflow.changeRequest.complianceInfo.modalClose')}
                </YonderButton>
            </DialogActions>
        </Dialog>
    );
};
