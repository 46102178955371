import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import utc from 'dayjs/plugin/utc';
import DateFnsUtils from '@date-io/dayjs';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Checkbox, FormControlLabel, OutlinedInput, Typography } from '@material-ui/core';
import { FileContextMetadata, useSelectorUiCore } from '@yonder-mind/ui-core';
import { DEFAULT_END_DATE, DEFAULT_START_DATE, OFFSET_DATE_TIME_MAX } from '../../utils/metadataUtils';
import { FileDropMetadata } from '../../domain/types';
import { MetadataPanelType, MetadataValidation } from './MetadataPanel';

dayjs.extend(isSameOrBefore);
dayjs.extend(utc);

interface IProps {
    type: MetadataPanelType;
    currentMetadata: FileDropMetadata;
    setMetadata: (metadata: FileDropMetadata) => void;
    setValidation: (v: MetadataValidation) => void;
    disabled?: boolean;
}

export const MetadataDateSelector: React.FC<IProps> = ({
    currentMetadata: { endDate, isDurationOpen, startDate, contextOid } = {
        endDate: 0,
        startDate: 0,
        isDurationOpen: false,
        contextOid: null,
    },
    setMetadata,
    setValidation,
    disabled,
    type,
}) => {
    const { t } = useTranslation();

    const { filesDocumentIntegrationEnabled, fileList } = useSelectorUiCore((state) => {
        return {
            filesDocumentIntegrationEnabled: state.tenantSettings.tenantSettings.filesDocumentIntegrationEnabled,
            fileList: state.files.fileList,
        };
    });

    const [dateFrom, setDateFrom] = useState<dayjs.Dayjs>(startDate ? dayjs.unix(startDate) : DEFAULT_START_DATE);
    const [dateTo, setDateTo] = useState<dayjs.Dayjs | null>(endDate ? dayjs.unix(endDate) : DEFAULT_END_DATE);
    const [duration, setDuration] = useState(0);
    const [openDateActive, setOpenDateActive] = useState(isDurationOpen ?? false);
    const [originalFile, setOriginalFile] = useState<FileContextMetadata>(null);

    const shouldValidateDateFrom = !disabled && type === 'import';

    useEffect(() => {
        if (fileList.length && contextOid) {
            setOriginalFile(fileList.find((file) => file.oid === contextOid));
        }
    }, [fileList, contextOid]);

    useEffect(() => {
        if (filesDocumentIntegrationEnabled) {
            if (!openDateActive && dateTo) {
                setDuration(dateTo.endOf('day').diff(dateFrom.endOf('day'), 'day'));
            }
            setMetadata({
                startDate: dayjs(dateFrom).startOf('day').utc(true).unix(),
                endDate: dateTo ? dateTo.startOf('day').utc(true).unix() : OFFSET_DATE_TIME_MAX,
                isDurationOpen: openDateActive,
            });

            const isDateFromInvalid =
                !dateFrom?.isValid() ||
                (shouldValidateDateFrom
                    ? dateFrom.isBefore(dayjs(), 'day')
                    : latestRevisionStartDate && latestRevisionStartDate.isAfter(dayjs(), 'day')
                    ? (previousRevisionStartDate && dateFrom.isSameOrBefore(previousRevisionStartDate, 'day')) ||
                      dateFrom.isBefore(dayjs(), 'day')
                    : dateFrom.unix() !== latestRevisionStartDate?.unix() &&
                      dateFrom.isBefore(previousRevisionStartDate, 'day'));

            const isDateToInvalid =
                dateTo?.isSameOrBefore(dateFrom) ||
                dateTo?.isBefore(dayjs(), 'day') ||
                (!dateFrom?.isValid() && !openDateActive) ||
                (duration < 1 && !openDateActive);

            setValidation({
                dateFrom: isDateFromInvalid ? t('fileDrop.error.invalidDate') : '',
                dateTo: isDateToInvalid ? t('fileDrop.error.invalidDate') : '',
            });
        } else {
            if (!openDateActive && dateTo) {
                setDuration(dateTo.endOf('day').diff(dateFrom.endOf('day'), 'day'));
            }
            setMetadata({
                startDate: dayjs(dateFrom).startOf('day').add(1, 'second').utc(true).unix(),
                endDate: dateTo ? dateTo.startOf('day').utc(true).unix() : null,
                isDurationOpen: openDateActive,
            });

            const isDateFromInvalid =
                !dateFrom?.isValid() || (shouldValidateDateFrom && dateFrom.isBefore(dayjs(), 'day'));

            const isDateToInvalid =
                dateTo?.isSameOrBefore(dateFrom) ||
                (!dateFrom?.isValid() && !openDateActive) ||
                (duration < 1 && !openDateActive);

            setValidation({
                dateFrom: isDateFromInvalid ? t('fileDrop.error.invalidDate') : '',
                dateTo: isDateToInvalid ? t('fileDrop.error.invalidDate') : '',
            });
        }
    }, [dateFrom, dateTo, openDateActive, duration, originalFile]);

    const setDate = (date: dayjs.Dayjs | null, dateOption: string) => {
        if (date && date.isValid()) {
            if (dateOption === 'fromDate') {
                setDateFrom(date);
            } else {
                setDateTo(date);
            }
        }
    };

    const getDefaultEndDate = () => {
        if (filesDocumentIntegrationEnabled) {
            return dateFrom.isBefore(dayjs(), 'day') ? dayjs().utc(true).add(1, 'day') : dateFrom.add(1, 'day');
        } else {
            return dateFrom.add(1, 'day');
        }
    };

    const onChangeDuration = (e: React.ChangeEvent<any>) => {
        if (e.target.value && e.target.value > 0) {
            const durationPeriod = e.target.value;
            setDuration(durationPeriod);
            setDateTo(dayjs(dateFrom).add(durationPeriod, 'day'));
        }
    };

    const latestRevisionStartDate =
        originalFile && originalFile.revisions.length
            ? dayjs.unix(originalFile.revisions[originalFile.revisions.length - 1].effectiveFrom)
            : null;

    const previousRevisionStartDate =
        originalFile && originalFile.revisions.length >= 2
            ? dayjs.unix(originalFile.revisions[originalFile.revisions.length - 2].effectiveFrom)
            : null;

    const minDateFrom = filesDocumentIntegrationEnabled
        ? shouldValidateDateFrom
            ? dayjs().startOf('day')
            : previousRevisionStartDate && previousRevisionStartDate.isAfter(dayjs().unix(), 'day')
            ? previousRevisionStartDate.add(1, 'day')
            : dayjs().startOf('day')
        : shouldValidateDateFrom
        ? dayjs().startOf('day')
        : '';

    const handleOpenChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setOpenDateActive(e.target.checked);
        if (e.target.checked) {
            setDateTo(null);
            setDuration(0);
        } else {
            setDateTo(getDefaultEndDate());
        }
    };

    const isEffectiveFromDisabled =
        disabled ||
        (filesDocumentIntegrationEnabled
            ? shouldValidateDateFrom
                ? false
                : latestRevisionStartDate && latestRevisionStartDate.isBefore(dayjs(), 'day')
            : false);

    const helperText = () => {
        if (
            filesDocumentIntegrationEnabled &&
            !shouldValidateDateFrom &&
            latestRevisionStartDate &&
            latestRevisionStartDate.isBefore(dayjs(), 'day')
        ) {
            return t('fileDrop.error.effectiveFromPast');
        }
        if (filesDocumentIntegrationEnabled && dateFrom.isBefore(minDateFrom, 'day')) {
            return t('fileDrop.error.minDateFrom');
        }
        if (shouldValidateDateFrom && dateFrom.isBefore(minDateFrom, 'day')) {
            return t('fileDrop.error.minDateFrom');
        }
        return '';
    };

    return (
        <div className="datepicker-wrapper">
            <MuiPickersUtilsProvider libInstance={dayjs} utils={DateFnsUtils}>
                <div className="datepicker-select">
                    <Typography paragraph={true}>{t('fileDrop.metadataPanel.datepicker.dateFrom')}</Typography>
                    <KeyboardDatePicker
                        placeholder="DD.MM.YYYY"
                        format="DD.MM.YYYY"
                        variant="inline"
                        inputVariant="outlined"
                        disableToolbar={true}
                        InputAdornmentProps={{ position: 'start' }}
                        value={dateFrom}
                        minDate={minDateFrom}
                        helperText={helperText()}
                        onChange={(e: dayjs.Dayjs) => setDate(e, 'fromDate')}
                        autoOk={true}
                        disabled={isEffectiveFromDisabled}
                        data-testid="effectiveFrom"
                    />
                </div>
                <div className="datepicker-select">
                    <Typography paragraph={true}>{t('fileDrop.metadataPanel.datepicker.dateTo')}</Typography>
                    <KeyboardDatePicker
                        placeholder={openDateActive ? t('fileDrop.metadataPanel.datepicker.open') : 'DD.MM.YYYY'}
                        format="DD.MM.YYYY"
                        variant="inline"
                        inputVariant="outlined"
                        disableToolbar={true}
                        minDate={
                            dateFrom && !disabled
                                ? dateFrom.isBefore(dayjs(), 'day')
                                    ? dayjs().utc(true).add(1, 'day')
                                    : dateFrom.add(1, 'day')
                                : ''
                        }
                        InputAdornmentProps={{ position: 'start' }}
                        value={dateTo && !openDateActive ? dateTo : null}
                        onChange={(e: dayjs.Dayjs) => setDate(e, 'toDate')}
                        autoOk={true}
                        disabled={openDateActive || disabled}
                        minDateMessage={t('fileDrop.error.minDateTo')}
                        data-testid="effectiveTo"
                    />
                </div>
            </MuiPickersUtilsProvider>
            <div className="duration">
                <Typography paragraph={true}>{t('fileDrop.metadataPanel.datepicker.duration')}</Typography>
                <FormControlLabel
                    control={
                        <OutlinedInput
                            value={duration}
                            onChange={onChangeDuration}
                            name="duration"
                            type="number"
                            disabled={openDateActive || disabled}
                            data-testid="duration"
                        />
                    }
                    label=""
                />
            </div>
            <div className="open-date">
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={openDateActive}
                            onChange={handleOpenChange}
                            name="open"
                            color="primary"
                            data-testid="openEnded"
                        />
                    }
                    label={t('fileDrop.metadataPanel.datepicker.open')}
                    disabled={disabled}
                />
            </div>
        </div>
    );
};
