import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import {
    changesActions,
    DocumentClass,
    Empty,
    filesActions,
    IApiChange,
    Spinner,
    YonderButton,
    YonderDialog,
} from '@yonder-mind/ui-core';
import { IWebApplicationStore } from '../../../../interfaces';
import { FileDropMetadata } from '../../domain/types';
import { WorkflowTool } from '../../../../components/Workflow/Tools';
import { ChangeForm } from '../../../../components/Workflow/Tools/Change/ChangeForm';

interface FileNotificationsProps {
    currentMetadata: FileDropMetadata;
    visible: boolean;
    disabled: boolean;
    isImportInProgress: boolean;
}

export const FileNotifications: React.FC<FileNotificationsProps> = ({
    visible,
    currentMetadata,
    disabled,
    isImportInProgress,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [showDeleteConfirmation, setResetConfirmationVisibility] = React.useState(false);

    const { change, isLoading, hasChanges, notificationSettings } = useSelector((state: IWebApplicationStore) => {
        return {
            change: (state.changes.changes.documents[currentMetadata.revisionOid] || [])[0],
            isLoading: state.changes.isLoading,
            hasChanges: state.files.hasNotificationSettingsChanges,
            notificationSettings: state.files.notificationSettings,
        };
    });

    const { revisionOid } = currentMetadata;

    useEffect(() => {
        if (change) {
            dispatch(
                filesActions.saveNotificationSettings({
                    contextVersionOid: currentMetadata?.revisionOid,
                    notificationSetting: change,
                })
            );
        }
    }, [change]);

    const updateChange = (changeUpdated: Partial<IApiChange>) => {
        const updatedChange = {
            ...notificationSettings[revisionOid],
            ...changeUpdated,
        };
        dispatch(
            filesActions.updateHasNotificationSettingsChanges({
                hasChanges: JSON.stringify(change) !== JSON.stringify(updatedChange),
            })
        );
        dispatch(
            filesActions.saveNotificationSettings({
                contextVersionOid: revisionOid,
                notificationSetting: updatedChange,
            })
        );
    };

    const createChange = () => {
        dispatch(
            changesActions.addChange('context', revisionOid, {
                revisionWorkflowId: '',
                revisionWorkflowLabel: '',
                summary: '',
                descriptionText: '',
            } as any)
        );
    };

    const resetConfirmed = () => {
        if (revisionOid && change) {
            dispatch(changesActions.deleteChange('context', revisionOid, change.oid));
        }
        createChange();
        setResetConfirmationVisibility(false);
    };

    const changeContextVersionOid = change ? change.contextVersionOid : null;

    const getActions = (): Array<{ name: string; onClick: () => any }> => {
        if (!revisionOid) {
            return [];
        }

        if (!change && !isImportInProgress) {
            return [{ name: t('workflow.tools.change.add'), onClick: createChange }];
        }

        return change && !isImportInProgress
            ? [{ name: t('form.actions.reset'), onClick: () => setResetConfirmationVisibility(true) }].filter(
                  (action) => !!action
              )
            : [];
    };

    const getFormLabels = () => {
        return [
            <Typography
                color="primary"
                align="center"
                children={t('workflow.tools.change.file.noChange')}
                key="title"
            />,
            ...(!change
                ? [
                      <Typography
                          color="primary"
                          align="center"
                          children={t('workflow.tools.change.file.clickAdd', {
                              action: t('workflow.tools.change.add'),
                          })}
                          key="info"
                      />,
                  ]
                : []),
        ];
    };

    const notificationIsAlreadyCreated = Boolean(change?.status);

    return (
        visible && (
            <WorkflowTool tool="change">
                <YonderDialog
                    isOpen={showDeleteConfirmation}
                    title={t('workflow.tools.change.reset.confirm.title')}
                    content={t('workflow.tools.change.reset.confirm.content')}
                    primaryAction={t('form.actions.confirm')}
                    secondaryAction={t('form.actions.cancel')}
                    onConfirm={resetConfirmed}
                    onCancel={() => setResetConfirmationVisibility(false)}
                    onClose={() => setResetConfirmationVisibility(false)}
                />

                {isLoading ? (
                    <Spinner />
                ) : change && !isImportInProgress ? (
                    <ChangeForm
                        actions={getActions()}
                        change={notificationSettings[revisionOid] || change}
                        changeContextVersionOid={changeContextVersionOid}
                        updateChange={updateChange}
                        hasChanges={hasChanges}
                        documentClass={DocumentClass.FILE}
                        disabled={disabled || notificationIsAlreadyCreated}
                    />
                ) : (
                    <>
                        <>
                            {!revisionOid || isImportInProgress ? (
                                <Empty
                                    variant="text"
                                    text={
                                        <Typography color="primary" children={t('workflow.tools.change.close.file')} />
                                    }
                                />
                            ) : (
                                <Empty variant="text" text={getFormLabels()} />
                            )}
                            <div className="change-form-actions">
                                {getActions().map((action, index, array) => (
                                    <YonderButton
                                        variant={index === array.length - 1 ? 'contained' : 'text'}
                                        children={action.name}
                                        onClick={action.onClick}
                                        key={index}
                                    />
                                ))}
                            </div>
                        </>
                    </>
                )}
            </WorkflowTool>
        )
    );
};
