import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, TextField } from '@material-ui/core';
import { Close, FormSubmissionStatusEnum } from '@yonder-mind/ui-core';

interface SubmissionSummaryTextFieldProps {
    summary: string;
    summaryInputRef: React.RefObject<HTMLInputElement> | null;
    formSubmissionStatus: FormSubmissionStatusEnum;
    updateQuestionsOnSummaryChange?: (value: string) => void;
}

const MAX_CHARACTER_LENGTH = 256;

export const SubmissionSummaryTextField: React.FC<SubmissionSummaryTextFieldProps> = ({
    summaryInputRef,
    summary,
    formSubmissionStatus,
    updateQuestionsOnSummaryChange,
}) => {
    const { t } = useTranslation();

    const [helperText, setHelperText] = useState('');

    useEffect(() => {
        if (!isDisabled) {
            setHelperText(`${MAX_CHARACTER_LENGTH}/${MAX_CHARACTER_LENGTH} ${charsLeftMessage}`);
        }
    }, []);

    const setCharacterError = () => {
        setHelperText(t('form.validation.maxLength', { max: MAX_CHARACTER_LENGTH }));
    };

    const isDisabled = formSubmissionStatus === FormSubmissionStatusEnum.SUBMITTED;

    const charsLeftMessage = t('form.summary.charsLeft');

    const handleSummaryChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
        if (!isDisabled) {
            const inputLength = target.value.length;
            setHelperText(`${MAX_CHARACTER_LENGTH - inputLength}/${MAX_CHARACTER_LENGTH} ${charsLeftMessage}`);
            target.value.length > MAX_CHARACTER_LENGTH
                ? setCharacterError()
                : updateQuestionsOnSummaryChange && updateQuestionsOnSummaryChange(target.value);
        }
    };

    const clearSummary = () => {
        updateQuestionsOnSummaryChange && updateQuestionsOnSummaryChange('');
        setTimeout(() => {
            summaryInputRef?.current?.focus();
            setHelperText(`${MAX_CHARACTER_LENGTH}/${MAX_CHARACTER_LENGTH} ${charsLeftMessage}`);
        }, 0);
    };

    const handleBlur = () => {
        updateQuestionsOnSummaryChange && updateQuestionsOnSummaryChange(summaryInputRef?.current?.value || '');
    };

    return (
        <TextField
            data-testid={'form-summary-field'}
            data-key={'form-summary-field'}
            className="summary-field"
            fullWidth
            inputRef={summaryInputRef}
            required
            autoFocus
            helperText={helperText}
            type={'text'}
            value={summary}
            disabled={isDisabled}
            onInput={handleSummaryChange}
            label={t('form.summary.label')}
            variant="outlined"
            InputProps={{
                onBlur: handleBlur,
                endAdornment: !isDisabled ? (
                    <IconButton
                        data-testid="form-clear-summary-button"
                        aria-label={t('form.summary.clearLabel')}
                        className={`summary-field__icon-button${!summary ? '--hidden' : ''}`}
                        onClick={() => {
                            clearSummary();
                        }}
                    >
                        <Close />
                    </IconButton>
                ) : null,
            }}
        />
    );
};
