import { useEffect } from 'react';
import { Survey } from 'survey-react-ui';
import { Model, Serializer } from 'survey-core';
import 'survey-core/defaultV2.css';
import {
    formActions,
    FormContent,
    FormSubmission,
    FormSubmissionStatusEnum,
    ManageableFormSubmission,
} from '@yonder-mind/ui-core';
import { useDispatch, useSelector } from 'react-redux';
import { IWebApplicationStore } from '../../../interfaces';
import { useTranslation } from 'react-i18next';
import { initializeSurveySerializer } from '../utils/SurveyJSUtils';

initializeSurveySerializer(Serializer);

interface SurveyIntegrationProps {
    moduleVersionOid: string;
    formContent: FormContent;
    formSubmission?: FormSubmission | ManageableFormSubmission;
}

export const SurveyIntegration: React.FC<SurveyIntegrationProps> = ({
    moduleVersionOid,
    formContent,
    formSubmission,
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { survey } = useSelector((state: IWebApplicationStore) => {
        return {
            ...state.form,
        };
    });

    useEffect(() => {
        if (formContent) {
            const survey = initializeSurvey(formContent, formSubmission ? formSubmission : null);
            dispatch(formActions.setSurvey(survey));
        }
    }, [formContent, formSubmission]);

    const validateFileType = (_sender: Model, options: any) => {
        if (
            options.question.getType() === 'file' &&
            options.question.allowedFileTypes === 'images' &&
            options.question.value
        ) {
            const fileValue = options.question.value[0];
            if (fileValue?.content) {
                if (!fileValue.content.includes('data:image')) {
                    options.error = t('form.messages.error.invalidFileType');
                    options.question.value = null;
                }
            }
        }
    };

    const initializeSurvey = (formContent: FormContent, formSubmission: FormSubmission | ManageableFormSubmission) => {
        const survey = new Model(formContent[moduleVersionOid]);
        survey.showNavigationButtons = false;
        survey.focusFirstQuestionAutomatic = false;
        survey.progressBarType = 'questions';
        survey.textUpdateMode = 'onTyping';
        survey.clearInvisibleValues = 'onHidden';
        survey.checkErrorsMode = 'onValueChanged';
        survey.onErrorCustomText.add((_sender, options) => {
            options.name === 'exceedsize' && (options.text = t('form.messages.error.fileSizeExceeded', { size: 5 }));
        });
        survey.onValidateQuestion.add(validateFileType);
        if (formSubmission) {
            survey.data = JSON.parse(formSubmission.content || '{}');
            if (formSubmission.formSubmissionStatus === FormSubmissionStatusEnum.SUBMITTED) {
                survey.mode = 'display';
            }
        }
        return survey;
    };

    return survey ? <Survey model={survey} /> : null;
};
