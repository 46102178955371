import { ApiConstants } from '../../utils/ApiConstants';

export class LinkConstants extends ApiConstants {
    static getModuleLinks(moduleOid: string): URL {
        return ApiConstants.relativeUrl(`/links/by-module/${moduleOid}`);
    }

    static getModuleLinkTypes(): URL {
        return ApiConstants.relativeUrl('/links/link-types');
    }

    static saveModuleLink(): URL {
        return ApiConstants.relativeUrl('/links');
    }

    static deleteModuleLink(linkOid: string): URL {
        return ApiConstants.relativeUrl(`/links/${linkOid}`);
    }
}
