import React, { useLayoutEffect } from 'react';
import { Table, TableBody, TableHead } from '@material-ui/core';
import { FixedSizeList as List, ListItemKeySelector } from 'react-window';
import AutoSizer, { Size } from 'react-virtualized-auto-sizer';
import { HighlyPerformantTableHeader } from './HighlyPerformantTableHeader';
import { HighlyPerformantTableColumn } from './HighlyPerformantTableInterfaces';
import { HighlyPerformantTableRow } from './HighlyPerformantTableRow';
import InfiniteLoader from 'react-window-infinite-loader';
import { makeStyles } from '@material-ui/core/styles';
import { HighlyPerformantTableStyles } from './HighlyPerformantTableStyles';

interface HighlyPerformantTableProps<T> {
    tableColumns: HighlyPerformantTableColumn[];
    tableRows: T[];
    isLoading: boolean;
    loadMoreItems: (startIndex: number, stopIndex: number) => void;
    minWidth?: number;
}

export const HIGHLY_PERFORMANT_ROW_HEIGHT = 32;
export const HIGHLY_PERFORMANT_HEADER_ROW_HEIGHT = 40;

export const HighlyPerformantTable: React.FC<HighlyPerformantTableProps<unknown>> = <T,>({
    tableColumns,
    tableRows,
    isLoading,
    loadMoreItems,
    minWidth,
}: HighlyPerformantTableProps<T>) => {
    const [rowWidth, setRowWidth] = React.useState(0);

    // @ts-ignore
    const classes = makeStyles(HighlyPerformantTableStyles(minWidth))();
    const propsConvertedToItemData = {
        columns: tableColumns,
        items: tableRows,
        classes,
    };

    const itemKey: ListItemKeySelector = (index, data) => data?.items[index]?.id;

    const tableHeadRef = React.useRef(null);

    useLayoutEffect(() => {
        const handleResize = () => {
            setRowWidth(tableHeadRef.current?.offsetWidth);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Table component="div" className={classes.table}>
            <TableHead component="div" className={classes.thead}>
                <HighlyPerformantTableHeader classes={classes} columns={tableColumns} ref={tableHeadRef} />
            </TableHead>
            <TableBody component="div" className={classes.tbody}>
                <AutoSizer>
                    {({ height }: Size) => (
                        <InfiniteLoader
                            isItemLoaded={(_index) => isLoading}
                            itemCount={tableRows.length}
                            loadMoreItems={loadMoreItems}
                        >
                            {({ onItemsRendered, ref }) => (
                                <List
                                    className={classes.list}
                                    width={rowWidth > minWidth ? rowWidth : minWidth}
                                    height={height}
                                    itemCount={tableRows.length}
                                    itemSize={HIGHLY_PERFORMANT_ROW_HEIGHT}
                                    itemKey={itemKey}
                                    itemData={propsConvertedToItemData}
                                    onItemsRendered={onItemsRendered}
                                    ref={ref}
                                >
                                    {HighlyPerformantTableRow}
                                </List>
                            )}
                        </InfiniteLoader>
                    )}
                </AutoSizer>
            </TableBody>
        </Table>
    );
};
