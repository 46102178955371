// @ts-nocheck
import React from 'react';

import { Text, View } from '@react-pdf/renderer';
import { DataTableCell, Table, TableBody, TableCell, TableHeader } from '@david.kucsai/react-pdf-table';
import { ComplianceTableItem } from '../domain/types';
import { pdfStyles } from './styles';
import { useTranslation } from 'react-i18next';
import { DateUtils, DocumentClass } from '@yonder-mind/ui-core';
import { getPercentageString } from '../DocumentsTable/ProgressBar';

type PdfDocumentsTableProps = {
    documents: ComplianceTableItem[];
};

export const PdfDocumentsTable: React.FC<PdfDocumentsTableProps> = ({ documents }) => {
    const { t } = useTranslation();
    return (
        <View style={pdfStyles.documentsTable}>
            <Text style={pdfStyles.titleText}>{t('complianceReports.filter.documents.title')}</Text>
            <Table data={documents}>
                <TableHeader>
                    <TableCell weighting={0.2} style={pdfStyles.headerTableCell}>
                        {t('complianceReports.documentsTable.name')}
                    </TableCell>
                    <TableCell weighting={0.1} style={pdfStyles.headerTableCell}>
                        {t('complianceReports.documentsTable.version')}
                    </TableCell>
                    <TableCell weighting={0.15} style={pdfStyles.headerTableCell}>
                        {t('complianceReports.documentsTable.read')}
                    </TableCell>
                    <TableCell weighting={0.15} style={pdfStyles.headerTableCell}>
                        {t('complianceReports.documentsTable.readAndAck')}
                    </TableCell>
                    <TableCell weighting={0.2} style={pdfStyles.headerTableCell}>
                        {t('complianceReports.documentsTable.effectiveFrom')}
                    </TableCell>
                    <TableCell weighting={0.2} style={pdfStyles.headerTableCell}>
                        {t('complianceReports.documentsTable.effectiveTo')}
                    </TableCell>
                </TableHeader>
                <TableBody>
                    <DataTableCell
                        weighting={0.2}
                        style={pdfStyles.tableCell}
                        getContent={(r: ComplianceTableItem) => r.name}
                    />
                    <DataTableCell
                        weighting={0.1}
                        style={pdfStyles.tableCell}
                        getContent={(r: ComplianceTableItem) => {
                            return r.documentClass === DocumentClass.FILE ? '—' : r.revision;
                        }}
                    />
                    <DataTableCell
                        weighting={0.15}
                        style={pdfStyles.tableCell}
                        getContent={(r: ComplianceTableItem) => {
                            return getPercentageString((r.readDoneCount * 100) / r.readTotalCount);
                        }}
                    />
                    <DataTableCell
                        weighting={0.15}
                        style={pdfStyles.tableCell}
                        getContent={(r: ComplianceTableItem) => {
                            return getPercentageString((r.ackDoneCount * 100) / r.ackTotalCount);
                        }}
                    />
                    <DataTableCell
                        weighting={0.2}
                        style={pdfStyles.tableCell}
                        getContent={(r: ComplianceTableItem) => {
                            return DateUtils.ISOToFormattedDateWithoutTime(r.effectiveFrom);
                        }}
                    />
                    <DataTableCell
                        weighting={0.2}
                        style={pdfStyles.tableCell}
                        getContent={(r: ComplianceTableItem) => {
                            return r.effectiveTo
                                ? DateUtils.ISOToFormattedDateWithoutTime(r.effectiveTo)
                                : t('complianceReports.documentsTable.effectiveToOpenEnded');
                        }}
                    />
                </TableBody>
            </Table>
        </View>
    );
};
