import * as React from 'react';
import { IWorkflowComment, DateUtils, parseUserIdToName, dayDateTimeFormat } from '@yonder-mind/ui-core';

interface IProps {
    comment: IWorkflowComment;
    own: boolean;
}

export const Comment: React.FC<IProps> = (props) => {
    const { comment } = props;

    return (
        <div className={`comment${props.own ? ' own' : ''}`}>
            <div className="comment-info">
                {/* TODO: Get User Information of userId */}
                {!props.own ? (
                    <>
                        <div className="user">{parseUserIdToName(comment.userId)}</div>
                        <div className="spacing" />
                    </>
                ) : (
                    <div />
                )}

                <div className="date">
                    {DateUtils.parseToDate(comment.time, { format: dayDateTimeFormat }) as string}
                </div>
            </div>

            <div className="spacing" />

            <div className="comment-content">{comment.fullMessage}</div>
        </div>
    );
};
