import * as React from 'react';
import { ListItem, ListItemText } from '@material-ui/core';
import { IWorkflowHistoricActivity, YonderList } from '@yonder-mind/ui-core';
import { HistoricActivityItem } from '../../List';

interface IProps {
    histories?: IWorkflowHistoricActivity[];
}

export const WorkflowHistory: React.FC<IProps> = ({ histories }) => {
    return (
        <YonderList
            dataTestId="workflowToolsHistoryList"
            className="workflow-history"
            variant="custom"
            componentListItem={HistoricActivityItem}
            items={histories || []}
            emptyComponent={
                <ListItem alignItems="flex-start">
                    <ListItemText primary={'No History available'} />
                </ListItem>
            }
        />
    );
};
