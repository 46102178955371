import {
    ContextVersionUtils,
    DashboardCard,
    filterAndSortActions,
    filterChangeRequestNotificationsTabConfig,
    filterDocumentNotificationsTabConfig,
    FilterUtils,
    IActiveSort,
    type IApiTask,
    IApiTaskType,
    IQueryParam,
    type IWorkflowActivity,
    type IWorkflowHistoricActivity,
    SortUtils,
    TaskAlertDialog,
    tasksActions,
    tasksSelector,
    TaskUtils,
    useHistory,
    userSelector,
    Workflow,
    WorkflowUtils,
    YonderDialog,
    YonderList,
} from '@yonder-mind/ui-core';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IWebApplicationStore } from '../../../interfaces';
import { crActions } from '../../../store';
import { ChangeRequestListItem } from '../../List';

const WORKFLOW_TASK_TYPES: IApiTaskType[] = [
    IApiTaskType.WF_CONTEXT_REVISION,
    IApiTaskType.WF_CHANGE_REQUEST,
    IApiTaskType.DISCUSSION,
];

export const WorkflowsCard = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { pushUrl } = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const [isTaskAlertPopupOpen, setIsTaskAlertPopupOpen] = useState(false);
    const [clickedTask, setClickedTask] = useState<IApiTask | undefined>(undefined);
    const [clickedTaskTypes, setClickedTaskTypes] = useState<IApiTaskType[]>([]);
    const [wfNotificationDialogOpen, setWfNotificationDialogOpen] = useState(false);
    const [documentNotificationsFiltersActive, setDocumentNotificationsFiltersActive] = useState(true);
    const [changeRequestNotificationsFiltersActive, setChangeRequestNotificationsFiltersActive] = useState(true);
    const docs = useSelector((state: IWebApplicationStore) => state.docs.currentContextVersionsMetadata);
    const isFirstLoading = useSelector((state: IWebApplicationStore) => state.docs.isFirstLoading);
    const userSettings = useSelector((state: IWebApplicationStore) => state.user.userSettings);
    const hasErrorTasks = useSelector((state: IWebApplicationStore) => state.tasks.hasError);
    const isLoadingTasks = useSelector((state: IWebApplicationStore) => state.tasks.isLoading);
    const tasksWorkflow = useSelector((state: IWebApplicationStore) =>
        tasksSelector.getTasks(state, WORKFLOW_TASK_TYPES)
    );
    const isLoadingBatchDelete = useSelector((state: IWebApplicationStore) => state.tasks.isLoadingBatchDelete);
    const filteredDocumentNotificationsData = useSelector(
        (state: IWebApplicationStore) => state.filterAndSort.filteredDocumentNotificationsData
    );
    const filteredChangeRequestNotificationsData = useSelector(
        (state: IWebApplicationStore) => state.filterAndSort.filteredChangeRequestNotificationsData
    );
    const usersChangeRequests = useSelector((state: IWebApplicationStore) => state.workflow.changeRequest.userCR);
    const isLoadingUsersChangeRequest = useSelector(
        (state: IWebApplicationStore) => state.workflow.changeRequest.isLoading
    );
    const workflowsActiveTab = useSelector(userSelector.getSelectDashboardTab(t('dashboard.cards.workflow.title')));

    useEffect(() => {
        setDocumentNotificationsFiltersActive(true);
    }, [filteredDocumentNotificationsData.activeFilters]);

    useEffect(() => {
        setChangeRequestNotificationsFiltersActive(true);
    }, [filteredChangeRequestNotificationsData.activeFilters]);

    useEffect(() => {
        if (!isFirstLoading && !isLoadingTasks) {
            dispatch(
                filterAndSortActions.saveFilterAndSortDocumentNotificationsValues(filteredDocumentNotificationsData)
            );
        }
    }, [filteredDocumentNotificationsData]);

    useEffect(() => {
        if (!isFirstLoading && !isLoadingTasks) {
            dispatch(
                filterAndSortActions.saveFilterAndSortChangeRequestNotificationsValues(
                    filteredChangeRequestNotificationsData
                )
            );
        }
    }, [filteredChangeRequestNotificationsData]);

    const deleteTask = (task: IApiTask) => {
        dispatch(tasksActions.deleteTask({ task, taskTypes: WORKFLOW_TASK_TYPES, enqueueSnackbar }));
    };

    const filteredNotifications = (activeFilters: {} | any, activeSort: IActiveSort | any) => {
        return SortUtils.sortNotifications(
            FilterUtils.filterNotifications([...tasksWorkflow], activeFilters),
            activeSort
        );
    };

    const filteredDocumentNotifications = () => {
        return filteredNotifications(
            filteredDocumentNotificationsData.activeFilters,
            filteredDocumentNotificationsData.activeSort
        ).filter((notification) => notification.taskType === 'WF_CONTEXT_REVISION');
    };

    const filteredChangeRequestNotifications = () => {
        return filteredNotifications(
            filteredChangeRequestNotificationsData.activeFilters,
            filteredChangeRequestNotificationsData.activeSort
        ).filter((notification) => notification.taskType !== 'WF_CONTEXT_REVISION');
    };

    const deleteFilteredNotificationTasks = () => {
        const tasksIdList =
            workflowsActiveTab === 0
                ? filteredDocumentNotifications().map((item) => item.taskId)
                : filteredChangeRequestNotifications().map((item) => item.taskId);
        dispatch(
            tasksActions.deleteTasks({
                tasks: tasksIdList,
                taskTypes: WORKFLOW_TASK_TYPES,
                enqueueSnackbar,
            })
        );
        setWfNotificationDialogOpen(false);
    };

    const getDeleteNotificationMessage = () => {
        const isFilterSelected = Object.values(filteredDocumentNotificationsData.activeFilters).every(
            (x) => x.length === 0 || x === ''
        );
        return !isFilterSelected ? (
            <p>{t('filterAndSort.footerAction.clearFilteredNotificationsMessage')}</p>
        ) : (
            <p>{t('filterAndSort.footerAction.clearAllNotificationsMessage')}</p>
        );
    };

    const fetchMyCrs = useCallback(() => {
        if (userSettings.username !== '') {
            dispatch(crActions.allChangeRequestsOfUserRequested(userSettings.username));
        }
    }, [userSettings.username]);

    // TODO: Remove duplicate function to a common upper component rather than Dashboard
    const openTask = ({ task }: { task: IApiTask }) => {
        pushUrl(TaskUtils.generateLinkPathname(task), TaskUtils.generateLinkParams(task));
    };

    const taskOpened = ({
        showAlert,
        task,
        taskTypes,
    }: {
        task: IApiTask;
        taskTypes: IApiTaskType[];
        showAlert: boolean;
    }) => {
        if (!showAlert) {
            openTask({ task });
            return;
        }

        const contextVersion = docs.find((doc) => doc.oid === task.contextVersionOid);
        if (contextVersion === undefined || ContextVersionUtils.isInvalid(contextVersion)) {
            setIsTaskAlertPopupOpen(true);
            setClickedTask(task);
            setClickedTaskTypes(taskTypes);
            return;
        }

        openTask({ task });
    };

    const openChangeRequest = (changeRequest: IWorkflowActivity | IWorkflowHistoricActivity) => {
        pushUrl(`/workflow/cr/${WorkflowUtils.getContextVersionOid(changeRequest.variables)}`, [
            {
                key: IQueryParam.CHANGE_REQUEST,
                value: changeRequest.processInstanceId,
            },
        ]);
    };

    return (
        <>
            <DashboardCard
                isLoading={isLoadingTasks || isFirstLoading}
                hasError={hasErrorTasks}
                primaryHeader={t('dashboard.cards.workflow.title')}
                secondaryHeader={t('dashboard.cards.workflow.subtitle')}
                icon={<Workflow size="large" />}
                defaultTab={1}
                fetchMyCrs={fetchMyCrs}
                filterFooterAction={() => setWfNotificationDialogOpen(true)}
                filterFooterTitle={t('filterAndSort.footerAction.clearNotifications')}
                filterFooterIsLoading={isLoadingBatchDelete}
                tabs={[
                    {
                        component: (
                            <YonderList
                                useVirtualList
                                variant="tasks"
                                items={filteredDocumentNotifications()}
                                onClick={(task) =>
                                    taskOpened({
                                        task,
                                        taskTypes: WORKFLOW_TASK_TYPES,
                                        showAlert: false,
                                    })
                                }
                                onDelete={deleteTask}
                                displayDeleteButton={true}
                                listItemClass="workflow-action"
                                wfNotificationLayout={true}
                                chips={{
                                    filterAvailable: documentNotificationsFiltersActive,
                                    filterType: filterDocumentNotificationsTabConfig.type,
                                    filterValues: filteredDocumentNotificationsData.activeFilters,
                                }}
                                dataTestId="documentUpdatesList"
                            />
                        ),
                        dataTestId: 'documentUpdatesButton',
                        label: t('dashboard.cards.workflow.tabs.documentTasks'),
                    },
                    {
                        component: (
                            <YonderList
                                useVirtualList
                                variant="tasks"
                                items={filteredChangeRequestNotifications()}
                                onClick={(task) =>
                                    taskOpened({
                                        task,
                                        taskTypes: WORKFLOW_TASK_TYPES,
                                        showAlert: false,
                                    })
                                }
                                onDelete={deleteTask}
                                displayDeleteButton={true}
                                listItemClass="workflow-action"
                                wfNotificationLayout={true}
                                chips={{
                                    filterAvailable: changeRequestNotificationsFiltersActive,
                                    filterType: filterChangeRequestNotificationsTabConfig.type,
                                    filterValues: filteredChangeRequestNotificationsData.activeFilters,
                                }}
                                dataTestId="changeRequestNotificationsList"
                            />
                        ),
                        dataTestId: 'changeRequestNotificationsButton',
                        label: t('dashboard.cards.workflow.tabs.changeRequestTasks'),
                    },
                    {
                        component: (
                            <YonderList
                                useVirtualList
                                variant="custom"
                                componentListItem={ChangeRequestListItem}
                                items={usersChangeRequests}
                                onClick={openChangeRequest}
                                isLoading={isLoadingUsersChangeRequest}
                                dataTestId="myChangeRequestsList"
                            />
                        ),
                        dataTestId: 'myChangeRequestsButton',
                        label: t('dashboard.cards.workflow.tabs.changeRequests'),
                    },
                ]}
                filterVisibleAtTabIndex={[0, 1]}
                filterOptions={FilterUtils.getWorkflowCardFilterOptions(
                    workflowsActiveTab,
                    filteredDocumentNotificationsData,
                    filteredChangeRequestNotificationsData,
                    t
                )}
            />
            <TaskAlertDialog
                clickedTask={clickedTask}
                clickedTaskTypes={clickedTaskTypes}
                markTaskAsReadAndTryOpen={openTask}
                isTaskAlertPopupOpen={isTaskAlertPopupOpen}
                setIsTaskAlertPopupOpen={setIsTaskAlertPopupOpen}
                t={t}
            />
            <YonderDialog
                isOpen={wfNotificationDialogOpen}
                title={t('filterAndSort.footerAction.clearNotifications')}
                content={<div>{getDeleteNotificationMessage()}</div>}
                primaryAction={t('filterAndSort.footerAction.buttons.confirm')}
                secondaryAction={t('filterAndSort.footerAction.buttons.cancel')}
                onConfirm={deleteFilteredNotificationTasks}
                onCancel={() => setWfNotificationDialogOpen(false)}
                onClose={() => setWfNotificationDialogOpen(false)}
                className="workflow-notifications-delete-dialog"
            />
        </>
    );
};
