import { Paper, Typography } from '@material-ui/core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
    ChangeRequestWorkflowTab,
    combineStrings,
    CRName,
    docsActions,
    IApiContextVersion,
    IApiModuleVersionChange,
    IApplicationStore,
    IQueryParam,
    ITab,
    IWorkflowActivity,
    IWorkflowHistoricActivity,
    IWorkflowTaskInfo,
    LockSource,
    modulesSelector,
    PinButton,
    SnackBar,
    Tabs,
    useChanges,
    useHistory,
    userActions,
    WorkflowUtils,
} from '@yonder-mind/ui-core';
import { useWorkflow } from '../../../context';
import { WorkflowCRProposalScreen } from '../../../config';
import { Change, Discussion } from '../Tools';
import { ChangeRequestWorkflowSidebar } from './ChangeRequestWorkflowSidebar';
import { ChangeRequestWorkflowSplitView } from './ChangeRequestWorkflowSplitView';
import { GroupedVarsList } from '../Tools/GroupedVarsList';
import { Attachment } from '../Tools/Attachment';
import { useDispatch, useSelector } from 'react-redux';
import { importJobActions } from '../../../store';
import { IWebApplicationStore } from '../../../interfaces';

interface ChangeRequestWorkflowProps {
    document: IApiContextVersion;
    changeRequest: IWorkflowActivity | IWorkflowHistoricActivity;
    workflowTab?: ChangeRequestWorkflowTab;
    fullScreenActive: boolean;
    contextVersions?: IApiContextVersion[];
    onUnsavedChanges: (hasChanges: boolean) => void;
    isEditing: boolean;
    setEditing: (isEditing: boolean) => void;
}

const CHANGE_REQUEST_VIEW_TAB_MAP = {
    [ChangeRequestWorkflowTab.OVERVIEW]: 0,
    [ChangeRequestWorkflowTab.ATTACHMENT]: 1,
    [ChangeRequestWorkflowTab.DISCUSSION]: 2,
    [ChangeRequestWorkflowTab.CHANGES]: 3,
};

export const ChangeRequestWorkflow: React.FC<ChangeRequestWorkflowProps> = ({
    document,
    changeRequest,
    workflowTab,
    fullScreenActive,
    contextVersions,
    onUnsavedChanges,
    isEditing,
    setEditing,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { setUrlParam } = useHistory();
    const changes = useChanges();

    const crModuleVersionOid = changeRequest.variables.CHANGE_REQUEST_MODULE_VERSION_ID;
    const crModuleOid = changeRequest.variables.CHANGE_REQUEST_MODULE_ID;

    const { actions, apiErrors, draftErrorSnackVisible, editDraftSuccess } = useWorkflow('cr');
    const { actions: processActions, apiErrors: processApiErrors, tasks } = useWorkflow('process');
    const { revisionsByContextOid } = useWorkflow('revision');

    const currentAndPreviousModuleVersionByModuleOid = useSelector((state: IApplicationStore) =>
        modulesSelector.currentAndPreviousModuleVersionByModuleOid(state, crModuleOid)
    );
    const currentAndPreviousModuleVersionByModuleVersionOid = useSelector((state: IApplicationStore) =>
        modulesSelector.currentAndPreviousModuleVersionByModuleVersionOid(state, crModuleVersionOid)
    );

    const contextVersionInRevision = revisionsByContextOid[document.contextOid]?.find(
        (revision) => !revision.variables.IS_TEMPORARY_REVISION
    );
    const contextVersionToEditOid = contextVersionInRevision
        ? WorkflowUtils.getContextVersionOid(contextVersionInRevision.variables)
        : document.oid;

    const importJobLockData = useSelector((state: IWebApplicationStore) => state.import.importJobLock);
    const importJobLock = importJobLockData?.[contextVersionToEditOid];
    const { userSettings } = useSelector((state: IWebApplicationStore) => state.user);
    const defaultChangeRequestViewTab = userSettings.uiSettings?.defaultChangeRequestViewTab;

    const [activeTab, setActiveTab] = React.useState<ChangeRequestWorkflowTab | undefined>(workflowTab);
    const [importJobLockSnackBarActive, setImportJobLockSnackBarActive] = React.useState(false);

    React.useEffect(() => {
        dispatch(importJobActions.importJobLockReset());
        actions.resetDraftErrors();
    }, []);

    React.useEffect(() => {
        if (defaultChangeRequestViewTab && defaultChangeRequestViewTab !== ChangeRequestWorkflowTab.OVERVIEW) {
            onChange(CHANGE_REQUEST_VIEW_TAB_MAP[defaultChangeRequestViewTab]);
        }
    }, [defaultChangeRequestViewTab]);

    React.useEffect(() => {
        if (crModuleOid) {
            dispatch(docsActions.moduleVersionsByModuleOidRequested(crModuleOid));
        } else {
            dispatch(docsActions.moduleVersionsByModuleVersionOidRequested(crModuleVersionOid));
        }
    }, [changeRequest?.processInstanceId]);

    React.useEffect(() => {
        if (tasks && Object.keys(tasks).length !== 0) {
            const processInstanceId = changeRequest?.processInstanceId;
            const currentTask = tasks?.[processInstanceId]?.[0];
            const taskName = processInstanceId && currentTask ? currentTask?.name : '';
            if (taskName === 'Retry') {
                setImportJobLockSnackBarActive(true);
                dispatch(importJobActions.importJobLockRequested(contextVersionToEditOid));
            }
        }
    }, [tasks]);

    React.useEffect(() => {
        if (
            importJobLockData &&
            importJobLock &&
            importJobLock.data &&
            importJobLock.data.lockSource === LockSource.ADMIN_UI
        ) {
            setImportJobLockSnackBarActive(true);
        }
    }, [importJobLockData]);

    const importance: string = changeRequest.variables.SEVERITY || '';
    const translatedImportance = importance ? importance : '';

    const label: string = changeRequest.variables.CHANGE_REQUEST_LABEL || 'Label';
    const title = `${label}${label && importance ? `: ${translatedImportance}` : ''}`;

    const getChangeRequestType = () => {
        switch (changeRequest.activityName || changeRequest.processDefinitionName) {
            case CRName.EDIT_CONTENT:
                return t('reader.moduleView.tools.changeRequest.actions.editContent');
            case CRName.EDIT_TITLE:
                return t('reader.moduleView.tools.changeRequest.actions.editTitle');
            case CRName.ADD_MODULE:
                return t('reader.moduleView.tools.changeRequest.actions.add');
            case CRName.DELETE_MODULE:
                return t('reader.moduleView.tools.changeRequest.actions.remove');
            default:
                return '';
        }
    };

    const summary = changeRequest.variables.CHANGE_REQUEST_SUMMARY;

    const status = WorkflowUtils.getStatus(changeRequest, t);

    const subTitle = (
        <>
            <Typography color="inherit">{getChangeRequestType()}</Typography>
            <Typography color="inherit">{summary}</Typography>
            <Typography
                className={combineStrings([
                    'secondary',
                    changeRequest.userHasTasks ? 'user-has-tasks' : null,
                    WorkflowUtils.isCompleted(changeRequest) ? 'completed' : null,
                ])}
            >
                {status}
            </Typography>
        </>
    );

    // TODO: These functions should be properly implemented - they get draft version and latest
    // Also CHANGE_REQUEST_EDIT_MODULE_VERSION_ID is not taken into account and should be used
    const [crModuleVersion, previousModuleVersion] = crModuleOid
        ? currentAndPreviousModuleVersionByModuleOid
        : currentAndPreviousModuleVersionByModuleVersionOid;

    const change = crModuleVersion
        ? (changes.moduleChanges[crModuleVersion.oid] || []).find(
              (moduleChange) => moduleChange.changeRequestId === changeRequest.id
          ) || null
        : null;

    const [visibleChange, setVisibleChange] = React.useState<IApiModuleVersionChange | null>(change);

    React.useEffect(() => {
        setVisibleChange(change);
    }, [change]);

    const editorVarsToHide = ['TEXT_PROPOSAL', 'MARK_CONTENT', 'MARK_LOCATION'];

    const tabs: ITab[] = [
        {
            key: ChangeRequestWorkflowTab.OVERVIEW,
            name: t('workflow.tools.overview.title'),
            dataTestId: '',
            component: (
                <GroupedVarsList
                    type="change-request"
                    processInstanceId={changeRequest.processInstanceId}
                    hiddenVars={
                        changeRequest.variables.PROPOSAL_SCREEN === WorkflowCRProposalScreen.EDITOR
                            ? editorVarsToHide
                            : undefined
                    }
                />
            ),
        },
        {
            key: ChangeRequestWorkflowTab.ATTACHMENT,
            name: t('workflow.tools.attachment.title'),
            dataTestId: '',
            component: <Attachment changeRequest={changeRequest} />,
        },
        {
            key: ChangeRequestWorkflowTab.DISCUSSION,
            name: t('workflow.tools.discussion.title'),
            dataTestId: '',
            component: <Discussion activity={changeRequest} key={'discussion-' + changeRequest?.id} />,
        },
        {
            key: ChangeRequestWorkflowTab.CHANGES,
            name: t('workflow.tools.change.title'),
            dataTestId: '',
            component: (
                <Change
                    draftContextVersionOid={
                        changeRequest?.variables && WorkflowUtils.getContextVersionToEditOid(changeRequest.variables)
                    }
                    type={'module'}
                    activity={changeRequest}
                    change={visibleChange}
                    moduleOid={crModuleOid}
                    moduleVersionOid={crModuleVersionOid}
                    nextModuleVersion={crModuleVersion}
                    key="change"
                    contextVersions={contextVersions}
                />
            ),
        },
    ];

    const onChange = (index: number) => {
        const toolTab: ITab = tabs[index];
        // eslint-disable-next-line
        setActiveTab(toolTab.key as ChangeRequestWorkflowTab);
        setUrlParam(IQueryParam.WORKFLOW_TAB, toolTab.key.toLowerCase());
    };

    const removeApiError = (index: number) => {
        const filteredErrors = [...apiErrors];
        filteredErrors.splice(index, 1);
        actions.apiErrorReset(filteredErrors);
    };

    const removeProcessApiError = (index: number) => {
        const filteredErrors = [...processApiErrors];
        filteredErrors.splice(index, 1);
        processActions.apiErrorReset(filteredErrors);
    };

    const layoutChangeRequestClassName = combineStrings([
        'layout--change-request',
        fullScreenActive ? 'full-screen' : null,
    ]);

    const closeImportJobLockError = () => {
        setImportJobLockSnackBarActive(false);
    };

    const hideChangeIfBackToWaitlistSubmitted = (task: IWorkflowTaskInfo) => {
        if (task.name === 'Back to waitlist') {
            setVisibleChange(null);
        }
    };

    const updateDefaultChangeRequestViewTab = (key: string) => {
        dispatch(
            userActions.updateUserSettings({
                userSettings: {
                    ...userSettings,
                    uiSettings: {
                        defaultChangeRequestViewTab: key.toUpperCase() as ChangeRequestWorkflowTab,
                    },
                },
            })
        );
    };

    const pinButtonRender = (key: string) => {
        return (
            <PinButton
                isPinned={defaultChangeRequestViewTab === key}
                onClickPin={() => updateDefaultChangeRequestViewTab(key)}
                dataTestId={`changeRequestViewTabPinButton-${key}`}
            ></PinButton>
        );
    };

    return (
        <div className={layoutChangeRequestClassName}>
            <ChangeRequestWorkflowSidebar
                title={title}
                subTitle={subTitle && <div className="subtitle" children={subTitle} />}
                changeRequest={changeRequest}
                isDisabled={isEditing}
                onTaskSubmitted={(task) => {
                    hideChangeIfBackToWaitlistSubmitted(task);
                }}
            />
            <div className="layout--workflow__content">
                <Paper className="paper">
                    <ChangeRequestWorkflowSplitView
                        document={document}
                        changeRequest={changeRequest}
                        crModuleVersion={crModuleVersion}
                        previousModuleVersion={previousModuleVersion}
                        setEditing={setEditing}
                        onUnsavedChanges={onUnsavedChanges}
                    />
                    <Tabs
                        key={'tabs-cr'}
                        tabs={tabs}
                        onChange={onChange}
                        value={activeTab}
                        secondaryAction={pinButtonRender}
                    />
                </Paper>
            </div>
            {apiErrors.map((error, index) => (
                <SnackBar
                    key={index}
                    isOpen={true}
                    message={t('workflow.revision.errors.apiError', {
                        message: error.message,
                    })}
                    variant="error"
                    position="fixed"
                    onClose={() => removeApiError(index)}
                />
            ))}
            {draftErrorSnackVisible && (
                <SnackBar
                    key="draft-error"
                    isOpen={true}
                    message={t('workflow.revision.errors.draftSaveError')}
                    variant="error"
                    position="fixed"
                    onClose={() => actions.closeDraftError()}
                />
            )}
            {editDraftSuccess && (
                <SnackBar
                    key="draft-error"
                    isOpen={true}
                    message={t('workflow.revision.errors.draftEditError')}
                    variant="error"
                    position="fixed"
                    onClose={() => actions.closeDraftError()}
                />
            )}
            {processApiErrors.map((error, index) => (
                <SnackBar
                    key={index}
                    isOpen={true}
                    message={t('workflow.revision.errors.apiError', {
                        message: error.message,
                    })}
                    variant="error"
                    position="fixed"
                    onClose={() => removeProcessApiError(index)}
                />
            ))}
            {importJobLock && importJobLock.data && (
                <SnackBar
                    key="import-lock-error"
                    isOpen={importJobLockSnackBarActive}
                    message={t('workflow.changeRequest.errors.jobLocked', {
                        username: importJobLock.data.username,
                        lockSource: importJobLock.data.lockSource,
                    })}
                    variant="warning"
                    position="fixed"
                    onClose={() => closeImportJobLockError()}
                />
            )}
        </div>
    );
};
