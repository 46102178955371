import { authSelector, useHistory, YonderButton } from '@yonder-mind/ui-core';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { complianceReportsActions, useSelectorUiWeb } from '../../../store';
import { Preset } from '../domain/types';
import PresetDialog from './PresetDialog';
import * as uuid from 'uuid';
import isEqual from 'lodash/isEqual';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export const PresetHeader: React.FC<{ currentPreset?: Preset }> = ({ currentPreset }) => {
    const [modalOpen, setModalOpen] = useState(false);

    const { pushUrl } = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { enqueueSnackbar } = useSnackbar();

    const [presetName, setPresetName] = useState('');
    const userInfo = useSelector(authSelector.userInfo);
    const {
        filterConfig,
        appliedFilterConfig,
        dateConfig: { effectiveAt },
    } = useSelectorUiWeb((state) => state.complianceReports);

    const savePreset = (value: string) => {
        setPresetName(value);
        const presetValues: Preset = {
            oid: uuid.v4(),
            userId: userInfo.preferred_username,
            name: value,
            status: appliedFilterConfig.status,
            contextVersionOids: appliedFilterConfig.selectedDocumentIds,
            roles: appliedFilterConfig.selectedRoleIds,
            tagOids: appliedFilterConfig.selectedTagIds,
            users: appliedFilterConfig.selectedUserIds,
        };
        dispatch(
            complianceReportsActions.saveCompliancePresetsRequested(
                effectiveAt ? { ...presetValues, date: effectiveAt } : { ...presetValues },
                (presetOid: string) => {
                    enqueueSnackbar(`${t('complianceReports.preset.messages.success.presetSaved')}`, {
                        variant: 'success',
                    }),
                        pushUrl(`/compliance-reports/${presetValues.status.toLowerCase()}/`, [
                            { key: 'preset', value: presetOid },
                        ]);
                },
                () =>
                    enqueueSnackbar(`${t('complianceReports.preset.messages.error.presetSaveError')}`, {
                        variant: 'error',
                    })
            )
        );
        setModalOpen(false);
    };

    React.useEffect(() => {
        if (currentPreset) {
            setPresetName(currentPreset.name);
        }
    }, [currentPreset]);

    const canSavePreset = isEqual(
        { ...filterConfig, status: '', date: '' },
        { ...appliedFilterConfig, status: '', date: '' }
    );

    const appliedFilterConfigHasData =
        appliedFilterConfig.selectedDocumentIds.length > 0 ||
        appliedFilterConfig.selectedRoleIds.length > 0 ||
        appliedFilterConfig.selectedTagIds.length > 0 ||
        appliedFilterConfig.selectedUserIds.length > 0;

    return (
        <>
            <div className="presets-header">
                {presetName && <div className="preset-title">{presetName}</div>}
                <div>
                    <YonderButton
                        onClick={() => setModalOpen(!modalOpen)}
                        variant="text"
                        className="clear-button"
                        color="primary"
                        disabled={!canSavePreset || !appliedFilterConfigHasData}
                    >
                        {t('complianceReports.preset.savePreset')}
                    </YonderButton>
                </div>
            </div>
            <PresetDialog open={modalOpen} onClose={() => setModalOpen(false)} savePreset={savePreset} />
        </>
    );
};

export default PresetHeader;
