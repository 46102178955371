import React, { useEffect } from 'react';
import { DropDownForSelectionButton, fileDropActions, ItemSelector } from '@yonder-mind/ui-core';
import { DropdownType } from '../../../interfaces';
import { complianceReportsActions, useSelectorUiWeb } from '../../../store';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

interface Props {
    selectedDropdown: DropdownType;
    handleOnClick: (type: DropdownType) => void;
    disabled?: boolean;
}

export const DropDownUsersSelector: React.FC<Props> = (props) => {
    const { selectedDropdown, handleOnClick } = props;
    const dispatch = useDispatch();

    const { t } = useTranslation();

    const users = useSelectorUiWeb((state) =>
        state.fileDrop.usersList.map((user) => {
            return { id: user.id, name: user.username };
        })
    );

    const setSelectedIds = (ids: string[]) => dispatch(complianceReportsActions.updateFilterConfigSelectedUserIds(ids));
    const selectedIds = useSelectorUiWeb((state) => state.complianceReports.filterConfig.selectedUserIds);
    const isLoading = useSelectorUiWeb((state) => state.fileDrop.usersListLoading);

    useEffect(() => {
        // TODO move usersRequested to a common reducer?
        dispatch(fileDropActions.usersRequested());
    }, []);

    return (
        <DropDownForSelectionButton
            disabled={props.disabled}
            title={t('complianceReports.filter.users.title')}
            expanded={selectedDropdown === 'users'}
            onClick={() => handleOnClick('users')}
            handleClose={() => handleOnClick('none')}
            numberOfAllItems={users.length}
            numberOfSelectedItems={selectedIds.length}
            multiSelect={true}
        >
            <ItemSelector
                isLoading={isLoading}
                itemList={users}
                selectedItemIds={selectedIds}
                updateSelectedItemIds={setSelectedIds}
                multiSelect={true}
                selectedItemsText={t('complianceReports.filter.users.selected')}
                allItemsText={t('complianceReports.filter.users.all')}
                emptyAllItemsText={t('complianceReports.filter.users.notAvailable')}
                noMatches={t('complianceReports.filter.users.noMatches')}
                otherItemsText={t('complianceReports.filter.users.other')}
                updateName={true}
            />
        </DropDownForSelectionButton>
    );
};
