import React, { useMemo, useState } from 'react';
import { FormControl, IconButton, List, MenuItem, Select, Tooltip } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import { MetadataTagsList } from './MetadataTagsList';
import { useSelector } from 'react-redux';
import {
    findTagByOid,
    getAllAncestorOids,
    getAllDescendantOids,
    IApplicationStore,
    ITagTreeNode,
} from '@yonder-mind/ui-core';
import { MetadataTagsSelectorModal } from './MetadataTagsSelectorModal';
import { MetadataUsersSelectorModal } from './MetadataUsersSelectorModal';
import { MetadataUsersList } from './MetadataUsersList';
import { useTranslation } from 'react-i18next';
import { FileDropMetadata } from '../../domain/types';

interface IProps {
    setMetadata: (metadata: FileDropMetadata) => void;
    currentMetadata: FileDropMetadata;
    disabled?: boolean;
}

enum SelectOption {
    tags = 'tags',
    users = 'users',
}

export const MetadataUsersTagsSelector: React.FC<IProps> = ({ setMetadata, currentMetadata, disabled }) => {
    const [selectedValue, setSelectedValue] = useState<SelectOption>(
        currentMetadata.users?.length ? SelectOption.users : SelectOption.tags
    );
    const [usersModalOpen, setUsersModalOpen] = useState(false);
    const [tagsModalOpen, setTagsModalOpen] = useState(false);

    const { t } = useTranslation();

    const hasTagsSelected = selectedValue === SelectOption.tags && !!currentMetadata.tagOids?.length;
    const hasUsersSelected = selectedValue === SelectOption.users && !!currentMetadata.users?.length;
    const toolTipTitle = hasTagsSelected
        ? t('fileDrop.metadataPanel.selector.removeTags')
        : hasUsersSelected
        ? t('fileDrop.metadataPanel.selector.removeUsers')
        : '';
    const handleChange = (e: React.ChangeEvent<any>) => {
        if (e.target.value === SelectOption.users) {
            setSelectedValue(SelectOption.users);
        } else if (e.target.value === SelectOption.tags) {
            setSelectedValue(SelectOption.tags);
        }
    };

    const tagDomains = useSelector((state: IApplicationStore) => state.fileDrop.fileDropTags);

    const allTags: Record<string, ITagTreeNode> = useMemo(
        () => tagDomains.reduce((acc, filter) => ({ ...acc, ...filter.tags }), {}),
        [tagDomains]
    );

    const deleteTag = (oid: string) => {
        const tagToDelete = findTagByOid(
            oid,
            tagDomains.flatMap((tagDomain) => tagDomain.children)
        );
        const allDescendantsOids = getAllDescendantOids(tagToDelete);
        const allAncestorsOids = getAllAncestorOids(
            tagToDelete,
            tagDomains.flatMap((tagDomain) => tagDomain.children)
        );

        setMetadata({
            tagOids: currentMetadata.tagOids.filter(
                (tag) => tag !== oid && !allDescendantsOids.includes(tag) && !allAncestorsOids.includes(tag)
            ),
        });
    };

    return (
        <>
            <div className="metadata-select">
                <div className="select-switcher">
                    <FormControl fullWidth>
                        <Tooltip
                            title={toolTipTitle}
                            style={hasTagsSelected || hasUsersSelected ? { cursor: 'not-allowed' } : {}}
                        >
                            <Select
                                disabled={hasTagsSelected || hasUsersSelected || disabled}
                                labelId="select-label"
                                id="select-metadata"
                                value={selectedValue}
                                onChange={handleChange}
                            >
                                <MenuItem value={SelectOption.tags} data-testid="tagsSelector">
                                    {t('fileDrop.metadataPanel.selector.tags')}
                                </MenuItem>
                                <MenuItem value={SelectOption.users} data-testid="usersSelector">
                                    {t('fileDrop.metadataPanel.selector.users')}
                                </MenuItem>
                            </Select>
                        </Tooltip>
                    </FormControl>
                    <div className="new-item-button">
                        <IconButton
                            className="new-item-button-icon"
                            color="primary"
                            component="span"
                            onClick={() =>
                                selectedValue === SelectOption.tags ? setTagsModalOpen(true) : setUsersModalOpen(true)
                            }
                            disabled={disabled}
                            size="small"
                            data-testid="newItemButton"
                        >
                            <AddCircle />
                        </IconButton>
                    </div>
                </div>
                <div className="list-items-wrapper">
                    {selectedValue === SelectOption.tags ? (
                        <List className="tags-selector" disablePadding={true}>
                            <MetadataTagsList
                                disabled={disabled}
                                tagDomains={tagDomains}
                                activeTags={currentMetadata.tagOids ?? []}
                                allTags={allTags}
                                handleDelete={(event) => {
                                    const oid = event.currentTarget.parentElement
                                        ? event.currentTarget.parentElement.id
                                        : '';

                                    deleteTag(oid);
                                }}
                            />
                        </List>
                    ) : (
                        <List className="users-selector" disablePadding={true}>
                            <MetadataUsersList
                                selectedUsers={currentMetadata.users ?? []}
                                setMetadata={setMetadata}
                                disabled={disabled}
                            />
                        </List>
                    )}
                </div>
            </div>
            {usersModalOpen && (
                <MetadataUsersSelectorModal
                    open={usersModalOpen}
                    onClose={() => setUsersModalOpen(false)}
                    initialMetadata={currentMetadata}
                    setMetadata={setMetadata}
                />
            )}
            {tagsModalOpen && (
                <MetadataTagsSelectorModal
                    open={tagsModalOpen}
                    onClose={() => setTagsModalOpen(false)}
                    tagOids={currentMetadata.tagOids}
                    setMetadata={setMetadata}
                    allTags={allTags}
                    tagDomains={tagDomains}
                />
            )}
        </>
    );
};
