import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TableCell, ButtonBase } from '@material-ui/core';
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';

interface ChangeSettingButtonProps {
    setting: string;
    checked?: boolean;
    onClick: () => any;
    disabled?: boolean;
}

export const ChangeSettingButton: React.FC<ChangeSettingButtonProps> = ({ setting, checked, onClick, disabled }) => {
    const { t } = useTranslation();

    const onTouchEnd = (e: React.TouchEvent) => {
        e.preventDefault();
        !disabled ? onClick() : null;
    };

    return (
        <TableCell
            className="settings-button"
            onClick={() => {
                !disabled ? onClick() : null;
            }}
            onTouchEnd={onTouchEnd}
            key={setting}
        >
            <ButtonBase disabled={disabled} className="settings-button-container">
                <div className="settings-button-text">{t(`workflow.tools.change.form.settings.${setting}`)}</div>
                <div className="settings-button-icon">
                    {checked ? (
                        <CheckBox color={disabled ? 'disabled' : 'primary'} />
                    ) : (
                        <CheckBoxOutlineBlank color={disabled ? 'disabled' : 'primary'} />
                    )}
                </div>
            </ButtonBase>
        </TableCell>
    );
};
