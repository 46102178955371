import React, { useMemo, useState } from 'react';
import { Table, TableContainer } from '@material-ui/core';
import { FileDropTableItem, SelectedFileKeys } from '../domain/types';
import { FileDropTableHead, SortingConfiguration } from './FileDropTableHead';
import { FileDropTableBody } from './FileDropTableBody';
import { KebabMenuProps } from './TableKebabMenu';
import { SearchBar } from '@yonder-mind/ui-core';

interface IProps {
    fileList: FileDropTableItem[];
    setSelectedFileKeys: (items: SelectedFileKeys) => void;
    selectedFileKeys: SelectedFileKeys;
    noDataComponent: React.ReactNode;
    disableMultiSelect?: boolean;
    kebabMenuProps?: KebabMenuProps;
    withUploadDate?: boolean;
    className?: string;
}

export const FileDropTable: React.FC<IProps> = (props) => {
    const {
        fileList,
        selectedFileKeys,
        setSelectedFileKeys,
        noDataComponent,
        disableMultiSelect,
        kebabMenuProps,
        withUploadDate,
        className,
    } = props;

    const [sortingConfiguration, setSortingConfiguration] = useState<SortingConfiguration>({
        order: 'asc',
        orderBy: 'name',
    });

    const [searchText, setSearchText] = useState('');

    const handleToggleSelectAllItems = () => {
        if (selectedFileKeys.length === fileList.length) {
            setSelectedFileKeys([]);
        } else {
            setSelectedFileKeys(fileList.map((item) => item.key));
        }
    };

    const onSearchRequested = (searchValue: string) => setSearchText(searchValue);
    const onSearchCleared = () => setSearchText('');
    const onInputChanged = (searchValue: string) => {
        setSearchText(searchValue);
    };
    const allItemsAreSelected = useMemo(() => {
        return fileList.length > 0 && fileList.length === selectedFileKeys.length;
    }, [fileList, selectedFileKeys]);

    return fileList.length === 0 ? (
        <>{noDataComponent}</>
    ) : (
        <div className={`file-drop-table-page ${className}`}>
            <SearchBar
                className="file-drop-search-bar"
                onSearchRequested={onSearchRequested}
                onSearchCleared={onSearchCleared}
                onInputChanged={onInputChanged}
            />
            <TableContainer className="file-drop-table-container">
                <Table size="small" stickyHeader>
                    <FileDropTableHead
                        disableMultiSelect={disableMultiSelect}
                        handleToggleSelectAllItems={handleToggleSelectAllItems}
                        allItemsAreSelected={allItemsAreSelected}
                        sortingConfiguration={sortingConfiguration}
                        setSortingConfiguration={setSortingConfiguration}
                        withUploadDate={withUploadDate}
                    />
                    <FileDropTableBody
                        fileList={fileList}
                        searchText={searchText}
                        sortingConfiguration={sortingConfiguration}
                        selectedFileKeys={selectedFileKeys}
                        setSelectedFileKeys={setSelectedFileKeys}
                        disableMultiSelect={disableMultiSelect}
                        kebabMenuProps={kebabMenuProps}
                        withUploadDate={withUploadDate}
                    />
                </Table>
            </TableContainer>
        </div>
    );
};
