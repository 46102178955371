import React from 'react';
import {
    Divider,
    IApiContextVersion,
    IWorkflowActivity,
    IWorkflowHistoricActivity,
    IWorkflowRevisionHistoric,
    IWorkflowTaskInfo,
    Spinner,
} from '@yonder-mind/ui-core';
import { useWorkflow } from '../../../context';
import { WorkflowTask } from './WorkflowTask';

export type WorkflowTaskVariant = 'change-request' | 'revision';

interface WorkflowTasksProps {
    contextVersion: IApiContextVersion;
    variant: WorkflowTaskVariant;
    activity?: IWorkflowActivity | IWorkflowHistoricActivity | IWorkflowRevisionHistoric;
    isDisabled: boolean;
    onTaskSubmitted?: (task: IWorkflowTaskInfo) => void;
}

export const WorkflowTasks: React.FC<WorkflowTasksProps> = ({
    contextVersion,
    activity,
    isDisabled,
    variant,
    onTaskSubmitted,
}) => {
    const { actions, tasks, apiErrors } = useWorkflow('process');
    const [isLoadingTasks, setLoadingTasks] = React.useState(false);

    const { id, processInstanceId } = activity || {};

    React.useEffect(() => {
        if (processInstanceId) {
            setLoadingTasks(true);
            actions.requestMyTasks(processInstanceId);
        }
    }, [id]);

    const myTaskOfProcess = processInstanceId ? tasks[processInstanceId] || [] : [];
    React.useEffect(() => {
        setLoadingTasks(false);
    }, [myTaskOfProcess, apiErrors]);

    const sortedTasks = myTaskOfProcess.sort(
        (taskA: IWorkflowTaskInfo, taskB: IWorkflowTaskInfo) => taskB.priority - taskA.priority
    );

    const handleOnClicked = (task: IWorkflowTaskInfo) => {
        setLoadingTasks(true);

        if (onTaskSubmitted != undefined) {
            onTaskSubmitted(task);
        }
    };

    return isLoadingTasks ? (
        <>
            <Spinner />
            <Divider />
        </>
    ) : sortedTasks.length !== 0 ? (
        <>
            <div className="workflow-tasks">
                {sortedTasks.map((task) => (
                    <WorkflowTask
                        contextVersion={contextVersion}
                        key={task.id}
                        task={task}
                        isDisabled={isDisabled}
                        onClick={() => handleOnClicked(task)}
                        variant={variant}
                    />
                ))}
            </div>
            <Divider />
        </>
    ) : (
        <></>
    );
};
