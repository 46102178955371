import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { DateUtils, DocumentClass } from '@yonder-mind/ui-core';
import { ComplianceTableItem } from '../domain/types';
import { ProgressBar } from '../DocumentsTable/ProgressBar';
import { useTranslation } from 'react-i18next';
import { useSelectorUiWeb } from '../../../store';

export const SingleDocumentTable: React.FC<{ complianceDocument: ComplianceTableItem }> = ({ complianceDocument }) => {
    const { t } = useTranslation();

    const status = useSelectorUiWeb((state) => state.complianceReports.filterConfig.status);
    return (
        <TableContainer className="compliance-documents-table">
            <Table size="small" stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>{t('complianceReports.documentsTable.name')}</TableCell>
                        <TableCell>{t('complianceReports.documentsTable.version')}</TableCell>
                        <TableCell>{t('complianceReports.documentsTable.read')}</TableCell>
                        <TableCell>{t('complianceReports.documentsTable.readAndAck')}</TableCell>
                        <TableCell>{t('complianceReports.documentsTable.effectiveFrom')}</TableCell>
                        <TableCell>{t('complianceReports.documentsTable.effectiveTo')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>{complianceDocument.name}</TableCell>
                        <TableCell>
                            {complianceDocument.documentClass === DocumentClass.DOCUMENT
                                ? complianceDocument.revision
                                : '—'}
                        </TableCell>
                        <TableCell>
                            <ProgressBar
                                currentAmount={complianceDocument.readDoneCount}
                                totalAmount={complianceDocument.readTotalCount}
                                futureUse={status === 'FUTURE'}
                            />
                        </TableCell>
                        <TableCell>
                            <ProgressBar
                                currentAmount={complianceDocument.ackDoneCount}
                                totalAmount={complianceDocument.ackTotalCount}
                                futureUse={status === 'FUTURE'}
                            />
                        </TableCell>
                        <TableCell>
                            {DateUtils.ISOToFormattedDateWithoutTime(complianceDocument.effectiveFrom)}
                        </TableCell>
                        <TableCell>
                            {complianceDocument.effectiveTo
                                ? DateUtils.ISOToFormattedDateWithoutTime(complianceDocument.effectiveTo)
                                : t('complianceReports.documentsTable.effectiveToOpenEnded')}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};
