import * as React from 'react';
import { Divider } from '@material-ui/core';
import { IWorkflowComment } from '@yonder-mind/ui-core';
import { Comment } from './Comment';

interface IProps {
    group: string;
    comments: IWorkflowComment[];
    userId: string;
    active: boolean;
}

export const CommentGroup: React.FC<IProps> = ({ active, group, comments, userId }) => {
    return (
        <div className={`comment-group ${active ? 'active' : ''}`}>
            <Divider className="divider" />

            <div className={`comment-group-header`}>
                <div className={`comment-group-label`}>Status: {group}</div>
            </div>

            {comments.map((comment, i) => (
                <Comment {...{ comment }} own={comment.userId === userId} key={comment.id || i} />
            ))}
        </div>
    );
};
