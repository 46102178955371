import { HistoryLogResponse, HistoryLogsFetchRequestProperties } from '../../interfaces/historyLog/historyLogApi';
import { Rest } from '@yonder-mind/ui-core';
import { historyLogsExportUrl, historyLogsUrl } from './history-log-api-constants';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
dayjs.extend(utc);

export const getHistoryLogs = async (
    historyLogsFetchRequestProperties: HistoryLogsFetchRequestProperties
): Promise<HistoryLogResponse> => {
    const response = await Rest.getRequest(historyLogsUrl(historyLogsFetchRequestProperties));
    return response.json();
};

export const exportHistoryLogs = async (historyLogsFetchRequestProperties: HistoryLogsFetchRequestProperties) => {
    const response = await Rest.getRequest(historyLogsExportUrl(historyLogsFetchRequestProperties));
    response
        .blob()
        .then((blob) => URL.createObjectURL(blob))
        .then((url) => {
            const link = document.createElement('a');
            link.href = url;
            link.download = `Yonder-History_${dayjs.utc().format('YYYY-MM-DD_HHmmss')}.xlsx`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
};

export type HistoryLogApiContract = {
    getHistoryLogs: typeof getHistoryLogs;
    exportHistoryLogs: typeof exportHistoryLogs;
};
