import React from 'react';
import { Excel, YonderButton } from '@yonder-mind/ui-core';
import { CircularProgress } from '@material-ui/core';

interface ExportExcelButtonProps {
    isDisabled: boolean;
    isExporting: boolean;
    onClick: () => void;
}

export const ExportExcelButton: React.FC<ExportExcelButtonProps> = ({ isDisabled, isExporting, onClick }) => {
    const icon = isExporting ? <CircularProgress size={24} /> : <Excel />;

    return (
        <YonderButton
            variant="contained"
            color="primary"
            onClick={onClick}
            startIcon={icon}
            disabled={isDisabled || isExporting}
        />
    );
};
