import { StructureType } from '@yonder-mind/ui-core';
import { ApiConstants } from '../../utils/ApiConstants';

export class FolderConstants extends ApiConstants {
    static getFoldersList(processResponse: boolean): URL {
        return ApiConstants.relativeUrl(
            `/v1/generic-structure?processResponse=${processResponse}&structureType=${StructureType.DocumentFolders}`
        );
    }

    static updateFolderStructure(structureOid: string): URL {
        return ApiConstants.relativeUrl(`/v1/generic-structure/${structureOid}/entries`);
    }
}
