import { ApiConstants } from './ApiConstants';

export class ApiNotificationsConstants {
    static getNotifications(): URL {
        return ApiConstants.relativeUrl(`/v1/notifications`);
    }

    static deleteNotification(notificationId: string): URL {
        return ApiConstants.relativeUrl(`/v1/notifications/${notificationId}`);
    }

    static readNotification(notificationId: string): URL {
        return ApiConstants.relativeUrl(`/v1/notifications/${notificationId}/read`);
    }
}
