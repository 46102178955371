import type { ComplianceInfo, ComplianceInfoStore } from '@yonder-mind/ui-core';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation } from 'react-i18next';
import HelpOutline from '@material-ui/icons/HelpOutline';

import * as Styled from './ComplianceInfoBox.style';

interface ComplianceInfoProps {
    complianceInfo: ComplianceInfoStore;
    onMoreInfoClick: (complianceInfo: ComplianceInfo[]) => void;
    dataTestId: string;
}
export const ComplianceInfoBox: React.FC<ComplianceInfoProps> = ({ dataTestId, complianceInfo, onMoreInfoClick }) => {
    const { t } = useTranslation();

    if (complianceInfo.isLoading || complianceInfo.error) {
        return null;
    }

    if (complianceInfo.data === null) {
        return null;
    }

    if (complianceInfo.data.length === 0) {
        return (
            <Styled.ComplianceInfoBox data-testid={dataTestId}>
                {t('workflow.changeRequest.complianceInfo.notFound')}
            </Styled.ComplianceInfoBox>
        );
    }

    return (
        <Styled.ComplianceInfoBox
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            data-testid={dataTestId}
        >
            {t('workflow.changeRequest.complianceInfo.found', { complianceInfoCount: complianceInfo.data.length })}
            <IconButton
                data-testid={`${dataTestId}-button`}
                onClick={() => onMoreInfoClick(complianceInfo.data)}
                size="small"
            >
                <HelpOutline />
            </IconButton>
        </Styled.ComplianceInfoBox>
    );
};
