import { type IApiLink, Rest, type LinkApi } from '@yonder-mind/ui-core';
import { LinkConstants } from './LinkConstants';

export class LinkRestApi implements LinkApi {
    async getModuleLinks(moduleOid: string): Promise<IApiLink[]> {
        const response = await Rest.getRequest(LinkConstants.getModuleLinks(moduleOid).toString());
        return response.json();
    }

    async getModuleLinkTypes(): Promise<string[]> {
        const response = await Rest.getRequest(LinkConstants.getModuleLinkTypes().toString());
        return response.json();
    }

    async saveModuleLink(link: IApiLink): Promise<void> {
        await Rest.postRequest(LinkConstants.saveModuleLink().toString(), link);
    }

    async deleteModuleLink(linkOid: string): Promise<boolean> {
        const response = await Rest.deleteRequest(LinkConstants.deleteModuleLink(linkOid).toString());
        return response.json();
    }
}
