import { GetIApiFileDropMetadata, IApiFileDropMetadata, Rest, FileDropApi } from '@yonder-mind/ui-core';
import { FileDropConstants } from './FileDropConstants';

// TODO: add tests for this in ui-web
export class FileDropRestApi implements FileDropApi {
    async deleteFile(payload: { fileId: string }): Promise<boolean> {
        await Rest.deleteRequest(FileDropConstants.deleteFile(payload.fileId).toString());
        return true;
    }
    async getFileDropList(): Promise<GetIApiFileDropMetadata[]> {
        const response = await Rest.getRequest(FileDropConstants.getFileDropList().toString());
        return response.json();
    }
    async getFileDropListDashboard(): Promise<GetIApiFileDropMetadata[]> {
        const response = await Rest.getRequest(FileDropConstants.getFileDropListDashboard().toString());
        return response.json();
    }
    async updateFileMetadata({
        fileId,
        metadata,
    }: {
        fileId: string;
        metadata: IApiFileDropMetadata;
    }): Promise<GetIApiFileDropMetadata> {
        const response = await Rest.postRequest(FileDropConstants.updateFileMetadata(fileId).toString(), metadata);
        return response.json();
    }

    async updateFileContent({ formData, fileId }: { formData: FormData; fileId: string }): Promise<boolean> {
        await Rest.postFormData(FileDropConstants.updateFileContent(fileId).toString(), formData);
        return true;
    }

    getFileContent(fileId: string): Promise<string> {
        return Promise.resolve(FileDropConstants.getFileContent(fileId).toString());
    }

    async getMemoContent(fileId: string): Promise<string> {
        const response = await Rest.getRequest(FileDropConstants.getFileContent(fileId).toString());
        return response.text();
    }

    async markFileAsRead(fileId: string): Promise<void> {
        await Rest.postRequest(FileDropConstants.markFileAsRead(fileId).toString());
    }

    async uploadFile(file: FormData, key: string): Promise<{ success: boolean; key: string }> {
        try {
            await Rest.postFormData(FileDropConstants.uploadFile().toString(), file);
            return { success: true, key };
        } catch (e) {
            return { success: false, key };
        }
    }

    async replaceFileContent(file: FormData, fileIdToUpdate: string): Promise<void> {
        await Rest.postFormData(FileDropConstants.replaceFileContent(fileIdToUpdate).toString(), file);
    }

    async createSuccessor(
        file: FormData,
        key: string,
        fileIdToUpdate: string
    ): Promise<{ success: boolean; key: string }> {
        try {
            await Rest.postFormData(FileDropConstants.createSuccessor(fileIdToUpdate).toString(), file);
            return { success: true, key };
        } catch (e) {
            return { success: false, key };
        }
    }
}
