import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, TextField, InputAdornment } from '@material-ui/core';
import { InputBaseProps } from '@material-ui/core/InputBase';
import { Clear, SearchOutlined } from '@material-ui/icons';

interface SubmissionSearchBarProps extends InputBaseProps {
    disabled?: boolean;
    focus?: boolean;
    searchValue?: string;
    placeholder?: string;
    label?: string;
    onSearchCleared?: () => void;
    onInputChanged?: (searchValue: string) => void;
    clearSize?: 'small' | 'medium';
    isResultsListEmpty?: boolean;
}

export const SubmissionsSearchBar: React.FC<SubmissionSearchBarProps> = (props) => {
    const { onInputChanged, placeholder, isResultsListEmpty, label } = props;
    const { t } = useTranslation();

    const [searchValue, setSearchValue] = useState(props.searchValue || '');

    useEffect(() => {
        setSearchValue(props.searchValue || '');
    }, [props.searchValue]);

    const handleInput = (event: any) => {
        setSearchValue(event.target.value);
        if (event.target.value === '' && props.onSearchCleared) {
            props.onSearchCleared();
            return;
        }
        onInputChanged && onInputChanged(event.target.value);
    };

    const handleCancel = () => {
        setSearchValue('');
        if (props.onSearchCleared) {
            props.onSearchCleared();
        }
    };

    return (
        <>
            <TextField
                data-testid={'submissions-search-field'}
                className={`submissions-search-field ${isResultsListEmpty ? 'error' : ''}`}
                type={'text'}
                label={label}
                placeholder={placeholder}
                variant="filled"
                value={searchValue}
                onChange={handleInput}
                autoFocus={props.focus}
                disabled={props.disabled}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchOutlined />
                        </InputAdornment>
                    ),
                    endAdornment:
                        searchValue && searchValue.trim().length > 0 ? (
                            <IconButton
                                data-testid="submissions-clear-search-button"
                                aria-label={t('form.search.clearLabel')}
                                onClick={handleCancel}
                            >
                                <Clear />
                            </IconButton>
                        ) : null,
                }}
            />
        </>
    );
};
