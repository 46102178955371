import {
    type IApiFlashCard,
    type IApiFlashCardDeletion,
    type IApiFlashCardPlay,
    type IApiFlashCardPlayAnswer,
    type IApiFlashCardSet,
    Rest,
    type FlashCardApi,
} from '@yonder-mind/ui-core';
import { FlashCardConstants } from './FlashCardConstants';

export class FlashCardRestApi implements FlashCardApi {
    async getFlashcards(moduleOid: string): Promise<IApiFlashCard[]> {
        const response = await Rest.getRequest(FlashCardConstants.getFlashcards(moduleOid).toString());
        return response.json();
    }

    async saveFlashcard(moduleOid: string, flashcard: IApiFlashCard): Promise<IApiFlashCard> {
        const response = await Rest.postRequest(FlashCardConstants.getFlashcards(moduleOid).toString(), flashcard);
        return response.json();
    }

    async deleteFlashcard(moduleOid: string, flashcard: IApiFlashCard): Promise<IApiFlashCardDeletion> {
        if (!flashcard.oid) {
            throw Error('flashcard oid missing!');
        }
        const response = await Rest.deleteRequest(
            FlashCardConstants.deleteFlashcard(moduleOid, flashcard.oid).toString()
        );
        return response.json();
    }

    async getFlashcardSets(): Promise<IApiFlashCardSet[]> {
        const response = await Rest.getRequest(FlashCardConstants.getFlashcardSets().toString());
        return response.json();
    }

    async saveFlashcardSet(set: IApiFlashCardSet): Promise<IApiFlashCardSet[]> {
        const response = await Rest.postRequest(FlashCardConstants.saveFlashcardSet().toString(), set);
        return response.json();
    }

    async deleteFlashcardSet(flashcardSetOid: string): Promise<void> {
        await Rest.deleteRequest(FlashCardConstants.deleteFlashcardSet(flashcardSetOid).toString());
    }

    async updateFlashcardSets(sets: IApiFlashCardSet[]): Promise<IApiFlashCardSet[]> {
        const response = await Rest.postRequest(FlashCardConstants.getFlashcardSets().toString(), sets);
        return response.json();
    }

    async playFlashcardSet(flashcardSetOid: string): Promise<IApiFlashCardPlay> {
        const response = await Rest.getRequest(FlashCardConstants.getPlayFlashcardSet(flashcardSetOid).toString());
        return response.json();
    }

    async answerFlashcard(answer: IApiFlashCardPlayAnswer): Promise<void> {
        await Rest.postRequest(FlashCardConstants.answerFlashcard().toString(), answer);
    }
}
