import { IWorkflowFormField, IWorkflowChangeRequestV2 } from '@yonder-mind/ui-core';
import { IWebApplicationStore, IWorkflowCrEdit } from '../../../interfaces';

const getNewCRForm = (state: IWebApplicationStore): { [type: string]: IWorkflowFormField[] } | null | undefined =>
    state.workflow.changeRequest.startingFormFields;

const getCREdit = (state: IWebApplicationStore, processInstanceId: string): IWorkflowCrEdit | undefined =>
    state.workflow.changeRequest.editCR[processInstanceId];

const getChangeRequests = (state: IWebApplicationStore): IWorkflowChangeRequestV2[] => {
    return [...state.workflow.changeRequest.activeCRs, ...state.workflow.changeRequest.finishedCRs];
};
export const changeRequestSelector = {
    getNewCRForm,
    getCREdit,
    getChangeRequests,
};
