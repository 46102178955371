import { IAuthApi, IAppModeApi, IAppModeStore, IAppModeStatus } from '@yonder-mind/ui-core';

export class AppModeApi implements IAppModeApi {
    private authUtilsApi: IAuthApi;

    constructor(authUtilsApi: IAuthApi) {
        this.authUtilsApi = authUtilsApi;
    }

    async getAppModeStatus(): Promise<IAppModeStatus> {
        const storageKey = await this.getStorageKey();

        const storedItems = window.localStorage.getItem(storageKey);

        if (!storedItems) {
            return Promise.resolve({
                appMode: 'light',
                isSaved: false,
            });
        }

        const items: IAppModeStore = JSON.parse(storedItems);

        await this.storeAppModeStatus(items);

        return Promise.resolve(items);
    }

    async saveAppModeStatus(item: IAppModeStatus): Promise<string> {
        const result = 'light';

        const statusStorage: IAppModeStatus = {
            appMode: item.appMode,
            isSaved: item.isSaved,
        };

        await this.storeAppModeStatus(statusStorage);

        return Promise.resolve(result);
    }

    private async getStorageKey(): Promise<string> {
        const configuration = await this.authUtilsApi.loadConfiguration();

        return Promise.resolve(`${configuration.realm}.appMode`);
    }

    private async storeAppModeStatus(items: IAppModeStatus): Promise<void> {
        const storageKey = await this.getStorageKey();

        window.localStorage.setItem(storageKey, JSON.stringify(items));
    }
}
