import {
    IWorkflowAttachment,
    IWorkflowComment,
    IWorkflowHistoricActivity,
    IWorkflowTaskInfo,
} from '@yonder-mind/ui-core';
import { IWebApplicationStore } from '../../../interfaces';

const getTasksByProcess = (state: IWebApplicationStore): { [processId: string]: IWorkflowTaskInfo[] } =>
    state.workflow.process.tasks;

const getHistoriesByOProcessId = (state: IWebApplicationStore): { [processId: string]: IWorkflowHistoricActivity[] } =>
    state.workflow.process.histories;

const getCommentsByProcess = (
    state: IWebApplicationStore
): { [processInstanceId: string]: { [group: string]: IWorkflowComment[] } } => state.workflow.process.comments;

const getAttachmentsByProcess = (state: IWebApplicationStore): { [processInstanceId: string]: IWorkflowAttachment[] } =>
    state.workflow.process.attachments;

const commentPermissionsByProcess = (state: IWebApplicationStore): { [processInstanceId: string]: boolean } =>
    state.workflow.process.permissions;

const getNotificationState = (state: IWebApplicationStore) => state.workflow.process.notifications;

const getVariables = (state: IWebApplicationStore) => state.workflow.process.variables;
const getVariablesGrouped = (state: IWebApplicationStore) => state.workflow.process.variablesGrouped;

export const processSelector = {
    commentPermissionsByProcess,
    getCommentsByProcess,
    getAttachmentsByProcess,
    getHistoriesByOProcessId,
    getNotificationState,
    getTasksByProcess,
    getVariablesGrouped,
    getVariables,
};
