import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@material-ui/core';
import { CloseButton, YonderButton } from '@yonder-mind/ui-core';
import { useTranslation } from 'react-i18next';

interface IProps {
    open: boolean;
    onClose: () => void;
    onOk: (fileName: string) => void;
    fileName: string;
}
export const RenameFileDialog: React.FC<IProps> = (props) => {
    const [inputValue, setInputValue] = React.useState('');
    const { open, onClose, onOk, fileName } = props;
    const { t } = useTranslation();

    React.useEffect(() => {
        fileName && setInputValue(fileName);
    }, [fileName]);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };

    const handleOk = () => {
        onOk(inputValue);
    };

    return (
        <Dialog className="modal-input" open={open} onClose={onClose} fullWidth={true} role="modal" data-testid="modal">
            <DialogTitle className="modal-input-title" disableTypography={true} data-testid="modalHeader">
                <Typography variant="h6">{t('fileDrop.kebabMenu.rename')}</Typography>
                <CloseButton onClick={onClose} size="small" data-testid="modalCloseButton" />
            </DialogTitle>
            <DialogContent className="modal-input-content" data-testid="modalContent">
                <TextField
                    label=""
                    variant="outlined"
                    size="medium"
                    onChange={onChange}
                    fullWidth={true}
                    value={inputValue}
                    autoFocus={true}
                />
            </DialogContent>
            <DialogActions className="modal-input-actions" data-testid="modalActions">
                <YonderButton onClick={onClose} variant="text" data-testid="modalCancelButton">
                    {t('fileDrop.renameModal.cancel')}
                </YonderButton>
                <YonderButton
                    onClick={handleOk}
                    variant="contained"
                    color="primary"
                    disabled={inputValue.length === 0}
                    data-testid="modalConfirmationButton"
                >
                    {t('fileDrop.renameModal.ok')}
                </YonderButton>
            </DialogActions>
        </Dialog>
    );
};
