import React from 'react';
import { useTranslation } from 'react-i18next';
import { Chip, TableBody, TableCell, TableRow } from '@material-ui/core';
import { DateUtils, FormSubmissionStatusEnum, FormSubmissionTableItem } from '@yonder-mind/ui-core';
import { SortingConfiguration } from './SubmissionsTableHead';
import { sortComparator } from '../utils/FormUtils';

interface SubmissionTableBodyProps {
    formSubmissionsList: FormSubmissionTableItem[];
    sortingConfiguration: SortingConfiguration;
    columnsToShow: string[];
    openSubmission: (oid: string, formVersionOid: string) => void;
}

export const SubmissionsTableBody: React.FC<SubmissionTableBodyProps> = ({
    formSubmissionsList,
    sortingConfiguration,
    columnsToShow,
    openSubmission,
}) => {
    const { t } = useTranslation();

    const isSubmitted = (item: FormSubmissionTableItem) => {
        return item.formSubmissionStatus === FormSubmissionStatusEnum.SUBMITTED;
    };

    const getBadgeStyle = (item: FormSubmissionTableItem) => {
        return item.color ? `color-${item.color}` : isSubmitted(item) ? 'submitted' : 'draft';
    };

    const getBadgeText = (item: FormSubmissionTableItem) => {
        return item.status
            ? item.status.toUpperCase()
            : isSubmitted(item)
            ? t('form.badge.submitted').toUpperCase()
            : t('form.badge.draft').toUpperCase();
    };

    return (
        <TableBody data-testid={'submissions-table-body'}>
            {formSubmissionsList.sort(sortComparator(sortingConfiguration)).map((item) => {
                return (
                    <TableRow
                        key={`table-item, ${item.oid}`}
                        data-testid={`submissions-table-row`}
                        onClick={() => openSubmission(item.oid, item.formVersionOid)}
                        className="submission-row"
                    >
                        <TableCell className="submission-status">
                            <Chip
                                variant="outlined"
                                size="medium"
                                label={getBadgeText(item)}
                                className={`submission-badge ${getBadgeStyle(item)}`}
                            />
                        </TableCell>
                        <TableCell>
                            {item.submittedAt ? DateUtils.ISOToFormattedDateWithoutTime(item.submittedAt) : ''}
                        </TableCell>
                        {columnsToShow.includes('submittedBy') && <TableCell>{item.submittedBy}</TableCell>}
                        <TableCell>{item.formVersionTitle}</TableCell>
                        <TableCell
                            style={{
                                whiteSpace: 'normal',
                                wordWrap: 'break-word',
                            }}
                        >
                            {item.summary}
                        </TableCell>
                    </TableRow>
                );
            })}
        </TableBody>
    );
};
