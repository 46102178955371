import { styled } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

export const ContentWrapper = styled(Box)(({ theme }) => ({
    overflow: 'auto',
    maxHeight: theme.spacing(40),
}));

export const BorderBox = styled(Box)(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.grey[300]}`
}));

export const GroupTitle = styled(Typography)(() => ({
    fontWeight: 'bold'
}))
