import { ApiConstants } from '../../utils/ApiConstants';

export class FileConstants extends ApiConstants {
    static getFileList(effectivityEpoch: string): URL {
        return ApiConstants.relativeUrl(`/files${effectivityEpoch ? `?effectivityEpoch=${effectivityEpoch}` : ''}`);
    }
    static getFileContent(contextOid: string, notificationOid?: string): URL {
        return ApiConstants.relativeUrl(
            `/files/${contextOid}/content${notificationOid ? `?notificationOid=${notificationOid}` : ''}`
        );
    }
    static uploadFile(): URL {
        return ApiConstants.relativeUrl('/files');
    }

    static updateFileRevisionMetadata({
        fileContextOid,
        fileRevisionOid,
        effectiveFrom,
        effectiveTo,
        title,
    }: {
        fileContextOid: string;
        fileRevisionOid: string;
        effectiveFrom?: number;
        effectiveTo?: number;
        title?: string;
    }): URL {
        const fileUpdateQuery = () => {
            const query = createFileUpdateQuery({ effectiveFrom, effectiveTo, title });
            return query ? `?${query}` : '';
        };
        return ApiConstants.relativeUrl(`/files/${fileContextOid}/revisions/${fileRevisionOid}${fileUpdateQuery()}`);
    }

    static updateFileContextMetadata({
        fileContextOid,
        tagIds,
        userIds,
    }: {
        fileContextOid: string;
        tagIds?: string[];
        userIds?: string[];
    }): URL {
        const fileUpdateQuery = () => {
            const query = createFileUpdateQuery({ tagIds, userIds });
            return query ? `?${query}` : '';
        };
        return ApiConstants.relativeUrl(`/files/${fileContextOid}${fileUpdateQuery()}`);
    }

    static createNewRevision(fileContextOid: string): URL {
        return ApiConstants.relativeUrl(`/files/${fileContextOid}/revisions`);
    }

    static deleteFile(fileContextOid: string): URL {
        return ApiConstants.relativeUrl(`/files/${fileContextOid}`);
    }
}

const createFileUpdateQuery = ({
    effectiveFrom,
    effectiveTo,
    title,
    tagIds,
    userIds,
}: {
    effectiveFrom?: number;
    effectiveTo?: number;
    title?: string;
    tagIds?: string[];
    userIds?: string[];
}): string => {
    const query = [];
    if (effectiveFrom) {
        query.push(`effectiveFrom=${effectiveFrom}`);
    }
    if (effectiveTo) {
        query.push(`effectiveTo=${effectiveTo}`);
    }
    if (title) {
        query.push(`title=${title}`);
    }
    if (tagIds) {
        query.push(`tagIds=${tagIds.join(',')}`);
    }
    if (userIds) {
        query.push(`userIds=${userIds.join(',')}`);
    }
    return query.join('&');
};
