import * as React from 'react';
import { connect, MapDispatchToProps } from 'react-redux';
import { Dispatch } from 'redux';
import {
    createUseFunction,
    IWorkflowFormField,
    IWorkflowHistoricActivity,
    IWorkflowTaskInfo,
    IWorkflowComment,
    IWorkflowRevisionHistoric,
    IWorkflowAttachment,
    RevisionsByContextOid,
} from '@yonder-mind/ui-core';
import {
    IWebApplicationStore,
    IFormValues,
    IWorkflowCrEditContent,
    IWorkflowCrEdit,
    IWorkflowCrEditTitle,
    IApiError,
} from '../interfaces';
import { changeRequestSelector, crActions } from '../store/workflow/change-request';
import { processActions, processSelector } from '../store/workflow/process';
import { revisionActions, revisionSelector } from '../store/workflow/revision';
import { NotificationState } from '../enums';
import { IVariablesGrouped } from '../interfaces/store/workflow';

interface IWorkflowContext extends IStateProps, IActionsProps {}

const WorkflowContext = React.createContext<IWorkflowContext | null>(null);
WorkflowContext.displayName = 'Workflow Context';

interface IWorkflowContextProps {
    [key: string]: {
        cr: any;
        revision: any;
        process: any;
    };
}

interface IStateProps extends IWorkflowContextProps {
    state: {
        cr: {
            startForm: { [type: string]: IWorkflowFormField[] } | null | undefined;
            getCREdit: (processInstanceId: string) => IWorkflowCrEdit | undefined;
            apiErrors: IApiError[];
            draftSaveError: boolean;
            draftErrorSnackVisible: boolean;
            draftSaved: boolean;
            editDraftSuccess: boolean;
            draftContentError: IApiError;
            finishedLoadingDraftContent: boolean;
            isLoading: boolean;
        };
        revision: {
            revisionsByContextOid: RevisionsByContextOid | undefined;
            histories: { [contextVersionOid: string]: IWorkflowHistoricActivity[] | undefined };
            revisionHistorics: { [contextVersionOid: string]: IWorkflowRevisionHistoric | undefined };
            startForm: IWorkflowFormField[];
            canOpenNewRevisionRole: boolean;
            apiErrors: IApiError[];
        };
        process: {
            tasks: { [processId: string]: IWorkflowTaskInfo[] };
            histories: { [processId: string]: IWorkflowHistoricActivity[] };
            comments: { [processId: string]: { [group: string]: IWorkflowComment[] } };
            attachments: { [processId: string]: IWorkflowAttachment[] };
            permissions: { [processId: string]: boolean };
            commentNotifications: { [processId: string]: NotificationState };
            variables: { [processInstanceId: string]: { [id: string]: string } };
            variablesGrouped: { [processInstanceId: string]: IVariablesGrouped };
            apiErrors: IApiError[];
        };
    };
}

interface IActionsProps extends IWorkflowContextProps {
    actions: {
        cr: {
            requestNewCRForm: (contextType: string, contextSubType: string, workflowId: string) => any;
            requestCRContent: (processInstanceId: string, contextVersionOid: string, moduleVersionOid: string) => any;
            requestPeekCRContent: (
                processInstanceId: string,
                contextVersionOid: string,
                moduleVersionOid: string
            ) => any;
            saveCRContent: (
                processInstanceId: string,
                contextVersionOid: string,
                moduleVersionOid: string,
                content: IWorkflowCrEditContent
            ) => any;
            requestPeekCRTitle: (processInstanceId: string, contextVersionOid: string, moduleOid: string) => any;
            requestCRTitle: (processInstanceId: string, contextVersionOid: string, moduleOid: string) => any;
            saveCRTitle: (
                processInstanceId: string,
                contextVersionOid: string,
                moduleOid: string,
                title: IWorkflowCrEditTitle
            ) => any;
            endEditingCR: (processInstanceId: string) => any;
            cancelEditCRContent: (
                processInstanceId: string,
                contextVersionOid: string,
                moduleVersionOid: string
            ) => any;
            cancelEditCRTitle: (processInstanceId: string, contextVersionOid: string, moduleOid: string) => any;
            refreshCREditLock: (processInstanceId: string, contextVersionOid: string) => any;
            apiErrorReset: (errors: IApiError[]) => void;
            closeDraftError: () => void;
            resetDraftErrors: () => void;

            calculatedDiffRequested: (previousContent: string, currentContent: string) => any;
        };
        revision: {
            requestHistoricActivity: (contextVersionOid: string) => void;
            requestRevisionHistorics: (documentId: string, state?: string) => void;
            requestNewRevisionForm: (contextType: string, contextSubType: string) => void;
            addNewRevisionRequest: (contextType: string, data: IFormValues) => void;
            apiErrorReset: (errors: IApiError[]) => void;
        };
        process: {
            requestMyTasks: (processInstanceId: string) => void;
            requestHistory: (processInstanceId: string) => void;
            // Comment
            requestCommentPermission: (processInstanceId: string) => void;
            requestCommentsOfProcess: (processInstanceId: string) => void;
            writeCommentOnProcess: (processInstanceId: string, message: string) => void;
            // Attachments
            requestAttachmentsOfProcess: (processInstanceId: string) => void;
            // Notifications
            requestCommentNotificationState: (processInstanceId: string) => void;
            enableCommentNotifications: (processInstanceId: string) => void;
            disableCommentNotifications: (processInstanceId: string) => void;
            // Variables
            requestProcessVariables: (processInstanceId: string) => void;
            requestProcessVariablesGrouped: (processInstanceId: string) => void;
            updateProcessVariables: (processInstanceId: string, updates: { [name: string]: string }) => void;
            apiErrorReset: (errors: IApiError[]) => void;
        };
    };
}

type IWorkflowProviderProps = IWorkflowContext;

export const WorkflowProvider: React.FC<IWorkflowProviderProps & { children: any }> = (props) => {
    const { state, actions } = props;

    return (
        <WorkflowContext.Provider
            value={{
                actions,
                state,
            }}
        >
            {props.children}
        </WorkflowContext.Provider>
    );
};

const mapStateToProps = (state: IWebApplicationStore): IStateProps => ({
    state: {
        cr: {
            startForm: changeRequestSelector.getNewCRForm(state),
            getCREdit: (processInstanceId: string) => changeRequestSelector.getCREdit(state, processInstanceId),
            apiErrors: state.workflow.changeRequest.apiErrors,
            draftSaveError: state.workflow.changeRequest.draftSaveError,
            draftErrorSnackVisible: state.workflow.changeRequest.draftErrorSnackVisible,
            draftSaved: state.workflow.changeRequest.draftSaved,
            editDraftSuccess: state.workflow.changeRequest.editDraftSuccess,
            draftContentError: state.workflow.changeRequest.draftContentError,
            finishedLoadingDraftContent: state.workflow.changeRequest.finishedLoadingDraftContent,
            isLoading: state.workflow.changeRequest.isLoading,
        },
        process: {
            commentNotifications: processSelector.getNotificationState(state),
            histories: processSelector.getHistoriesByOProcessId(state),
            comments: processSelector.getCommentsByProcess(state),
            attachments: processSelector.getAttachmentsByProcess(state),
            permissions: processSelector.commentPermissionsByProcess(state),
            tasks: processSelector.getTasksByProcess(state),
            variables: processSelector.getVariables(state),
            variablesGrouped: processSelector.getVariablesGrouped(state),
            apiErrors: state.workflow.process.apiErrors,
        },
        revision: {
            revisionsByContextOid: state.workflow.revision.revisionsByContextOid,
            histories: state.workflow.revision.documentRevisionHistories,
            revisionHistorics: state.workflow.revision.revisionHistorics,
            startForm: revisionSelector.getNewRevisionsFormFields(state),
            canOpenNewRevisionRole: state.workflow.revision.allowedToStartRevisionWorkflow,
            apiErrors: state.workflow.revision.apiErrors,
        },
    },
});

type CreateDispatchAction<T extends any = any> = (action: T) => ReplaceReturnType<T, void>;
function mapDispatch<R>(actions: (createAction: CreateDispatchAction) => R): (dispatch: Dispatch) => R {
    function createAction<Action extends any>(dispatch: Dispatch) {
        return (actionCreator: Action): ReplaceReturnType<Action, void> => {
            return ((...params: any[]) => {
                // @ts-ignore
                dispatch(actionCreator(...params));
            }) as any;
        };
    }

    return (dispatch: Dispatch) => actions(createAction(dispatch));
}

const mapActions: MapDispatchToProps<IActionsProps, any> = mapDispatch(
    (createAction): IActionsProps => ({
        actions: {
            cr: {
                requestNewCRForm: createAction(crActions.newChangeRequestFormFieldsRequested),
                requestCRContent: createAction(crActions.startEditingChangeRequestContent),
                requestPeekCRContent: createAction(crActions.peekEditingChangeRequestContent),
                saveCRContent: createAction(crActions.saveChangeRequestContent),
                requestPeekCRTitle: createAction(crActions.peekEditingChangeRequestTitle),
                requestCRTitle: createAction(crActions.startEditingChangeRequestTitle),
                saveCRTitle: createAction(crActions.saveChangeRequestTitle),
                endEditingCR: createAction(crActions.endEditingChangeRequest),
                cancelEditCRContent: createAction(crActions.cancelEditCRContentRequested),
                cancelEditCRTitle: createAction(crActions.cancelEditCRTitleRequested),
                refreshCREditLock: createAction(crActions.refreshChangeRequestEditLockRequested),
                apiErrorReset: createAction(crActions.apiErrorReset),
                closeDraftError: createAction(crActions.closeDraftErrorSnack),
                resetDraftErrors: createAction(crActions.resetDraftErrors),
                calculatedDiffRequested: createAction(crActions.calculatedDiffRequested),
            },
            process: {
                disableCommentNotifications: createAction(processActions.disableCommentNotificationsOfProcess),
                enableCommentNotifications: createAction(processActions.enableCommentNotificationsOfProcess),
                requestCommentNotificationState: createAction(processActions.requestCommentNotificationState),
                requestCommentPermission: createAction(processActions.canCommentOnProcessRequested),
                requestCommentsOfProcess: createAction(processActions.commentsOfProcessRequested),
                requestAttachmentsOfProcess: createAction(processActions.attachmentsOfProcessRequested),
                requestHistory: createAction(processActions.historiesOfProcessRequested),
                requestMyTasks: createAction(processActions.myTasksOfProcessRequested),
                requestProcessVariables: createAction(processActions.requestVariables),
                requestProcessVariablesGrouped: createAction(processActions.requestVariablesGrouped),
                updateProcessVariables: createAction(processActions.updateVariables),
                writeCommentOnProcess: createAction(processActions.addCommentToProcessRequested),
                apiErrorReset: createAction(processActions.apiErrorReset),
            },
            revision: {
                addNewRevisionRequest: createAction(revisionActions.startNewRevisionRequested),
                requestHistoricActivity: createAction(revisionActions.revisionHistoryOfDocumentRequested),
                requestRevisionHistorics: createAction(revisionActions.revisionHistoricRequested),
                requestNewRevisionForm: createAction(revisionActions.newRevisionFormFieldsRequested),
                apiErrorReset: createAction(revisionActions.apiErrorReset),
            },
        },
    })
);

// The magic of typescript
type ArgumentTypes<T> = T extends (...args: infer U) => unknown ? U : any;
type ReplaceReturnType<T, TNewReturn> = (...a: ArgumentTypes<T>) => TNewReturn;

export const ConnectedWorkflowProvider = connect(mapStateToProps, mapActions)(WorkflowProvider);

const useWorkflowFunction = createUseFunction<IWorkflowContext>(WorkflowContext);

export function useWorkflow(): IWorkflowContext;
export function useWorkflow<K extends keyof IWorkflowContextProps[keyof IWorkflowContextProps]>(
    part: K
): IStateProps['state'][K] & { actions: IActionsProps['actions'][K] };
export function useWorkflow<K extends keyof IWorkflowContextProps[keyof IWorkflowContextProps]>(part?: K) {
    const value = useWorkflowFunction();

    if (part) {
        return {
            actions: value.actions[part] || {},
            ...(value.state[part] || {}),
        };
    }

    return value;
}
