import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Layout, routerSelectors, SideBarProvider } from '@yonder-mind/ui-core';
import { CompareRevisions } from './CompareRevisions';

export const CompareRevisionsLayout: React.FC = () => {
    const { t } = useTranslation();

    const { sidebarTool, sidebarToolTab } = useSelector(routerSelectors.queryParams);

    return (
        <SideBarProvider tool={sidebarTool} toolTab={sidebarToolTab} extraTools={[]}>
            <Layout
                title={t('revisionDiffer.title')}
                variant="compare-revisions"
                sidebarTool={sidebarTool}
                sidebarToolTab={sidebarToolTab}
            >
                <CompareRevisions />
            </Layout>
        </SideBarProvider>
    );
};
