import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';
import {
    IApiModuleVersionChangeContent,
    IApiContextVersionChangeContent,
    IApiChange,
    takeFirst,
    changesActions,
} from '@yonder-mind/ui-core';
import { IWebDatabaseApi } from '../../interfaces';
import { getTypedChange } from './utils';

function* requestChanges(api: IWebDatabaseApi, action: ActionType<typeof changesActions.requestChanges>) {
    const { oid, type, activityId } = action.payload;

    try {
        let changes: IApiChange[];

        switch (type) {
            case 'context':
                changes = yield call([api, api.getContextVersionChanges], oid, activityId);
                break;
            case 'module':
                changes = yield call([api, api.getModuleVersionChanges], oid, activityId);
                break;
            default:
                return;
        }

        yield put(changesActions.receivedChanges(type, oid, changes));
    } catch (e) {
        yield put(changesActions.requestChangesFailed(type, oid, e));
    }
}

function* addChange(action: ActionType<typeof changesActions.addChange>, api: IWebDatabaseApi): any {
    const { type, oid, content } = action.payload;

    try {
        let change;

        switch (type) {
            case 'context':
                change = yield call(
                    [api, api.addContextVersionChange],
                    oid,
                    content as IApiContextVersionChangeContent
                );
                yield put(changesActions.changesRequested({ contextVersionOid: oid }));

                break;
            case 'module':
                change = yield call([api, api.addModuleVersionChange], oid, content as IApiModuleVersionChangeContent);
                yield put(changesActions.changesRequested({ moduleVersionOids: [oid] }));

                break;
            default:
                return;
        }

        yield put(changesActions.addedChange(type, oid, change));
    } catch (error) {
        yield put(changesActions.addChangeFailed(type, oid, error));
    }
}

function* updateChange(api: IWebDatabaseApi, action: ActionType<typeof changesActions.updateChange>): any {
    const { type, oid, change, successNotification, errorNotification } = action.payload;

    // yield delay(500);

    try {
        switch (type) {
            case 'context': {
                const newContextChange = yield call(
                    [api, api.updateContextVersionChange],
                    getTypedChange<typeof type>(change).contextVersionOid,
                    change.oid,
                    getTypedChange<typeof type>(change)
                );
                yield put(changesActions.updatedChange(type, oid, newContextChange));
                successNotification && successNotification();
                break;
            }
            case 'module': {
                const newModuleChange = yield call(
                    [api, api.updateModuleVersionChange],
                    getTypedChange<typeof type>(change).moduleVersionOid,
                    change.oid,
                    getTypedChange<typeof type>(change)
                );
                yield put(changesActions.updatedChange(type, oid, newModuleChange));
                break;
            }
            default:
                return;
        }
    } catch (error) {
        yield put(changesActions.updateChangeFailed(type, oid, change, error));
        errorNotification && errorNotification();
    }
}

function* deleteChange(api: IWebDatabaseApi, action: ActionType<typeof changesActions.deleteChange>) {
    const { type, oid, changeOid } = action.payload;
    try {
        switch (type) {
            case 'context':
                yield call([api, api.deleteContextVersionChange], oid, changeOid);
                break;
            case 'module':
                yield call([api, api.deleteModuleVersionChange], oid, changeOid);
                break;
            default:
                return;
        }

        yield put(changesActions.deletedChange(type, oid, changeOid));
    } catch (error) {
        yield put(changesActions.deleteChangeFailed(type, oid, changeOid, error));
    }
}

export default function* changesSaga(api: IWebDatabaseApi) {
    yield takeEvery(getType(changesActions.requestChanges), requestChanges, api);
    yield takeFirst<any>(getType(changesActions.addChange), addChange, api);
    yield takeLatest(getType(changesActions.updateChange), updateChange, api);
    yield takeEvery(getType(changesActions.deleteChange), deleteChange, api);
}
