import { createAction } from 'typesafe-actions';
import { IApiImportJobLock } from '@yonder-mind/ui-core';

export const IMPORT_JOB_LOCK_REQUESTED = 'IMPORT_JOB_LOCK_REQUESTED';
export const importJobLockRequested = createAction(
    IMPORT_JOB_LOCK_REQUESTED,
    (contextVersionOid: string) => contextVersionOid
)();

export const IMPORT_JOB_LOCK_RECEIVED = 'IMPORT_JOB_LOCK_RECEIVED';
export const importJobLockReceived = createAction(
    IMPORT_JOB_LOCK_RECEIVED,
    (contextVersionOid: string, importJobLockData: IApiImportJobLock) => ({ contextVersionOid, importJobLockData })
)();

export const IMPORT_JOB_LOCK_RESET = 'IMPORT_JOB_LOCK_RESET';
export const importJobLockReset = createAction(IMPORT_JOB_LOCK_RESET)();

export const IMPORT_JOB_LOCK_ERROR = 'IMPORT_JOB_LOCK_ERROR';
export const importJobLockError = createAction(IMPORT_JOB_LOCK_ERROR, (contextVersionOid: string, error: any) => ({
    contextVersionOid,
    error,
}))();
