export enum WorkflowTopic {
    COMMENT = '/topic/comment',
    REVISION = '/topic/revision',
    PROCESS = '/topic/process',
}

export const WORKFLOW_WEBSOCKET_PATH = '/workflow/yondersocket';
export const WORKFLOW_WEBSOCKET_URL = new URL(WORKFLOW_WEBSOCKET_PATH, window.location.origin);

export enum WorkflowCRProposalScreen {
    EDITOR = 'Editor',
    HIGHLIGHTER = 'Highlight',
}

export enum CRName {
    ADD_MODULE = 'NewChangeRequestAddModule',
    DELETE_MODULE = 'NewChangeRequestDeleteModule',
    EDIT_TITLE = 'NewChangeRequestTitle',
    EDIT_CONTENT = 'NewChangeRequest',
}
