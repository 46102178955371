import { HistoryLogsFetchRequestProperties } from '../../interfaces/historyLog/historyLogApi';

const relativeUrl = (relative: string): URL => new URL(`/api${relative}`, window.location.origin);

export const historyLogsUrl = ({ page, limit, contextOid, contextVersionOid }: HistoryLogsFetchRequestProperties) =>
    relativeUrl(
        `/history-logs?limit=${limit}&page=${page}${contextOid ? `&contextOid=${contextOid}` : ''}${
            contextVersionOid ? `&contextVersionOid=${contextVersionOid}` : ''
        }`
    ).toString();

export const historyLogsExportUrl = ({ from, contextOid }: HistoryLogsFetchRequestProperties) =>
    relativeUrl(`/history-logs/export?from=${from}${contextOid ? `&contextOid=${contextOid}` : ''}`).toString();
