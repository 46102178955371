import * as React from 'react';
import { FormField } from './FormField';
import { IFormFields, IFormValues, IFormErrors } from '../../interfaces';

export interface IFormComponents {
    [type: string]: any;
}

interface IProps {
    className?: string;

    fields: IFormFields;
    values?: IFormValues;
    errors: IFormErrors;

    onChange: (id: string, value: string) => any;
}

export const FormFields: React.FC<IProps> = (props) => (
    <>
        {Object.entries(props.fields).map(([id, field]) => (
            <FormField
                field={field}
                value={(props.values || {})[id] || ''}
                errors={props.errors[id]}
                onChange={props.onChange}
                key={id}
            />
        ))}
    </>
);
