import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { historyLogActions } from '../../store/historyLog/historyLogSlice';
import { HighlyPerformantTable } from '../HighlyPerformantTable/HighlyPerformantTable';
import { HistoryLogTableColumnConfiguration } from './HistoryLogTableColumnConfiguration';
import { useTranslation } from 'react-i18next';
import { IWebApplicationStore } from '../../interfaces';
import { HistoryLogsFetchRequestProperties } from '../../interfaces/historyLog/historyLogApi';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

interface HistoryLogProps {
    contextOid?: string;
    contextVersionOid?: string;
    isGlobalView?: boolean;
    minWidth?: number;
}

export const HistoryLog: React.FC<HistoryLogProps> = ({
    contextOid,
    contextVersionOid,
    isGlobalView,
    minWidth = 1000,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { isLoading, historyLogElements, pageSize, currentPage } = useSelector((state: IWebApplicationStore) => ({
        ...state.historyLogs,
    }));

    const fetchHistoryLogs = () => {
        const historyLogsFetchRequestProperties: HistoryLogsFetchRequestProperties = {
            limit: pageSize,
            page: 0,
        };

        if (contextVersionOid) {
            historyLogsFetchRequestProperties.contextVersionOid = contextVersionOid;
        }

        if (contextOid) {
            historyLogsFetchRequestProperties.contextOid = contextOid;
        }

        dispatch(historyLogActions.historyLogsRequested(historyLogsFetchRequestProperties));
    };

    useEffect(() => {
        fetchHistoryLogs();
        return () => {
            dispatch(historyLogActions.clearHistoryLogs());
        };
    }, [contextOid]);

    const loadMoreItems = (_startIndex: number, stopIndex: number) => {
        const lastFetchedIndex = (currentPage + 1) * pageSize;

        if (stopIndex + 20 > lastFetchedIndex) {
            dispatch(
                historyLogActions.historyLogsRequested({
                    limit: pageSize,
                    page: currentPage + 1,
                    objectId: contextVersionOid,
                })
            );
            dispatch(historyLogActions.setCurrentPage(currentPage + 1));
        }
    };

    const tableColumns = HistoryLogTableColumnConfiguration.filter((column) =>
        column.displayInGlobalView ? isGlobalView : true
    ).map((column) => ({ ...column, label: t(column.label) }));

    const formattedHistoryLogElements = historyLogElements.map((historyLogElement) => {
        return {
            ...historyLogElement,
            date: dayjs.utc(historyLogElement.date).format('DD.MM.YYYY HH:mm:ss'),
            action: t(`historyLog.action.${historyLogElement.action}`),
        };
    });

    return (
        <div className="history-log" data-testid="historyLog">
            {historyLogElements.length === 0 ? (
                <span className="history-log-no-items">{t('historyLog.noItems')}</span>
            ) : (
                <HighlyPerformantTable
                    tableColumns={tableColumns}
                    tableRows={formattedHistoryLogElements}
                    isLoading={isLoading}
                    loadMoreItems={loadMoreItems}
                    minWidth={minWidth}
                />
            )}
        </div>
    );
};
