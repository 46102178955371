import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import {
    flashCardsActions,
    flashcardSelector,
    FlashCardsProvider,
    IApiContextVersion,
    IApiFlashCard,
    IApiFlashCardDeletion,
    IApiFlashCardPlayAnswer,
    IApiFlashCardSet,
    IApiLink,
    IContextVersionCache,
    IModuleCache,
    IPlayFlashCardSet,
    IReaderTool,
    IReaderToolTab,
    Layout,
    linksActions,
    modulesSelector,
    PlayFlashcards,
    routerSelectors,
    Spinner,
    userActions,
} from '@yonder-mind/ui-core';
import { IWebApplicationStore } from '../../interfaces';

interface IPlayFlashcardsProps
    extends RouteComponentProps<{
        flashcardSetOid: string;
    }> {}

interface IActionProps {
    flashcardSetsRequested: typeof flashCardsActions.flashcardSetsRequested;
    playFlashcardSetRequested: typeof flashCardsActions.playFlashcardSetRequested;
    updatePlayFlashcardSet: typeof flashCardsActions.updatePlayFlashcardSet;
    setActiveFlashcardSet: typeof flashCardsActions.setActiveFlashcardSet;
    answerFlashcard: typeof flashCardsActions.answerFlashcard;
    saveFlashcardSet: typeof flashCardsActions.saveFlashcardSet;
    deleteFlashcardSet: typeof flashCardsActions.deleteFlashcardSet;
    setActiveFlashcard: typeof flashCardsActions.setActiveFlashcard;
    userSettingsRequested: typeof userActions.userSettingsRequested;
    saveFlashcard: typeof flashCardsActions.saveFlashcard;
    deleteFlashcard: typeof flashCardsActions.deleteFlashcard;
    updateFlashcardSets: typeof flashCardsActions.updateFlashcardSets;
    setActiveFlashcardDelete: typeof flashCardsActions.setActiveFlashcardDelete;
    moduleLinksRequested: typeof linksActions.moduleLinksRequested;
}

interface IStoreProps {
    documents: { [oid: string]: IContextVersionCache };
    modules: { [oid: string]: IModuleCache };
    modulesLinks: { [oid: string]: IApiLink[] };
    flashcardSetOid: string;
    readerTools: IReaderTool[];
    readerToolsTab: IReaderToolTab[];
    flashcardSets: IApiFlashCardSet[];
    playFlashcardSet?: IPlayFlashCardSet;
    activeFlashcard?: IApiFlashCard;
    activeFlashcardDeletion?: IApiFlashCardDeletion;
    activeFlashcardSet?: IApiFlashCardSet;
    isSavingFlashcard: boolean;
    isSavedFlashcard: boolean;
    isSavingFlashcardSet: boolean;
    isSavedFlashcardSet: boolean;
    isSavingFlashcardSets: boolean;
    isSavedFlashcardSets: boolean;
    isDeletingFlashcardSet: boolean;
    isDeletedFlashcardSet: boolean;
}

interface IProps extends IPlayFlashcardsProps, IActionProps, IStoreProps {}

export const PlayFlashcardSet: React.FC<IProps> = (props) => {
    const { t } = useTranslation();

    const handleRequestFlashcardSet = () => {
        props.playFlashcardSetRequested({ flashcardSetOid: props.flashcardSetOid });
    };

    const handleUpdateFlashcardSet = (playFlashcardSet: IPlayFlashCardSet) => {
        props.updatePlayFlashcardSet({ flashcardSetOid: props.flashcardSetOid, playFlashcardSet });
    };

    const handleAnswerFlashcard = (answer: IApiFlashCardPlayAnswer) => {
        props.answerFlashcard({ flashcardSetOid: props.flashcardSetOid, answer });
    };

    useEffect(() => {
        props.userSettingsRequested();
        props.flashcardSetsRequested();
        if (!props.playFlashcardSet) {
            handleRequestFlashcardSet();
        }
    }, []);

    useEffect(() => {
        const flashcardSet = props.flashcardSets.find((set) => set.oid === props.flashcardSetOid);
        if (flashcardSet) {
            props.setActiveFlashcardSet({ set: flashcardSet });
        }
    }, [props.flashcardSets]);

    useEffect(() => {
        if (!props.playFlashcardSet) {
            handleRequestFlashcardSet();
        }
    }, [props.playFlashcardSet]);

    const documents = Object.values(props.documents).map((doc) => doc.contextVersion);
    const flashcardsModuleOids = props.playFlashcardSet
        ? props.playFlashcardSet.flashCards.flatMap((f) => f.moduleOid)
        : [];
    const contentCssPaths = documents
        .filter((doc) => doc.moduleOids.find((moduleOid) => flashcardsModuleOids.includes(moduleOid)))
        .reduce(
            (acc: string[], doc: IApiContextVersion) =>
                doc.type && !acc.includes(doc.type) ? [...acc, doc.type] : acc,
            []
        )
        .map((type) => `/api/content-styling/${type}`);

    return (
        <FlashCardsProvider
            moduleFlashcards={{}}
            flashcardSets={props.flashcardSets}
            playFlashcardSet={props.playFlashcardSet}
            flashcardsRequested={() => ({} as any)}
            saveFlashcard={props.saveFlashcard}
            deleteFlashcard={props.deleteFlashcard}
            saveFlashcardSet={props.saveFlashcardSet}
            deleteFlashcardSet={props.deleteFlashcardSet}
            updateFlashcardSets={props.updateFlashcardSets}
            activeFlashcard={props.activeFlashcard}
            setActiveFlashcard={props.setActiveFlashcard}
            activeFlashcardSet={props.activeFlashcardSet}
            setActiveFlashcardSet={props.setActiveFlashcardSet}
            activeFlashcardDeletion={props.activeFlashcardDeletion}
            setActiveFlashcardDelete={props.setActiveFlashcardDelete}
            isSaving={props.isSavingFlashcard}
            isSaved={props.isSavedFlashcard}
            isSavingSet={props.isSavingFlashcardSet}
            isSavedSet={props.isSavedFlashcardSet}
            isSavingSets={props.isSavingFlashcardSets}
            isSavedSets={props.isSavedFlashcardSets}
            isDeletingSet={props.isDeletingFlashcardSet}
            isDeletedSet={props.isDeletedFlashcardSet}
        >
            <Layout title={t('playFlashcards.title')} variant="play-flashcards" contentCssPaths={contentCssPaths}>
                {props.playFlashcardSet ? (
                    <PlayFlashcards
                        tools={props.readerTools}
                        toolsTab={props.readerToolsTab}
                        flashcardSet={props.playFlashcardSet}
                        requestFlashcardSet={handleRequestFlashcardSet}
                        updateFlashcardSet={handleUpdateFlashcardSet}
                        answerFlashcard={handleAnswerFlashcard}
                    />
                ) : (
                    <Spinner className="container" />
                )}
            </Layout>
        </FlashCardsProvider>
    );
};

const actions: IActionProps = {
    playFlashcardSetRequested: flashCardsActions.playFlashcardSetRequested,
    flashcardSetsRequested: flashCardsActions.flashcardSetsRequested,
    updatePlayFlashcardSet: flashCardsActions.updatePlayFlashcardSet,
    setActiveFlashcardSet: flashCardsActions.setActiveFlashcardSet,
    answerFlashcard: flashCardsActions.answerFlashcard,
    saveFlashcardSet: flashCardsActions.saveFlashcardSet,
    deleteFlashcardSet: flashCardsActions.deleteFlashcardSet,
    setActiveFlashcard: flashCardsActions.setActiveFlashcard,
    userSettingsRequested: userActions.userSettingsRequested,
    saveFlashcard: flashCardsActions.saveFlashcard,
    deleteFlashcard: flashCardsActions.deleteFlashcard,
    updateFlashcardSets: flashCardsActions.updateFlashcardSets,
    setActiveFlashcardDelete: flashCardsActions.setActiveFlashcardDelete,
    moduleLinksRequested: linksActions.moduleLinksRequested,
};

const mapStateToProps = (state: IWebApplicationStore, ownProps: IPlayFlashcardsProps): IStoreProps => {
    const { readerTools, readerToolsTab } = routerSelectors.queryParams(state);
    const { flashcardSetOid } = ownProps.match.params;

    return {
        flashcardSetOid,
        readerTools,
        readerToolsTab,
        documents: state.docs.documentCache,
        modules: modulesSelector.modules(state),
        modulesLinks: state.links.modules,
        flashcardSets: state.flashcards.flashcardSets,
        activeFlashcard: state.flashcards.activeFlashcard,
        activeFlashcardDeletion: state.flashcards.activeFlashcardDeletion,
        activeFlashcardSet: state.flashcards.activeFlashcardSet,
        playFlashcardSet: flashcardSelector.getPlayFlashcardSet(state, flashcardSetOid),
        isSavingFlashcard: state.flashcards.isSaving,
        isSavedFlashcard: state.flashcards.isSaved,
        isSavingFlashcardSet: state.flashcards.isSavingSet,
        isSavedFlashcardSet: state.flashcards.isSavedSet,
        isSavingFlashcardSets: state.flashcards.isSavingSets,
        isSavedFlashcardSets: state.flashcards.isSavedSets,
        isDeletingFlashcardSet: state.flashcards.isDeletingSet,
        isDeletedFlashcardSet: state.flashcards.isDeletedSet,
    };
};

export default connect(mapStateToProps, actions)(PlayFlashcardSet);
