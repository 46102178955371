import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
    authSelector,
    dateTimeFormat,
    DateUtils,
    IListItemProps,
    IWorkflowAttachment,
    parseUserIdToName,
    YonderDialog,
    YonderListItem,
} from '@yonder-mind/ui-core';
import filesize from 'filesize';
import { DeleteOutlined } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { ApiWorkflowConstants } from '../../../../utils';
import { useSelector } from 'react-redux';

type Variant = 'attachments';

export interface IAttachmentListItemProps extends IListItemProps<Variant, IWorkflowAttachment> {
    canDelete: boolean;
    onDelete: (attachment: IWorkflowAttachment) => void;
    dataTestId: string;
}

export const AttachmentListItem: React.FC<IAttachmentListItemProps> = ({
    index,
    item,
    icon,
    onClick,
    canDelete,
    onDelete,
    ...listItemProps
}) => {
    const { t } = useTranslation();

    const userInfo = useSelector(authSelector.userInfo);
    const hasAdminRole = useSelector(authSelector.hasAdminRole);
    const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = React.useState(false);
    const openDeleteConfirmation = () => setDeleteConfirmationOpen(true);
    const closeDeleteConfirmation = () => setDeleteConfirmationOpen(false);

    const handleClick = () => {
        if (onClick) {
            onClick(item, index);
        }

        window.open(ApiWorkflowConstants.downloadAttachment(item.id.toString()).toString());
    };

    const handleDelete = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        openDeleteConfirmation();
    };

    const deleteAttachment = () => {
        onDelete(item);
        closeDeleteConfirmation();
    };

    const canDeleteItem = canDelete && (hasAdminRole || item.user === userInfo.name);
    const deleteButton = canDeleteItem ? (
        <IconButton className="delete-button" onClick={handleDelete} disableRipple={true}>
            <DeleteOutlined />
        </IconButton>
    ) : null;

    return (
        <>
            <YonderListItem
                {...listItemProps}
                icon={icon}
                primary={item.name}
                secondary={filesize(item.size, { round: 0 })}
                tertiary={parseUserIdToName(item.user)}
                meta={DateUtils.parseToDate(item.uploadDate, { format: dateTimeFormat }) as string}
                action={deleteButton}
                onClick={handleClick}
                button={true}
            />
            <YonderDialog
                isOpen={isDeleteConfirmationOpen}
                title={t('workflow.tools.attachment.deleteConfirmation.title')}
                content={t('workflow.tools.attachment.deleteConfirmation.content', { name: item.name })}
                primaryAction={t('workflow.tools.attachment.deleteConfirmation.confirm')}
                secondaryAction={t('workflow.tools.attachment.deleteConfirmation.cancel')}
                onConfirm={deleteAttachment}
                onCancel={closeDeleteConfirmation}
                onClose={closeDeleteConfirmation}
            />
        </>
    );
};
