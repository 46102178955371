import { call, put, takeLatest } from 'redux-saga/effects';
import { historyLogActions } from './historyLogSlice';
import { HistoryLogApiContract } from '../../api/HistoryLog/history-log-api';
import { HistoryLogResponse, HistoryLogsFetchRequestProperties } from '../../interfaces/historyLog/historyLogApi';
import { i18n } from '@yonder-mind/ui-core';
import { ActionType } from 'typesafe-actions';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
dayjs.extend(utc);

function* historyLogsRequested(
    api: HistoryLogApiContract,
    action: ActionType<typeof historyLogActions.historyLogsRequested>
) {
    try {
        const historyLogsResponse: HistoryLogResponse = yield call([api, api.getHistoryLogs], action.payload);
        yield put(historyLogActions.historyLogsReceived(historyLogsResponse));
    } catch (e) {
        console.log(i18n.t('dashboard.error'));
        console.log(e);
    }
}

function* exportHistoryLogsRequested(
    api: HistoryLogApiContract,
    action: ActionType<typeof historyLogActions.exportHistoryLogsRequested>
) {
    try {
        yield call([api, api.exportHistoryLogs], {
            ...(action.payload as HistoryLogsFetchRequestProperties),
            from: dayjs.utc().subtract(3, 'month').toISOString(),
        });
    } catch (e) {
        console.log(i18n.t('dashboard.error'));
        console.log(e);
    }
    yield put(historyLogActions.exportHistoryLogsReceived());
}

export default function* historyLogSagas(api: HistoryLogApiContract) {
    yield takeLatest(historyLogActions.historyLogsRequested, historyLogsRequested, api);
    yield takeLatest(historyLogActions.exportHistoryLogsRequested, exportHistoryLogsRequested, api);
}
