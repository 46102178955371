import React from 'react';

import { Divider, ICheckbox } from '@yonder-mind/ui-core';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { KeyboardArrowUp } from '@material-ui/icons';

interface IProps {
    value: string[];
    data: ICheckbox[];
    label: string;
    onChange: (value: string, checked: boolean) => void;
}

export const RoleFilter: React.FC<IProps> = (props) => {
    const [filterOpened, setFilterOpened] = React.useState(false);
    const { data, label, value } = props;

    const ref = React.useRef(null);

    const openFilter = () => {
        setFilterOpened(!filterOpened);
    };

    const onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange(e.target.value, e.target.checked);
    };

    const handleOutsideClick = (event: any) => {
        //@ts-ignore
        if (ref.current && !ref?.current?.contains(event?.target)) {
            setFilterOpened(false);
        }
    };

    React.useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    return (
        <div className="type-filter" onBlur={(e: any) => handleOutsideClick(e)} ref={ref}>
            <div onClick={() => openFilter()} className={`filter-button ${filterOpened ? 'active' : ''}`}>
                <span>
                    {label}
                    <KeyboardArrowUp />
                </span>
            </div>
            {filterOpened && (
                <div className="changes-filter-wrapper">
                    {data.map((item, key) => {
                        return (
                            <div className="checkbox-item" key={key}>
                                <FormControlLabel
                                    key={key}
                                    control={
                                        <Checkbox
                                            checked={value.includes(item.value)}
                                            color="primary"
                                            onChange={onCheckboxChange}
                                            value={item.value}
                                        />
                                    }
                                    label={item.name}
                                />
                                <Divider />
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default RoleFilter;
