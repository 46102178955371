import { useTranslation } from 'react-i18next';
import { Box, Button, Chip, Typography } from '@material-ui/core';
import { DateUtils, FormSubmissionStatusEnum } from '@yonder-mind/ui-core';

interface SubmissionHeaderProps {
    isSubmitButtonActive: boolean;
    formSubmissionStatus: FormSubmissionStatusEnum;
    unsavedChanges: boolean;
    submittedAt?: string;
    isEffective: boolean;
    saveForm: () => void;
    submitForm: () => void;
    setClearModalOpen: (isOpen: boolean) => void;
}

export const SubmissionHeader: React.FC<SubmissionHeaderProps> = ({
    isSubmitButtonActive,
    formSubmissionStatus,
    unsavedChanges,
    isEffective,
    saveForm,
    submitForm,
    submittedAt,
    setClearModalOpen,
}) => {
    const { t } = useTranslation();

    const isSubmitted = () => {
        return formSubmissionStatus === FormSubmissionStatusEnum.SUBMITTED;
    };

    const getBadgeType = () => {
        if (!formSubmissionStatus) {
            return '';
        }
        return isSubmitted() ? 'submitted' : 'draft';
    };

    const getLabel = () => {
        return (
            <div>
                {isSubmitted() ? t('form.badge.submitted').toUpperCase() : t('form.badge.draft').toUpperCase()}
                {isSubmitted() ? (
                    <div className={'submission-date'}>{DateUtils.ISOToFormattedDateWithoutTime(submittedAt)}</div>
                ) : (
                    <></>
                )}
            </div>
        );
    };

    return (
        <Box component="span" mb={2} display="flex" justifyContent="space-between" alignItems="center">
            <Box
                component="span"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                className="form-submission__header"
            >
                <Typography data-testid={'form-summary-title'} variant="h5" className="title">
                    {t('form.summary.title')}
                </Typography>
                {formSubmissionStatus ? (
                    <div className="submission-status">
                        <Chip
                            variant="outlined"
                            size="medium"
                            label={getLabel()}
                            className={`submission-badge ${getBadgeType()}`}
                        />
                    </div>
                ) : null}
            </Box>
            {!isSubmitted() ? (
                <div className={'form-submission__action-buttons'}>
                    {!isEffective && <p className={'warning'}>{t('form.messages.warning.notEffective')}</p>}
                    <Button
                        data-testid={'form-submit-button'}
                        disabled={!isSubmitButtonActive || !isEffective}
                        className="submit"
                        variant="outlined"
                        onClick={() => submitForm()}
                    >
                        {t('form.actions.submit')}
                    </Button>
                    <Button
                        data-testid={'form-save-button'}
                        disabled={!unsavedChanges || !isEffective}
                        variant="outlined"
                        onClick={() => saveForm()}
                    >
                        {t('form.actions.save')}
                    </Button>
                    <Button
                        data-testid={'form-clear-button'}
                        variant="outlined"
                        onClick={() => setClearModalOpen(true)}
                    >
                        {t('form.actions.clear')}
                    </Button>
                </div>
            ) : null}
        </Box>
    );
};
