import * as React from 'react';
import { connect } from 'react-redux';
import { createUseFunction } from '@yonder-mind/ui-core';
import { WorkflowTopic } from '../config';
import { websocketActions, websocketSelector } from '../store';
import { IWebApplicationStore } from '../interfaces';

interface IWebsocketContext {
    initialized?: boolean;
    initSocket: () => any;
    closeSocket: () => any;
    subscribeToTopic: (topic: string) => any;
    unsubscribeFromTopic: (topic: string) => any;
}

const WebsocketContext = React.createContext<null | IWebsocketContext>(null);
WebsocketContext.displayName = 'Websocket Context';

type IWebsocketProviderProps = IWebsocketContext & React.PropsWithChildren;

export const WebsocketProvider: React.FC<IWebsocketProviderProps> = ({ children, ...providerProps }) => {
    React.useEffect((): any => {
        if (providerProps.initialized) {
            providerProps.subscribeToTopic(WorkflowTopic.PROCESS);
            return () => providerProps.unsubscribeFromTopic(WorkflowTopic.PROCESS);
        }
    }, [providerProps.initialized]);

    React.useEffect(() => {
        providerProps.initSocket();
        return () => providerProps.closeSocket();
    }, []);

    return <WebsocketContext.Provider value={{ ...providerProps }}>{children}</WebsocketContext.Provider>;
};

export const useWebsocket = createUseFunction(WebsocketContext);

const mapStateToProps = (state: IWebApplicationStore) => ({
    initialized: websocketSelector.initialized(state),
});

const actions = {
    initSocket: websocketActions.initSocket,
    closeSocket: websocketActions.closeSocket,
    subscribeToTopic: websocketActions.subscribeToTopic,
    unsubscribeFromTopic: websocketActions.unsubscribeFromTopic,
};

export const ConnectedWebsocketProvider = connect(mapStateToProps, actions)(WebsocketProvider);
