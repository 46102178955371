import { Rest, type UserApi, type IApiUserSettings } from '@yonder-mind/ui-core';
import { UserConstants } from './UserConstants';

export class UserRestApi implements UserApi {
    async getUserSettings(): Promise<IApiUserSettings> {
        const response = await Rest.getRequest(UserConstants.userSettings().toString());
        return response.json();
    }

    async updateUserSettings(userSettings: IApiUserSettings): Promise<IApiUserSettings> {
        const response = await Rest.postRequest(UserConstants.userSettings().toString(), userSettings);
        return response.json();
    }

    async uploadUserAvatar(avatar: File): Promise<void> {
        const formData = new FormData();
        formData.append('file', avatar, avatar.name);
        await Rest.uploadRequest(UserConstants.userSettingsAvatar().toString(), formData);
    }
}
