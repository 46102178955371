import { Rest, CompareRevisionsApi, CompareRevisionsJob, CompareRevisionsResult } from '@yonder-mind/ui-core';
import { CompareRevisionsConstants } from './CompareRevisionsConstants';

export class CompareRevisionsRestApi implements CompareRevisionsApi {
    async getCompareRevisionsResults(): Promise<CompareRevisionsResult[]> {
        const response = await Rest.getRequest(CompareRevisionsConstants.getCompareRevisionsResults().toString());
        return response.json();
    }

    async startCompareRevisions(contextVersionOid1: string, contextVersionOid2: string, name: string): Promise<string> {
        const response = await Rest.postRequest(
            CompareRevisionsConstants.postCompareRevisionsGeneration(
                contextVersionOid1,
                contextVersionOid2,
                name
            ).toString()
        );
        return response.json();
    }

    async getActiveCompareRevisionsJobs(): Promise<CompareRevisionsJob[]> {
        const response = await Rest.getRequest(CompareRevisionsConstants.getActiveCompareRevisionsJobs().toString());
        return response.json();
    }

    async getCompareRevisionsJob(compareRevisionsJobId: string): Promise<CompareRevisionsJob> {
        const response = await Rest.getRequest(
            CompareRevisionsConstants.getCompareRevisionsJob(compareRevisionsJobId).toString()
        );
        return response.json();
    }
}
