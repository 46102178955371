import React from 'react';
import {
    docsSelector,
    IApplicationStore,
    IReaderTool,
    IReaderToolTab,
    IWorkflowChangeRequestV2,
    useReaderTools,
} from '@yonder-mind/ui-core';
import { ModuleVersionContentHighlighter } from '../../ModuleVersion';
import { useSelectorUiWeb } from '../../../store';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';

function getModuleVersionOid(changeRequest: IWorkflowChangeRequestV2) {
    return changeRequest.variables.CHANGE_REQUEST_MODULE_VERSION_ID;
}

export const ChangeRequestModuleContent: React.FC = () => {
    const { activeToolsTab } = useReaderTools();
    const { params: routerParams } = useRouteMatch<{ documentOid: string }>();
    const { documentOid } = routerParams;
    const contextVersion = useSelector((state: IApplicationStore) => docsSelector.getDocument(state, documentOid));

    const { moduleVersion } = useSelector((state: IApplicationStore) => state.moduleView);
    const changeRequests = useSelectorUiWeb((state) => {
        return [
            ...state.workflow.changeRequest.activeCRs.filter((cr) => getModuleVersionOid(cr) === moduleVersion?.oid),
            ...state.workflow.changeRequest.finishedCRs.filter((cr) => getModuleVersionOid(cr) === moduleVersion?.oid),
        ];
    });

    const isChangeRequestTab = () => {
        return activeToolsTab[IReaderTool.MODULE_VIEW] === IReaderToolTab.MODULE_VIEW__CHANGE_REQUESTS;
    };

    return (
        <div
            style={{
                display: !isChangeRequestTab() ? 'none' : undefined,
                height: '100%',
                overflowY: 'auto',
            }}
        >
            {moduleVersion && changeRequests && (
                <ModuleVersionContentHighlighter
                    doc={contextVersion}
                    moduleVersion={moduleVersion}
                    crSelections={changeRequests.map((cr) => ({
                        location: cr.variables.MARK_LOCATION,
                        moduleVersionOid: getModuleVersionOid(cr),
                        textContent: cr.variables.MARK_CONTENT,
                    }))}
                    showBubbleHighlights={true}
                />
            )}
        </div>
    );
};
