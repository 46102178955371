import { DEFAULT_FILTER_ICON, ITagDomain, ITagTreeNode, TagDomainIcon, TagUtils } from '@yonder-mind/ui-core';
import { Divider, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import React from 'react';
import { NoItems } from '../../../../components';
import { useTranslation } from 'react-i18next';

type IProps = {
    tagDomains: ITagDomain[];
    handleDelete: (event: React.MouseEvent<HTMLElement>) => void;
    activeTags: string[];
    allTags: { [key: string]: ITagTreeNode };
    disabled?: boolean;
};

export const MetadataTagsList: React.FC<IProps> = ({ activeTags, allTags, tagDomains, handleDelete, disabled }) => {
    const { t } = useTranslation();

    if (activeTags.length) {
        const tagsList = activeTags.map((filter: string) => {
            const filterValue = allTags[filter];
            if (filterValue) {
                const tagDomainIcon = TagUtils.getTagDomainIcon(filterValue.oid, tagDomains);
                return (
                    <div key={filter}>
                        <ListItem className="list-item" id={filter} data-testid="tagDomainList">
                            <ListItemIcon>
                                <TagDomainIcon
                                    size={'18px'}
                                    filterIcon={tagDomainIcon ? tagDomainIcon : DEFAULT_FILTER_ICON}
                                    coloring={'grey'}
                                />
                            </ListItemIcon>
                            <ListItemText primary={TagUtils.getTagName(filterValue)} />
                            {!disabled && (
                                <div onClick={handleDelete} className="remove-item">
                                    <Delete />
                                </div>
                            )}
                        </ListItem>
                        <Divider className="filter-divider" />
                    </div>
                );
            }
            return <></>;
        });
        return (
            <List component="div" disablePadding>
                {tagsList}
            </List>
        );
    }
    return <NoItems className="metadata-no-tags-selected" message={t('fileDrop.metadataPanel.tags.noTagsSelected')} />;
};
