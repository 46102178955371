import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, IconButton } from '@material-ui/core';
import { Send } from '@material-ui/icons';

const ENTER_KEY_CODE = 13;
const MAXIMUM_CHARACTERS = 250;

interface IProps {
    canComment: boolean;
    onSend: (message: string) => any;
}

export const DiscussionFooter: React.FC<IProps> = ({ canComment, onSend }) => {
    const submitButton = React.useRef<HTMLButtonElement>(null);

    const { t } = useTranslation();

    const [message, setMessage] = React.useState('');
    const [error, setError] = React.useState(false);

    const charsLeftMessage = t('workflow.tools.discussion.charsLeft');
    const [infoMessage, setInfoMessage] = React.useState(
        `${MAXIMUM_CHARACTERS}/${MAXIMUM_CHARACTERS} ${charsLeftMessage}`
    );

    const changeMessage = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { value } = e.target;
        if (value.length > MAXIMUM_CHARACTERS) {
            setInfoMessage(t('form.validation.maxLength', { max: MAXIMUM_CHARACTERS }));
            setMessage(message);
            setError(true);
        } else {
            setInfoMessage(`${MAXIMUM_CHARACTERS - value.length}/${MAXIMUM_CHARACTERS} ${charsLeftMessage}`);
            setMessage(value);
            setError(false);
        }
    };

    const comment = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (message && message.length > 0) {
            if (canComment) {
                onSend(message);
                setMessage('');
                setInfoMessage(`${MAXIMUM_CHARACTERS}/${MAXIMUM_CHARACTERS} ${charsLeftMessage}`);
                setError(false);
            }
        } else {
            setError(true);
        }
    };

    const submit = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.keyCode === ENTER_KEY_CODE && e.shiftKey === false) {
            e.preventDefault();
            if (submitButton.current) {
                submitButton.current.click();
            }
        }
    };

    return (
        <div className="discussion-footer">
            <form className="comment-box" onSubmit={comment}>
                {!canComment ? (
                    <div className="discussion-disabled" children={t('workflow.tools.discussion.notAllowed')} />
                ) : (
                    <div className="input-container">
                        <TextField
                            value={message}
                            onChange={changeMessage}
                            onKeyDown={submit}
                            variant="outlined"
                            margin="dense"
                            placeholder="Comment"
                            fullWidth={true}
                            multiline={true}
                            error={error}
                            maxRows={4}
                            helperText={infoMessage}
                        />

                        <div className="input-send">
                            <IconButton ref={submitButton} type="submit" size="small" disabled={message.length === 0}>
                                <Send />
                            </IconButton>
                        </div>
                    </div>
                )}
            </form>
        </div>
    );
};
