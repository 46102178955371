import { useState } from 'react';
import { TableContainer, Table } from '@material-ui/core';
import { FormSubmissionTableItem } from '@yonder-mind/ui-core';
import { SortingConfiguration, SubmissionsTableHead } from './SubmissionsTableHead';
import { SubmissionsTableBody } from './SubmissionsTableBody';

interface SubmissionTableProps {
    formSubmissionsList: FormSubmissionTableItem[];
    columnsToShow?: string[];
    openSubmission: (oid: string, formVersionOid: string) => void;
}

const defaultColumnsToShow = ['formSubmissionStatus', 'submittedAt', 'formVersionTitle', 'summary'];

export const SubmissionsTable: React.FC<SubmissionTableProps> = ({
    formSubmissionsList,
    columnsToShow,
    openSubmission,
}) => {
    const [sortingConfiguration, setSortingConfiguration] = useState<SortingConfiguration>({
        order: 'asc',
        orderBy: 'formVersionTitle',
    });

    return (
        <TableContainer className="form-submissions-table-container">
            <Table size="small" stickyHeader className="form-submissions-table">
                <SubmissionsTableHead
                    columnsToShow={columnsToShow ? columnsToShow : defaultColumnsToShow}
                    sortingConfiguration={sortingConfiguration}
                    setSortingConfiguration={setSortingConfiguration}
                />
                <SubmissionsTableBody
                    columnsToShow={columnsToShow ? columnsToShow : defaultColumnsToShow}
                    formSubmissionsList={formSubmissionsList}
                    sortingConfiguration={sortingConfiguration}
                    openSubmission={openSubmission}
                />
            </Table>
        </TableContainer>
    );
};
