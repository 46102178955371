import React from 'react';

import { Divider, ICheckbox } from '@yonder-mind/ui-core';
import { FormControlLabel } from '@material-ui/core';
import { KeyboardArrowUp } from '@material-ui/icons';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';

interface IProps {
    value: string;
    data: ICheckbox[];
    label: string;
    onChange: (value: string, checked: boolean) => void;
}

export const TypeFilter: React.FC<IProps> = (props) => {
    const [filterOpened, setFilterOpened] = React.useState(false);
    const { data, label, value } = props;

    const ref = React.useRef(null);

    const openFilter = () => {
        setFilterOpened(!filterOpened);
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange(e.target.value, e.target.checked);
    };

    const handleOutsideClick = (event: any) => {
        if (ref.current && !ref?.current?.contains(event?.target)) {
            setFilterOpened(false);
        }
    };

    React.useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    return (
        <div className="type-filter" onBlur={(e: any) => handleOutsideClick(e)} ref={ref}>
            <div onClick={() => openFilter()} className={`filter-button ${filterOpened ? 'active' : ''}`}>
                <span>
                    {label}
                    <KeyboardArrowUp />
                </span>
            </div>
            {filterOpened && (
                <div className="changes-filter-wrapper">
                    <FormControl component="fieldset">
                        <RadioGroup value={value} onChange={onChange}>
                            {data.map((item, key) => {
                                return (
                                    <div className="checkbox-item" key={key}>
                                        <FormControlLabel
                                            key={key}
                                            value={item.value}
                                            control={<Radio checked={value === item.value} />}
                                            label={item.name}
                                        />
                                        <Divider />
                                    </div>
                                );
                            })}
                        </RadioGroup>
                    </FormControl>
                </div>
            )}
        </div>
    );
};

export default TypeFilter;
