import { takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { authActions } from '@yonder-mind/ui-core';

function authSucceed() {
    if (window.location.search !== '') {
        window.location.search = '';
    }
}

export default function* authSagas() {
    yield takeLatest(getType(authActions.authLoginSucceed), authSucceed);
}
