import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    AddFavoriteFolderButton,
    DashboardCard,
    DragAndDropFavoritesList,
    Favorites,
    filterAndSortActions,
    filterFavoritesTabConfig,
    FilterUtils,
    IWithCreatedAt,
    YonderList,
} from '@yonder-mind/ui-core';
import { IWebApplicationStore } from '../../../interfaces';

export const DragAndDropFavoritesCard = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const userSettings = useSelector((state: IWebApplicationStore) => state.user.userSettings);
    const hasErrorFavorites = useSelector((state: IWebApplicationStore) => state.user.hasError);
    const isFirstLoading = useSelector((state: IWebApplicationStore) => state.docs.isFirstLoading);
    const isLoading = useSelector((state: IWebApplicationStore) => state.docs.isLoading);
    const isFavoritesLoading = useSelector((state: IWebApplicationStore) => state.userSettings.isFavoritesLoading);
    const { filteredFavoritesData } = useSelector((state: IWebApplicationStore) => state.filterAndSort);
    const { activeFilters } = filteredFavoritesData;

    useEffect(() => {
        if (!isFavoritesLoading && !isFirstLoading && !isLoading) {
            dispatch(filterAndSortActions.saveFilterFavoritesValues(activeFilters));
        }
    }, [filteredFavoritesData]);

    const sortByCreatedAt = (a: IWithCreatedAt, b: IWithCreatedAt) => {
        // undefined first
        if (!a.createdAt) {
            return -1;
        }
        if (!b.createdAt) {
            return 1;
        }
        return b.createdAt.localeCompare(a.createdAt);
    };

    return (
        <DashboardCard
            isLoading={isFirstLoading || isLoading || isFavoritesLoading}
            hasError={hasErrorFavorites}
            primaryHeader={t('dashboard.cards.favorites.title')}
            secondaryHeader={t('dashboard.cards.favorites.subtitle')}
            icon={<Favorites size="large" />}
            headerTrailingComponent={<AddFavoriteFolderButton />}
            headerTrailingComponentVisibleOnTabs={[0]}
            tabs={[
                {
                    component: <DragAndDropFavoritesList />,
                    dataTestId: 'dashboardFavoritesCardTabDocs',
                    label: t('dashboard.cards.favorites.tabs.documents'),
                    chips: {
                        filterAvailable: true,
                        filterType: filterFavoritesTabConfig.type,
                        filterValues: activeFilters,
                    },
                },
                {
                    component: (
                        <YonderList
                            useVirtualList
                            variant="hot-changes"
                            items={userSettings.hotChanges}
                            sort={sortByCreatedAt}
                            dataTestId="dashboardFavoritesCardTabChangesList"
                        />
                    ),
                    dataTestId: 'dashboardFavoritesCardTabChanges',
                    label: t('dashboard.cards.favorites.tabs.hotChanges'),
                },
            ]}
            filterOptions={FilterUtils.getFavoriteCardFilterOptions({
                t,
                filteredFavoritesData: filteredFavoritesData,
            })}
        />
    );
};
