import React, { useEffect, useState } from 'react';
import { docsActions, DropDownForSelectionButton, ItemSelector, ListItem } from '@yonder-mind/ui-core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IWebApplicationStore } from '../../interfaces';

interface GlobalHistoryLogDocumentSelectorProps {
    selectedDocumentItem: ListItem;
    setSelectedDocumentItem: React.Dispatch<React.SetStateAction<ListItem>>;
}

export const GlobalHistoryLogDocumentSelector: React.FC<GlobalHistoryLogDocumentSelectorProps> = ({
    selectedDocumentItem,
    setSelectedDocumentItem,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [expanded, setExpanded] = useState(false);
    const { contextTitleBasicInfo, isContextTitleBasicInfoLoading } = useSelector((state: IWebApplicationStore) => ({
        ...state.docs,
    }));

    useEffect(() => {
        dispatch(docsActions.contextTitleBasicInfoRequested({}));
        return () => {
            dispatch(docsActions.clearContextTitleBasicInfo());
        };
    }, []);

    useEffect(() => {}, []);

    const itemList = contextTitleBasicInfo
        .map((item) => ({ id: item.contextOid, name: item.title }))
        .sort((a, b) => a.name.localeCompare(b.name));

    const updateSelectedItemIds = (selectedItemIds: string[]) => {
        setSelectedDocumentItem(itemList.find((i) => i.id === selectedItemIds.at(0)));
    };

    return (
        <DropDownForSelectionButton
            disabled={isContextTitleBasicInfoLoading}
            title={t('historyLog.filter.selectDocument')}
            onClick={() => {
                setExpanded(!expanded);
            }}
            expanded={expanded}
            numberOfAllItems={itemList.length}
            numberOfSelectedItems={selectedDocumentItem ? 1 : 0}
            handleClose={() => setExpanded(false)}
            multiSelect={false}
        >
            <ItemSelector
                isLoading={isContextTitleBasicInfoLoading}
                itemList={itemList}
                selectedItemIds={selectedDocumentItem ? [selectedDocumentItem.id] : []}
                updateSelectedItemIds={updateSelectedItemIds}
                multiSelect={false}
                emptyAllItemsText={t('historyLog.filter.noDocumentsAvailable')}
                noMatches={t('historyLog.filter.noMatchesFound')}
                handleClose={() => setExpanded(false)}
            />
        </DropDownForSelectionButton>
    );
};
