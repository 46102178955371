import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
    DocumentClass,
    IApiContextVersion,
    IQueryParam,
    ISidebarTool,
    ISidebarToolTab,
    Layout,
    routerSelectors,
    searchActions,
    SearchResultsWrapper,
    searchSelector,
    useHistory,
    userActions,
} from '@yonder-mind/ui-core';
import { IWebApplicationStore } from '../../interfaces';

interface IActionProps {
    searchFiltersRequested: typeof searchActions.searchFiltersRequested;
    searchReceived: typeof searchActions.searchReceived;
    userSettingsRequested: typeof userActions.userSettingsRequested;
}

interface IStoreProps {
    documents: IApiContextVersion[];
    sidebarTool?: ISidebarTool;
    sidebarToolTab?: ISidebarToolTab;
}

interface IProps extends IActionProps, IStoreProps {}

const SearchResult: React.FC<IProps> = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { setUrlParam } = useHistory();

    const { searchQuery, selectedSort } = useSelector(routerSelectors.queryParams);
    const selectedTags = useSelector(searchSelector.getSelectedTags);
    const searchResult = useSelector(searchSelector.getSearchResult);

    useEffect(() => {
        if (searchQuery && searchQuery.trim().length > 0) {
            const { documents } = props;
            const isDocumentWithModules = (document: IApiContextVersion) =>
                document.documentClass === DocumentClass.DOCUMENT;

            // YON-3570: Need to map the list of documents (from the search result of document search)
            // to list of context OIDs which will be passed as a filter to the module version search
            const selectedDocuments = documents.filter(isDocumentWithModules).map((document) => document.contextOid);

            if (selectedDocuments.length > 0) {
                dispatch(
                    searchActions.searchRequested({
                        searchQuery,
                        selectedTags,
                        selectedDocuments,
                        selectedSort,
                        persistSelectedFilters: true,
                    })
                );
            } else {
                props.searchReceived({
                    searchResult: {
                        elements: [],
                        page: 0,
                        pageSize: 25,
                    },
                    selectedTags,
                    selectedDocuments,
                    selectedSort,
                    persistSelectedFilters: true,
                });
            }
        }
    }, [props.documents]);

    const searchFiltersRequested = (query: string) => {
        if (query && query.trim().length > 0) {
            setUrlParam(IQueryParam.SEARCH, query);
            props.searchFiltersRequested(query);
        }
    };

    useEffect(() => {
        searchFiltersRequested(searchQuery);
        props.userSettingsRequested();
        return () => {
            dispatch(searchActions.clearSearchResult());
        };
    }, []);

    const contentCssPaths = searchResult.elements
        .map((result) => result.masterContextVersionType)
        .filter((curr, index, list) => list.indexOf(curr) === index)
        .map((documentType) => `/api/content-styling/${documentType}`);

    return (
        <Layout
            title={t('searchResults.title')}
            variant="search-results"
            contentCssPaths={contentCssPaths}
            sidebarTool={props.sidebarTool}
            sidebarToolTab={props.sidebarToolTab}
            searchProps={{
                focus: false,
                searchValue: searchQuery,
                onSearchRequested: searchFiltersRequested,
            }}
        >
            <SearchResultsWrapper searchReceived={props.searchReceived} documents={props.documents} />
        </Layout>
    );
};

const actions: IActionProps = {
    searchFiltersRequested: searchActions.searchFiltersRequested,
    searchReceived: searchActions.searchReceived,
    userSettingsRequested: userActions.userSettingsRequested,
};

const mapStateToProps = (state: IWebApplicationStore): IStoreProps => {
    const { sidebarTool, sidebarToolTab } = routerSelectors.queryParams(state);

    return {
        documents: searchSelector.getDocuments(state),
        sidebarTool,
        sidebarToolTab,
    };
};

export default connect(mapStateToProps, actions)(SearchResult);
