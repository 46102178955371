import * as React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    IWorkflowActivity,
    IWorkflowAttachment,
    IWorkflowHistoricActivity,
    WorkflowUtils,
    FabButton,
    YonderList,
} from '@yonder-mind/ui-core';
import { Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { useWorkflow } from '../../../../context';
import { UploadAttachment } from './UploadAttachment';
import { WorkflowTool } from '../WorkflowTool';
import { processActions } from '../../../../store/workflow/process';
import { IWebApplicationStore } from '../../../../interfaces';
import { AttachmentListItem } from '../../../List';

interface IOwnProps {
    changeRequest: IWorkflowActivity | IWorkflowHistoricActivity;
}

interface IActionProps {
    uploadAttachmentRequested: typeof processActions.uploadAttachment;
    deleteAttachmentRequested: typeof processActions.deleteAttachment;
}

interface IStoreProps {
    isUploading: boolean;
    hasUploadError: boolean;
}

interface IProps extends IOwnProps, IActionProps, IStoreProps {}

const Attachment: React.FC<IProps> = (props) => {
    const { t } = useTranslation();
    const { actions, attachments: attachmentsByProcessId } = useWorkflow('process');
    const [isUploadAttachmentOpen, setUploadAttachmentOpen] = React.useState(false);

    const openUploadAttachment = () => setUploadAttachmentOpen(true);
    const closeUploadAttachment = () => setUploadAttachmentOpen(false);

    React.useEffect((): any => {
        if (props.changeRequest.processInstanceId) {
            actions.requestAttachmentsOfProcess(props.changeRequest.processInstanceId);
        }
    }, [props.changeRequest.processInstanceId]);

    React.useEffect(() => {
        if (!props.isUploading && !props.hasUploadError) {
            closeUploadAttachment();
        }
    }, [props.isUploading, props.hasUploadError]);

    const attachments = (
        (props.changeRequest.processInstanceId && attachmentsByProcessId[props.changeRequest.processInstanceId]) ||
        ([] as IWorkflowAttachment[])
    ).sort((a1, a2) => a2.uploadDate.getTime() - a1.uploadDate.getTime());

    const uploadAttachment = (attachment: File) => {
        props.uploadAttachmentRequested(props.changeRequest.processInstanceId, attachment);
    };

    const deleteAttachment = (attachment: IWorkflowAttachment) => {
        props.deleteAttachmentRequested(props.changeRequest.processInstanceId, attachment.id);
    };

    return (
        <WorkflowTool tool="attachment">
            <YonderList
                dataTestId="workflowToolsAttachmentsList"
                variant="custom"
                componentListItem={AttachmentListItem}
                items={attachments}
                canDelete={!WorkflowUtils.isCompleted(props.changeRequest)}
                onDelete={deleteAttachment}
                emptyComponent={
                    <Typography className="no-items">{t('workflow.tools.attachment.notAvailable')}</Typography>
                }
            />
            <UploadAttachment
                fileNamesInCR={attachments.map((it) => it.name)}
                isOpen={isUploadAttachmentOpen}
                isUploading={props.isUploading}
                hasError={props.hasUploadError}
                onUpload={uploadAttachment}
                onClose={closeUploadAttachment}
            />
            <FabButton onClick={openUploadAttachment}>
                <Add />
            </FabButton>
        </WorkflowTool>
    );
};

const actions: IActionProps = {
    uploadAttachmentRequested: processActions.uploadAttachment,
    deleteAttachmentRequested: processActions.deleteAttachment,
};

const mapStateToProps = (state: IWebApplicationStore): IStoreProps => ({
    isUploading: state.workflow.process.isUploading,
    hasUploadError: state.workflow.process.hasUploadError,
});

export default connect(mapStateToProps, actions)(Attachment);
