import { ImportedFiles } from '../domain/types';
import { OptionsObject } from 'notistack';
import { FileDropFileActionStatus } from '@yonder-mind/ui-core';
import { TFunction } from 'i18next';

export const handleFileDropPublish = ({
    importedFiles,
    fileDropUploadStatus,
    enqueueSnackbar,
    setSelectedFileKeys,
    setImportedFiles,
    t,
}: {
    t: TFunction;
    fileDropUploadStatus: FileDropFileActionStatus;
    importedFiles: ImportedFiles;
    setImportedFiles: (items: ImportedFiles) => void;
    setSelectedFileKeys: (items: string[]) => void;
    enqueueSnackbar: (message: React.ReactNode, options?: OptionsObject) => string | number;
}) => {
    const importedFilesAux = { ...importedFiles };
    let successfulUploadCounter = 0;
    Object.entries(fileDropUploadStatus).forEach(([key, status]) => {
        if (status.error && importedFiles[key]) {
            delete importedFilesAux[key];
            enqueueSnackbar(`${importedFiles[key].name}: ${t('fileDrop.error.publish')}`, { variant: 'warning' });
        } else if (status.success && importedFiles[key]) {
            delete importedFilesAux[key];
            successfulUploadCounter += 1;
        }
    });
    if (successfulUploadCounter) {
        setSelectedFileKeys([]);
        setImportedFiles(importedFilesAux);
        enqueueSnackbar(`${successfulUploadCounter} ${t('fileDrop.success.publish')}`, {
            variant: 'success',
        });
    }
};
