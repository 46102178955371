import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';

interface ChangeInfoFormProps {
    summary: string;
    descriptionText: string;
    onChange: (updates: { summary: string; descriptionText: string }) => any;
    hasDescription: boolean;
    disabled?: boolean;
}

export const ChangeInfoForm: React.FC<ChangeInfoFormProps> = ({
    summary,
    descriptionText,
    onChange,
    hasDescription,
    disabled,
}) => {
    const { t } = useTranslation();

    const [errors, setErrors] = useState<any>({});
    const [values, setValues] = useState({
        summary,
        descriptionText,
    });

    const validators: { [field: string]: Array<(value: string) => string | null> } = {
        summary: [(value: string) => (value.length > 50 ? t('form.validation.maxLength', { max: 50 }) : null)],
    };

    const changeField = (field: string) => (event: React.ChangeEvent<any>) => {
        const { value } = event.target;

        const newErrors = (validators[field] || []).map((validator) => validator(value)).filter((err) => !!err);

        if (newErrors.length > 0) {
            setErrors((old: any) => ({ ...old, [field]: newErrors[0] }));
        } else {
            const newValues = { ...values, [field]: value };
            setValues(newValues);
            setErrors((old: any) => {
                const err = { ...old };
                delete err[field];
                return err;
            });

            onChange(newValues);
        }
    };

    return (
        <>
            <TextField
                value={values.summary || ''}
                onChange={changeField('summary')}
                label={t('workflow.tools.change.form.summary')}
                placeholder={t('workflow.tools.change.form.summary')}
                variant="filled"
                helperText={errors.summary}
                disabled={disabled}
            />

            {hasDescription && (
                <TextField
                    value={values.descriptionText || ''}
                    onChange={changeField('descriptionText')}
                    label={t('workflow.tools.change.form.description')}
                    placeholder={t('workflow.tools.change.form.description')}
                    variant="filled"
                    type="textarea"
                    multiline={true}
                    minRows={8}
                    maxRows={8}
                    disabled={disabled}
                />
            )}
        </>
    );
};
