import { Model } from 'survey-core';
import {
    FormLastSubmission,
    FormQuestion,
    FormSubmission,
    FormSubmissionStatusEnum,
    FormSubmissionTableItem,
    FormSubmissionType,
    i18n,
    ManageableFormSubmission,
} from '@yonder-mind/ui-core';
import dayjs from 'dayjs';
import * as uuid from 'uuid';
import { SortingConfiguration } from '../SubmissionsTable/SubmissionsTableHead';

export const isReadyToSubmit = (survey: Model, summary: string) => {
    return summary?.trim() && areRequiredQuestionsFilled(survey) && isErrorFree(survey);
};

const areRequiredQuestionsFilled = (survey: Model) => {
    if (!survey) return false;
    return survey
        .getAllQuestions()
        .filter((question) => question.isRequired && question.isVisible)
        .every((question) => !question.isEmpty());
};

const isErrorFree = (survey: Model) => {
    return survey.getAllQuestions().every((question) => !question.containsErrors);
};

export const updateQuestions = (
    survey: Model,
    summaryInputRef: React.RefObject<HTMLInputElement> | null
): FormQuestion[] => {
    if (!survey) {
        return [];
    }
    const questions = survey.getAllQuestions(true).map((question) => ({
        id: question.id,
        title: question.title,
        name: question.name,
        isRequired: question.isRequired,
        isEmpty: question.isEmpty(),
        focus: () => question.focus(),
        type: question.getType(),
    }));

    questions.unshift({
        id: uuid.v4(),
        title: i18n.t('form.summary.title'),
        name: 'form-summary-field',
        isRequired: true,
        isEmpty: summaryInputRef ? !summaryInputRef.current?.value?.trim() : false,
        focus: () => summaryInputRef && summaryInputRef.current?.focus(),
        type: 'text',
    });
    return questions;
};

export const sortComparator = ({ order, orderBy }: SortingConfiguration) => {
    return (a: FormSubmissionTableItem, b: FormSubmissionTableItem) => {
        if (orderBy === 'formVersionTitle') {
            if (a.formVersionTitle?.toLowerCase() < b.formVersionTitle?.toLowerCase()) {
                return order === 'asc' ? -1 : 1;
            }
            return order === 'asc' ? 1 : -1;
        }
        if (orderBy === 'submittedAt') {
            if (!a.submittedAt) return 1;
            if (!b.submittedAt) return -1;
            if (dayjs(a.submittedAt).millisecond() < dayjs(b.submittedAt).millisecond()) {
                return order === 'asc' ? -1 : 1;
            }
            if (dayjs(a.submittedAt).millisecond() > dayjs(b.submittedAt).millisecond()) {
                return order === 'asc' ? 1 : -1;
            }
        }
        if (a[orderBy] < b[orderBy]) {
            return order === 'asc' ? -1 : 1;
        }
        if (a[orderBy] > b[orderBy]) {
            return order === 'asc' ? 1 : -1;
        }
        return 0;
    };
};

export const mapFormSubmissionList = (
    formSubmissionList:
        | {
              [submissionOid: string]: ManageableFormSubmission;
          }
        | {
              [formVersionOid: string]: FormSubmission[];
          },
    formSubmissionTypes: {
        [formSubmissionTypeOid: string]: FormSubmissionType;
    }
): FormSubmissionTableItem[] => {
    return Object.values(formSubmissionList)
        .flat()
        .map(({ oid, summary, formSubmissionStatus, submittedAt, formVersionOid, userId, statusOid, formTitle }) => ({
            oid,
            formSubmissionStatus,
            status: formSubmissionTypes ? getStatusName(formSubmissionTypes, statusOid) : null,
            color: formSubmissionTypes ? getColor(formSubmissionTypes, statusOid) : null,
            submittedAt,
            formVersionTitle: formTitle,
            summary,
            formVersionOid,
            submittedBy: userId,
        }));
};

const getColor = (
    formSubmissionTypes: {
        [formSubmissionTypeOid: string]: FormSubmissionType;
    },
    statusOid: string
) => {
    return Object.values(formSubmissionTypes)
        .map((formSubmissionTypes) => formSubmissionTypes.statuses)
        .flat()
        .find((status) => status.oid === statusOid)?.color;
};

const getStatusName = (
    formSubmissionTypes: {
        [formSubmissionTypeOid: string]: FormSubmissionType;
    },
    statusOid: string
) => {
    return Object.values(formSubmissionTypes)
        .map((formSubmissionTypes) => formSubmissionTypes.statuses)
        .flat()
        .find((status) => status.oid === statusOid)?.name;
};

export const mapLastSubmittedSubmissions = (lastFormSubmissions: FormSubmission[]): FormLastSubmission[] => {
    return lastFormSubmissions
        ?.filter((formSubmission) => formSubmission.formSubmissionStatus === FormSubmissionStatusEnum.SUBMITTED)
        .sort((a, b) => (dayjs(a.submittedAt).isAfter(dayjs(b.submittedAt)) ? -1 : 1))
        .map((formSubmission) => {
            return {
                oid: formSubmission.oid,
                summary: formSubmission.summary,
                formVersionOid: formSubmission.formVersionOid,
                submittedAt: formSubmission.submittedAt,
            };
        });
};
