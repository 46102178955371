import { type IApiTask, type IApiTaskType, Rest, type TaskApi } from '@yonder-mind/ui-core';
import { TaskConstants } from './TaskConstants';

export class TaskRestApi implements TaskApi {
    async getTasks({
        contextVersionOid,
        readOrAcknowledged,
        taskTypes,
        latestByTitleAndMessage,
    }: {
        readOrAcknowledged?: boolean;
        taskTypes?: IApiTaskType[];
        contextVersionOid?: string;
        latestByTitleAndMessage?: boolean;
    }): Promise<IApiTask[]> {
        const response = await Rest.getRequest(
            TaskConstants.getTasks({
                readOrAcknowledged,
                taskTypes,
                contextVersionOid,
                latestByTitleAndMessage,
            }).toString()
        );
        return response.json();
    }

    async updateTask(task: IApiTask): Promise<IApiTask> {
        await Rest.postRequest(TaskConstants.updateTask().toString(), task);
        return task;
    }

    async deleteTask(task: IApiTask): Promise<void> {
        await Rest.deleteRequest(TaskConstants.deleteTask(task.taskId, task.taskType).toString());
    }

    async deleteTasks(tasks: string[]): Promise<void> {
        await Rest.postRequest(TaskConstants.deleteTasks().toString(), tasks);
    }
}
