import { useTranslation } from 'react-i18next';
import { Layout } from '@yonder-mind/ui-core';
import { MySubmissionsBoard } from './MySubmissionsBoard';

export const MySubmissions: React.FC = () => {
    const { t } = useTranslation();
    return (
        <Layout title={t('mySubmissions.title')} variant="my-submissions">
            <div className="my-submissions-wrapper">
                <div className="my-submissions-wrapper-content">
                    <MySubmissionsBoard></MySubmissionsBoard>
                </div>
            </div>
        </Layout>
    );
};
