import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
    docsSelector,
    filterAndSortActions,
    FilterUtils,
    filterWorkflowTabConfig,
    IApplicationStore,
    IWorkflowChangeRequestV2,
    IWorkflowStatus,
    SortUtils,
    Spinner,
    useSideBar,
    WorkflowUtils,
    YonderList,
} from '@yonder-mind/ui-core';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { ChangeRequestV2ListItem } from '../../../List/ListItemVariants/ChangeRequestV2ListItem';
import { IWebApplicationStore } from '../../../../interfaces';
import { useRouteMatch } from 'react-router';

interface ChangeRequestListProps {
    itemsKey: string;
    activeWorkflowTab: string;
    values?: {};
}

export const ChangeRequestList: React.FC<ChangeRequestListProps> = ({ itemsKey, activeWorkflowTab, values }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { collapseIfMobile } = useSideBar();

    const { params: routerParams } = useRouteMatch<{ documentOid: string }>();
    const { documentOid } = routerParams;
    const contextVersion = useSelector((state: IApplicationStore) => docsSelector.getDocument(state, documentOid));

    const { filteredWorkflowData } = useSelector((state: IApplicationStore) => state.filterAndSort);

    const { activeCrs, isLoading, releasedCrs } = useSelector((state: IWebApplicationStore) => {
        return {
            activeCrs: state.workflow.changeRequest.activeCRs,
            isLoading:
                itemsKey === 'cr-released'
                    ? state.workflow.changeRequest.isLoadingFinishedCRs
                    : state.workflow.changeRequest.isLoadingActiveCRs,
            releasedCrs: state.workflow.changeRequest.finishedCRs,
        };
    });
    const [statusesLoaded, setStatusesLoaded] = React.useState(false);

    const getWorkflowStatusesList = (changeRequestsList: IWorkflowChangeRequestV2[]) => {
        const statuses: IWorkflowStatus[] = changeRequestsList.map((cr) => {
            const status = WorkflowUtils.getStatusV2(cr, t);
            return {
                value: status,
                name: status,
            };
        });

        const uniqueStatuses = statuses.filter((obj, pos, arr) => {
            return arr.map((mapObj) => mapObj.name).indexOf(obj.name) == pos;
        });
        return uniqueStatuses;
    };

    const getWorkflowSeveritiesList = (changeRequestsList: IWorkflowChangeRequestV2[]) => {
        const severities: IWorkflowStatus[] = changeRequestsList.map((cr) => {
            const severity = cr.variables.SEVERITY;
            return {
                value: severity ? severity : '',
                name: severity ? severity : '',
            };
        });
        const uniqueSeverities = severities.filter((obj, pos, arr) => {
            if (obj.name !== '') {
                return arr.map((mapObj) => mapObj.name).indexOf(obj.name) == pos;
            }
            return null;
        });
        return uniqueSeverities;
    };

    React.useEffect(() => {
        dispatch(filterAndSortActions.filterAndSortWorkflowValuesRequested());
    }, []);

    const changeRequests = React.useMemo(() => {
        return itemsKey === 'cr-released'
            ? releasedCrs
            : activeCrs.filter((cr) => {
                  const isInWaitlist = WorkflowUtils.isInWaitListV2(cr);

                  switch (itemsKey) {
                      case 'cr-active':
                          return !isInWaitlist;
                      case 'cr-waitlist':
                          return isInWaitlist;
                      default:
                          return false;
                  }
              });
    }, [itemsKey, activeCrs, releasedCrs, contextVersion]);

    React.useEffect(() => {
        if (changeRequests?.length > 0 && !statusesLoaded && activeWorkflowTab) {
            setStatusesLoaded(true);
            activeWorkflowTab === itemsKey &&
                dispatch(
                    filterAndSortActions.setFilterAndSortWorkflowStatuses(
                        contextVersion.oid,
                        getWorkflowStatusesList(changeRequests)
                    )
                );
            activeWorkflowTab === itemsKey &&
                dispatch(
                    filterAndSortActions.setFilterAndSortWorkflowSeverity(
                        contextVersion.oid,
                        getWorkflowSeveritiesList(changeRequests)
                    )
                );
            return;
        } else if (
            statusesLoaded &&
            changeRequests?.length > 0 &&
            activeWorkflowTab &&
            !isEqual(
                filteredWorkflowData[contextVersion.oid]
                    ? filteredWorkflowData[contextVersion.oid].availableStatuses
                    : [],
                getWorkflowStatusesList(changeRequests)
            )
        ) {
            activeWorkflowTab === itemsKey &&
                dispatch(
                    filterAndSortActions.setFilterAndSortWorkflowStatuses(
                        contextVersion.oid,
                        getWorkflowStatusesList(changeRequests)
                    )
                );
            activeWorkflowTab === itemsKey &&
                dispatch(
                    filterAndSortActions.setFilterAndSortWorkflowSeverity(
                        contextVersion.oid,
                        getWorkflowSeveritiesList(changeRequests)
                    )
                );
        } else {
            const activeSort =
                filteredWorkflowData[contextVersion.oid] && filteredWorkflowData[contextVersion.oid].activeSort;
            const hasActiveSort = Object.values(activeSort ? activeSort : {}).some((val) => val.length);
            !hasActiveSort && dispatch(filterAndSortActions.workflowSortByCriteriaRequested('ASC', 'number'));
        }
    }, [changeRequests, activeWorkflowTab]);

    React.useEffect(() => {
        const activeSort =
            filteredWorkflowData &&
            filteredWorkflowData[contextVersion.oid] &&
            filteredWorkflowData[contextVersion.oid].activeSort;
        const hasActiveSort = Object.values(activeSort ? activeSort : {}).some((val) => val.length);
        !hasActiveSort && dispatch(filterAndSortActions.workflowSortByCriteriaRequested('ASC', 'number'));
        dispatch(filterAndSortActions.saveFilterAndSortWorkflowValues(filteredWorkflowData));
    }, [filteredWorkflowData]);

    const filteredChangeRequestList = React.useMemo(() => {
        if (changeRequests && values && Object.values(values).some((val: any) => val && val.length !== 0)) {
            let changeRequestsList = FilterUtils.filterChangeRequests(changeRequests, values, t);

            changeRequestsList =
                filteredWorkflowData &&
                filteredWorkflowData[contextVersion.oid] &&
                filteredWorkflowData[contextVersion.oid].activeSort
                    ? SortUtils.sortChangeRequests(
                          changeRequestsList,
                          filteredWorkflowData[contextVersion.oid].activeSort,
                          contextVersion.outline
                      )
                    : changeRequestsList;

            return changeRequestsList;
        } else if (changeRequests) {
            return filteredWorkflowData &&
                filteredWorkflowData[contextVersion.oid] &&
                filteredWorkflowData[contextVersion.oid].activeSort
                ? SortUtils.sortChangeRequests(
                      changeRequests,
                      filteredWorkflowData[contextVersion.oid].activeSort,
                      contextVersion.outline
                  )
                : changeRequests;
        } else {
            return [];
        }
    }, [filteredWorkflowData, changeRequests]);

    return isLoading ? (
        <Spinner />
    ) : (
        <YonderList
            dataTestId={`sidebarCRList${itemsKey}`}
            useVirtualList
            variant="custom"
            componentListItem={ChangeRequestV2ListItem}
            items={filteredChangeRequestList}
            onClick={collapseIfMobile}
            chips={
                contextVersion.oid && {
                    filterAvailable: values && Object.values(values).some((val: any) => val && val.length !== 0),
                    filterType: filterWorkflowTabConfig.type,
                    filterValues: values,
                }
            }
        />
    );
};
