import * as React from 'react';
import { createUseFunction } from '@yonder-mind/ui-core';
import { INotification } from '../interfaces';

interface INotifyOptions {
    duration?: number;
}

type NotifyFunction =
    | ((notification: INotification) => any)
    | ((notification: INotification, options?: INotifyOptions) => any);

interface INotificationContext {
    open: boolean;
    close: () => any;

    notification?: INotification;
    notify: NotifyFunction;

    notificationsEnabledByProcess: { [processInstanceId: string]: boolean };
    disable: (processInstanceId: string) => any;
    enable: (processInstanceId: string) => any;
}

const NotificationContext = React.createContext<null | INotificationContext>(null);
NotificationContext.displayName = 'Notification Context';

interface INotificationProviderProps extends React.PropsWithChildren {
    open: boolean;

    notification?: INotification;
    options?: INotifyOptions;

    notificationsEnabledByProcess: { [processInstanceId: string]: boolean };

    onEnable: (processInstanceId: string) => any;
    onDisable: (processInstanceId: string) => any;
}

export const NotificationProvider: React.FC<INotificationProviderProps> = ({
    children,
    onEnable,
    onDisable,
    ...providerProps
}) => {
    const [open, setOpen] = React.useState(false);

    const close = () => setOpen(false);

    const enable = (processInstanceId: string) => {
        onEnable(processInstanceId);
    };

    const disable = (processInstanceId: string) => {
        onDisable(processInstanceId);
    };

    const notify: NotifyFunction = (notification: INotification, options?: INotifyOptions) => {
        // TODO: Add implementation
        console.log(notification, options);
    };

    return (
        <NotificationContext.Provider
            value={{
                ...providerProps,
                open,
                close,
                enable,
                disable,
                notify,
            }}
        >
            {children}
        </NotificationContext.Provider>
    );
};

export const useNotification = createUseFunction(NotificationContext);
