import React from 'react';
import {
    docsSelector,
    IApplicationStore,
    IWorkflowActivity,
    IWorkflowHistoricActivity,
    IWorkflowTaskInfo,
} from '@yonder-mind/ui-core';
import { WorkflowSidebar } from '../WorkflowSidebar';
import { WorkflowTasks } from '../WorkflowTasks';
import { useRouteMatch } from 'react-router';
import { useSelector } from 'react-redux';

interface IProps {
    title: string;
    subTitle?: React.ReactNode;
    changeRequest: IWorkflowActivity | IWorkflowHistoricActivity;
    isDisabled: boolean;
    onTaskSubmitted: (task: IWorkflowTaskInfo) => void;
}

export const ChangeRequestWorkflowSidebar: React.FC<IProps> = ({
    title,
    subTitle,
    changeRequest,
    isDisabled,
    onTaskSubmitted,
}) => {
    const { params: routerParams } = useRouteMatch<{ documentOid: string }>();
    const { documentOid } = routerParams;
    const contextVersion = useSelector((state: IApplicationStore) => docsSelector.getDocument(state, documentOid));

    const tasks = (
        <WorkflowTasks
            contextVersion={contextVersion}
            variant="change-request"
            activity={changeRequest}
            isDisabled={isDisabled}
            onTaskSubmitted={onTaskSubmitted}
        />
    );

    return (
        <WorkflowSidebar
            key={'workflowsidebar-' + changeRequest.processInstanceId}
            title={title}
            subTitle={subTitle}
            tasks={tasks}
            activity={changeRequest}
        />
    );
};
