import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Text, View } from '@react-pdf/renderer';
import { pdfStyles } from './styles';
import { TagsSelectorUtils } from '@yonder-mind/ui-core';
import { ComplianceReportsFilterConfig, IWebApplicationStore } from '../../../interfaces';
import dayjs from 'dayjs';

export const PdfHeaderPropsSelector = (state: IWebApplicationStore) => {
    const getConfig = (webState: IWebApplicationStore) => {
        return {
            currentFilterConfig: {
                ...webState.complianceReports.appliedFilterConfig,
                date: webState.complianceReports.dateConfig.effectiveAt,
            },
            username: webState.user.userSettings.username,
            tagDomains: webState.complianceReports.tagDomains,
        };
    };
    const { currentFilterConfig, username, tagDomains } = getConfig(state);

    const tagNames = useMemo(
        () =>
            TagsSelectorUtils.getSelectedIdsWithoutAllSelectedChildren(
                currentFilterConfig.selectedTagIds,
                tagDomains
            ).map((chipId) => {
                const tagTreeNode = TagsSelectorUtils.findTagTreeNodeInTagDomains(chipId, tagDomains);

                return TagsSelectorUtils.getName(tagTreeNode.names);
            }),
        [currentFilterConfig.selectedTagIds, tagDomains]
    );

    return { currentFilterConfig, username, tagNames };
};

export const PdfHeader: React.FC<{
    currentFilterConfig: ComplianceReportsFilterConfig & { date: string };
    username: string;
    tagNames: string[];
}> = ({ currentFilterConfig, tagNames, username }) => {
    const { t } = useTranslation();

    let roleString = '';
    currentFilterConfig.selectedRoleIds.forEach((r, index) => {
        roleString += `${index !== 0 ? ', ' : ''}${r}`;
    });

    let userString = '';
    currentFilterConfig.selectedUserIds.forEach((u, index) => {
        userString += `${index !== 0 ? ', ' : ''}${u}`;
    });

    let tagsString = '';
    tagNames.forEach((tag, index) => {
        tagsString += `${index !== 0 ? ', ' : ''}${tag}`;
    });

    const hasQuery = roleString || userString || tagsString;
    const isFuture = currentFilterConfig.status === 'FUTURE';
    return (
        <View>
            <Text style={pdfStyles.headerContainer}>{t('complianceReports.pdf.title')}</Text>
            <View style={pdfStyles.dateInfoContainer}>
                <Text style={pdfStyles.titleText}>
                    {`${t(`complianceReports.effectivityHeader.${currentFilterConfig.status.toLowerCase()}`)} ${
                        isFuture
                            ? t('complianceReports.effectivityHeader.futureDate', {
                                  date: dayjs().format('YYYY-MM-DD'),
                              })
                            : ''
                    }`}
                </Text>
                {currentFilterConfig.date ? (
                    <Text>
                        {t('complianceReports.pdf.date')} {currentFilterConfig.date}
                    </Text>
                ) : (
                    <View />
                )}
                {!currentFilterConfig.date && !isFuture ? (
                    <Text>
                        {t('complianceReports.pdf.date')} {dayjs().format('YYYY-MM-DD')}
                    </Text>
                ) : (
                    <View />
                )}
                <Text style={pdfStyles.generatedBy}>
                    {t('complianceReports.pdf.generatedBy', {
                        username: username,
                        date: dayjs().format('YYYY-MM-DD HH:mm'),
                    })}
                </Text>
            </View>
            {hasQuery ? (
                <View style={pdfStyles.queryContainer}>
                    <Text style={pdfStyles.titleText}>{t('complianceReports.pdf.query')}</Text>
                    {tagsString ? (
                        <Text style={pdfStyles.queryList}>
                            {t('complianceReports.filter.tags.title')}: {tagsString}
                        </Text>
                    ) : (
                        <View />
                    )}
                    {roleString ? (
                        <Text style={pdfStyles.queryList}>
                            {t('complianceReports.filter.roles.title')}: {roleString}
                        </Text>
                    ) : (
                        <View />
                    )}
                    {userString ? (
                        <Text style={pdfStyles.queryList}>
                            {t('complianceReports.filter.users.title')}: {userString}
                        </Text>
                    ) : (
                        <View />
                    )}
                </View>
            ) : (
                <View />
            )}
        </View>
    );
};
