import { GridSize } from '@material-ui/core';

interface ChangeNotificationMapperLayout {
    changeInfoForm: {
        xs?: GridSize;
        md?: GridSize;
        lg?: GridSize;
        xl?: GridSize;
    };
    notificationSettingsTable: {
        xs?: GridSize;
        md?: GridSize;
        lg?: GridSize;
        xl?: GridSize;
    };
}
export const getGridLayout = (isSmallSpace: boolean): ChangeNotificationMapperLayout => {
    return {
        changeInfoForm: {
            xs: 12,
            md: isSmallSpace ? 12 : 6,
            lg: isSmallSpace ? 12 : 7,
            xl: isSmallSpace ? 6 : 8,
        },
        notificationSettingsTable: {
            xs: 12,
            md: isSmallSpace ? 12 : 6,
            lg: isSmallSpace ? 12 : 5,
            xl: isSmallSpace ? 6 : 4,
        },
    };
};
