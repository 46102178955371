import { useTranslation } from 'react-i18next';
import { TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import { ArrowUpward } from '@material-ui/icons';

interface SubmissionTableHeadProps {
    columnsToShow: string[];
    sortingConfiguration: SortingConfiguration;
    setSortingConfiguration: (sortingConfiguration: SortingConfiguration) => void;
}

type Order = 'asc' | 'desc';

export interface SortingConfiguration {
    order: Order;
    orderBy: string;
}

const columnKeyToLabelMap = {
    status: 'status',
    formSubmissionStatus: 'status',
    submittedAt: 'date',
    formVersionTitle: 'title',
    summary: 'summary',
    submittedBy: 'user',
};

export const SubmissionsTableHead: React.FC<SubmissionTableHeadProps> = ({
    columnsToShow,
    sortingConfiguration,
    setSortingConfiguration,
}) => {
    const { t } = useTranslation();
    const handleSortClick = (columnKey: string) => {
        if (sortingConfiguration.orderBy === columnKey) {
            setSortingConfiguration({
                order: sortingConfiguration.order === 'asc' ? 'desc' : 'asc',
                orderBy: columnKey,
            });
        } else {
            setSortingConfiguration({
                order: 'asc',
                orderBy: columnKey,
            });
        }
    };

    const getColumnDirection = (columnKey: string): Order => {
        if (sortingConfiguration.orderBy === columnKey) {
            return sortingConfiguration.order;
        }
        return 'asc';
    };

    const renderSortableHeaderCell = (columnKey: string, label: string, className?: string) => {
        const isActive = (columKey: string) => sortingConfiguration.orderBy === columKey;
        const getClassName = `${className ? className : ''}${isActive(columnKey) ? ' table-head-active' : ''}`;

        return (
            <TableCell key={columnKey} className={getClassName}>
                <TableSortLabel
                    onClick={() => handleSortClick(columnKey)}
                    direction={getColumnDirection(columnKey)}
                    active={isActive(columnKey)}
                    IconComponent={ArrowUpward}
                >
                    {label}
                </TableSortLabel>
            </TableCell>
        );
    };

    return (
        <TableHead data-testid={'submissions-table-head'}>
            <TableRow key={'form-submission-table-head'}>
                {columnsToShow.map((columnKey) => {
                    return [
                        renderSortableHeaderCell(
                            columnKey,
                            t(`form.submissionTable.tableHead.${columnKey}`),
                            `submission-${columnKeyToLabelMap[columnKey]}`
                        ),
                    ];
                })}
            </TableRow>
        </TableHead>
    );
};
