import * as React from 'react';
import { IconButton } from '@material-ui/core';
import { VisibilityOff, Visibility } from '@material-ui/icons';
import { LabeledButton } from '@yonder-mind/ui-core';
import { NotificationState } from '../../../../enums';

interface IProps {
    className?: string;
    notificationStatus: NotificationState;
    changeNotificationStatus: (status: NotificationState) => any;
}

export const NotificationStatusButton: React.FC<IProps> = ({
    className,
    notificationStatus,
    changeNotificationStatus,
}) => {
    const toggleStatus = () => {
        switch (notificationStatus) {
            case NotificationState.ENABLED:
                changeNotificationStatus(NotificationState.DISABLED);
                break;
            case NotificationState.DISABLED:
                changeNotificationStatus(NotificationState.ENABLED);
                break;
        }
    };

    return (
        <LabeledButton
            className={className}
            label={notificationStatus === NotificationState.ENABLED ? 'Enabled' : 'Disabled'}
        >
            <IconButton onClick={toggleStatus} size="small">
                {notificationStatus === NotificationState.ENABLED ? <Visibility /> : <VisibilityOff />}
            </IconButton>
        </LabeledButton>
    );
};
