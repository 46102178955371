import React from 'react';
import { Paper } from '@material-ui/core';

interface IProps extends React.PropsWithChildren {
    className?: string;
    title?: string;
}

export const WidgetCard: React.FC<IProps> = (props) => {
    const { className, title, children } = props;
    return (
        <Paper className={`widget-card${className ? ' ' + className : ''}`}>
            {title && <div className="widget-card-title">{title}</div>}
            <div
                className="widget-card-body"
                style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    height: '100%',
                    width: '100%',
                }}
            >
                {children}
            </div>
        </Paper>
    );
};
