import { StyleSheet } from '@react-pdf/renderer';

export const pdfStyles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        marginLeft: 30,
        marginTop: 20,
    },

    headerContainer: {
        fontWeight: 600,
        fontSize: '24',
        borderBottom: '2px',
        marginBottom: 20,
        paddingBottom: 2,
        width: '90%',
    },
    dateInfoContainer: { marginBottom: 20, fontSize: 12 },
    titleText: {
        fontSize: '14',
        fontWeight: 600,
        marginBottom: 10,
    },
    queryContainer: { marginBottom: 20 },
    queryList: { textIndent: 24, fontSize: 12 },
    documentsTable: { width: '90%' },
    tableCell: { padding: 5, fontSize: '10' },
    selectedChangeTableCel: {
        padding: 5,
        fontSize: '10',
        backgroundColor: 'rgba(92, 168, 243, 0.3)',
        flexGrow: 1,
    },
    generatedBy: { fontSize: '10' },
    headerTableCell: { padding: 5, backgroundColor: '#E4E4E4', fontSize: '10' },
    changesHeaderTableCell: { padding: 5, backgroundColor: '#E4E4E4', fontSize: '10', flexGrow: 1 },
});
