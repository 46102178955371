import { Checkbox, TableBody, TableCell, TableRow, Tooltip } from '@material-ui/core';
import { crop, DateUtils, FileIcon } from '@yonder-mind/ui-core';
import React from 'react';
import { FileDropTableItem, SelectedFileKeys } from '../domain/types';
import { SortingConfiguration } from './FileDropTableHead';
import { useTranslation } from 'react-i18next';
import { KebabMenuProps, TableKebabMenu } from './TableKebabMenu';

interface IProps {
    fileList: FileDropTableItem[];
    searchText: string;
    sortingConfiguration: SortingConfiguration;
    setSelectedFileKeys: (items: SelectedFileKeys) => void;
    selectedFileKeys: SelectedFileKeys;
    disableMultiSelect?: boolean;
    kebabMenuProps?: KebabMenuProps;
    withUploadDate?: boolean;
}

const TOOLTIP_MOUSE_ENTER_DELAY_MILLIS = 600;
const MAX_CHARACTERS_IN_FILENAME_COLUMN = 50;

export const FileDropTableBody: React.FC<IProps> = (props) => {
    const {
        fileList,
        searchText,
        sortingConfiguration,
        selectedFileKeys,
        setSelectedFileKeys,
        disableMultiSelect,
        kebabMenuProps,
        withUploadDate,
    } = props;

    const { t } = useTranslation();
    const isSelected = (item: FileDropTableItem) => selectedFileKeys.includes(item.key);

    const handleSelectedItems = (
        item: FileDropTableItem,
        checkboxClicked: boolean,
        event?: React.MouseEvent<HTMLButtonElement>
    ) => {
        if (disableMultiSelect) {
            if (!isSelected(item)) {
                setSelectedFileKeys([item.key]);
            }
        } else if (checkboxClicked && event) {
            event.stopPropagation();
            if (isSelected(item)) {
                setSelectedFileKeys(selectedFileKeys.filter((selectedItem) => selectedItem !== item.key));
            } else {
                setSelectedFileKeys([...selectedFileKeys, item.key]);
            }
        } else {
            setSelectedFileKeys([item.key]);
        }
    };

    const sortComparator = (a: FileDropTableItem, b: FileDropTableItem) => {
        const { order, orderBy } = sortingConfiguration;
        if (orderBy === 'name') {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
                return order === 'asc' ? -1 : 1;
            }
            return order === 'asc' ? 1 : -1;
        }
        if (a[orderBy] < b[orderBy]) {
            return order === 'asc' ? -1 : 1;
        }
        if (a[orderBy] > b[orderBy]) {
            return order === 'asc' ? 1 : -1;
        }
        return 0;
    };

    const searchFilter = (item: FileDropTableItem) =>
        searchText ? item.name.toLowerCase().includes(searchText.toLowerCase()) : true;

    const getEndDate = (item: FileDropTableItem) => {
        if (item.isDurationOpen) {
            return t('fileDrop.metadataPanel.datepicker.open');
        }
        if (item.endDate === null) {
            return '';
        }
        return DateUtils.unixTimestampToFormattedDateWithoutTime(item.endDate);
    };

    const renderActionsCell = (item: FileDropTableItem) =>
        kebabMenuProps && <TableKebabMenu {...kebabMenuProps} itemKey={item.key} itemType={item.type} />;

    function getFilename(item: FileDropTableItem) {
        return item?.name.length > MAX_CHARACTERS_IN_FILENAME_COLUMN ? (
            <Tooltip title={item.name} enterDelay={TOOLTIP_MOUSE_ENTER_DELAY_MILLIS}>
                <span>{crop(item.name, MAX_CHARACTERS_IN_FILENAME_COLUMN)}</span>
            </Tooltip>
        ) : (
            <span>{item.name}</span>
        );
    }

    return (
        <TableBody>
            {fileList
                .filter(searchFilter)
                .sort(sortComparator)
                .map((item) => {
                    return (
                        <TableRow
                            className="file-drop-table-row"
                            key={'table-item' + item.key}
                            onClick={() => handleSelectedItems(item, false)}
                            selected={isSelected(item)}
                            data-testid="tableRow"
                        >
                            {!disableMultiSelect && (
                                <TableCell>
                                    <Checkbox
                                        checked={isSelected(item)}
                                        color="primary"
                                        onClick={(e) => handleSelectedItems(item, true, e)}
                                    />
                                </TableCell>
                            )}
                            <TableCell size="small" component="th" scope="row" className="file-type-icon">
                                <FileIcon fileType={item.type} />
                            </TableCell>
                            <TableCell>{getFilename(item)}</TableCell>
                            <TableCell size="small">
                                {DateUtils.unixTimestampToFormattedDateWithoutTime(item.startDate)}
                            </TableCell>
                            <TableCell size="small">{getEndDate(item)}</TableCell>
                            {withUploadDate && (
                                <TableCell>
                                    {DateUtils.unixTimestampToFormattedDateWithoutTime(item.uploadDate)}
                                </TableCell>
                            )}
                            {renderActionsCell(item)}
                        </TableRow>
                    );
                })}
        </TableBody>
    );
};
