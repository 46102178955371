import * as React from 'react';
import { Radio, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { NotificationReceiverType, NotificationSettingType } from '@yonder-mind/ui-core';
import { ChangeSettingButton } from './ChangeSettingButton';

interface NotificationSettingsTableProps {
    value: { [role: string]: NotificationReceiverType };
    onChange: (
        value:
            | { roleMapping: { [role: string]: NotificationReceiverType } }
            | { userMapping: { [role: string]: NotificationReceiverType } }
    ) => any;
    notificationReceiverTypes: NotificationReceiverType[];
    mappingType?: NotificationSettingType;
    disabled?: boolean;
}

export const NotificationSettingsTable: React.FC<NotificationSettingsTableProps> = ({
    value,
    onChange,
    notificationReceiverTypes,
    mappingType,
    disabled,
}) => {
    const mapTaskToRole = (role: string, setting: NotificationReceiverType) => () => {
        mappingType === NotificationSettingType.USERS
            ? onChange({ userMapping: { ...value, [role]: setting } })
            : onChange({ roleMapping: { ...value, [role]: setting } });
    };

    const changeAllTo = (setting: NotificationReceiverType) => () =>
        mappingType === NotificationSettingType.USERS
            ? onChange({
                  userMapping: Object.keys(value).reduce(
                      (acc, role) => ({
                          ...acc,
                          [role]: setting,
                      }),
                      {}
                  ),
              })
            : onChange({
                  roleMapping: Object.keys(value).reduce(
                      (acc, role) => ({
                          ...acc,
                          [role]: setting,
                      }),
                      {}
                  ),
              });

    return (
        <Table className="role-task-mapping">
            <TableHead>
                <TableRow>
                    <TableCell />
                    {notificationReceiverTypes.map((notificationReceiverType) => (
                        <ChangeSettingButton
                            setting={notificationReceiverType}
                            checked={
                                Object.keys(value).length > 0 &&
                                !Object.values(value).some((val) => val !== notificationReceiverType)
                            }
                            onClick={changeAllTo(notificationReceiverType)}
                            key={notificationReceiverType}
                            disabled={disabled}
                        />
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {Object.keys(value)
                    .sort((a, b) => a.localeCompare(b))
                    .map((role) => (
                        <TableRow key={role}>
                            <TableCell children={role} />
                            {notificationReceiverTypes.map((notificationReceiverType) => (
                                <TableCell key={`${role}-${notificationReceiverType}`}>
                                    <Radio
                                        checked={value[role] === notificationReceiverType}
                                        onClick={mapTaskToRole(role, notificationReceiverType)}
                                        color="primary"
                                        disabled={disabled}
                                    />
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
            </TableBody>
        </Table>
    );
};
