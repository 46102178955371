import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { CloseButton, YonderButton } from '@yonder-mind/ui-core';
import { useTranslation } from 'react-i18next';

interface IProps {
    open: boolean;
    onClose: () => void;
    fileName: string;
}
export const NewRevisionNotAllowedDialog: React.FC<IProps> = (props) => {
    const { open, onClose, fileName } = props;
    const { t } = useTranslation();

    return (
        <Dialog className="modal-input" open={open} onClose={onClose} fullWidth={true} role="modal" data-testid="modal">
            <DialogTitle className="modal-input-title" disableTypography={true} data-testid="modalHeader">
                <Typography variant="h6">{t('fileDrop.newRevisionModal.title')}</Typography>
                <CloseButton onClick={onClose} size="small" data-testid="modalCloseButton" />
            </DialogTitle>
            <DialogContent className="modal-input-content" data-testid="modalContent">
                <Typography variant="body1">
                    {fileName && t('fileDrop.newRevisionModal.content', { fileName })}
                </Typography>
            </DialogContent>
            <DialogActions className="modal-input-actions" data-testid="modalActions">
                <YonderButton onClick={onClose} variant="text" data-testid="modalOkButton">
                    {t('form.actions.ok')}
                </YonderButton>
            </DialogActions>
        </Dialog>
    );
};
