import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
    AddModule,
    combineStrings,
    docsSelector,
    EditContent,
    EditTitle,
    IApplicationStore,
    IListItemProps,
    IQueryParam,
    IWorkflowChangeRequestV2,
    IWorkflowType,
    RemoveModule,
    useHistory,
    WorkflowUtils,
    YonderListItem,
} from '@yonder-mind/ui-core';
import { useSelectorUiWeb } from '../../../../store';
import { useRouteMatch } from 'react-router';
import { useSelector } from 'react-redux';

type Variant = 'change-requests';

export interface IChangeRequestV2ListItemProps extends IListItemProps<Variant, IWorkflowChangeRequestV2> {
    dataTestId: string;
}

export const ChangeRequestV2ListItem: React.FC<IChangeRequestV2ListItemProps> = ({
    index,
    item,
    icon,
    onClick,
    ...listItemProps
}) => {
    const { t } = useTranslation();
    const { pushUrl } = useHistory();

    const { params: routerParams } = useRouteMatch<{ documentOid: string }>();
    const { documentOid } = routerParams;
    const contextVersion = useSelector((state: IApplicationStore) => docsSelector.getDocument(state, documentOid));

    const importance: string = item.variables.SEVERITY && item.variables.SEVERITY;
    const label = `${item.variables.CHANGE_REQUEST_LABEL}${importance ? ` - ${importance}` : ''}`;

    const summary = item.variables.CHANGE_REQUEST_SUMMARY || 'Summary'; // TODO: Replace

    const description = item.variables.CHANGE_REQUEST;

    const userHasTasks = !item.completed;

    let status = WorkflowUtils.getStatusV2(item, t);

    const contextVersionToEdit = useSelectorUiWeb((state) =>
        docsSelector.getDocumentVersion(state, WorkflowUtils.getContextVersionToEditOid(item.variables))
    );

    const isCrReleased = WorkflowUtils.isReleasedV2(item, contextVersionToEdit);

    if (isCrReleased) {
        status = t('workflow.activityId.EndEvent_Released');
    }

    const handleClick = () => {
        if (onClick) {
            onClick(item, index);
        }

        pushUrl(`/workflow/cr/${contextVersion.oid}`, [
            {
                key: IQueryParam.CHANGE_REQUEST,
                value: item.processInstanceId,
            },
        ]);
    };

    const getWorkflowIcon = () => {
        switch (item.processDefinitionKey) {
            case IWorkflowType.EDIT_CONTENT:
                return <EditContent />;
            case IWorkflowType.EDIT_TITLE:
                return <EditTitle />;
            case IWorkflowType.ADD_MODULE:
                return <AddModule />;
            case IWorkflowType.DELETE_MODULE:
                return <RemoveModule />;
            default:
                return icon;
        }
    };

    return (
        <YonderListItem
            {...listItemProps}
            className="change-request__list-item"
            icon={getWorkflowIcon()}
            primary={summary}
            secondary={description}
            tertiary={label}
            meta={status}
            metaClassName={combineStrings([
                userHasTasks ? 'user-has-tasks' : undefined,
                isCrReleased ? 'released' : undefined,
            ])}
            onClick={handleClick}
            button={true}
            crLayout={true}
        />
    );
};
