import fetchIntercept from 'fetch-intercept';
import { IAuthServiceApi } from '@yonder-mind/ui-core';

export const registerFetchIntercept = (authService: IAuthServiceApi) => {
    fetchIntercept.register({
        request: async (url, config) => {
            try {
                if (!authService.isLoggedIn() || url.includes('/auth/realms/')) {
                    return Promise.resolve([url, config]);
                }

                const tokens = await authService.getTokens();

                return Promise.resolve([
                    url,
                    {
                        ...config,
                        headers: {
                            ...config.headers,
                            Authorization: `${tokens.tokenType} ${tokens.accessToken}`,
                        },
                    },
                ]);
            } catch (e) {
                return Promise.reject(e);
            }
        },
    });
};
