import { IWebApplicationStore } from '../../interfaces';

const initialized = (state: IWebApplicationStore) => state.websocket.initialized;

const countSubscriptionsOnTopic = (state: IWebApplicationStore, topic: string) => {
    return state.websocket.subscriptions[topic] || 0;
};

export const websocketSelector = {
    countSubscriptionsOnTopic,
    initialized,
};
