import * as React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IQueryParam, ISidebarTool, Layout, searchActions, SearchHistory, useHistory } from '@yonder-mind/ui-core';
import { IWebApplicationStore } from '../../interfaces';

interface IActionProps {
    deleteSearchTerm: typeof searchActions.deleteSearchTerm;
}

interface IStoreProps {
    queries: string[];
}

interface IProps extends IActionProps, IStoreProps {}

const Search: React.FunctionComponent<IProps> = (props) => {
    const { t } = useTranslation();
    const { pushUrl } = useHistory();

    const handleSearchRequested = (search: string) => {
        pushUrl('/search/result', [
            {
                key: IQueryParam.SEARCH,
                value: search,
            },
            {
                key: IQueryParam.SIDEBAR_TOOL,
                value: ISidebarTool.DOCUMENT,
            },
        ]);
    };

    const handleClearHistoryItem = (item: string) => {
        props.deleteSearchTerm(item);
    };

    return (
        <Layout
            title={t('search.title')}
            variant="search"
            searchProps={{
                focus: true,
                onSearchRequested: handleSearchRequested,
            }}
        >
            <SearchHistory queries={props.queries} onClick={handleSearchRequested} onDelete={handleClearHistoryItem} />
        </Layout>
    );
};

const actions: IActionProps = {
    deleteSearchTerm: searchActions.deleteSearchTerm,
};

const mapStateToProps = (state: IWebApplicationStore): IStoreProps => ({
    queries: state.search.queries,
});

export default connect(mapStateToProps, actions)(Search);
