import moment from 'moment';
import { IApiScoreDoc } from '@yonder-mind/ui-core';

export class ApiConstants {
    static appendEffectiveAt(url: URL): void {
        url.searchParams.append('effectiveAt', `${moment().toISOString(false)}`);
    }

    static getAllContextVersions(): URL {
        return ApiConstants.relativeUrl('/context-versions');
    }

    static getAllContextVersionsMetadata(): URL {
        return ApiConstants.relativeUrl('/context-versions/metadata');
    }

    static getAllContentStyles(): URL {
        return ApiConstants.relativeUrl('/content-styling');
    }

    static getAllTagDomainsForUser(): URL {
        return ApiConstants.relativeUrl('/tag-domains/filtered-for-user');
    }

    static getModuleVersionContent(moduleVersionOid: string): URL {
        return ApiConstants.relativeUrl(`/module-versions/${moduleVersionOid}/content`);
    }

    static calculateDiff(): URL {
        return ApiConstants.relativeUrl(`/module-versions/compare`);
    }

    static getModuleComplianceInfo(moduleOid: string): URL {
        return ApiConstants.relativeUrl(`/modules/${moduleOid}/compliance-info`);
    }

    static getModuleByModuleOid(moduleOid: string): URL {
        return ApiConstants.relativeUrl(`/modules/${moduleOid}`);
    }

    static getContextVersion(contextVersionOid: string): URL {
        return ApiConstants.relativeUrl(`/context-versions/${contextVersionOid}`);
    }

    static getContextVersionFilter(contextVersionOid: string, selectedTagOids: string[]): URL {
        const url = ApiConstants.relativeUrl(`/context-versions/${contextVersionOid}/filter`);
        selectedTagOids.forEach((tagOid) => url.searchParams.append('selectedTags', tagOid));
        return url;
    }

    static getContentFlow(contextVersionOid: string, contentFlowIndex: number, searchTerm?: string): URL {
        const url = ApiConstants.relativeUrl(`/context-versions/${contextVersionOid}/content-flow`);
        if (searchTerm && searchTerm !== '') {
            url.searchParams.append('searchTerm', searchTerm);
        }
        url.searchParams.append('contentFlowIndex', contentFlowIndex.toString());
        return url;
    }

    static getContentFlows(contextVersionOid: string): URL {
        const url = ApiConstants.relativeUrl(`/context-versions/${contextVersionOid}/content-flows`);
        ApiConstants.appendEffectiveAt(url);
        return url;
    }

    static getContextVersionImportJobHasChanges(contextVersionOid: string): URL {
        return ApiConstants.relativeUrl(`/import/jobs/v1/by-purpose/${contextVersionOid}/has-changes`);
    }

    static getAllContexts(): URL {
        return ApiConstants.relativeUrl('/contexts');
    }

    static getContextTitleBasicInfo({ minRevisions }: { minRevisions?: number }): URL {
        return ApiConstants.relativeUrl(
            `/contexts/title-basic-info${minRevisions ? `?minRevisions=${minRevisions}` : ''}`
        );
    }

    static getContextVersionsByContextOid(contextOid: string): URL {
        return ApiConstants.relativeUrl(`/contexts/${contextOid}/versions`);
    }

    static getContextVersionsMetadataByContextOid(contextOid: string): URL {
        return ApiConstants.relativeUrl(`/contexts/${contextOid}/versions/metadata`);
    }

    static getChanges(): URL {
        return ApiConstants.relativeUrl('/changes');
    }

    static getContextVersionChanges(contextVersionOid: string, revisionId: string): URL {
        return ApiConstants.relativeUrl(
            `/context-versions/${contextVersionOid}/changes${revisionId ? `?revisionWorkflowId=${revisionId}` : ''}`
        );
    }

    static createContextVersionChanges(contextVersionOid: string): URL {
        return ApiConstants.relativeUrl(`/context-versions/${contextVersionOid}/changes`);
    }

    static updateContextVersionChanges(contextVersionOid: string, changeOid?: string): URL {
        return ApiConstants.relativeUrl(`/context-versions/${contextVersionOid}/changes/${changeOid}`);
    }

    static getModuleVersionChanges(moduleVersionOid: string, changeRequestId: string): URL {
        return ApiConstants.relativeUrl(
            `/module-versions/${moduleVersionOid}/changes?changeRequestId=${changeRequestId}`
        );
    }

    static createModuleVersionChanges(contextVersionOid: string): URL {
        return ApiConstants.relativeUrl(`/module-versions/${contextVersionOid}/changes`);
    }

    static updateModuleVersionChanges(moduleVersionOid: string, changeOid: string): URL {
        return ApiConstants.relativeUrl(`/module-versions/${moduleVersionOid}/changes/${changeOid}`);
    }

    static getSearchFilters(query: string): URL {
        const url = ApiConstants.relativeUrl('/search/tagDomainsAndContextVersions');
        url.searchParams.append('searchTerm', query);
        return url;
    }

    static search(
        query: string,
        page: number,
        pageSize: number,
        selectedTagOids: string[],
        selectedContextOids: string[],
        selectedSort: string,
        nextPageOffset?: IApiScoreDoc
    ): URL {
        const url = ApiConstants.relativeUrl('/module-versions/search');
        url.searchParams.append('q', query);
        url.searchParams.append('page', page.toString());
        url.searchParams.append('pageSize', pageSize.toString());
        selectedTagOids.forEach((tagOid) => url.searchParams.append('selectedTags', tagOid));
        selectedContextOids.forEach((docOid) => url.searchParams.append('selectedContextOids', docOid));
        if (selectedSort !== 'relevance') {
            url.searchParams.append('sortField', selectedSort);
        }
        if (nextPageOffset) {
            url.searchParams.append('nextPageOffsetDoc', nextPageOffset.doc.toString());
            url.searchParams.append('nextPageOffsetScore', nextPageOffset.score.toString());
            nextPageOffset.sortFields.forEach((field) => url.searchParams.append('nextPageOffsetSortFields', field));
        }
        return url;
    }

    static getAllModuleVersionsByModuleOid(moduleOid: string): URL {
        return ApiConstants.relativeUrl(`/module-versions/byModuleOid/${moduleOid}`);
    }

    static getAllModuleVersionsByModuleVersionOid(moduleOid: string): URL {
        return ApiConstants.relativeUrl(`/module-versions/byModuleVersionOid/${moduleOid}`);
    }

    static startEditingCRContent(contextVersionOid: string, moduleVersionOid: string): URL {
        return ApiConstants.relativeUrl(`/context-versions/${contextVersionOid}/content-cr/${moduleVersionOid}`);
    }

    static peekEditingCRContent(contextVersionOid: string, moduleVersionOid: string): URL {
        return ApiConstants.relativeUrl(`/context-versions/${contextVersionOid}/content-cr/${moduleVersionOid}/peek`);
    }

    static cancelEditingCRContent(contextVersionOid: string, moduleVersionOid: string): URL {
        return ApiConstants.relativeUrl(`/context-versions/${contextVersionOid}/content-cr/${moduleVersionOid}/cancel`);
    }

    static peekEditingCRTitle(contextVersionOid: string, moduleOid: string): URL {
        return ApiConstants.relativeUrl(`/context-versions/${contextVersionOid}/title-cr/${moduleOid}/peek`);
    }

    static startEditingCRTitle(contextVersionOid: string, moduleOid: string): URL {
        return ApiConstants.relativeUrl(`/context-versions/${contextVersionOid}/title-cr/${moduleOid}`);
    }

    static cancelEditingCRTitle(contextVersionOid: string, moduleOid: string): URL {
        return ApiConstants.relativeUrl(`/context-versions/${contextVersionOid}/title-cr/${moduleOid}/cancel`);
    }

    static refreshLock(contextVersionOid: string): URL {
        return ApiConstants.relativeUrl(`/context-versions/${contextVersionOid}/refresh-lock`);
    }

    static uploadMedia(): URL {
        return ApiConstants.relativeUrl('/media-versions');
    }

    static getPrintProfile(contextOid: string): URL {
        return ApiConstants.relativeUrl(`/contexts/${contextOid}/print-profile`);
    }

    static printDocument(contextOid: string): URL {
        return ApiConstants.relativeUrl(`/context-versions/${contextOid}/print`);
    }

    static getExportJobs(exportJobId: number): URL {
        return ApiConstants.relativeUrl(`/export-jobs/${exportJobId}`);
    }

    static downloadExportJob(exportJobId: number): URL {
        return ApiConstants.relativeUrl(`/export-jobs/${exportJobId}/content`);
    }

    static getMaintenanceStatus(): URL {
        return ApiConstants.relativeUrl('/maintenance/status');
    }

    static getImportJobLockByContextVersionOid(contextVersionOid: string): URL {
        return ApiConstants.relativeUrl(`/import/jobs/v1/by-purpose/${contextVersionOid}/lock`);
    }

    static getUsers(): URL {
        return ApiConstants.relativeUrl(`/admin/user-management/users`);
    }

    static getAllTagDomainsForFileDrop(): URL {
        return ApiConstants.relativeUrl('/tag-domains/file-drop');
    }

    static getTenantSettings(): URL {
        return ApiConstants.relativeUrl('/tenant-settings');
    }

    static getContextTypes(): URL {
        return ApiConstants.relativeUrl('/context-types');
    }

    static transformXmlToHtml(contentSchemaName: string): URL {
        return ApiConstants.relativeUrl(`/transform/xml/${contentSchemaName}`);
    }

    static getMediaVersion(mediaVersionOid: string): URL {
        return ApiConstants.relativeUrl(`/media-versions/${mediaVersionOid}`);
    }

    static relativeUrl(relative: string): URL {
        return new URL(`/api${relative}`, window.location.origin);
    }
}
