import { TextField, MenuItem } from '@material-ui/core';
import * as React from 'react';
import { IFormFieldConfig } from '../../interfaces';
import { useTranslation } from 'react-i18next';

interface IProps {
    field: IFormFieldConfig;

    value: string;
    onChange: (id: string, value: string) => any;

    errors?: string | string[];
}

const typeMapping: { [key: string]: string } = {
    string: 'text',
    date: 'date',
    long: 'number',
    int: 'number',
    boolean: 'checkbox',
    enum: 'text',
};

export const FormField: React.FC<IProps> = (props) => {
    const [helperText, setHelperText] = React.useState('');

    const { t } = useTranslation();

    const charsLeftMessage = t('workflow.tools.discussion.charsLeft');

    function setCharacterError(maxChar: number) {
        setHelperText(t('form.validation.maxLength', { max: maxChar }));
    }

    React.useEffect(() => {
        props.field.maxCharacters &&
            setHelperText(`${props.field.maxCharacters}/${props.field.maxCharacters} ${charsLeftMessage}`);
    }, [props.field.id]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!props.field.disabled) {
            if (props.field.maxCharacters) {
                const inputLength = event.target.value.length;
                const maxCharLength = props.field.maxCharacters;
                setHelperText(`${maxCharLength - inputLength}/${maxCharLength} ${charsLeftMessage}`);
                event.target.value.length > maxCharLength
                    ? setCharacterError(maxCharLength)
                    : props.onChange(props.field.id, event.target.value);
            } else {
                props.onChange(props.field.id, event.target.value);
            }
        }
    };

    const error =
        props.errors && props.errors.length > 0
            ? typeof props.errors === 'string'
                ? props.errors
                : props.errors[0]
            : undefined;

    if (props.field.hidden) {
        return null;
    }

    let values;
    if (props.field.values) {
        values = Object.keys(props.field.values).map((key) => (
            <MenuItem key={key} value={key}>
                {props.field.values[key]}
            </MenuItem>
        ));
    }

    return (
        <TextField
            type={typeMapping[props.field.type] || 'text'}
            select={props.field.type === 'enum'}
            value={props.field.type === 'boolean' ? 'true' : props.value}
            onChange={handleChange}
            error={!!error}
            helperText={error || '' || helperText}
            label={props.field.label}
            placeholder={props.field.placeholder}
            required={props.field.required}
            disabled={props.field.disabled}
            hidden={props.field.hidden}
            fullWidth={true}
            multiline={props.field.multiLine}
            minRows={3}
            maxRows={3}
            margin={'dense'}
            InputLabelProps={{
                shrink: props.field.type === 'date' ? true : undefined, // Fix for date fields
            }}
        >
            {values}
        </TextField>
    );
};
