import { IApiComplianceReportsDocument, IApiTagDomain, ListItem, Rest } from '@yonder-mind/ui-core';
import { ApiComplianceReportsConstants } from '../utils/ApiComplianceReportsConstants';
import {
    ChangesTableItem,
    ComplianceTableItem,
    Preset,
    UsersTableItem,
} from '../container/ComplianceReports/domain/types';
import { ApiComplianceReportsFilterConfig } from '../interfaces';
import { saveAs } from 'file-saver';

export class RestComplianceReportsApi {
    async getAllTagDomainsForComplianceReports(): Promise<IApiTagDomain[]> {
        const response = await Rest.getRequest(
            ApiComplianceReportsConstants.getAllTagDomainsForComplianceReports().toString()
        );
        return response.json();
    }

    async getAllRolesForComplianceReports(): Promise<string[]> {
        const response = await Rest.getRequest(
            ApiComplianceReportsConstants.getAllRolesDomainsForComplianceReports().toString()
        );
        return response.json();
    }

    async getComplianceDocuments(config: ApiComplianceReportsFilterConfig): Promise<ComplianceTableItem[]> {
        const response = await Rest.getRequest(
            ApiComplianceReportsConstants.getComplianceReportsDocuments(config).toString()
        );
        return response.json();
    }

    async getComplianceChanges(
        contextVersionOid: string,
        config: ApiComplianceReportsFilterConfig
    ): Promise<ChangesTableItem[]> {
        const response = await Rest.getRequest(
            ApiComplianceReportsConstants.getComplianceReportsChanges(contextVersionOid, config).toString()
        );

        return response.json();
    }

    async getComplianceUsers(
        changeOid: string,
        role: string,
        tagOids?: string[],
        users?: string[]
    ): Promise<UsersTableItem[]> {
        const response = await Rest.getRequest(
            ApiComplianceReportsConstants.getComplianceReportsUsers(changeOid, role, tagOids, users).toString()
        );

        return response.json();
    }

    async getComplianceFileUserStatuses(
        fileRevisionOid: string,
        role?: string,
        tagOids?: string[],
        users?: string[]
    ): Promise<UsersTableItem[]> {
        const response = await Rest.getRequest(
            ApiComplianceReportsConstants.getComplianceReportsFileUserStatuses(
                fileRevisionOid,
                role,
                tagOids,
                users
            ).toString()
        );

        return response.json();
    }

    async getComplianceReportsPresets(userOid: string): Promise<Preset[]> {
        const response = await Rest.getRequest(
            ApiComplianceReportsConstants.getComplianceReportsPresets(userOid).toString()
        );

        return response.json();
    }

    async saveComplianceReportsPreset(preset: Preset): Promise<void> {
        const response = await Rest.postRequest(
            ApiComplianceReportsConstants.saveComplianceReportsPreset().toString(),
            preset
        );

        return response.json();
    }

    async deleteComplianceReportsPreset(oid: string): Promise<any> {
        const response = await Rest.deleteRequest(
            ApiComplianceReportsConstants.deleteComplianceReportsPreset(oid).toString()
        );
        return response;
    }

    async getFilterDocuments(status: string, effectiveAt?: string): Promise<ListItem[]> {
        const response = await Rest.getRequest(
            ApiComplianceReportsConstants.getFilterDocuments(status, effectiveAt).toString()
        );
        const documents: IApiComplianceReportsDocument[] = await response.json();
        return documents.map((cv) => {
            return { id: cv.oid, name: cv.title };
        });
    }

    async exportComplianceCsv(
        contextVersionOids: string[],
        status: string,
        role: string[],
        date?: string,
        tagOids?: string[],
        users?: string[]
    ): Promise<any> {
        const response = await Rest.getRequest(
            ApiComplianceReportsConstants.exportComplianceCsv(
                contextVersionOids,
                status,
                role,
                date,
                tagOids,
                users
            ).toString()
        );

        const filename = response.headers
            .get('content-disposition')
            .split(';')
            .find((n: any) => n.includes('filename='))
            .replace('filename=', '')
            .trim()
            .replace('"', '')
            .replace('"', '');
        const blob: Blob = await response.blob();
        // Download the file
        saveAs(blob, filename);
        //Temporary return url so that saga isnt broken
        return response.url;
    }
}
