import { call, delay, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';
import {
    IApiImportJobLock,
    IWorkflowActivity,
    IWorkflowFormField,
    IWorkflowType,
    UrlSchemeUtils,
} from '@yonder-mind/ui-core';
import { IWebDatabaseApi, IWorkflowApi, IWorkflowCrEditContent, IWorkflowCrEditTitle } from '../../../interfaces';
import { processActions } from '../process';
import * as actions from './cr-actions';

function* requestAllChangeRequestsOfUser(
    api: IWorkflowApi,
    action: ActionType<typeof actions.allChangeRequestsOfUserRequested>
) {
    const { userName } = action.payload;

    try {
        const changeRequests: IWorkflowActivity[] = yield call([api, api.getChangeRequests], { initiator: userName });
        yield put(actions.allChangeRequestsOfUserReceived(changeRequests));
    } catch (error) {
        try {
            yield put(actions.apiErrorReceived(yield call([error, (error as any).json])));
        } catch (e) {
            console.error('Error handling error', e);
        }
        console.error(error);
    }
}

function* getChangeRequestsForWfSidebarSaga(action: ActionType<typeof actions.getChangeRequestsForWfSidebar>) {
    const { contextOid, contextVersionOid, isDraft } = action.payload;

    if (isDraft) {
        yield put(
            actions.getChangeRequestsForDraftDoc({
                contextOid,
                contextVersionOid,
            })
        );
    } else {
        yield put(
            actions.getChangeRequestsForReleasedDoc({
                contextOid,
                contextVersionOid,
            })
        );
        yield put(actions.getFinishedChangeRequests(contextVersionOid));
    }
}

function* requestContextVersionUpdateJobHasChanges(
    api: IWebDatabaseApi,
    action: ActionType<typeof actions.contextVersionImportJobHasChangesRequested>
) {
    try {
        const { contextVersionOid } = action.payload;

        const hasChanges: boolean = yield call([api, api.getContextVersionImportJobHasChanges], contextVersionOid);
        yield put(actions.contextVersionImportJobHasChangesReceived(hasChanges));
    } catch (error) {
        try {
            yield put(actions.contextVersionImportJobHasChangesReceived(false));
            yield put(actions.apiErrorReceived(yield call([error, (error as any).json])));
        } catch (e) {
            console.error('Error handling error', e);
        }
        console.error(error);
    }
}

function* requestNewChangeRequestFormFields(
    api: IWorkflowApi,
    action: ActionType<typeof actions.newChangeRequestFormFieldsRequested>
) {
    const { contextType, contextSubType, workflowId } = action.payload;
    try {
        const fields: IWorkflowFormField[] = yield call(
            [api, api.getNewChangeRequestForm],
            contextType,
            contextSubType,
            workflowId
        );
        yield put(actions.newChangeRequestFormFieldsReceived(workflowId, fields));
    } catch (error) {
        try {
            yield put(actions.apiErrorReceived(yield call([error, (error as any).json])));
        } catch (e) {
            console.error('Error handling error', e);
        }
        console.error(error);
    }
}

function* calculateDiff(api: IWorkflowApi, action: ActionType<typeof actions.calculatedDiffRequested>) {
    try {
        const calculatedDiff: { diffContent: string } = yield call(
            [api, api.calculateDiff],
            action.payload.previousContent,
            action.payload.currentContent
        );

        yield put(actions.calculatedDiffReceived(calculatedDiff.diffContent));
    } catch (error) {
        try {
            yield put(actions.apiErrorReceived(yield call([error, (error as any).json])));
        } catch (e) {
            console.error('Error handling error', e);
        }
        console.error(error);
    }
}

function* addChangeRequest(api: IWorkflowApi, action: ActionType<typeof actions.addChangeRequest>) {
    const { contextType, workflowId, data, contextOid, contextVersionOid, previousContent, moduleOid, isDraft } =
        action.payload;

    try {
        let calculatedDiff: { diffContent: string } = { diffContent: '' };
        if (workflowId === IWorkflowType.ADD_MODULE) {
            calculatedDiff = { diffContent: data.TEXT_PROPOSAL };
        } else {
            calculatedDiff = yield call([api, api.calculateDiff], previousContent, data.TEXT_PROPOSAL);
        }

        const payload: IWorkflowActivity = yield call([api, api.startNewChangeRequest], contextType, workflowId, {
            ...data,
            TEXT_DIFF: calculatedDiff.diffContent,
        });
        yield put(actions.addChangeRequestCompleted(contextVersionOid, payload));

        yield put(actions.getChangeRequestsForModule({ contextOid, contextVersionOid, isDraft, moduleOid }));
        yield put(actions.getChangeRequestsForWfSidebar({ contextOid, contextVersionOid, isDraft }));
    } catch (error) {
        try {
            yield put(actions.apiErrorReceived(yield call([error, (error as any).json])));
        } catch (e) {
            console.error('Error handling error', e);
        }
        console.error(error);
    }
}

function* startEditingCRContent(
    api: IWebDatabaseApi,
    action: ActionType<typeof actions.startEditingChangeRequestContent>
) {
    const { processInstanceId, contextVersionOid, moduleVersionOid } = action.payload;

    try {
        const crContent: IWorkflowCrEditContent = yield call(
            [api, api.getCRContent],
            contextVersionOid,
            moduleVersionOid
        );
        const content = {
            ...crContent,
            moduleVersionContent: UrlSchemeUtils.replaceUrlScheme(
                crContent.moduleVersionContent,
                window.location.origin
            ),
        };

        yield put(actions.editChangeRequestContentReceived(processInstanceId, content));
    } catch (error) {
        console.error(error);
    }
}

function* peekEditingCRContent(
    api: IWebDatabaseApi,
    action: ActionType<typeof actions.peekEditingChangeRequestContent>
) {
    const { processInstanceId, contextVersionOid, moduleVersionOid } = action.payload;

    try {
        const crContent: IWorkflowCrEditContent = yield call(
            [api, api.peekGetCRContent],
            contextVersionOid,
            moduleVersionOid
        );
        const content = {
            ...crContent,
            moduleVersionContent: UrlSchemeUtils.replaceUrlScheme(
                crContent.moduleVersionContent,
                window.location.origin
            ),
        };

        yield put(actions.editChangeRequestContentReceived(processInstanceId, content));
    } catch (error) {
        yield put(actions.editChangeRequestContentError(error as any));
    }
}

function* saveCRContent(api: IWebDatabaseApi, action: ActionType<typeof actions.saveChangeRequestContent>) {
    const { processInstanceId, contextVersionOid, moduleVersionOid, content } = action.payload;

    try {
        const crContent = {
            ...content,
            moduleVersionContent: UrlSchemeUtils.resetUrlScheme(content.moduleVersionContent, window.location.origin),
        };
        const updatedContent: IWorkflowCrEditContent = yield call(
            [api, api.saveCRContent],
            contextVersionOid,
            moduleVersionOid,
            crContent
        );

        yield put(
            actions.editChangeRequestContentReceived(processInstanceId, {
                ...content,
                diffContent: updatedContent.diffContent,
            })
        );
        yield put(actions.crContentSaved(true));
        yield delay(100);
        yield put(actions.resetDraftErrors());
    } catch (error) {
        try {
            yield put(actions.saveDraftErrorReceived(true));
        } catch (e) {
            console.error('Error handling error', e);
        }
    }
}

function* peekEditingCRTitle(api: IWebDatabaseApi, action: ActionType<typeof actions.peekEditingChangeRequestTitle>) {
    const { processInstanceId, contextVersionOid, moduleOid } = action.payload;

    try {
        const title: IWorkflowCrEditTitle = yield call([api, api.peekCRTitle], contextVersionOid, moduleOid);

        yield put(actions.peekEditingChangeRequestTitleReceived(processInstanceId, title));
    } catch (error) {
        console.error(error);
    }
}

function* startEditingCRTitle(api: IWebDatabaseApi, action: ActionType<typeof actions.startEditingChangeRequestTitle>) {
    const { processInstanceId, contextVersionOid, moduleOid } = action.payload;

    try {
        const title: IWorkflowCrEditTitle = yield call([api, api.getCRTitle], contextVersionOid, moduleOid);

        yield put(actions.editChangeRequestTitleReceived(processInstanceId, title));
    } catch (error) {
        console.error(error);
    }
}

function* saveCRTitle(api: IWebDatabaseApi, action: ActionType<typeof actions.saveChangeRequestTitle>) {
    const { processInstanceId, contextVersionOid, moduleOid, title } = action.payload;

    try {
        yield call([api, api.saveCRTitle], contextVersionOid, moduleOid, title);

        yield put(actions.editChangeRequestTitleReceived(processInstanceId, title));
    } catch (error) {
        console.error(error);
    }
}

function* refreshChangeRequestEditLock(
    api: IWebDatabaseApi,
    action: ActionType<typeof actions.refreshChangeRequestEditLockRequested>
) {
    const { processInstanceId, contextVersionOid } = action.payload;

    try {
        const importJobLock: IApiImportJobLock = yield call([api, api.refreshLock], contextVersionOid);

        yield put(actions.changeRequestEditLockReceived(processInstanceId, importJobLock));
    } catch (error) {
        console.error(error);
    }
}

function* cancelEditCRContent(api: IWebDatabaseApi, action: ActionType<typeof actions.cancelEditCRContentRequested>) {
    const { processInstanceId, contextVersionOid, moduleVersionOid } = action.payload;

    try {
        yield call([api, api.cancelEditCRContent], contextVersionOid, moduleVersionOid);
        yield put(actions.cancelEditCRContentReceived(processInstanceId));
    } catch (error) {
        console.log(error);
    }
}

function* cancelEditCRTitle(api: IWebDatabaseApi, action: ActionType<typeof actions.cancelEditCRTitleRequested>) {
    const { processInstanceId, contextVersionOid, moduleOid } = action.payload;

    try {
        yield call([api, api.cancelEditCRTitle], contextVersionOid, moduleOid);
        yield put(actions.cancelEditCRTitleReceived(processInstanceId));
    } catch (error) {
        console.log(error);
    }
}

function* getChangeRequestsReleasedDocSaga(
    api: IWorkflowApi,
    action: ActionType<typeof actions.getChangeRequestsForReleasedDoc>
): any {
    const { contextOid, contextVersionOid } = action.payload;

    try {
        const changeRequestsReleased = yield call(() =>
            api.getChangeRequestsForReleasedDoc({ contextOid, contextVersionOid })
        );

        yield put(actions.getChangeRequestsSuccess(changeRequestsReleased));
    } catch (error) {
        yield put(actions.getChangeRequestsError(error as any));
    }
}

function* getChangeRequestSaga(api: IWorkflowApi, action: ActionType<typeof actions.getChangeRequest>): any {
    const { changeRequestId } = action.payload;

    try {
        const changeRequest = yield call(() => api.getChangeRequest({ changeRequestId }));

        yield put(actions.getChangeRequestSuccess(changeRequest));
    } catch (error) {
        yield put(actions.getChangeRequestError(error as any));
    }
}

function* updateChangeRequestProcessSaga(
    api: IWorkflowApi,
    action: ActionType<typeof actions.updateChangeRequestProcess>
): any {
    const { changeRequestId } = action.payload;

    try {
        const changeRequest = yield call(() => api.getChangeRequest({ changeRequestId }));
        yield put(actions.getChangeRequestSuccess(changeRequest));

        yield put(processActions.requestVariablesGrouped(changeRequestId));
        yield put(processActions.canCommentOnProcessRequested(changeRequestId));
    } catch (error) {
        yield put(actions.getChangeRequestError(error as any));
    }
}

function* getChangeRequestsForModuleSaga(
    api: IWorkflowApi,
    action: ActionType<typeof actions.getChangeRequestsForModule>
): any {
    const { contextOid, contextVersionOid, moduleOid, isDraft } = action.payload;

    try {
        const moduleCRs = yield call(() =>
            api.getChangeRequestsForModule({ contextOid, contextVersionOid, moduleOid, isDraft })
        );

        yield put(actions.getChangeRequestsForModuleSuccess(moduleCRs));
    } catch (error) {
        yield put(actions.getChangeRequestsForModuleError(error as any));
    }
}

function* getChangeRequestsDraftDocSaga(
    api: IWorkflowApi,
    action: ActionType<typeof actions.getChangeRequestsForDraftDoc>
): any {
    const { contextOid, contextVersionOid } = action.payload;

    try {
        const changeRequestsDraft = yield call(() =>
            api.getChangeRequestsForDraftDoc({ contextOid, contextVersionOid })
        );

        yield put(actions.getChangeRequestsSuccess(changeRequestsDraft));
    } catch (error) {
        yield put(actions.getChangeRequestsError(error as any));
    }
}

function* getFinishedChangeRequestsSaga(
    api: IWorkflowApi,
    action: ActionType<typeof actions.getFinishedChangeRequests>
): any {
    const contextVersionOid = action.payload;

    try {
        const changeRequestsFinished = yield call(() => api.getFinishedChangeRequests({ contextVersionOid }));

        yield put(actions.getFinishedChangeRequestsSuccess(changeRequestsFinished));
    } catch (error) {
        yield put(actions.getChangeRequestsError(error as any));
    }
}

export function* changeRequestSagas(api: IWorkflowApi, webApi: IWebDatabaseApi) {
    yield takeLatest(getType(actions.getChangeRequestsForModule), getChangeRequestsForModuleSaga, api);

    yield takeLatest(getType(actions.getChangeRequestsForDraftDoc), getChangeRequestsDraftDocSaga, api);
    yield takeLatest(getType(actions.getChangeRequestsForReleasedDoc), getChangeRequestsReleasedDocSaga, api);
    yield takeLatest(getType(actions.getFinishedChangeRequests), getFinishedChangeRequestsSaga, api);
    yield takeLatest(getType(actions.getChangeRequest), getChangeRequestSaga, api);
    yield takeLatest(getType(actions.allChangeRequestsOfUserRequested), requestAllChangeRequestsOfUser, api);
    yield takeEvery(getType(actions.newChangeRequestFormFieldsRequested), requestNewChangeRequestFormFields, api);
    yield takeLatest(getType(actions.addChangeRequest), addChangeRequest, api);
    yield takeLatest(getType(actions.startEditingChangeRequestContent), startEditingCRContent, webApi);
    yield takeLatest(getType(actions.peekEditingChangeRequestContent), peekEditingCRContent, webApi);
    yield takeLatest(getType(actions.saveChangeRequestContent), saveCRContent, webApi);
    yield takeLatest(getType(actions.peekEditingChangeRequestTitle), peekEditingCRTitle, webApi);
    yield takeLatest(getType(actions.startEditingChangeRequestTitle), startEditingCRTitle, webApi);
    yield takeLatest(getType(actions.saveChangeRequestTitle), saveCRTitle, webApi);
    yield takeLatest(getType(actions.refreshChangeRequestEditLockRequested), refreshChangeRequestEditLock, webApi);
    yield takeLatest(getType(actions.cancelEditCRContentRequested), cancelEditCRContent, webApi);
    yield takeLatest(getType(actions.cancelEditCRTitleRequested), cancelEditCRTitle, webApi);
    yield takeLatest(
        getType(actions.contextVersionImportJobHasChangesRequested),
        requestContextVersionUpdateJobHasChanges,
        webApi
    );
    yield takeLatest(getType(actions.getChangeRequestsForWfSidebar), getChangeRequestsForWfSidebarSaga);
    yield takeLatest(getType(actions.updateChangeRequestProcess), updateChangeRequestProcessSaga, api);
    yield takeLatest(getType(actions.calculatedDiffRequested), calculateDiff, api);
}
