import React from 'react';
import {
    IWorkflowActivity,
    IWorkflowHistoricActivity,
    IWorkflowRevisionHistoric,
    SidebarTool,
} from '@yonder-mind/ui-core';
import { useWorkflow } from '../../../context';
import { WorkflowHistory } from '../WorkflowHistory';

interface IProps {
    title: string;
    subTitle?: React.ReactNode;
    activity?: IWorkflowActivity | IWorkflowHistoricActivity | IWorkflowRevisionHistoric;
    tasks: React.ReactNode;
}

export const WorkflowSidebar: React.FC<IProps> = ({ title, subTitle, activity, tasks }) => {
    const { actions, histories: historiesByProcessId } = useWorkflow('process');

    const { processInstanceId } = activity || ({} as any);

    React.useEffect(() => {
        if (processInstanceId) {
            actions.requestHistory(processInstanceId);
        }
    }, [processInstanceId]);

    const histories = historiesByProcessId[processInstanceId];

    return (
        <div className="sidebar sidebar--workflow">
            <div className="container">
                <SidebarTool title={title} subTitle={subTitle && <div className="subtitle" children={subTitle} />}>
                    <>{tasks}</>
                    <WorkflowHistory histories={histories} />
                </SidebarTool>
            </div>
        </div>
    );
};
