import { ApiConstants } from '../../utils/ApiConstants';

export class FileDropConstants extends ApiConstants {
    static uploadFile(): URL {
        return ApiConstants.relativeUrl('/file-drop/upload');
    }

    static replaceFileContent(fileId: string): URL {
        return ApiConstants.relativeUrl(`/file-drop/files/${fileId}/replace-content`);
    }

    static getFileDropList(): URL {
        return ApiConstants.relativeUrl('/file-drop/files');
    }

    static getFileDropListDashboard(): URL {
        return ApiConstants.relativeUrl('/file-drop/my-files');
    }

    static deleteFile(fileId: string): URL {
        return ApiConstants.relativeUrl(`/file-drop/files/${fileId}`);
    }

    static updateFileMetadata(fileId: string): URL {
        return ApiConstants.relativeUrl(`/file-drop/files/${fileId}`);
    }

    static updateFileContent(fileId: string): URL {
        return ApiConstants.relativeUrl(`/file-drop/files/${fileId}/content`);
    }

    static getFileContent(fileId: string): URL {
        return ApiConstants.relativeUrl(`/file-drop/files/${fileId}/content`);
    }

    static markFileAsRead(fileId: string): URL {
        return ApiConstants.relativeUrl(`/file-drop/files/${fileId}/markFileAsRead`);
    }

    static createSuccessor(fileId: string): URL {
        return ApiConstants.relativeUrl(`/file-drop/files/${fileId}/create-successor`);
    }
}
