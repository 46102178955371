import {
    TagDomainIcon,
    IDomainSelection,
    IFilterNode,
    ITagTreeNode,
    FilterHelper,
    TagUtils,
    DEFAULT_FILTER_ICON,
    ITagDomain,
} from '@yonder-mind/ui-core';
import { Chip } from '@material-ui/core';

import React from 'react';

type IProps = {
    tagDomains: ITagDomain[];
    selection: IDomainSelection;
    handleUpdate: (newSelection: IFilterNode[], domainOid: string) => void;
    activeChips: string[];
    allTags: { [key: string]: ITagTreeNode };
};

export const MetadataTagsChips: React.FC<IProps> = ({ activeChips, allTags, tagDomains, selection, handleUpdate }) => {
    const handleDelete = (event: React.MouseEvent<HTMLElement>) => {
        const oid = event.currentTarget.parentElement ? event.currentTarget.parentElement.id : '';
        const domain = tagDomains.find((value) => value.tags[oid] !== undefined);
        if (domain) {
            const newSelection: IFilterNode[] = FilterHelper.updateSelection(oid, false, selection[domain.oid]);
            handleUpdate(newSelection, domain.oid);
        }
    };

    if (activeChips.length) {
        const chipsList = activeChips.map((filter: string) => {
            const filterValue = allTags[filter];
            if (filterValue) {
                const tagDomainIcon = TagUtils.getTagDomainIcon(filterValue.oid, tagDomains);
                const coloring = 'primary';
                return (
                    <Chip
                        id={filter}
                        key={filter}
                        icon={
                            <TagDomainIcon
                                filterIcon={tagDomainIcon ? tagDomainIcon : DEFAULT_FILTER_ICON}
                                coloring={coloring}
                            />
                        }
                        label={TagUtils.getTagName(filterValue)}
                        onDelete={handleDelete}
                        className="chip"
                        variant="outlined"
                        color={coloring}
                    />
                );
            }
            return <></>;
        });
        return <>{chipsList}</>;
    }
    return <></>;
};
