import { TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowUpward } from '@material-ui/icons';

interface IProps {
    sortingConfiguration: SortingConfiguration;
    setSortingConfiguration: (sortingConfiguration: SortingConfiguration) => void;
}

type ColumnKey = 'name' | 'revision' | 'readDoneCount' | 'ackDoneCount' | 'effectiveFrom' | 'effectiveTo';
type Order = 'asc' | 'desc';

export interface SortingConfiguration {
    order: Order;
    orderBy: ColumnKey;
}

export const DocumentsTableHead: React.FC<IProps> = (props) => {
    const { sortingConfiguration, setSortingConfiguration } = props;

    const { t } = useTranslation();

    const handleSortClick = (columnKey: ColumnKey) => {
        if (sortingConfiguration.orderBy === columnKey) {
            setSortingConfiguration({
                order: sortingConfiguration.order === 'asc' ? 'desc' : 'asc',
                orderBy: columnKey,
            });
        } else {
            setSortingConfiguration({
                order: 'asc',
                orderBy: columnKey,
            });
        }
    };

    const getColumnDirection = (columnKey: ColumnKey): Order => {
        if (sortingConfiguration.orderBy === columnKey) {
            return sortingConfiguration.order;
        }
        return 'asc';
    };

    const renderSortableHeaderCell = (columnKey: ColumnKey, label: string, className?: string) => {
        const isActive = (columKey: ColumnKey) => sortingConfiguration.orderBy === columKey;
        const getClassName = `${className ? className : ''}${isActive(columnKey) ? ' table-head-active' : ''}`;

        return (
            <TableCell className={getClassName}>
                <TableSortLabel
                    onClick={() => handleSortClick(columnKey)}
                    direction={getColumnDirection(columnKey)}
                    active={isActive(columnKey)}
                    IconComponent={ArrowUpward}
                >
                    {label}
                </TableSortLabel>
            </TableCell>
        );
    };

    return (
        <TableHead>
            <TableRow>
                {renderSortableHeaderCell('name', t('complianceReports.documentsTable.name'))}
                {renderSortableHeaderCell('revision', t('complianceReports.documentsTable.version'))}
                {renderSortableHeaderCell('readDoneCount', t('complianceReports.documentsTable.read'))}
                {renderSortableHeaderCell('ackDoneCount', t('complianceReports.documentsTable.readAndAck'))}
                {renderSortableHeaderCell('effectiveFrom', t('complianceReports.documentsTable.effectiveFrom'))}
                {renderSortableHeaderCell('effectiveTo', t('complianceReports.documentsTable.effectiveTo'))}
            </TableRow>
        </TableHead>
    );
};
