import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { IWorkflowFormField } from '@yonder-mind/ui-core';
import { IFormErrors, IFormFieldConfig, IFormFields, IFormValidators, IFormValues } from '../../interfaces';
import { getValidator } from './validators';

interface IFormFieldsProps {
    fields: IFormFields;
    values: IFormValues;
    errors: IFormErrors;
    onChange: (values: IFormValues) => any;
}

export type FormFieldsProps = IFormFieldsProps;
export type CustomRenderFunction = (props: IFormFieldsProps) => any;

interface IFormInformation {
    fields: IFormFields;
    values: IFormValues;
    errors: IFormErrors;
    validators: IFormValidators;
    onChange: (id: string, value: string) => any;
}

type IButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

interface IProps {
    fields: IWorkflowFormField[];
    hiddenFields?: string[];
    injectedValues?: IFormValues;
    onSubmit: (values: IFormValues) => any;
    children: (
        form: IFormInformation,
        submitProps: Pick<IButtonProps, Exclude<keyof IButtonProps, 'color'>> & { onClick: () => any }
    ) => React.ReactNode;
}

function fixDate(date: string) {
    return date.split('-').reverse().join('/');
}

const toFormField = (formField: IWorkflowFormField): IFormFieldConfig => ({
    id: formField.id,
    initialValue: formField.defaultValue,
    label: formField.label,
    placeholder: formField.label,
    required: !!formField.validationConstraints.find(
        (constraint) => constraint.name === 'required' && constraint.configuration === 'true'
    ),
    type: formField.typeName,
    values: formField.type.values,
    disabled: !!formField.properties.autoComplete,
    hidden: !!formField.properties.hidden,
    multiLine: !!formField.properties.multiLine,
    maxCharacters:
        (formField.validationConstraints.length > 0 &&
            parseInt(
                formField.validationConstraints.find((constraint) => constraint.name === 'maxlength')?.configuration
            )) ||
        null,
});

const buildForm = (fields: IWorkflowFormField[], hiddenFields: string[]) =>
    fields.reduce(
        (acc, field) =>
            hiddenFields.includes(field.id)
                ? acc
                : {
                      ...acc,
                      [field.id]: toFormField(field),
                  },
        {}
    );

const generateInitialValues = (fields: IWorkflowFormField[]) =>
    fields.reduce(
        (acc, field) => ({
            ...acc,
            [field.id]: field.defaultValue || '',
        }),
        {}
    );

const generateValidators = (fields: IWorkflowFormField[], hiddenFields: string[], t: TFunction) =>
    fields.reduce(
        (acc, field) =>
            hiddenFields.includes(field.id)
                ? acc
                : {
                      ...acc,
                      [field.id]: field.validationConstraints.map((constraint) => getValidator(constraint, t)),
                  },
        {}
    );

const validate = (formValidators: IFormValidators, values: IFormValues) => {
    if (formValidators) {
        const errors = Object.entries(formValidators).reduce((formAcc, [fieldId, validators]) => {
            let errorMessages: string[] = [];

            errorMessages = validators.reduce((acc: string[], validator) => {
                const errorMessage = validator(values[fieldId]);

                if (errorMessage) {
                    return [...acc, errorMessage];
                }

                return acc;
            }, []);

            if (errorMessages.length > 0) {
                return {
                    ...formAcc,
                    [fieldId]: errorMessages,
                };
            }

            return formAcc;
        }, {});

        if (Object.keys(errors).length === 0) {
            return null;
        }

        return errors;
    }

    return undefined;
};

export const Form: React.FC<IProps> = (props) => {
    const { t } = useTranslation();
    const submitButton = React.useRef<HTMLButtonElement>(null);

    const [fields, setFields] = React.useState<IFormFields>({});
    const [values, setValues] = React.useState<IFormValues>({});
    const [errors, setErrors] = React.useState<IFormErrors>({});
    const [validators, setValidators] = React.useState<IFormValidators>({});

    const injectedFieldIds = Object.keys(props.injectedValues || {});
    const hiddenFieldIds = props.hiddenFields || [];
    const hiddenFields = [...injectedFieldIds, ...hiddenFieldIds];

    React.useEffect(() => {
        setFields(buildForm(props.fields || [], hiddenFields));
        setValues({ ...generateInitialValues(props.fields), ...(props.injectedValues || {}) });
        setValidators(generateValidators(props.fields, hiddenFields, t));
    }, []);

    React.useEffect(() => {
        setFields(buildForm(props.fields || [], hiddenFields));
        setValidators(generateValidators(props.fields, hiddenFields, t));
    }, [props.injectedValues]);

    const handleFieldValueChange = (id: string, value: string) => {
        setValues((oldValues) => ({
            ...oldValues,
            [id]: value,
        }));
    };

    const submit = () => {
        if (submitButton.current) {
            submitButton.current.click();
        }
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        const validationErrors = validate(validators, values);

        if (validationErrors) {
            setErrors(validationErrors);
        } else {
            const fixedDateValues = Object.entries(values).reduce(
                (acc, [id, value]) => ({
                    ...acc,
                    [id]: fields[id] && fields[id].type === 'date' ? fixDate(value) : value,
                }),
                {}
            );

            props.onSubmit({
                ...fixedDateValues,
            });
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            {props.children(
                { fields, values, errors, validators, onChange: handleFieldValueChange },
                { onClick: submit }
            )}
            <button ref={submitButton} hidden={true} />
        </form>
    );
};
