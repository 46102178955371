import { TFunction } from 'i18next';
import { IWorkflowFormFieldValidationConstraint } from '@yonder-mind/ui-core';
import { FormValidator, Validator } from './../../interfaces';

const required: Validator = (configuration: any, t: TFunction) => (value: string): string | void => {
    if ((!value || value.length === 0) && JSON.parse(configuration)) {
        return `${t('workflow.changeRequest.create.errors.required')}`;
    }
};

const maxLength: Validator = (configuration: any, t: TFunction) => (value: string): string | void => {
    const maxLen = JSON.parse(configuration);
    if (value && value.length > maxLen) {
        return `${t('workflow.changeRequest.create.errors.maxLength', { maxLength: maxLen })}`;
    }
};

export function getValidator(constraint: IWorkflowFormFieldValidationConstraint, t: TFunction): FormValidator {
    switch (constraint.name) {
        case 'required':
            return required(constraint.configuration, t);
        case 'maxlength':
            return maxLength(constraint.configuration, t);
    }

    return (): undefined => undefined;
}
