import React, { useEffect, useState } from 'react';
import { Checkbox, Dialog, DialogActions, DialogContent, FormControlLabel, FormGroup } from '@material-ui/core';
import { CloseButton, IApplicationStore, IUser, SearchBar, Spinner, YonderButton } from '@yonder-mind/ui-core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FileDropMetadata } from '../../domain/types';
import { MetadataDialogTitle } from './MetadataDialogTitle';

export const MetadataUsersSelectorModal: React.FC<{
    setMetadata: (metadata: FileDropMetadata) => void;
    initialMetadata: FileDropMetadata;
    open: boolean;
    onClose: () => void;
}> = ({ setMetadata, initialMetadata, onClose, open }) => {
    const [usersListState, setUsersListState] = useState<IUser[] | undefined>(undefined);
    const [usersSelected, setUsersSelected] = useState<string[]>([]);

    const usersList = useSelector((state: IApplicationStore) => state.fileDrop.usersList);
    const usersListLoading = useSelector((state: IApplicationStore) => state.fileDrop.usersListLoading);

    const { t } = useTranslation();

    const onSearch = (value: string) => {
        setUsersListState(
            usersList.filter(
                (user: IUser) =>
                    user.firstName.toLowerCase().trim().includes(value.toLowerCase().trim()) ||
                    user.lastName.toLowerCase().trim().includes(value.toLowerCase().trim()) ||
                    user.email.toLowerCase().trim().includes(value.toLowerCase().trim())
            )
        );
    };

    const clearFilters = () => {
        setUsersListState(usersList);
    };

    const onChange = (checked: boolean, username: string) => {
        if (checked) {
            setUsersSelected(usersSelected.concat(username));
        } else {
            setUsersSelected(usersSelected.filter((item: string) => item !== username));
        }
    };

    useEffect(() => {
        usersList && setUsersListState(usersList);
    }, [usersList]);

    const onOk = () => {
        setMetadata({ users: [...(initialMetadata.users ?? []), ...usersSelected] });
        onClose();
    };

    const renderList = () => {
        return usersListState
            .filter((user) => !(initialMetadata.users?.includes(user.username) ?? false))
            .map((user: IUser, index: number) => {
                return (
                    <div key={index}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={usersSelected && usersSelected.includes(user.username)}
                                        onChange={(e) => onChange(e.target.checked, user.username)}
                                        color="primary"
                                    />
                                }
                                label={`${user.firstName} ${user.lastName}`}
                            />
                        </FormGroup>
                    </div>
                );
            });
    };

    return (
        <Dialog
            className="metadata-dialog"
            PaperProps={{ className: 'metadata-dialog-paper' }}
            open={open}
            onClose={onClose}
            fullWidth={true}
        >
            <MetadataDialogTitle
                title={t('fileDrop.metadataPanel.users.assignUsers')}
                subTitle={`${usersSelected.length} ${t('fileDrop.metadataPanel.users.usersSelected')}`}
                type="users"
            />
            <div className="close-button">
                <CloseButton onClick={onClose} size="small" />
            </div>
            <DialogContent className="metadata-users-dialog-content">
                <SearchBar
                    className="metadata-dialog-search-bar"
                    placeholder={t('fileDrop.metadataPanel.users.findUser')}
                    onSearchRequested={onSearch}
                    onSearchCleared={clearFilters}
                    onInputChanged={onSearch}
                />
                <div className="metadata-users-list-wrapper">
                    <div className="metadata-users-list">
                        {usersListLoading ? <Spinner /> : usersListState && renderList()}
                    </div>
                </div>
            </DialogContent>
            <DialogActions className="metadata-dialog-actions">
                <YonderButton onClick={onClose} variant="text">
                    {t('fileDrop.metadataPanel.users.cancel')}
                </YonderButton>
                <YonderButton onClick={onOk} variant="contained" color="primary">
                    {t('fileDrop.metadataPanel.users.add')}
                </YonderButton>
            </DialogActions>
        </Dialog>
    );
};
