import { default as messagesDe } from '../messages/messages-de.json';
import { default as messagesEn } from '../messages/messages-en.json';
import { default as messagesFr } from '../messages/messages-fr.json';
import { default as messagesIt } from '../messages/messages-it.json';

export const i18nDefaultLanguage = 'en';

export const i18nOrderedLanguages = ['de', 'en', 'fr', 'it'];

export const i18nResources = {
    de: messagesDe,
    en: messagesEn,
    fr: messagesFr,
    it: messagesIt,
};

export const i18nNames = {
    de: 'Deutsch',
    en: 'English',
    fr: 'Français',
    it: 'Italiano',
};
