import {
    IApiContextTreeNode,
    IApiContextVersion,
    IWorkflowChangeRequestV2,
    IWorkflowType,
    OutlineUtils,
} from '@yonder-mind/ui-core';

function focusChangeRequest(
    context: 'module-view' | 'change-request-subscreen',
    moduleVersionOid: string,
    index: number
) {
    const container = document.getElementsByClassName(context);
    if (container.length === 0) {
        return;
    }

    const elem = container[0].querySelector(`#cr-bubble-${moduleVersionOid}-${index}`);
    if (!elem) {
        return;
    }

    const highlightBubble = elem.getElementsByClassName('change-request-highlight-bubble');
    if (highlightBubble.length) {
        highlightBubble[0].scrollIntoView({ behavior: 'smooth' });
    } else {
        elem.scrollIntoView({ behavior: 'smooth' });
    }
}

function hasDeleteModule(moduleVersionId: string, moduleVersionIdToCRs: {}) {
    return (
        moduleVersionIdToCRs[moduleVersionId] != undefined &&
        moduleVersionIdToCRs[moduleVersionId].some((cr: IWorkflowChangeRequestV2) => isDeleteModule(cr))
    );
}

const openedModuleVersionsPath = (openedModulesPath: string[], version: IApiContextVersion) => {
    const moduleIdToModuleVersionId = new Map(
        OutlineUtils.flatten(version.outline)?.map((n: IApiContextTreeNode) => [n.moduleOid, n.moduleVersionOid])
    );
    return openedModulesPath.map((moduleId) => moduleIdToModuleVersionId.get(moduleId));
};

function isDeleteModule(changeRequest: IWorkflowChangeRequestV2) {
    return (
        (changeRequest.processDefinitionKey === IWorkflowType.DELETE_MODULE &&
            changeRequest.status !== 'EndEvent_ChangeObsolete') ||
        changeRequest.status == 'EndEvent_ModuleDeleted'
    );
}

export const ChangeRequestUtils = {
    focusChangeRequest,
    hasDeleteModule,
    openedModuleVersionsPath,
};
