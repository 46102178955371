import React from 'react';
import { useDispatch } from 'react-redux';
import { complianceReportsActions, useSelectorUiWeb } from '../../../store';
import { DropDownForSelectionButton, ItemSelector } from '@yonder-mind/ui-core';
import { DropdownType } from '../../../interfaces';
import { useTranslation } from 'react-i18next';

interface Props {
    selectedDropdown: DropdownType;
    handleOnClick: (type: DropdownType) => void;
    disabled?: boolean;
}

export const DropDownDocumentSelector: React.FC<Props> = (props) => {
    const { selectedDropdown, handleOnClick, disabled } = props;
    const dispatch = useDispatch();

    const { t } = useTranslation();

    const documentItemList = useSelectorUiWeb((state) => state.complianceReports.selectableDocuments);

    const updateSelectedItemIds = (selectedItemIds: string[]) => {
        dispatch(complianceReportsActions.updateFilterConfigSelectedDocumentIds(selectedItemIds));
    };

    const selectedDocumentIds = useSelectorUiWeb((state) => state.complianceReports.filterConfig.selectedDocumentIds);

    const isLoading = useSelectorUiWeb((state) => state.complianceReports.isLoadingSelectableDocuments);

    return (
        <DropDownForSelectionButton
            disabled={disabled}
            title={t('complianceReports.filter.documents.title')}
            expanded={selectedDropdown === 'documents'}
            onClick={() => handleOnClick('documents')}
            numberOfAllItems={documentItemList.length}
            numberOfSelectedItems={selectedDocumentIds.length}
            handleClose={() => handleOnClick('none')}
            multiSelect={true}
        >
            <ItemSelector
                isLoading={isLoading}
                itemList={documentItemList}
                selectedItemIds={selectedDocumentIds}
                updateSelectedItemIds={updateSelectedItemIds}
                multiSelect={true}
                selectedItemsText={t('complianceReports.filter.documents.selected')}
                allItemsText={t('complianceReports.filter.documents.all')}
                emptyAllItemsText={t('complianceReports.filter.documents.notAvailable')}
                noMatches={t('complianceReports.filter.documents.noMatches')}
                otherItemsText={t('complianceReports.filter.documents.other')}
            />
        </DropDownForSelectionButton>
    );
};
