import { forwardRef } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/styles/withStyles';
import { HighlyPerformantTableColumn } from './HighlyPerformantTableInterfaces';

interface HighlyPerformantTableHeaderProps {
    classes: ClassNameMap;
    columns: HighlyPerformantTableColumn[];
}

export const HighlyPerformantTableHeader = forwardRef(({ classes, columns }: HighlyPerformantTableHeaderProps, ref) => (
    // @ts-ignore
    <TableRow component="div" className={`${classes.row} ${classes.headerRow}`} ref={ref}>
        {columns.map((column, colIndex) => {
            return (
                <TableCell
                    key={colIndex}
                    component="div"
                    variant="head"
                    align={'left'}
                    scope="col"
                    className={`${classes.headerCell}${column.width ? '' : ` ${classes.expandingCell}`}`}
                    style={{
                        flexBasis: column.width || 0,
                    }}
                >
                    {column.label}
                </TableCell>
            );
        })}
    </TableRow>
));
