import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import {
    docsActions,
    Layout,
    SideBarProvider,
    routerSelectors,
    IApplicationStore,
    getContextVersionStatusName,
} from '@yonder-mind/ui-core';
import { SubmissionDetails } from '../../components/Form';

export interface UrlParam {
    documentOid: string;
    formSubmissionOid?: string;
}

export const Form: React.FC = () => {
    const dispatch = useDispatch();
    const { params } = useRouteMatch<UrlParam>();
    const [unsavedChanges, setUnsavedChanges] = useState(false);

    const fetchFormContent = () => {
        dispatch(docsActions.contentByModuleVersionOidRequested(moduleVersionOid));
    };

    const { form, formContent, moduleVersionOid, sidebarTool, sidebarToolTab } = useSelector(
        (state: IApplicationStore) => {
            const { sidebarTool, sidebarToolTab } = routerSelectors.queryParams(state);
            return {
                form: state.docs.documentCache[params.documentOid]?.contextVersion,
                moduleVersionOid:
                    state.docs.documentCache[params.documentOid]?.contextVersion?.outline[0]?.moduleVersionOid,
                formContent: state.docs.formContent,
                sidebarTool,
                sidebarToolTab,
            };
        }
    );

    const debounceContentLoad = useCallback(debounce(fetchFormContent, 300), [moduleVersionOid]);

    useEffect(() => {
        params.documentOid &&
            dispatch(
                docsActions.documentRequested({
                    documentId: params.documentOid,
                    filters: [],
                })
            );
    }, [params.documentOid]);

    useEffect(() => {
        if (!isEmpty(form) && isEmpty(formContent[moduleVersionOid])) {
            debounceContentLoad();
        }
    }, [form]);

    const getFormVersionStatus = () => {
        return getContextVersionStatusName(form?.status, { from: form?.effectiveFrom, to: form?.effectiveTo });
    };

    return (
        <SideBarProvider tool={sidebarTool} toolTab={sidebarToolTab} extraTools={[]}>
            <Layout
                title={form?.title}
                variant="form"
                sidebarTool={sidebarTool}
                sidebarToolTab={sidebarToolTab}
                hasUnsavedChanges={unsavedChanges}
            >
                <SubmissionDetails
                    moduleVersionOid={moduleVersionOid}
                    formVersionOid={params.documentOid}
                    formSubmissionOid={params.formSubmissionOid}
                    formSubmissionTypeOid={form?.formSubmissionTypeOid}
                    formStatus={getFormVersionStatus()}
                    formContent={formContent}
                    unsavedChanges={unsavedChanges}
                    onUnsavedChanges={setUnsavedChanges}
                />
            </Layout>
        </SideBarProvider>
    );
};
