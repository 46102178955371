import { ApiConstants } from '../../utils';

export class CompareRevisionsConstants {
    static getCompareRevisionsResults(): URL {
        return ApiConstants.relativeUrl(`/compare-revisions`);
    }

    static postCompareRevisionsGeneration(contextVersionOid1: string, contextVersionOid2: string, name: string): URL {
        return ApiConstants.relativeUrl(
            `/compare-revisions?contextVersionOid1=${contextVersionOid1}&contextVersionOid2=${contextVersionOid2}&name=${name}`
        );
    }

    static getCompareRevisionsJob(compareRevisionsJobId: string): URL {
        return ApiConstants.relativeUrl(`/compare-revisions/job/${compareRevisionsJobId}`);
    }

    static getActiveCompareRevisionsJobs(): URL {
        return ApiConstants.relativeUrl(`/compare-revisions/jobs`);
    }
}
