import { Theme } from '@material-ui/core/styles';
import { HIGHLY_PERFORMANT_HEADER_ROW_HEIGHT } from './HighlyPerformantTable';

export const HighlyPerformantTableStyles =
    (minWidth = 1350) =>
    (theme: Theme) => ({
        root: {
            display: 'block',
            flex: 1,
        },
        table: {
            height: '100%',
            width: '100%',
            minWidth,
        },
        list: {},
        thead: {
            height: HIGHLY_PERFORMANT_HEADER_ROW_HEIGHT,
        },
        tbody: {
            height: 'calc(100% - 2em)',
            width: '100%',
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            alignItems: 'center',
            boxSizing: 'border-box',
            width: '100%',
        },
        headerRow: {
            height: HIGHLY_PERFORMANT_HEADER_ROW_HEIGHT,
            backgroundColor: theme.palette.background.default,
            minWidth,
        },
        headerCell: {
            flexGrow: 0,
            flexShrink: 0,
            display: 'flex',
            alignItems: 'center',
            height: HIGHLY_PERFORMANT_HEADER_ROW_HEIGHT,
        },
        cell: {
            display: 'inline-block',
            flexGrow: 0,
            flexShrink: 0,
            padding: '6px 16px',
            backgroundColor:
                theme.palette.type === 'light' ? theme.palette.background.paper : theme.palette.background.default,
        },
        expandingCell: {
            flex: 1,
            minWidth: 150,
        },
        column: {},
    });
