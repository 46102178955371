import React, { useEffect } from 'react';
import { DropDownForSelectionButton, Spinner, TagsSelector } from '@yonder-mind/ui-core';
import { DropdownType } from '../../../interfaces';
import { useDispatch } from 'react-redux';
import { complianceReportsActions, useSelectorUiWeb } from '../../../store';
import { useTranslation } from 'react-i18next';

interface Props {
    selectedDropdown: DropdownType;
    handleOnClick: (type: DropdownType) => void;
    disabled?: boolean;
}

export const DropDownTagsSelector: React.FC<Props> = (props) => {
    const { selectedDropdown, handleOnClick, disabled } = props;
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const setSelectedIds = (ids: string[]) => dispatch(complianceReportsActions.updateFilterConfigSelectedTagIds(ids));
    const selectedIds = useSelectorUiWeb((state) => state.complianceReports.filterConfig.selectedTagIds);
    const tagDomains = useSelectorUiWeb((state) => state.complianceReports.tagDomains);
    const isLoading = useSelectorUiWeb((state) => state.complianceReports.isLoadingTags);

    useEffect(() => {
        dispatch(complianceReportsActions.complianceReportsTagsRequested());
    }, [dispatch]);

    return (
        <DropDownForSelectionButton
            disabled={disabled}
            title={t('complianceReports.filter.tags.title')}
            expanded={selectedDropdown === 'tags'}
            onClick={() => handleOnClick('tags')}
            numberOfAllItems={tagDomains.length}
            numberOfSelectedItems={selectedIds.length}
            handleClose={() => handleOnClick('none')}
            isTagSelection
        >
            {isLoading && <Spinner />}
            <TagsSelector tagDomains={tagDomains} selectedIds={selectedIds} setSelectedIds={setSelectedIds} />
        </DropDownForSelectionButton>
    );
};
