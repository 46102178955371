import { useState } from 'react';
import { DropDownButton, YonderButton } from '@yonder-mind/ui-core';

export default {
    component: YonderButton,
    title: 'Components/YonderButton',
};

export const SubmitButton = () => <YonderButton type={'submit'}>Submit Button</YonderButton>;

export const ResetButton = () => <YonderButton type={'reset'}>Reset Button</YonderButton>;

export const TextButton = () => <YonderButton variant={'text'}>Text Button</YonderButton>;

export const OutlinedButton = () => <YonderButton variant={'outlined'}>Outlined Button</YonderButton>;

export const ContainedButton = () => <YonderButton variant={'contained'}>Contained Button</YonderButton>;

export const PrimaryButton = () => (
    <YonderButton variant={'contained'} color={'primary'}>
        Primary Button
    </YonderButton>
);

export const SecondaryButton = () => (
    <YonderButton variant={'contained'} color={'secondary'}>
        Secondary Button
    </YonderButton>
);

export const Dropdown = () => {
    const [expanded, setExpanded] = useState(false);
    return <DropDownButton title={'Dropdown Button'} expanded={expanded} onClick={() => setExpanded(!expanded)} />;
};
