// @ts-nocheck
import React from 'react';

import { Text, View } from '@react-pdf/renderer';
import { Table, TableHeader, TableCell, DataTableCell, TableBody } from '@david.kucsai/react-pdf-table';
import { UsersTableItem } from '../domain/types';
import { pdfStyles } from './styles';
import { useTranslation } from 'react-i18next';
import { DateUtils } from '@yonder-mind/ui-core';

type PdfUsersTableProps = {
    users: UsersTableItem[];
    isFile?: boolean;
};

export const PdfUsersTable: React.FC<PdfUsersTableProps> = ({ users, isFile }) => {
    const { t } = useTranslation();
    return (
        <View style={pdfStyles.documentsTable}>
            <Text style={pdfStyles.titleText}>
                {isFile ? t('complianceReports.usersTable.headingFiles') : t('complianceReports.usersTable.heading')}
            </Text>
            <Table data={users}>
                <TableHeader>
                    <TableCell weighting={0.3} style={pdfStyles.headerTableCell}>
                        {t('complianceReports.usersTable.username')}
                    </TableCell>
                    <TableCell weighting={0.2} style={pdfStyles.headerTableCell}>
                        {t('complianceReports.usersTable.role')}
                    </TableCell>
                    <TableCell weighting={0.2} style={pdfStyles.headerTableCell}>
                        {t('complianceReports.usersTable.type')}
                    </TableCell>
                    <TableCell weighting={0.1} style={pdfStyles.headerTableCell}>
                        {t('complianceReports.usersTable.status')}
                    </TableCell>
                    <TableCell weighting={0.2} style={pdfStyles.headerTableCell}>
                        {t('complianceReports.usersTable.date')}
                    </TableCell>
                </TableHeader>
                <TableBody>
                    <DataTableCell
                        weighting={0.3}
                        style={pdfStyles.tableCell}
                        getContent={(r: UsersTableItem) => r.username}
                    />
                    <DataTableCell
                        weighting={0.2}
                        style={pdfStyles.tableCell}
                        getContent={(r: UsersTableItem) => {
                            return r.role ? r.role : '—';
                        }}
                    />
                    <DataTableCell
                        weighting={0.2}
                        style={pdfStyles.tableCell}
                        getContent={(r: UsersTableItem) =>
                            r.ackRequired
                                ? t('complianceReports.documentsTable.readAndAck')
                                : t('complianceReports.documentsTable.read')
                        }
                    />
                    <DataTableCell
                        weighting={0.1}
                        style={pdfStyles.tableCell}
                        getContent={(r: UsersTableItem) => (r.status ? 'OK' : 'NOK')}
                    />
                    <DataTableCell
                        weighting={0.2}
                        style={pdfStyles.tableCell}
                        getContent={(r: UsersTableItem) =>
                            r.actionDate ? DateUtils.ISOToFormattedDateWithoutTime(r.actionDate) : 'N/A'
                        }
                    />
                </TableBody>
            </Table>
        </View>
    );
};
