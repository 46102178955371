import { type ApiRootStructure, Rest, type FolderApi, ContextAssignment } from '@yonder-mind/ui-core';
import { FolderConstants } from './FolderConstants';

export class FolderRestApi implements FolderApi {
    async getFoldersList(processResponse: boolean): Promise<ApiRootStructure> {
        const response = await Rest.getRequest(FolderConstants.getFoldersList(processResponse).toString());
        return response.json();
    }

    async updateFolderEntries(structureOid: string, contextAssignment: ContextAssignment): Promise<ApiRootStructure> {
        const response = await Rest.putRequest(
            FolderConstants.updateFolderStructure(structureOid).toString(),
            contextAssignment
        );
        return response.json();
    }
}
