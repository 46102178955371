import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Grid, Typography } from '@material-ui/core';
import {
    ContextVersionUtils,
    DateUtils,
    docsActions,
    docsSelector,
    IApiContextVersion,
    IWorkflowComment,
    Layout,
    dateTimeFormat,
    Spinner,
} from '@yonder-mind/ui-core';
import { IAggregatedWorkflowInformation, IWebApplicationStore, IVariableGroup } from '../../interfaces';
import { pdfExportActions, pdfExportSelector } from '../../store/pdfexport';

interface IOwnProps
    extends RouteComponentProps<{
        documentOid: string;
    }> {}

interface IActionProps {
    documentRequested: typeof docsActions.documentRequested;
    documentVersionsRequested: typeof docsActions.documentVersionsRequested;
    requestChangeRequestsForDocumentVersion: typeof pdfExportActions.requestChangeRequestsForDocumentVersion;
    requestRevisions: typeof pdfExportActions.requestRevisions;
}

interface IStoreProps {
    documentOid: string;
    document?: IApiContextVersion;
    versions: IApiContextVersion[];
    changeRequests: IAggregatedWorkflowInformation[];
    revisions: IAggregatedWorkflowInformation[];
    isLoadingChangeRequests: boolean;
    isLoadingRevisions: boolean;
}

interface IProps extends IOwnProps, IStoreProps, IActionProps {}

const PrintRevision: React.FC<IProps> = (props) => {
    const { t } = useTranslation();
    const [documentRevision, setDocumentRevision] = React.useState<IApiContextVersion | undefined>(undefined);

    useEffect(() => {
        if (!props.document) {
            props.documentRequested({
                documentId: props.documentOid,
                filters: [],
                searchTerm: undefined,
            });
        }
    }, []);

    useEffect(() => {
        if (props.document && props.versions.length === 0) {
            props.documentVersionsRequested(props.document.contextOid);
        }
    }, [props.document]);

    useEffect(() => {
        if (props.versions.length > 0) {
            const sortedVersions = ContextVersionUtils.sortByLatestEffective(props.versions);
            const currentVersionIndex: number = sortedVersions.findIndex(
                (version) => version.oid === props.documentOid
            );
            if (currentVersionIndex + 1 < sortedVersions.length) {
                const previousVersion = sortedVersions[currentVersionIndex + 1];
                setDocumentRevision(previousVersion);
                props.requestChangeRequestsForDocumentVersion(previousVersion.oid);
                props.requestRevisions(previousVersion.oid);
            }
        }
    }, [props.versions.length]);

    const renderAsTable = (object: any) =>
        Object.keys(object).map((key: string, i: number) => (
            <div key={i}>
                <b>{t(`printer.revision.table.${key}`)}:</b>&nbsp;
                {object[key]}
            </div>
        ));

    const renderProcessStep = (variableGroup: IVariableGroup) =>
        renderAsTable({
            name: variableGroup.taskName,
            user: variableGroup.taskExecutedBy,
            dateTime: DateUtils.parseToDate(variableGroup.endTime, { format: dateTimeFormat }),
        });

    const renderComment = (comment: any) =>
        renderAsTable({
            message: comment.message,
            user: comment.userId,
            dateTime: DateUtils.parseToDate(comment.time, { format: dateTimeFormat }),
        });

    const renderAggregatedWorkflowInformation = (
        workflowInfos: IAggregatedWorkflowInformation[],
        forRevision: boolean
    ) => (
        <>
            {forRevision ? (
                <ul className="workflow-infos">
                    {workflowInfos.map((cr: IAggregatedWorkflowInformation) => (
                        <>
                            <li>
                                <Typography variant="subtitle1">{t('printer.revision.groups.tasks')}</Typography>
                                <ul className="workflow-infos__group">
                                    {cr.variablesHistory.tasks.map((activity: IVariableGroup, i: number) => (
                                        <li key={i}>{renderProcessStep(activity)}</li>
                                    ))}
                                </ul>
                            </li>
                            <li>
                                <Typography variant="subtitle1">{t('printer.revision.groups.discussions')}</Typography>
                                <ul className="workflow-infos__group">
                                    {cr.comments && Object.keys(cr.comments).length > 0 ? (
                                        Object.keys(cr.comments).map(
                                            (comments: string) =>
                                                cr.comments[comments] &&
                                                cr.comments[comments].map((comment: IWorkflowComment, i: number) => (
                                                    <li key={i}>{renderComment(comment)}</li>
                                                ))
                                        )
                                    ) : (
                                        <li>{t('printer.revision.noDiscussions')}</li>
                                    )}
                                </ul>
                            </li>
                            <li>
                                <Typography variant="subtitle1">{t('printer.revision.groups.infos')}</Typography>
                                <ul className="workflow-infos__group">
                                    {Object.keys(cr.variablesHistory.allVars)
                                        .filter((variable) => variable !== 'REVISION_LABEL')
                                        .filter((variable) => !!cr.variablesHistory.allVars[variable])
                                        .reduce((acc, variable) => {
                                            if (acc.includes(variable.toUpperCase())) {
                                                return acc;
                                            }

                                            acc.push(variable);
                                            return acc;
                                        }, [] as string[])
                                        .map((variable: string) => (
                                            <li key={variable}>
                                                <b>
                                                    {t(
                                                        `workflow.${
                                                            forRevision ? 'revision' : 'changeRequest'
                                                        }.variables.${variable.toUpperCase()}`
                                                    )}
                                                    :
                                                </b>
                                                &nbsp;
                                                {cr.variablesHistory.allVars[variable]}
                                            </li>
                                        ))}
                                </ul>
                            </li>
                        </>
                    ))}
                </ul>
            ) : (
                <ul className="workflow-infos">
                    {workflowInfos.map((cr: IAggregatedWorkflowInformation) => (
                        <li key={cr.processInstance.processInstanceId}>
                            <Typography variant="h6">{cr.processInstance.businessKey}</Typography>
                            <ul>
                                <li>
                                    <Typography variant="subtitle1">{t('printer.revision.groups.tasks')}</Typography>
                                    <ul className="workflow-infos__group">
                                        {cr.variablesHistory.tasks.map((activity: IVariableGroup, i: number) => (
                                            <li key={i}>{renderProcessStep(activity)}</li>
                                        ))}
                                    </ul>
                                </li>
                                <li>
                                    <Typography variant="subtitle1">
                                        {t('printer.revision.groups.discussions')}
                                    </Typography>
                                    <ul className="workflow-infos__group">
                                        {cr.comments && Object.keys(cr.comments).length > 0 ? (
                                            Object.keys(cr.comments).map(
                                                (comments: string) =>
                                                    cr.comments[comments] &&
                                                    cr.comments[
                                                        comments
                                                    ].map((comment: IWorkflowComment, i: number) => (
                                                        <li key={i}>{renderComment(comment)}</li>
                                                    ))
                                            )
                                        ) : (
                                            <li>{t('printer.revision.noDiscussions')}</li>
                                        )}
                                    </ul>
                                </li>
                                <li>
                                    <Typography variant="subtitle1">{t('printer.revision.groups.infos')}</Typography>
                                    <ul className="workflow-infos__group">
                                        {Object.keys(cr.variablesHistory.allVars)
                                            .filter((variable) => !!cr.variablesHistory.allVars[variable])
                                            .reduce((acc, variable) => {
                                                if (acc.includes(variable.toUpperCase())) {
                                                    return acc;
                                                }

                                                acc.push(variable);
                                                return acc;
                                            }, [] as string[])
                                            .map((variable: string) => (
                                                <li key={variable}>
                                                    <b>
                                                        {t(
                                                            `workflow.${
                                                                forRevision ? 'revision' : 'changeRequest'
                                                            }.variables.${variable.toUpperCase()}`
                                                        )}
                                                        :
                                                    </b>
                                                    &nbsp;
                                                    {cr.variablesHistory.allVars[variable]}
                                                </li>
                                            ))}
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    ))}
                </ul>
            )}
        </>
    );

    const renderAggregatedWorkflowInformationForRevision = (workflowInfos: IAggregatedWorkflowInformation[]) =>
        renderAggregatedWorkflowInformation(workflowInfos, true);
    const renderAggregatedWorkflowInformationForCr = (workflowInfos: IAggregatedWorkflowInformation[]) => {
        if (!props.document) {
            return renderAggregatedWorkflowInformation(workflowInfos, false);
        }

        const sortedCRs = workflowInfos.sort((cr1, cr2) => {
            const changeRequestPrefixCode1 = cr1.variablesHistory.allVars.CHANGE_REQUEST_CODE;
            const changeRequestPrefixCode2 = cr2.variablesHistory.allVars.CHANGE_REQUEST_CODE;
            if (changeRequestPrefixCode1 !== '' && changeRequestPrefixCode2 !== '') {
                const numberCR1 = cr1.processInstance.businessKey.replace(`${changeRequestPrefixCode1}-`, '');
                const numberCR2 = cr2.processInstance.businessKey.replace(`${changeRequestPrefixCode2}-`, '');
                return parseInt(numberCR1, 10) < parseInt(numberCR2, 10) ? -1 : 1;
            } else if (props.document) {
                const numberCR1 = cr1.processInstance.businessKey.replace(`${props.document.code}-`, '');
                const numberCR2 = cr2.processInstance.businessKey.replace(`${props.document.code}-`, '');
                return parseInt(numberCR1, 10) < parseInt(numberCR2, 10) ? -1 : 1;
            }
            return 0;
        });
        return renderAggregatedWorkflowInformation(sortedCRs, false);
    };

    const documentTitle = props.document ? `${props.document.title}: ${props.document.name}` : '';

    return (
        <Layout
            title={documentTitle}
            variant="printer"
            navbarProps={{
                centerActionButtons: [<Typography variant="subtitle1" children={documentTitle} key={0} />],
                rightActionButtons: [],
            }}
        >
            {props.isLoadingChangeRequests || props.isLoadingRevisions ? (
                <Spinner />
            ) : documentRevision ? (
                <Grid item={true}>
                    <Typography variant="body2">
                        Disclaimer: Change requests numbers are consecutive over all manuals and revisions of this
                        customer. In case of non consecutive change request numbers in this revision information
                        document, check revision information documents of all other documents of this customer too.
                    </Typography>
                    <Grid item={true}>
                        <Typography variant="h5">
                            {t('printer.revision.groups.revisions', {
                                document: documentTitle,
                            })}
                        </Typography>
                        {renderAggregatedWorkflowInformationForRevision(props.revisions)}
                    </Grid>
                    <Grid item={true}>
                        <Typography variant="h5">
                            {t('printer.revision.groups.changeRequests', {
                                document: documentTitle,
                            })}
                        </Typography>
                        {renderAggregatedWorkflowInformationForCr(props.changeRequests)}
                    </Grid>
                </Grid>
            ) : (
                <Typography children={<>{t('printer.revision.noRevision')}</>} />
            )}
        </Layout>
    );
};

const actions: IActionProps = {
    documentRequested: docsActions.documentRequested,
    documentVersionsRequested: docsActions.documentVersionsRequested,
    requestChangeRequestsForDocumentVersion: pdfExportActions.requestChangeRequestsForDocumentVersion,
    requestRevisions: pdfExportActions.requestRevisions,
};

const mapStateToProps = (state: IWebApplicationStore, ownProps: IOwnProps): IStoreProps => {
    const { documentOid } = ownProps.match.params;

    return {
        documentOid,
        document: docsSelector.getDocument(state, documentOid),
        versions: docsSelector.getVersions(state, documentOid) || [],
        changeRequests: pdfExportSelector.getChangeRequests(state),
        revisions: pdfExportSelector.getRevisions(state),
        isLoadingChangeRequests: state.pdfExport.isLoadingChangeRequests,
        isLoadingRevisions: state.pdfExport.isLoadingRevisions,
    };
};

export default connect(mapStateToProps, actions)(PrintRevision);
