import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    IApiContextVersion,
    IApiModuleVersion,
    IWorkflowActivity,
    IWorkflowHistoricActivity,
} from '@yonder-mind/ui-core';
import { ModuleVersionContentHighlighter } from '../../../ModuleVersion';

interface IProps {
    document: IApiContextVersion;
    changeRequest: IWorkflowActivity | IWorkflowHistoricActivity;
    moduleVersion: IApiModuleVersion | null;
}

export const ModuleVersionView: React.FC<IProps> = ({ document, changeRequest, moduleVersion }) => {
    const { t } = useTranslation();

    return (
        <div className="module-version module-version-item moduleVersionView">
            {moduleVersion ? (
                <ModuleVersionContentHighlighter
                    doc={document}
                    moduleVersion={moduleVersion}
                    crSelections={[
                        {
                            moduleVersionOid: moduleVersion.oid,
                            location: changeRequest.variables.MARK_LOCATION,
                            textContent: changeRequest.variables.MARK_CONTENT,
                        },
                    ]}
                    showBubbleHighlights={false}
                    autofocus={true}
                />
            ) : null}
            <div className="module-version-item__view">{t('workflow.changeRequest.views.current')}</div>
        </div>
    );
};
