import type { IApiTaskType } from '@yonder-mind/ui-core';
import { ApiConstants } from '../../utils/ApiConstants';

export class TaskConstants extends ApiConstants {
    static getTasks({
        contextVersionOid,
        latestByTitleAndMessage,
        readOrAcknowledged,
        taskTypes,
    }: {
        readOrAcknowledged?: boolean;
        taskTypes?: IApiTaskType[];
        contextVersionOid?: string;
        latestByTitleAndMessage?: boolean;
    }): URL {
        const url = ApiConstants.relativeUrl('/tasks');
        if (readOrAcknowledged !== undefined) {
            url.searchParams.append('readOrAcknowledged', readOrAcknowledged.toString());
        }
        if (taskTypes) {
            taskTypes.forEach((taskType) => {
                url.searchParams.append('taskTypes', taskType);
            });
        }
        if (contextVersionOid) {
            url.searchParams.append('contextVersionOid', contextVersionOid);
        }
        if (latestByTitleAndMessage) {
            url.searchParams.append('latestByTitleAndMessage', latestByTitleAndMessage.toString());
        }
        return url;
    }

    static updateTask(): URL {
        return ApiConstants.relativeUrl('/tasks');
    }

    static deleteTask(taskId: string, taskType: IApiTaskType): URL {
        const url = ApiConstants.relativeUrl(`/tasks/${taskId}`);
        url.searchParams.append('taskType', taskType);
        return url;
    }

    static deleteTasks(): URL {
        return ApiConstants.relativeUrl(`/tasks/batch-delete`);
    }
}
