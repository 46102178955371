import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@material-ui/core';
import { YonderButton } from '@yonder-mind/ui-core';
import { useTranslation } from 'react-i18next';

interface IProps {
    open: boolean;
    onClose: () => void;
    savePreset?: (presetName: string) => void;
}

export const PresetDialog: React.FC<IProps> = ({ open, onClose, savePreset }) => {
    const [presetTitle, setPresetTitle] = useState('');

    const { t } = useTranslation();
    return (
        <>
            <Dialog
                className="preset-dialog"
                open={open}
                onClose={() => onClose()}
                fullWidth={true}
                maxWidth="sm"
                role="modal"
                data-testid="modal"
            >
                <DialogTitle className="presets-dialog-title" data-testid="modalHeader">
                    {t('complianceReports.preset.dialog.title')}
                </DialogTitle>
                <DialogContent className="presets-dialog-content">
                    <div className="presets-title-wrapper">
                        <TextField
                            id="outlined-basic"
                            variant="standard"
                            placeholder={t('complianceReports.preset.dialog.placeholder')}
                            fullWidth
                            onChange={(e) => setPresetTitle(e.target.value)}
                        />
                    </div>
                </DialogContent>
                <DialogActions className="presets-dialog-actions">
                    <YonderButton onClick={onClose} variant="text">
                        {t('complianceReports.preset.dialog.cancel')}
                    </YonderButton>
                    <YonderButton
                        onClick={() => savePreset(presetTitle)}
                        variant="contained"
                        color="primary"
                        disabled={presetTitle.length === 0}
                    >
                        {t('complianceReports.preset.dialog.save')}
                    </YonderButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default PresetDialog;
