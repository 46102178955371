import * as actions from './websocket.actions';
import { ActionType, getType } from 'typesafe-actions';
import { IWebsocketStore } from '../../interfaces';

type WebsocketActions = ActionType<typeof actions>;

const initialState: IWebsocketStore = {
    initialized: false,
    subscriptions: {},
};

export default function websocketReducer(
    state: IWebsocketStore = initialState,
    action: WebsocketActions
): IWebsocketStore {
    switch (action.type) {
        case getType(actions.initializedSocket):
            return {
                ...state,
                initialized: true,
            };
        case getType(actions.closeSocket):
            return {
                ...state,
                initialized: false,
            };
        case getType(actions.subscribeToTopic): {
            const { topic } = action.payload;
            return {
                ...state,
                subscriptions: {
                    ...state.subscriptions,
                    [topic]: (state.subscriptions[topic] || 0) + 1,
                },
            };
        }
        case getType(actions.unsubscribeFromTopic): {
            const { topic } = action.payload;
            return {
                ...state,
                subscriptions: {
                    ...state.subscriptions,
                    [topic]: Math.max(0, (state.subscriptions[topic] || 0) - 1),
                },
            };
        }
        default:
            return state;
    }
}
