import React from 'react';
import { useTranslation } from 'react-i18next';
import { i18n } from '@yonder-mind/ui-core';

interface IProps {
    currentAmount: number;
    totalAmount: number;
    futureUse?: boolean;
}

const getCorrectedPercentage = (percentage: number) => {
    if (percentage < 1 && percentage > 0) return 1;
    if (percentage > 99 && percentage < 100) return 99;
    return percentage;
};

export const getProgressPercentages = (total: number, current: number) => {
    const totalPercentage = 100;

    const positiveBarPercentage = getCorrectedPercentage((current * totalPercentage) / total);
    const negativeBarPercentage = getCorrectedPercentage(totalPercentage - positiveBarPercentage);

    return { positiveBarPercentage, negativeBarPercentage };
};

export const getPercentageString = (value: number) => {
    return !isNaN(value) ? value.toFixed(0) + '%' : i18n.t('complianceReports.documentsTable.notAvailable');
};

export const ProgressBar: React.FC<IProps> = ({ currentAmount, totalAmount, futureUse }) => {
    const { positiveBarPercentage, negativeBarPercentage } = getProgressPercentages(totalAmount, currentAmount);
    const { t } = useTranslation();

    if (!positiveBarPercentage && !negativeBarPercentage) {
        return <span>{t('complianceReports.documentsTable.notAvailable')}</span>;
    }

    const positiveWidth = positiveBarPercentage ? positiveBarPercentage.toFixed(0) + '%' : '0%';
    const negativeWidth = negativeBarPercentage ? negativeBarPercentage.toFixed(0) + '%' : '0%';

    return (
        <>
            <div className="progress-bar">
                <div className="progress-bar-level">
                    <div className="positive-bar" style={{ width: positiveWidth }}></div>
                    <div className={`negative-bar ${futureUse ? 'future' : ''}`} style={{ width: negativeWidth }}></div>
                </div>
                <div className="progress-bar-percentage">
                    {positiveBarPercentage && Number(positiveBarPercentage.toFixed(0)) > 0 ? (
                        <div>{positiveWidth}</div>
                    ) : (
                        <div></div>
                    )}
                    {negativeBarPercentage && Number(negativeBarPercentage.toFixed(0)) > 0 ? (
                        <div>{negativeWidth}</div>
                    ) : (
                        <div></div>
                    )}
                </div>
            </div>
        </>
    );
};
