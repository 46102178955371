import rangy from 'rangy';
import 'rangy/lib/rangy-classapplier';
import 'rangy/lib/rangy-serializer';
import React, { useRef, useState } from 'react';
import { combineStrings, IApiContextVersion, IApiModuleVersion, ModuleVersionUtils } from '@yonder-mind/ui-core';
import { ICrSelection, ModuleVersionContentHighlighter } from './ModuleVersionContentHighlighter';

interface IProps {
    document: IApiContextVersion;
    moduleVersion: IApiModuleVersion;
    selection?: ICrSelection;
    addRange: (range: ICrSelection | undefined) => void;
    onError?: (error: any) => any;
}

export const ModuleVersionContentHighlightSelector: React.FC<IProps> = (props: IProps) => {
    const div = useRef<HTMLDivElement>(null);
    const { moduleVersion } = props;

    const [currentSelection, setCurrentSelection] = useState(undefined as ICrSelection | undefined);

    React.useEffect(() => setCurrentSelection(props.selection || currentSelection), [props.selection]);

    const cancel = () => {
        const sel = window.getSelection ? window.getSelection() : (document as any).selection;
        if (sel) {
            if (sel.removeAllRanges) {
                sel.removeAllRanges();
            } else if (sel.empty) {
                sel.empty();
            }
        }
    };

    const highlight = (sel = rangy.getSelection()) => {
        const baseElement = div.current;

        try {
            const range = rangy.serializeSelection(sel, true, baseElement);

            const [start, end] = range.split(',');
            if (start === end) {
                return sel.removeAllRanges();
            }

            const textContent = sel.toString();
            sel.removeAllRanges();

            const selection = {
                location: range as string,
                moduleVersionOid: moduleVersion.oid,
                textContent,
            };

            setCurrentSelection(selection);
            props.addRange(selection);
        } catch (e) {
            const message = 'Probably selection is not contained in the elements inside the rangy library.\n\n';
            console.warn(message, e);

            cancel();

            if (props.onError) {
                props.onError(e);
            }
        }
    };

    const createImageRange = (sel: any, event: any) => {
        sel.removeAllRanges();
        const range = rangy.createRange();
        range.selectNode(event.target);
        sel.addRange(range);
        return sel;
    };

    const handleHighlighting = (event: any) => {
        const sel = rangy.getSelection();
        if (sel.rangeCount === 0 && event.target.tagName.toLowerCase() === 'img') {
            highlight(createImageRange(sel, event));
            return;
        }
        highlight(sel);
    };

    if (currentSelection && currentSelection.location) {
        return (
            <>
                <div className={'change-request-highlight-selector'}>
                    <ModuleVersionContentHighlighter
                        doc={props.document}
                        moduleVersion={moduleVersion}
                        crSelections={[currentSelection]}
                        showBubbleHighlights={false}
                    />
                </div>
            </>
        );
    }

    return (
        <>
            <div
                className={`content cr-view change-request-highlight-selector`}
                ref={div}
                onMouseDown={cancel}
                onMouseUp={handleHighlighting}
                onTouchStart={handleHighlighting}
            >
                <div
                    className={combineStrings(['document-content fr-view', props.document.type])}
                    dangerouslySetInnerHTML={ModuleVersionUtils.createMarkup(
                        moduleVersion.content ? moduleVersion.content : ''
                    )}
                />
            </div>
        </>
    );
};
