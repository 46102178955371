import { ApiHighlightsConstants } from '../utils/ApiHighlightsConstants';
import { Highlight, HighlightsApi, Rest } from '@yonder-mind/ui-core';

export class RestHighlightsApi implements HighlightsApi {
    async getHighlightsForContextVersion(contextVersionOid: string): Promise<Highlight[]> {
        const response = await Rest.getRequest(ApiHighlightsConstants.getHighlights(contextVersionOid).toString());
        return response.json();
    }

    async postHighlight(highlight: Partial<Highlight>): Promise<Highlight> {
        const response = await Rest.postRequest(ApiHighlightsConstants.postHighlight().toString(), { ...highlight });
        return response.json();
    }

    async putHighlight(highlight: Highlight): Promise<Highlight> {
        const response = await Rest.putRequest(ApiHighlightsConstants.highlightOid(highlight.oid).toString(), {
            ...highlight,
        });
        return response.json();
    }

    async deleteHighlight(highlight: Highlight): Promise<void> {
        await Rest.deleteRequest(ApiHighlightsConstants.highlightOid(highlight.oid).toString());
        return;
    }
}
