import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    ChangesProvider,
    changesSelector,
    docsActions,
    docsSelector,
    DocumentRevisionWorkflowTab,
    Layout,
    routerSelectors,
    Spinner,
    userActions,
    useSelectorUiCore,
} from '@yonder-mind/ui-core';
import { revisionActions, revisionSelector, useSelectorUiWeb } from '../../store';
import { RevisionWorkflow } from '../../components';
import { ConnectedWebsocketProvider, ConnectedWorkflowProvider } from '../../context';
import type { RouteComponentProps } from 'react-router-dom';

interface DocumentRevisionProps
    extends RouteComponentProps<{
        documentOid: string;
    }> {}

export const DocumentRevision: React.FC<DocumentRevisionProps> = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { documentOid } = props.match.params;
    const contextVersion = useSelectorUiWeb((state) => docsSelector.getDocument(state, documentOid));
    const contextVersions = useSelectorUiCore((state) => docsSelector.getVersions(state, documentOid));
    const { workflowTab } = useSelector(routerSelectors.queryParams);
    const userSettings = useSelectorUiWeb((state) => state.user.userSettings);
    const revisions = useSelectorUiWeb((state) => revisionSelector.getRevisionsByContextVersion(state));
    const documentChanges = useSelectorUiCore((state) => changesSelector.getAllContextVersionChanges(state));
    const isLoading = useSelectorUiWeb((state) => {
        return state.workflow.revision.isLoading && state.workflow.revision.revisionsByContextOid.length;
    });

    const revisionsByContextOid = useSelectorUiWeb((state) => state.workflow.revision.revisionsByContextOid);
    const isRevisionsByContextOidEmpty =
        !Object.keys(revisionsByContextOid).length || !revisionsByContextOid[contextVersion?.contextOid]?.length;

    useEffect(() => {
        dispatch(
            docsActions.documentRequested({
                documentId: documentOid,
                filters: [],
            })
        );

        if (userSettings.username === '') {
            dispatch(userActions.userSettingsRequested());
        }
    }, []);

    useEffect(() => {
        if (isRevisionsByContextOidEmpty && contextVersion?.contextOid) {
            dispatch(revisionActions.revisionsByContextOidRequested(contextVersion.contextOid, contextVersion.oid));
        }
    }, [contextVersion?.contextOid]);

    const revision = revisions[documentOid];
    const documentType = contextVersion ? contextVersion.type : 'ovidius-export-type';

    return (
        <ChangesProvider
            value={{
                contextVersion: [],
                moduleVersionsOfContextVersion: [],
                moduleVersions: {},
                moduleVersionChangesRequested: () => ({} as any),
                documentChanges,
                moduleChanges: {},
            }}
        >
            <ConnectedWorkflowProvider>
                <ConnectedWebsocketProvider>
                    <Layout
                        title={t('workflow.revision.title')}
                        variant="workflow"
                        contentCssPaths={[`/api/content-styling/${documentType}`]}
                        navbarProps={{ contextVersion: contextVersion, contextVersions: contextVersions }}
                    >
                        {contextVersion && !isLoading ? (
                            <RevisionWorkflow
                                document={contextVersion}
                                revision={revision}
                                workflowTab={workflowTab as DocumentRevisionWorkflowTab}
                                contextVersions={contextVersions}
                            />
                        ) : (
                            <Spinner className="container" />
                        )}
                    </Layout>
                </ConnectedWebsocketProvider>
            </ConnectedWorkflowProvider>
        </ChangesProvider>
    );
};
