import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import { useSnackbar } from 'notistack';
import { Model } from 'survey-core';
import 'survey-core/defaultV2.css';
import {
    ContextVersionStatus,
    formActions,
    FormContent,
    FormSubmission,
    SnackBarCloseButton,
    Spinner,
    SubmissionTools,
    useHistory,
    useSideBar,
    YonderDialog,
} from '@yonder-mind/ui-core';
import { IWebApplicationStore } from '../../../interfaces';
import { SubmissionHeader } from './SubmissionHeader';
import { SurveyIntegration } from './SurveyIntegration';
import { isReadyToSubmit, mapLastSubmittedSubmissions, updateQuestions } from '../utils/FormUtils';
import { SubmissionSummaryTextField } from './SubmissionSummaryTextField';

enum MessageType {
    SUCCESS = 'success',
    ERROR = 'error',
}

const SPINNER_STYLE = { width: '2em', height: '2em' };

interface SubmissionDetailsProps {
    moduleVersionOid: string;
    formSubmissionTypeOid: string;
    formSubmissionOid?: string;
    formVersionOid: string;
    formContent: FormContent;
    formStatus: string;
    unsavedChanges: boolean;
    onUnsavedChanges: (hasUnsavedChanges: boolean) => void;
}

export const SubmissionDetails: React.FC<SubmissionDetailsProps> = ({
    moduleVersionOid,
    formVersionOid,
    formSubmissionOid,
    formSubmissionTypeOid,
    formContent,
    formStatus,
    unsavedChanges,
    onUnsavedChanges,
}) => {
    const { isExpanded } = useSideBar();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { pushUrl } = useHistory();
    const summaryInputRef = useRef<HTMLInputElement>(null);

    const [summary, setSummary] = useState('');
    const [isClearModalOpen, setClearModalOpen] = useState(false);
    const [isSubmitButtonActive, setSubmitButtonActive] = useState(true);

    useEffect(() => {
        if (formSubmissionTypeOid) {
            dispatch(
                formActions.formSubmissionTypeRequested(formSubmissionTypeOid, () =>
                    createSnackbar(MessageType.ERROR, t('form.messages.error.generalError'))
                )
            );
        }
    }, [formSubmissionTypeOid]);

    useEffect(() => {
        if (formSubmissionOid) {
            dispatch(
                formActions.formSubmissionRequested(formSubmissionOid, () =>
                    createSnackbar(MessageType.ERROR, t('form.messages.error.generalError'))
                )
            );
        }
    }, [formSubmissionOid]);

    useEffect(() => {
        if (formVersionOid) {
            dispatch(
                formActions.formSubmissionsRequested(formVersionOid, () =>
                    createSnackbar(MessageType.ERROR, t('form.messages.error.generalError'))
                )
            );
        }
    }, [formVersionOid]);

    const { formSubmissionType, formSubmission, formSubmissions, isLoading, survey } = useSelector(
        (state: IWebApplicationStore) => {
            return {
                formSubmissionType: state.form?.formSubmissionTypes[formSubmissionTypeOid],
                formSubmission: state.form?.formSubmissions[formVersionOid]
                    ? state.form?.formSubmissions[formVersionOid].find(
                          (formSubmission) => formSubmission.oid === formSubmissionOid
                      )
                    : null,
                formSubmissions: state.form?.formSubmissions,
                survey: state.form?.survey,
                questions: state.form?.questions,
                isLoading: state.form.isLoading,
            };
        }
    );

    const handleChanges = (_sender: Model, _options: any) => {
        dispatch(formActions.setQuestions(updateQuestions(survey, summaryInputRef)));
        setSubmitButtonActive(isReadyToSubmit(survey, summaryInputRef.current?.value));
        onUnsavedChanges(hasChanges(summary, survey.data));
    };

    useEffect(() => {
        if (survey) {
            survey.onValueChanged.add((_sender, _options) => handleChanges(_sender, _options));
        }
    }, [survey]);

    useEffect(() => {
        setSummary(formSubmission?.summary ? formSubmission.summary : '');
        dispatch(formActions.setQuestions(updateQuestions(survey, summaryInputRef)));
        setSubmitButtonActive(isReadyToSubmit(survey, summaryInputRef.current?.value));
    }, [formSubmission, survey]);

    useEffect(() => {
        if (document.activeElement !== summaryInputRef.current) {
            dispatch(formActions.setQuestions(updateQuestions(survey, summaryInputRef)));
            setSubmitButtonActive(isReadyToSubmit(survey, summaryInputRef.current?.value));
        }
    }, [summary]);

    const createSnackbar = (messageType: MessageType, message: string) => {
        return messageType === MessageType.SUCCESS
            ? enqueueSnackbar(message, {
                  variant: 'success',
              })
            : enqueueSnackbar(message, {
                  action: (key) => <SnackBarCloseButton snackbarKey={key} />,
                  variant: 'error',
                  persist: true,
                  preventDuplicate: true,
              });
    };

    const hasChanges = (summary: string, surveyData: Model) => {
        if (isEmpty(surveyData) && !formSubmission?.content && !summary.trim()) return false;
        return (
            JSON.stringify(surveyData) !== formSubmission?.content || summary.trim() !== formSubmission?.summary.trim()
        );
    };

    const isEffective = formStatus === ContextVersionStatus.VALID;

    const updateQuestionsOnSummaryChange = (summaryValue: string) => {
        setSummary(summaryValue);
        dispatch(formActions.setQuestions(updateQuestions(survey, summaryInputRef)));
        setSubmitButtonActive(isReadyToSubmit(survey, summaryInputRef?.current.value));
        onUnsavedChanges(hasChanges(summaryValue, survey.data));
    };

    const clearForm = () => {
        survey.clear();
        setSummary('');
        onUnsavedChanges(hasChanges('', survey.data));
        setClearModalOpen(false);
        createSnackbar(MessageType.SUCCESS, t('form.messages.success.cleared'));
    };

    const saveForm = () => {
        onUnsavedChanges(false);
        if (!formSubmission) {
            saveNewForm();
        } else {
            updateExistingForm();
        }
    };

    const saveNewForm = () => {
        const newSubmission: FormSubmission = {
            formVersionOid,
            subformVersionOid: moduleVersionOid,
            statusOid: formSubmissionType.statuses[0].oid,
            summary,
            content: JSON.stringify(survey.data),
        };
        dispatch(
            formActions.saveFormRequested(
                newSubmission,
                (formSubmissionOid) => {
                    pushUrl(`/form/${formVersionOid}/${formSubmissionOid}`, [
                        { key: 'sidebar-tool', value: 'form-navigation' },
                    ]);
                    createSnackbar(MessageType.SUCCESS, t('form.messages.success.saved'));
                },
                () => {
                    onUnsavedChanges(true);
                    createSnackbar(MessageType.ERROR, t('form.messages.error.generalError'));
                }
            )
        );
    };

    const updateExistingForm = () => {
        const updatedSubmission: FormSubmission = {
            ...formSubmission,
            summary,
            content: JSON.stringify(survey.data),
        };
        dispatch(
            formActions.updateFormRequested(
                updatedSubmission,
                () => createSnackbar(MessageType.SUCCESS, t('form.messages.success.saved')),
                () => {
                    onUnsavedChanges(true);
                    createSnackbar(MessageType.ERROR, t('form.messages.error.generalError'));
                }
            )
        );
    };

    const submitForm = () => {
        if (survey.hasErrors()) {
            createSnackbar(MessageType.ERROR, 'Please correct the errors in the form before submitting.');
            return;
        }
        onUnsavedChanges(false);
        if (!formSubmission) {
            submitNewForm();
        } else {
            submitExistingForm();
        }
    };

    const submitNewForm = () => {
        const newSubmission: FormSubmission = {
            formVersionOid,
            subformVersionOid: moduleVersionOid,
            statusOid: formSubmissionType.statuses[0].oid,
            summary,
            content: JSON.stringify(survey.data),
        };
        dispatch(
            formActions.saveAndSubmitFormRequested(
                newSubmission,
                () => {
                    pushUrl('/');
                    createSnackbar(MessageType.SUCCESS, t('form.messages.success.submitted'));
                },
                () => {
                    onUnsavedChanges(true);
                    createSnackbar(MessageType.ERROR, t('form.messages.error.generalError'));
                }
            )
        );
    };

    const submitExistingForm = () => {
        const updatedSubmission: FormSubmission = {
            ...formSubmission,
            summary,
            content: JSON.stringify(survey.data),
        };
        dispatch(
            formActions.submitFormRequested(
                updatedSubmission,
                () => {
                    pushUrl('/');
                    createSnackbar(MessageType.SUCCESS, t('form.messages.success.submitted'));
                },
                () => {
                    onUnsavedChanges(true);
                    createSnackbar(MessageType.ERROR, t('form.messages.error.generalError'));
                }
            )
        );
    };

    return (
        <>
            <SubmissionTools
                unsavedChanges={unsavedChanges}
                lastFormSubmissions={mapLastSubmittedSubmissions(formSubmissions[formVersionOid])}
                formSubmissionStatus={formSubmission?.formSubmissionStatus}
            />
            <div className={`form-submission${isExpanded ? ' tools-open' : ''}`}>
                {isLoading || isEmpty(formContent) ? (
                    <div className={'form-submission__spinner'}>
                        <Spinner style={SPINNER_STYLE} />
                    </div>
                ) : (
                    <div>
                        <SubmissionHeader
                            isSubmitButtonActive={isSubmitButtonActive}
                            formSubmissionStatus={formSubmission?.formSubmissionStatus}
                            submittedAt={formSubmission?.submittedAt}
                            unsavedChanges={unsavedChanges}
                            isEffective={isEffective}
                            saveForm={saveForm}
                            submitForm={submitForm}
                            setClearModalOpen={setClearModalOpen}
                        />
                        <SubmissionSummaryTextField
                            summary={summary}
                            formSubmissionStatus={formSubmission?.formSubmissionStatus}
                            updateQuestionsOnSummaryChange={updateQuestionsOnSummaryChange}
                            summaryInputRef={summaryInputRef}
                        />
                        <SurveyIntegration
                            moduleVersionOid={moduleVersionOid}
                            formContent={formContent}
                            formSubmission={formSubmission}
                        />
                    </div>
                )}
            </div>
            <YonderDialog
                isOpen={isClearModalOpen}
                className="form-submission__clear-form-modal"
                maxWidth="sm"
                title={<Typography variant="h6">{t('form.modal.clear.title')}</Typography>}
                content={
                    <div>
                        <p>{t('form.modal.clear.content')}</p>
                    </div>
                }
                primaryAction={t('form.modal.clear.confirm')}
                primaryButtonDataTest="form-clear-modal-confirm-button"
                data-testid="form-clear-modal"
                secondaryAction={t('form.modal.clear.cancel')}
                onCancel={() => setClearModalOpen(false)}
                onConfirm={() => clearForm()}
                onClose={() => setClearModalOpen(false)}
            />
        </>
    );
};
