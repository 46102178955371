import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useSnackbar } from 'notistack';
import { Document, Font, Page, View } from '@react-pdf/renderer';
import { authSelector, DocumentClass, Layout, Spinner, useQuery, userActions } from '@yonder-mind/ui-core';
import { complianceReportsActions, useSelectorUiWeb } from '../../store';
import { ExportKebabMenu } from './ExportKebabMenu/ExportKebabMenu';
import StatusSidebar from './StatusSidebar/StatusSidebar';
import { UsersTable } from './UsersTable/UsersTable';
import { DateSelector } from './FilterConfigSelector/DateSelector';
import ChangesTable from './ChangesTable/ChangesTable';
import { FilterConfigSelector } from './FilterConfigSelector/FilterConfigSelector';
import { SingleDocumentTable } from './ChangesTable/SingleDocumentTable';
import { PdfHeader, PdfHeaderPropsSelector } from './Pdf/PdfHeader';
import { pdfStyles } from './Pdf/styles';
import { PdfDocumentsTable } from './Pdf/PdfDocumentsTable';
import { PdfChangesTable } from './Pdf/PdfChangesTable';
import { PdfUsersTable } from './Pdf/PdfUsersTable';
import { hyphenationCallback } from './Pdf/utils';

dayjs.extend(utc);

export type ReportListKey = { oid: string; role: string };

Font.registerHyphenationCallback(hyphenationCallback);

export const DocumentChanges: React.FC = () => {
    const { enqueueSnackbar } = useSnackbar();

    const history = useHistory();
    const query = useQuery();
    const location = useLocation();
    const selectedStatus = query.get('status');
    const querySelectedTagIds = query.get('tagIds') ? Array.of(query.get('tagIds')) : [];
    const querySelectedUserIds = query.get('userIds') ? Array.of(query.get('userIds')) : [];
    const querySelectedRoleIds = query.get('roleIds') ? Array.of(query.get('roleIds')) : [];
    const querySelectedDate = query.get('date');
    const querySelectedChange = { oid: query.get('changeOid'), role: query.get('selectedRole') };
    const [selectedQueriedReportListKey, setSelectedQueriedReportListKey] = useState<ReportListKey>(
        querySelectedChange ? { ...querySelectedChange, role: '' } : { oid: '', role: '' }
    );
    const hasComplianceViewRole = useSelector(authSelector.hasComplianceViewRole);
    const queryParams = new URLSearchParams(location.search);

    useEffect(() => {
        queryParams.delete('selectedRole');
    }, []);

    const setReportListKey = (reportListKey: ReportListKey) => {
        if (isEqual(reportListKey, selectedQueriedReportListKey)) {
            setSelectedQueriedReportListKey({ oid: '', role: '' });
            return;
        }
        setSelectedQueriedReportListKey(reportListKey);
        if (query.get('changeOid')) {
            queryParams.delete('changeOid');
            queryParams.delete('selectedRole');
        }
        history.replace({
            search: `${queryParams.toString()}&changeOid=${reportListKey.oid}&selectedRole=${reportListKey.role}`,
        });
    };

    const componentRef = useRef(null);
    const dispatch = useDispatch();
    const { contextVersionOid }: { contextVersionOid: string } = useParams();
    const { t } = useTranslation();

    const userIds = querySelectedUserIds.length > 0 ? querySelectedUserIds[0].split(',') : querySelectedUserIds;
    const tagIds = querySelectedTagIds.length > 0 ? querySelectedTagIds[0].split(',') : querySelectedTagIds;
    const roleIds = querySelectedRoleIds.length > 0 ? querySelectedRoleIds[0].split(',') : querySelectedRoleIds;
    const appliedFilterConfig = useSelectorUiWeb((state) => state.complianceReports.appliedFilterConfig);
    const date = useSelectorUiWeb((state) => state.complianceReports.dateConfig.effectiveAt);
    const hasComplianceShowUserRole = useSelectorUiWeb(authSelector.hasComplianceShowUserRole);

    const selectedUserIds = useMemo(() => {
        return appliedFilterConfig.selectedUserIds;
    }, [appliedFilterConfig.selectedUserIds]);
    const documents = useSelectorUiWeb((state) => state.complianceReports.documents);
    const isLoadingDocuments = useSelectorUiWeb((state) => state.complianceReports.isLoadingDocuments);
    const complianceDocument = documents.find((item) => item.contextVersionOid === contextVersionOid);
    const isExportingCsv = useSelectorUiWeb((state) => state.complianceReports.isExportingCsv);
    const username = useSelectorUiWeb((state) => state.user.userSettings.username);

    useEffect(() => {
        if (username === '') {
            dispatch(userActions.userSettingsRequested());
        }
    }, [username]);

    useEffect(() => {
        dispatch(complianceReportsActions.updateFilterConfigStatus(selectedStatus));
        if (contextVersionOid && !complianceDocument) {
            dispatch(complianceReportsActions.updateFilterConfigSelectedDocumentIds([contextVersionOid]));
            dispatch(complianceReportsActions.updateFilterConfigSelectedUserIds(userIds));
            dispatch(complianceReportsActions.updateFilterConfigSelectedTagIds(tagIds));
            dispatch(complianceReportsActions.updateFilterConfigSelectedRoleIds(roleIds));
            dispatch(
                complianceReportsActions.complianceDocumentsRequested({
                    ...appliedFilterConfig,
                    selectedTagIds: tagIds,
                    selectedRoleIds: roleIds,
                    selectedDocumentIds: [contextVersionOid],
                    selectedUserIds: userIds,
                    status: selectedStatus,
                    date: selectedStatus === 'PAST' ? querySelectedDate : '',
                })
            );
        }
    }, [contextVersionOid]);

    useEffect(() => {
        if (!isEqual(userIds, appliedFilterConfig.selectedUserIds)) {
            queryParams.delete('userIds');
            history.replace({
                search: `${queryParams.toString()}&userIds=${appliedFilterConfig.selectedUserIds.toString()}`,
            });
        }
    }, [selectedUserIds]);

    const hasChanges = useSelectorUiWeb((state) => state.complianceReports[contextVersionOid]?.length !== 0);

    useEffect(() => {
        if (!complianceDocument || isFile) return;
        dispatch(
            complianceReportsActions.complianceChangesRequested(contextVersionOid, { ...appliedFilterConfig, date })
        );
        setSelectedQueriedReportListKey({ oid: '', role: '' });
    }, [
        contextVersionOid,
        appliedFilterConfig.selectedUserIds,
        appliedFilterConfig.selectedRoleIds,
        complianceDocument,
    ]);

    useEffect(() => {
        if (!complianceDocument || !isFile) return;
        setSelectedQueriedReportListKey({ oid: complianceDocument.contextVersionOid, role: '' });
        dispatch(
            complianceReportsActions.complianceFileUserStatusesRequested({
                fileRevisionOid: complianceDocument.contextVersionOid,
                role: selectedQueriedReportListKey.role,
                tagOids: appliedFilterConfig.selectedTagIds,
                users: appliedFilterConfig.selectedUserIds,
            })
        );
    }, [complianceDocument]);

    useEffect(() => {
        if (date) {
            if (query.get('date')) {
                queryParams.delete('date');
            }
            history.replace({
                search: `${queryParams.toString()}&date=${date}`,
            });
        }
    }, [date]);

    const pdfHeaderProps = useSelectorUiWeb((state) => PdfHeaderPropsSelector(state));
    const changes = useSelectorUiWeb((state) => state.complianceReports.changesByContextVersion[contextVersionOid]);
    const { usersList } = useSelectorUiWeb((state) => {
        return {
            usersList:
                state.complianceReports.usersByChange[
                    `${selectedQueriedReportListKey.oid}${selectedQueriedReportListKey.role}`
                ],
        };
    });

    const isFile = complianceDocument?.documentClass === DocumentClass.FILE;

    const ChangesPdf = () => (
        <Document>
            <Page size="A4" style={pdfStyles.page}>
                <View wrap={false} fixed style={{ height: 30 }} />
                <PdfHeader {...pdfHeaderProps} />
                {complianceDocument && (
                    <View>
                        <PdfDocumentsTable documents={[complianceDocument]} />
                        <View style={{ height: 20 }} />
                    </View>
                )}
                {changes?.length ? (
                    <View>
                        <PdfChangesTable changes={changes} reportListKey={selectedQueriedReportListKey} />
                        <View style={{ height: 20 }} />
                    </View>
                ) : (
                    <View />
                )}
                {selectedQueriedReportListKey.oid ? <PdfUsersTable users={usersList} isFile={isFile} /> : <View />}
                <View wrap={false} fixed style={{ height: 60 }} />
            </Page>
        </Document>
    );

    const fileName = `${date ? date : dayjs().utc(true).toISOString()}_${t(
        'complianceReports.sidebar.' + selectedStatus.toLowerCase()
    )}`;

    const handleCsv = () => {
        dispatch(
            complianceReportsActions.exportComplianceCsvRequested({
                contextVersionOids: [contextVersionOid],
                status: selectedStatus,
                role: roleIds,
                date: selectedStatus === 'PAST' ? querySelectedDate : '',
                tagOids: tagIds,
                users: userIds,
                errorAction: () =>
                    enqueueSnackbar(`${t('complianceReports.export.csvError')}`, {
                        variant: 'error',
                    }),
            })
        );
    };

    return hasComplianceViewRole ? (
        <div>
            <Layout
                title={t('complianceReports.title')}
                variant="compliance-document-changes"
                navbarProps={{
                    rightActionButtons: [
                        <ExportKebabMenu
                            disabled={!hasChanges}
                            key="export-kebab"
                            pdfDocument={<ChangesPdf />}
                            fileName={fileName}
                            onCSV={handleCsv}
                            disabledCsv={!hasChanges}
                            isExportingCsv={isExportingCsv}
                        />,
                    ],
                }}
            >
                <div className="compliance-wrapper">
                    <StatusSidebar />
                    <div className="compliance-content">
                        <div ref={componentRef} className="print-container">
                            <DateSelector />
                            <FilterConfigSelector isChangesFilter />
                            <div className="document-details">
                                {isLoadingDocuments ? (
                                    <div className="compliance-filter-no-items-wrapper">
                                        <div className="compliance-no-filter-applied">
                                            <Spinner />
                                        </div>
                                    </div>
                                ) : complianceDocument ? (
                                    <SingleDocumentTable complianceDocument={complianceDocument} />
                                ) : (
                                    <div className="compliance-filter-no-items-wrapper">
                                        <div className="compliance-no-filter-applied">
                                            <p>{t('complianceReports.documentsTable.notFound')}</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="changes-and-users-wrapper ">
                                {isFile ? null : (
                                    <div className="changes-wrapper">
                                        <ChangesTable
                                            selectedDocumentId={contextVersionOid}
                                            setReportListKey={setReportListKey}
                                            reportListKey={selectedQueriedReportListKey}
                                            users={querySelectedUserIds}
                                            tags={querySelectedTagIds}
                                        />
                                    </div>
                                )}
                                {hasComplianceShowUserRole && (
                                    <div className="users-wrapper">
                                        {isFile ? (
                                            <UsersTable
                                                selectedReportListKey={{
                                                    oid: complianceDocument.contextVersionOid,
                                                    role: '',
                                                }}
                                                isFile={isFile}
                                            />
                                        ) : (
                                            selectedQueriedReportListKey.oid && (
                                                <UsersTable selectedReportListKey={selectedQueriedReportListKey} />
                                            )
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </div>
    ) : (
        <Layout title={t('complianceReports.title')} variant="compliance-reports">
            <div>{t('fileDrop.metadataPanel.notFound')}</div>
        </Layout>
    );
};

export default DocumentChanges;
