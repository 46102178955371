import React from 'react';
import { HistoryLog } from '../../../../components/HistoryLog/HistoryLog';

interface HistoryLogProps {
    contextOid: string;
    visible: boolean;
}

export const HistoryLogPanel: React.FC<HistoryLogProps> = ({ contextOid, visible }) => {
    return visible && <HistoryLog contextOid={contextOid} minWidth={1000} />;
};
