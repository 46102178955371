import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import {
    useSelectorUiCore,
    SplitView,
    ModuleVersionHeader,
    ModuleVersionTitle,
    ModuleVersionContent,
    Empty,
    Info,
    IApiContextVersion,
    IApiModuleVersion,
    ModuleVersionUtils,
    combineStrings,
    DateUtils,
    useHighlight,
    docsActions,
    Highlight,
    TransferHighlightPopup,
    IModuleStatus,
    highlightsActions,
    IApplicationStore,
    getBoundingClientRectWithZoom,
    convertSelectionToHighlightSelection,
    HighlightTransferState,
} from '@yonder-mind/ui-core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';

export const TransferHighlightsContent: React.FC<{
    document: IApiContextVersion | undefined;
    openHighlights: Highlight[] | undefined;
    closedHighlights: Highlight[] | undefined;
    onTransferReject: Function | undefined;
    onTransferSuccess: Function | undefined;
}> = (props) => {
    const dispatch = useDispatch();
    const { moduleOid, moduleVersionOid, highlightOid } = useParams<{
        moduleOid: string;
        moduleVersionOid: string;
        highlightOid: string;
        contextVersionOid: string;
    }>();

    const previousVersionDiv = useRef<HTMLDivElement | null>(null);
    const [transfer, setTransfer] = useState<Highlight | null>(null);
    const [highlightTransferred, setHighlightTransferred] = useState<boolean>(false);
    const [scrolledByNavigation, setScrolledByNavigation] = useState<boolean>(false);
    const { t } = useTranslation();
    const { setAnchorEl, setHighlightAnchor, highlightToScrollTo, setHighlightToScrollTo, currentHighlight } =
        useHighlight();
    const isLoading = useSelectorUiCore((state: IApplicationStore) => state.highlights.isLoading);
    const fontSize =
        useSelector((state: IApplicationStore) => state.appSettings?.readerFontSizeSettings.fontSize) || 'default';

    useEffect(() => {
        dispatch(docsActions.moduleVersionsByModuleOidRequested(moduleOid));
    }, [moduleVersionOid]);

    useEffect(() => {
        setHighlightToScrollTo({ highlightOid: highlightOid, moduleVersionOid: moduleVersionOid });
    }, [moduleVersionOid, highlightOid]);

    const moduleVersions: IApiModuleVersion[] = useSelectorUiCore(
        (state: IApplicationStore) => state.docs.modules[moduleOid]?.versions || []
    );

    useLayoutEffect(() => {
        if (
            highlightToScrollTo &&
            highlightToScrollTo?.moduleVersionOid ===
                moduleVersions.find((version) => version.oid === moduleVersionOid)?.oid
        ) {
            const highlight = document.getElementsByClassName('highlight-' + highlightToScrollTo.highlightOid)?.[0];

            if (highlight) {
                setScrolledByNavigation(true);
                highlight.scrollIntoView();
                setHighlightToScrollTo(null);
                setAnchorEl({
                    getBoundingClientRect: () => getBoundingClientRectWithZoom(highlight, fontSize),
                });
                setHighlightAnchor({ highlightId: highlightToScrollTo.highlightOid, element: highlight });
                dispatch(highlightsActions.setIsNoteOpen(true));
                setTimeout(() => {
                    setScrolledByNavigation(false);
                });
            }
        }
    }, [moduleVersions, highlightToScrollTo]);

    // @ts-ignore
    useEffect(() => {
        if (previousVersionDiv.current) {
            const element = previousVersionDiv.current.querySelectorAll('.module-version-content')[0];

            const hideHighlights = () => {
                if (currentHighlight && !scrolledByNavigation) {
                    setAnchorEl(null);
                    setHighlightAnchor(null);
                    dispatch(highlightsActions.setIsNoteOpen(false));
                }
            };

            element.addEventListener('scroll', hideHighlights, { passive: true });

            return () => {
                element.removeEventListener('scroll', hideHighlights);
            };
        }
    }, [previousVersionDiv.current, currentHighlight, scrolledByNavigation]);

    useEffect(() => {
        const handleSelection = () => {
            const selection = window.getSelection();
            const highlightSelection = convertSelectionToHighlightSelection(selection);

            if (highlightSelection.startNodeId === '' && highlightSelection.endNodeId === '') {
                return;
            }

            if (highlightSelection.endOffset - highlightSelection.startOffset <= 0) {
                return;
            }

            const newHighlight: Highlight = {
                ...transfer,
                moduleVersionOid: moduleVersions[0].oid,
                selection: highlightSelection,
                transferState: HighlightTransferState.MANUALLY_TRANSFERRED,
                selectedText: selection.toString(),
            };

            const newHighlightElement = document.getElementsByClassName('highlight-' + newHighlight.oid)?.[0];

            setAnchorEl({
                getBoundingClientRect: () => getBoundingClientRectWithZoom(newHighlightElement, fontSize),
            });
            setHighlightAnchor({ highlightId: newHighlight.oid, element: newHighlightElement });

            dispatch(highlightsActions.setIsNoteOpen(true));
            dispatch(highlightsActions.putHighlight(newHighlight));

            setHighlightTransferred(true);

            props.onTransferSuccess(transfer);
        };

        const debouncedSaveNoteContent = debounce(handleSelection, 500);

        if (transfer) {
            document.addEventListener('mouseup', debouncedSaveNoteContent);
        }

        return () => {
            document.removeEventListener('mouseup', debouncedSaveNoteContent);
        };
    }, [transfer]);

    useEffect(() => {
        if (highlightTransferred && transfer && !isLoading) {
            setTransfer(null);
            setAnchorEl(null);
            setHighlightAnchor(null);
            setHighlightTransferred(false);
            dispatch(highlightsActions.setIsNoteOpen(false));
        }
    }, [highlightTransferred, transfer, isLoading]);

    if (!moduleVersions || moduleVersions.length === 0) {
        return null;
    }

    const renderHeaderTitle = (temporary: boolean, moduleVersion: IApiModuleVersion | null, index: number) => {
        if (!moduleVersion) {
            return t('moduleVersionSplitScreen.noPreviousVersion');
        }
        return combineStrings([
            moduleVersion.name,
            t('moduleVersionSplitScreen.effectiveFrom'),
            `${DateUtils.parseToDate(moduleVersion.effectiveFrom)}`,
            temporary && index === 1 ? ' - ' + t('moduleVersionSplitScreen.temporaryRevision') : null,
        ]);
    };

    const getHeaderColor = (moduleVersion: IApiModuleVersion): string => {
        return ModuleVersionUtils.isInvalid(moduleVersion) ? 'orange' : 'blue';
    };

    const onTransferHighlight = (transferHighlight: Highlight) => {
        setAnchorEl(null);
        setHighlightAnchor(null);
        dispatch(highlightsActions.setIsNoteOpen(false));

        setTransfer(transferHighlight);
    };

    const onRemoveHighlight = (transferHighlight: Highlight) => {
        dispatch(highlightsActions.deleteHighlight(transferHighlight));
        props.onTransferReject(transferHighlight);
    };

    const moduleVersion = moduleVersions.find((moduleVersion) => moduleVersion.oid === moduleVersionOid);
    const isClosedHighlight = props.closedHighlights.find((highlight) => highlight.oid === highlightOid);

    if (isClosedHighlight) {
        return (
            <SplitView
                views={[
                    {
                        header: (
                            <ModuleVersionHeader
                                text={renderHeaderTitle(Boolean(false), moduleVersion, 0)}
                                color={getHeaderColor(moduleVersion)}
                            />
                        ),
                        content: (
                            <div
                                key={moduleVersion.oid}
                                ref={previousVersionDiv}
                                className={combineStrings(['module-version module-version-item', 'no-selection'])}
                            >
                                <ModuleVersionTitle document={props.document} moduleVersion={moduleVersion} />
                                <ModuleVersionContent
                                    document={props.document}
                                    moduleVersion={moduleVersion}
                                    links={[]}
                                    setAnchorEl={setAnchorEl}
                                    setHighlightAnchor={setHighlightAnchor}
                                    showNoContentMessage={true}
                                    useTemporaryContent={false}
                                    htmlContent={moduleVersion.content ?? ''}
                                    moduleHighlights={props.closedHighlights}
                                />
                            </div>
                        ),
                    },
                ]}
            />
        );
    }

    return (
        <SplitView
            views={
                [moduleVersion, moduleVersions[0]].map((version, i) =>
                    version
                        ? {
                              header: (
                                  <ModuleVersionHeader
                                      text={renderHeaderTitle(Boolean(false), version, i)}
                                      color={getHeaderColor(version)}
                                  />
                              ),
                              content: (
                                  <>
                                      <div
                                          key={`${version.oid}-${i}`}
                                          ref={i === 0 ? previousVersionDiv : null}
                                          className={combineStrings([
                                              'module-version module-version-item',
                                              i === 0 ? 'no-selection' : '',
                                          ])}
                                      >
                                          <ModuleVersionTitle document={props.document} moduleVersion={version} />
                                          <ModuleVersionContent
                                              document={props.document}
                                              moduleVersion={version}
                                              links={[]}
                                              setAnchorEl={setAnchorEl}
                                              setHighlightAnchor={setHighlightAnchor}
                                              showNoContentMessage={true}
                                              useTemporaryContent={false}
                                              htmlContent={version.content ?? ''}
                                              moduleHighlights={i === 0 ? props.openHighlights : props.closedHighlights}
                                          />
                                          {i === 0 &&
                                          !transfer &&
                                          currentHighlight &&
                                          currentHighlight.moduleVersionOid === version.oid ? (
                                              <TransferHighlightPopup
                                                  contextVersionOid={version.oid}
                                                  documentReleased={version?.status === IModuleStatus.RELEASED}
                                                  onRemoveHighlight={onRemoveHighlight}
                                                  onTransferHighlight={onTransferHighlight}
                                              />
                                          ) : null}
                                      </div>
                                      {i === 0 && !transfer ? (
                                          <div className="split-view-item-info-box">
                                              <Info size="medium" />
                                              <span> {t('transferHighlights.infoBox.previousModuleVersion')}</span>
                                          </div>
                                      ) : null}
                                      {i === 1 && transfer ? (
                                          <div className="split-view-item-info-box">
                                              <Info size="medium" />
                                              <span> {t('transferHighlights.infoBox.currentModuleVersion')}</span>
                                          </div>
                                      ) : null}
                                  </>
                              ),
                          }
                        : {
                              header: <ModuleVersionHeader text={renderHeaderTitle(false, version, i)} />,
                              content: <Empty variant="text" text={t('moduleVersionSplitScreen.noPreviousVersion')} />,
                          }
                ) as [any, any]
            }
        />
    );
};
