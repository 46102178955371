import React from 'react';
import { DropDownForSelectionButton, ItemSelector } from '@yonder-mind/ui-core';
import { DropdownType } from '../../../interfaces';
import { useDispatch } from 'react-redux';
import { complianceReportsActions, useSelectorUiWeb } from '../../../store';
import { useTranslation } from 'react-i18next';

interface Props {
    selectedDropdown: DropdownType;
    handleOnClick: (type: DropdownType) => void;
    disabled?: boolean;
}

export const RolesSelector: React.FC<Props> = (props) => {
    const { selectedDropdown, handleOnClick } = props;
    const dispatch = useDispatch();

    const { t } = useTranslation();

    const roles = useSelectorUiWeb((state) =>
        state.complianceReports.roles.map((role) => {
            return { id: role, name: role };
        })
    );

    const setSelectedIds = (ids: string[]) => dispatch(complianceReportsActions.updateFilterConfigSelectedRoleIds(ids));
    const selectedIds = useSelectorUiWeb((state) => state.complianceReports.filterConfig.selectedRoleIds);
    const isLoading = useSelectorUiWeb((state) => state.complianceReports.isLoadingRoles);

    React.useEffect(() => {
        dispatch(complianceReportsActions.complianceReportsRolesRequested());
    }, [dispatch]);

    return (
        <DropDownForSelectionButton
            disabled={props.disabled}
            title={t('complianceReports.filter.roles.title')}
            expanded={selectedDropdown === 'roles'}
            onClick={() => handleOnClick('roles')}
            handleClose={() => handleOnClick('none')}
            numberOfAllItems={roles.length}
            numberOfSelectedItems={selectedIds.length}
            multiSelect={true}
        >
            <ItemSelector
                isLoading={isLoading}
                itemList={roles}
                selectedItemIds={selectedIds}
                updateSelectedItemIds={setSelectedIds}
                multiSelect={true}
                selectedItemsText={t('complianceReports.filter.roles.selected')}
                allItemsText={t('complianceReports.filter.roles.all')}
                emptyAllItemsText={t('complianceReports.filter.roles.notAvailable')}
                noMatches={t('complianceReports.filter.roles.noMatches')}
                otherItemsText={t('complianceReports.filter.roles.other')}
            />
        </DropDownForSelectionButton>
    );
};
