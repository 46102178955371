import {
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

import { useTranslation } from 'react-i18next';
import {
    CloseButton,
    emptyFilterInfo,
    FilterHelper,
    IDomainSelection,
    IFilterInfo,
    IFilterNode,
    ITagDomain,
    ITagTreeNode,
    TagDomainIcon,
    TagFilter,
    TagUtils,
    YonderButton,
} from '@yonder-mind/ui-core';

import React, { useCallback, useEffect, useState } from 'react';
import { MetadataTagsChips } from './MetadataTagsChips';
import { FileDropMetadata } from '../../domain/types';
import { MetadataDialogTitle } from './MetadataDialogTitle';

// Trimmed down version of @yonder-mind/ui-core/src/components/SideBar/SideBarTools/Filter/FilterSelector.tsx - SHARING MOST OF THE CODE

interface IProps {
    setMetadata: (metadata: FileDropMetadata) => void;
    tagOids: string[];
    open: boolean;
    onClose: () => void;
    tagDomains: ITagDomain[];
    allTags: Record<string, ITagTreeNode>;
}

export const MetadataTagsSelectorModal: React.FC<IProps> = ({
    setMetadata,
    tagOids,
    onClose,
    open,
    tagDomains,
    allTags,
}) => {
    const { t } = useTranslation();

    const initialFilterSetup = FilterHelper.createFilterSetup(tagDomains, tagOids);
    const [domainSelection, setDomainSelection] = useState(initialFilterSetup.domainSelection);
    const [activeFilterOids, setActiveFilterOids] = useState(initialFilterSetup.filterInfo.activeOids);
    const [activeChipOids, setActiveChipOids] = useState(initialFilterSetup.filterInfo.chipOids);
    const [listStatus, setListStatus] = useState({} as any);

    const setActiveOids = (info: IFilterInfo) => {
        setActiveFilterOids(info.activeOids);
        setActiveChipOids(info.chipOids);
    };

    const updateFilterSetup = (currentlyActiveFilterOids: string[], activeFilterOidsFromProps: string[]) => {
        const newActiveFilterOids =
            currentlyActiveFilterOids?.length > 0 ? currentlyActiveFilterOids : activeFilterOidsFromProps;
        const filterSetup = FilterHelper.createFilterSetup(tagDomains, newActiveFilterOids);

        setDomainSelection(filterSetup.domainSelection);
        setActiveOids(filterSetup.filterInfo);
    };

    const onSaveFilterSelections = () => {
        onClose();
        setMetadata({ tagOids: activeFilterOids });
    };

    useEffect(() => {
        updateFilterSetup(activeFilterOids, tagOids);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tagDomains]);

    const handleUpdate = useCallback(
        (newSelection: IFilterNode[], domainOid: string) => {
            const newDomainSelection: IDomainSelection = {
                ...domainSelection,
                [domainOid]: newSelection,
            };
            const filterInformation = Object.keys(newDomainSelection)
                .map((key: string) => FilterHelper.collectFilterInformation(newDomainSelection[key]))
                .reduce(FilterHelper.reduceFilterInfo, emptyFilterInfo);
            setActiveOids(filterInformation);
            setDomainSelection(newDomainSelection);
        },
        [domainSelection, setActiveOids]
    );

    const handleClick = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            event.preventDefault();
            const id = event.currentTarget.id;
            setListStatus({ [id]: !listStatus[id] });
        },
        [listStatus]
    );

    const renderList = useCallback(() => {
        const onClick = (domainOid: string) => {
            return (value: boolean, _: ITagTreeNode, selected: IFilterNode) => {
                const newSelection: IFilterNode[] = FilterHelper.updateSelection(
                    selected.tag.oid,
                    value,
                    domainSelection[domainOid]
                );
                handleUpdate(newSelection, domainOid);
            };
        };
        return tagDomains.reduce((acc, tagDomain) => {
            const filterNodeOfTagDomain = domainSelection[tagDomain.oid];
            if (tagDomain.children.length !== 0 && filterNodeOfTagDomain !== undefined) {
                acc.push(
                    <div key={tagDomain.oid}>
                        <ListItem id={tagDomain.oid} className="filter-domain" button={true} onClick={handleClick}>
                            <ListItemIcon>
                                <TagDomainIcon size={'18px'} filterIcon={tagDomain.filterIcon} coloring={'grey'} />
                            </ListItemIcon>
                            <ListItemText inset={false} primary={TagUtils.getTagDomainName(tagDomain)} />
                            {listStatus[tagDomain.oid] ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Divider className="filter-divider" />
                        <Collapse
                            className="collapse"
                            in={listStatus[tagDomain.oid]}
                            timeout="auto"
                            unmountOnExit={true}
                        >
                            <List className="filter-list" disablePadding={true}>
                                <TagFilter
                                    selection={filterNodeOfTagDomain}
                                    children={tagDomain.children}
                                    click={onClick(tagDomain.oid)}
                                    level={1}
                                />
                            </List>
                        </Collapse>
                    </div>
                );
            }
            return acc;
        }, [] as React.ReactNode[]);
    }, [tagDomains, handleClick, handleUpdate, listStatus, domainSelection]);

    const clearFilters = () => {
        updateFilterSetup([], []);
    };

    return (
        <Dialog
            className="metadata-dialog"
            PaperProps={{ className: 'metadata-dialog-paper' }}
            open={open}
            onClose={onClose}
            fullWidth={true}
        >
            <MetadataDialogTitle
                title={t('fileDrop.metadataPanel.tags.assignTags')}
                subTitle={`${activeChipOids.length} ${t('fileDrop.metadataPanel.tags.tagsSelected')}`}
                type="tags"
            />
            <div className="close-button">
                <CloseButton onClick={onClose} size="small" />
            </div>
            <div className="active-filters" key="filters">
                {activeFilterOids && activeFilterOids.length ? (
                    <>
                        <MetadataTagsChips
                            activeChips={activeChipOids}
                            allTags={allTags}
                            tagDomains={tagDomains}
                            handleUpdate={handleUpdate}
                            selection={domainSelection}
                        />
                        <Link className="clear" component="a" variant="body2" onClick={clearFilters}>
                            {t('fileDrop.metadataPanel.selector.clear')}
                        </Link>
                    </>
                ) : (
                    t('fileDrop.metadataPanel.tags.noTagsSelected')
                )}
            </div>
            <Divider key="divider" />
            <DialogContent className="metadata-tags-dialog-content">
                <List className="filter-selector" disablePadding={true}>
                    {renderList()}
                </List>
            </DialogContent>

            <DialogActions className="metadata-dialog-actions">
                <YonderButton onClick={onClose} variant="text">
                    {t('fileDrop.metadataPanel.users.cancel')}
                </YonderButton>
                <YonderButton onClick={onSaveFilterSelections} variant="contained" color="primary">
                    {t('form.actions.save')}
                </YonderButton>
            </DialogActions>
        </Dialog>
    );
};
