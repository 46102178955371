import React, { useCallback } from 'react';
import { DropzoneState, useDropzone } from 'react-dropzone';

import { TFunction } from 'i18next';
import { OptionsObject } from 'notistack';
import * as uuid from 'uuid';
import { ImportedFiles, FileDropTableItem } from '../domain/types';
import { IApiTenantSettings, FileTypeEnum } from '@yonder-mind/ui-core';
import { DEFAULT_START_DATE, DEFAULT_END_DATE } from './metadataUtils';

type FileDropZoneHookProps = {
    t: TFunction;
    importedFiles: ImportedFiles;
    setImportedFiles: (items: ImportedFiles) => void;
    enqueueSnackbar: (message: React.ReactNode, options?: OptionsObject) => string | number;
    pushSelectedFileKey: (key: string) => void;
    tenantSettings: IApiTenantSettings;
};

type HandleFileDropProps = {
    fileList: File[];
} & FileDropZoneHookProps;

const getFileType = (fileType: string) => {
    const fileTypeMap = Object.keys(FileTypeEnum).map((key) => {
        return { key: key, value: FileTypeEnum[key] };
    });
    return FileTypeEnum[fileTypeMap.find((fileTypeMap) => fileTypeMap.value === fileType)?.key || 'pdf'];
};

const getFileDropTableItemFromFile = (file: File, fileKey: string): FileDropTableItem => {
    return {
        file: file,
        name: file.name.substring(0, file.name.lastIndexOf('.')) || file.name,
        endDate: DEFAULT_END_DATE,
        startDate: DEFAULT_START_DATE.unix(),
        type: getFileType(file.type),
        key: fileKey,
        isDurationOpen: true,
    };
};

export const validateFileDrop = ({
    enqueueSnackbar,
    file,
    t,
    tenantSettings,
}: {
    file: File;
    tenantSettings: IApiTenantSettings;
    enqueueSnackbar: (message: React.ReactNode, options?: OptionsObject) => string | number;
    t: TFunction;
}): boolean => {
    const sizeInBytes = file.size;
    const sizeInMB = sizeInBytes / (1024 * 1024);
    const fileType = file.type;
    if (sizeInMB > tenantSettings.fileDropFileMaxSizeInMb) {
        enqueueSnackbar(`${file.name}: ${t('fileDrop.error.fileSize')}`, { variant: 'warning' });
        return false;
    } else if (!tenantSettings.fileDropAllowedFileTypes.map((fileTypes) => fileTypes).includes(fileType)) {
        enqueueSnackbar(`${file.name}: ${t('fileDrop.error.fileType')}`, { variant: 'warning' });
        return false;
    } else {
        return true;
    }
};

const handleFileDrop = ({
    fileList = [],
    importedFiles,
    setImportedFiles,
    enqueueSnackbar,
    t,
    pushSelectedFileKey,
    tenantSettings,
}: HandleFileDropProps) => {
    const importedFilesAux: ImportedFiles = {};

    fileList.forEach((file) => {
        if (validateFileDrop({ enqueueSnackbar, file, t, tenantSettings })) {
            const fileKey = uuid.v4();
            importedFilesAux[fileKey] = getFileDropTableItemFromFile(file, fileKey);
        }
    });

    const importedFileKeys = Object.keys(importedFilesAux);

    setImportedFiles({ ...importedFiles, ...importedFilesAux });
    importedFileKeys.forEach((key) => pushSelectedFileKey(key));
};

export const useFileDropZone = ({
    enqueueSnackbar,
    importedFiles,
    pushSelectedFileKey,
    setImportedFiles,
    t,
    tenantSettings,
}: FileDropZoneHookProps): DropzoneState => {
    const onDrop = useCallback(
        (acceptedFiles: any) => {
            handleFileDrop({
                fileList: acceptedFiles,
                t,
                enqueueSnackbar,
                importedFiles,
                setImportedFiles,
                pushSelectedFileKey,
                tenantSettings,
            });
        },
        [enqueueSnackbar, importedFiles, pushSelectedFileKey, setImportedFiles, t, tenantSettings]
    );

    return useDropzone({
        onDrop,
        noClick: true,
        noKeyboard: true,
        multiple: true,
    });
};
