import * as React from 'react';

interface IProps extends React.PropsWithChildren {
    tool: string;
    className?: string;
}

export const WorkflowTool: React.FC<IProps> = ({ children, className, tool }) => (
    <div className={`workflow-tool ${tool} ${className || ''}`}>{children}</div>
);
