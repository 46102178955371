import { ApiConstants } from '../../utils/ApiConstants';

export class UserConstants extends ApiConstants {
    static userSettings(): URL {
        return ApiConstants.relativeUrl('/user-settings');
    }

    static userSettingsAvatar(): URL {
        return ApiConstants.relativeUrl('/user-settings/avatar');
    }
}
