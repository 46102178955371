import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    ContextVersionUtils,
    docsSelector,
    FilterWrapper,
    IActiveFilters,
    IApplicationStore,
    IDashboardCardToolComponent,
    ISidebarTool,
    ISidebarToolTab,
    ITab,
    SidebarTool,
    Tabs,
    useSideBar,
} from '@yonder-mind/ui-core';
import { ChangeRequestList } from './ChangeRequestList';
import { useRouteMatch } from 'react-router';
import { useSelector } from 'react-redux';

interface IProps {
    activeWorkflowTab: string;
    isDocsLoading: boolean;
    filterOptions?: {
        filterType: string;
        filterValues?: IActiveFilters;
        filterTabs: IDashboardCardToolComponent[];
    };
}
export const Workflow: React.FC<IProps> = (props) => {
    const { t } = useTranslation();
    const { activeTool, activeToolTab, handleSetActiveToolTab } = useSideBar();

    const { params: routerParams } = useRouteMatch<{ documentOid: string }>();
    const { documentOid } = routerParams;
    const contextVersion = useSelector((state: IApplicationStore) => docsSelector.getDocument(state, documentOid));
    const contextVersions = useSelector((state: IApplicationStore) => docsSelector.getVersions(state, documentOid));

    const [filterSortActive, setFilterSortActive] = React.useState(true);

    if (!contextVersion) {
        return null;
    }

    const version = contextVersions?.find((v) => v.oid === contextVersion.oid);
    if (!version) {
        return null;
    }

    const tabs: ITab[] = [
        {
            key: ISidebarToolTab.WORKFLOW__ACTIVE,
            name: t('reader.drawer.workflow.tabs.active'),
            component: (
                <ChangeRequestList
                    key="cr-active"
                    itemsKey="cr-active"
                    values={props.filterOptions.filterValues}
                    activeWorkflowTab={props.activeWorkflowTab}
                />
            ),
            dataTestId: '',
            visible: ContextVersionUtils.isValid(version) || ContextVersionUtils.isDraft(version),
        },
        {
            key: ISidebarToolTab.WORKFLOW__RELEASED,
            name: t('reader.drawer.workflow.tabs.released'),
            component: (
                <ChangeRequestList
                    key="cr-released"
                    itemsKey="cr-released"
                    values={props.filterOptions.filterValues}
                    activeWorkflowTab={props.activeWorkflowTab}
                />
            ),
            dataTestId: '',
            visible: ContextVersionUtils.isValid(version) || ContextVersionUtils.isInvalid(version),
        },
        {
            key: ISidebarToolTab.WORKFLOW__WAITLIST,
            name: t('reader.drawer.workflow.tabs.waitlist'),
            component: (
                <ChangeRequestList
                    key="cr-waitlist"
                    itemsKey="cr-waitlist"
                    values={props.filterOptions.filterValues}
                    activeWorkflowTab={props.activeWorkflowTab}
                />
            ),
            dataTestId: '',
            visible: ContextVersionUtils.isValid(version) || ContextVersionUtils.isDraft(version),
        },
    ].filter((tab) => tab.visible);

    const onChange = (index: number) => {
        const toolTab: ITab = tabs[index];
        // eslint-disable-next-line
        handleSetActiveToolTab(toolTab.key as ISidebarToolTab);
    };

    const activateFilterSortOptions = (value: boolean) => {
        setFilterSortActive(value);
    };

    return (
        <SidebarTool title={t('reader.drawer.workflow.title')} divider={false}>
            <Tabs
                tabs={tabs}
                value={activeTool === ISidebarTool.WORKFLOW ? activeToolTab : ISidebarToolTab.WORKFLOW__ACTIVE}
                onChange={onChange}
                isLoading={props.isDocsLoading}
                noScroll={true}
                className={`${tabs.length > 2 && 'multi_tabs'} ${!filterSortActive ? 'tabs-hidden' : ''}`}
            />
            {contextVersion && (
                <FilterWrapper
                    activateFilterSortOptions={activateFilterSortOptions}
                    tabs={props.filterOptions.filterTabs}
                    filterType={props.filterOptions.filterType}
                />
            )}
        </SidebarTool>
    );
};
