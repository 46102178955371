import { TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import React from 'react';
// import { useTranslation } from 'react-i18next';
import { ArrowUpward } from '@material-ui/icons';
import TypeFilter from '../ChangesFilter/TypeFilter';
import RoleFilter from '../ChangesFilter/RoleFilter';
import { useTranslation } from 'react-i18next';

interface IProps {
    setFilterType?: (value: string[]) => void;
    availableRoles: string[];
    selectedRoles: string[];
    setSelectedRoles: (value: string[]) => void;
}

type ColumnKey = 'chapter' | 'title' | 'type' | 'role' | 'count';

export const ChangesTableHead: React.FC<IProps> = ({ selectedRoles, setSelectedRoles, availableRoles, ...props }) => {
    const [selectedTypes, setSelectedTypes] = React.useState<string>('all');

    const { t } = useTranslation();

    const renderHeaderCell = (_: ColumnKey, label: string, className?: string) => {
        const getClassName = `${className ? className : ''}`;
        return (
            <TableCell className={getClassName}>
                <TableSortLabel disabled IconComponent={ArrowUpward}>
                    {label}
                </TableSortLabel>
            </TableCell>
        );
    };

    const typeData = [
        {
            name: 'Show all',
            value: 'all',
        },
        {
            name: 'Read',
            value: 'read',
        },
        {
            name: 'Read&Ack',
            value: 'readAck',
        },
    ];

    const rolesData = availableRoles.map((r) => {
        return { name: r, value: r };
    });

    const onChangeType = (value: string) => {
        setSelectedTypes(value);
        props.setFilterType && props.setFilterType([value]);
    };

    const onChangeRole = (value: string, checked: boolean) => {
        if (!checked) {
            setSelectedRoles([...selectedRoles].filter((item: string) => item !== value));
            props.setFilterType && props.setFilterType([...selectedRoles].filter((item: string) => item !== value));
        } else {
            setSelectedRoles([...selectedRoles].concat(value));
            props.setFilterType && props.setFilterType([...selectedRoles].concat(value));
        }
    };

    return (
        <TableHead>
            <TableRow>
                {renderHeaderCell('chapter', t('complianceReports.changesTable.chapter'))}
                {renderHeaderCell('title', t('complianceReports.changesTable.title'))}
                <TableCell>
                    <div>
                        <TypeFilter
                            data={typeData}
                            value={selectedTypes}
                            label={t('complianceReports.changesTable.type')}
                            onChange={onChangeType}
                        />
                    </div>
                </TableCell>
                {renderHeaderCell('count', '')}
                <TableCell>
                    <div>
                        <RoleFilter
                            data={rolesData}
                            value={selectedRoles}
                            label={t('complianceReports.changesTable.assignedRole')}
                            onChange={onChangeRole}
                        />
                    </div>
                </TableCell>
                <TableCell />
            </TableRow>
        </TableHead>
    );
};
