import { useTranslation } from 'react-i18next';
import * as React from 'react';
import {
    dateTimeFormat,
    DateUtils,
    IListItemProps,
    IWorkflowHistoricActivity,
    parseUserIdToName,
    YonderListItem,
} from '@yonder-mind/ui-core';

type Variant = 'historic-activity';

export interface IHistoricActivityItemProps extends IListItemProps<Variant, IWorkflowHistoricActivity> {
    dataTestId: string;
}

export const HistoricActivityItem: React.FunctionComponent<IHistoricActivityItemProps> = ({
    index,
    item,
    onClick,
    ...listItemProps
}) => {
    const { t } = useTranslation();

    const handleClick = () => {
        if (onClick) {
            onClick(item, index);
        }
    };

    return (
        <YonderListItem
            {...listItemProps}
            className="workflow-history-item"
            primary={item.taskName || t('workflow.revision.create.title')}
            secondary={`➔ ${item.activityName}`}
            tertiary={item.taskExecutedBy ? parseUserIdToName(item.taskExecutedBy) : ''}
            meta={
                DateUtils.parseToDate(
                    listItemProps.size && index === listItemProps.size - 1 ? item.startTime : item.endTime,
                    { format: dateTimeFormat }
                ) as string
            }
            onClick={handleClick}
        />
    );
};
