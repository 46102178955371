import { useTranslation } from 'react-i18next';
import { IApplicationStore, Layout, routerSelectors, SideBarProvider } from '@yonder-mind/ui-core';
import { useSelector } from 'react-redux';
import { ManageSubmissionsTableBoard } from './ManageSubmissionsTableBoard';

export const ManageSubmissionsTable: React.FC = () => {
    const { t } = useTranslation();

    const { sidebarTool, sidebarToolTab } = useSelector((state: IApplicationStore) => {
        const { sidebarTool, sidebarToolTab } = routerSelectors.queryParams(state);
        return {
            sidebarTool,
            sidebarToolTab,
        };
    });

    return (
        <SideBarProvider tool={sidebarTool} toolTab={sidebarToolTab} extraTools={[]}>
            <Layout
                title={t('manageSubmissions.title')}
                variant="manage-submissions-table"
                sidebarTool={sidebarTool}
                sidebarToolTab={sidebarToolTab}
            >
                <ManageSubmissionsTableBoard />
            </Layout>
        </SideBarProvider>
    );
};
