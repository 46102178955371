import React from 'react';
import { GlobalHistoryLogDocumentSelector } from './GlobalHistoryLogDocumentSelector';
import { ListItem, YonderButton } from '@yonder-mind/ui-core';
import { Close } from '@material-ui/icons';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { historyLogActions } from '../../store/historyLog/historyLogSlice';
import { useDispatch, useSelector } from 'react-redux';
import { ExportExcelButton } from '../../components/HistoryLog/ExportExcelButton';
import { IWebApplicationStore } from '../../interfaces';

interface GlobalHistoryLogFilterConfigurationProps {
    selectedDocumentItem: ListItem;
    setSelectedDocumentItem: React.Dispatch<React.SetStateAction<ListItem>>;
}

export const GlobalHistoryLogFilterConfiguration: React.FC<GlobalHistoryLogFilterConfigurationProps> = ({
    selectedDocumentItem,
    setSelectedDocumentItem,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { isExporting, historyLogElements } = useSelector((state: IWebApplicationStore) => state.historyLogs);

    const clearButtonDisabled = !selectedDocumentItem;

    const handleClearOnClick = () => {
        setSelectedDocumentItem(null);
    };

    const handleExcelExportOnClick = () => {
        dispatch(historyLogActions.exportHistoryLogsRequested({ contextOid: selectedDocumentItem?.id }));
    };

    return (
        <>
            <div className="global-history-log-filter-configuration" data-testid="globalHistoryLogFilterConfiguration">
                <Typography className="global-history-log-selected-title" variant="h6">
                    {t('historyLog.logEntriesFor', {
                        title: selectedDocumentItem ? selectedDocumentItem.name : t('historyLog.allDocuments'),
                    })}
                </Typography>

                <div className="global-history-log-filter-actions">
                    <div className="global-history-log-document-selector">
                        <GlobalHistoryLogDocumentSelector
                            selectedDocumentItem={selectedDocumentItem}
                            setSelectedDocumentItem={setSelectedDocumentItem}
                        />
                        <div className="global-history-log-clear-button">
                            <YonderButton
                                onClick={handleClearOnClick}
                                variant="text"
                                className="clear-button"
                                color="primary"
                                disabled={clearButtonDisabled}
                            >
                                <Close /> {t('historyLog.filter.clear')}
                            </YonderButton>
                        </div>
                    </div>
                    <div className={'global-history-log-export-button-container'}>
                        <ExportExcelButton
                            onClick={handleExcelExportOnClick}
                            isDisabled={historyLogElements.length === 0}
                            isExporting={isExporting}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
