import React from 'react';

interface IProps {
    message: string;
    className: string;
    icon?: React.ReactNode;
    onClick?: () => void;
}
export const NoItems: React.FC<IProps> = ({ message, icon, className, onClick }) => {
    return (
        <div className={className} onClick={onClick}>
            {icon && icon}
            <span>{message}</span>
        </div>
    );
};
