import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    authSelector,
    DashboardCard,
    DocumentClass,
    DocumentFilterVariables,
    Documents as DocumentsIcon,
    DocumentsCardSecondaryHeader,
    FileTypeEnum,
    filterAndSortActions,
    filterDocumentsTabConfig,
    filterFilesTabConfig,
    FilterUtils,
    FoldersList,
    IDocumentStatus,
    LastUsedList,
    SortUtils,
    userSelector,
    YonderList,
} from '@yonder-mind/ui-core';
import { IWebApplicationStore } from '../../../interfaces';

export const DocumentsCard = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [filtersActive, setFiltersActive] = useState(true);

    const hasDraftDocsAccess = useSelector(authSelector.hasDraftDocsAccess);
    const hasYonUserRole = useSelector(authSelector.hasYonUserRole);
    const hasViewerRole = useSelector(authSelector.hasViewerRole);
    const { fileListDashboard } = useSelector((state: IWebApplicationStore) => state.fileDrop);
    const filesDocumentIntegrationEnabled = useSelector(
        (state: IWebApplicationStore) => state.tenantSettings.tenantSettings.filesDocumentIntegrationEnabled
    );
    const documentsActiveTab = useSelector(userSelector.getSelectDashboardTab(t('dashboard.cards.documents.title')));

    const {
        docs,
        hasErrorDocs,
        isLoadingDocs,
        isFirstLoadingDocs,
        isLoadingFileListDashboard,
        folders,
        filteredDocumentsDataWithMemos,
        filteredFilesData,
        isLoadingTenantSettings,
    } = useSelector((state: IWebApplicationStore) => {
        return {
            docs: state.docs.currentContextVersionsMetadata,
            hasErrorDocs: state.docs.hasError,
            isLoadingDocs: state.docs.isLoading,
            isFirstLoadingDocs: state.docs.isFirstLoading,
            isLoadingFileListDashboard: state.fileDrop.isLoadingFileListDashboard,
            filteredFilesData: state.filterAndSort.filteredFilesData,
            folders: state.folder.folders,
            filteredDocumentsDataWithMemos: state.filterAndSort.filteredDocumentsData,
            isLoadingTenantSettings: state.tenantSettings.isLoading,
        };
    });

    const filteredDocumentsData = useMemo(() => {
        return {
            ...filteredDocumentsDataWithMemos,
            documents: filteredDocumentsDataWithMemos.documents.filter((document) =>
                filesDocumentIntegrationEnabled ? true : document.documentClass !== DocumentClass.FILE
            ),
        };
    }, [filteredDocumentsDataWithMemos, filesDocumentIntegrationEnabled]);

    const documentsDraftCount = useMemo(() => {
        if (docs) {
            return docs.filter((doc) => doc.status === IDocumentStatus.DRAFT).length;
        }
        return 0;
    }, [docs]);

    const documentsReleasedCount = useMemo(() => {
        if (docs) {
            return docs.filter(
                (doc) => doc.status === IDocumentStatus.RELEASED && doc.fileMetadata?.mimeType !== FileTypeEnum.html
            ).length;
        }
        return 0;
    }, [docs]);

    const filesCount = useMemo(() => {
        const dashboardFilesLength = fileListDashboard.length;
        const filteredDashboardFilesLength = filteredFilesData.files.length;
        if (dashboardFilesLength === filteredDashboardFilesLength) {
            return dashboardFilesLength;
        }
        return `${filteredDashboardFilesLength}/${dashboardFilesLength}`;
    }, [fileListDashboard, filteredFilesData.files.length]);

    const filteredDocsCount = useMemo(() => {
        const {
            activeFilters: { status: activeStatusFilter },
            documents: filteredDocuments,
        } = filteredDocumentsData;

        const filterStatusesDocCount = {
            [IDocumentStatus.DRAFT]: documentsDraftCount,
            [IDocumentStatus.RELEASED]: documentsReleasedCount,
        };

        if (activeStatusFilter in filterStatusesDocCount) {
            if (filteredDocuments.length === filterStatusesDocCount[activeStatusFilter]) {
                return filterStatusesDocCount[activeStatusFilter];
            }
            return `${filteredDocuments.length}/${filterStatusesDocCount[activeStatusFilter]}`;
        }

        return docs.length;
    }, [docs, filteredDocumentsData.documents.length]);

    useEffect(() => {
        const activeStatusFilter = filteredDocumentsData.activeFilters.status || IDocumentStatus.RELEASED;
        dispatch(filterAndSortActions.documentsFilterByCriteriaRequested(activeStatusFilter, 'status', undefined));
    }, [docs]);

    useEffect(() => {
        setFiltersActive(true);
        const documentsList = FilterUtils.filterDocuments(docs, filteredDocumentsData.activeFilters, true);
        dispatch(
            filterAndSortActions.saveFilteredDocuments(
                SortUtils.sortDocuments(documentsList, filteredDocumentsData.activeSort)
            )
        );
    }, [filteredDocumentsData.activeFilters, filteredDocumentsData.activeSort]);

    useEffect(() => {
        if (!isFirstLoadingDocs && !isLoadingDocs && docs.length > 0) {
            const { activeFilters, activeSort } = filteredDocumentsData;
            dispatch(filterAndSortActions.saveFilterAndSortDocumentsValues(activeFilters, activeSort));
        }
    }, [filteredDocumentsData]);

    useEffect(() => {
        const filesList = FilterUtils.filterFiles(fileListDashboard, filteredFilesData.activeFilters, true);
        (hasYonUserRole || hasViewerRole) &&
            dispatch(
                filterAndSortActions.saveFilteredFiles(SortUtils.sortFiles(filesList, filteredFilesData.activeSort))
            );
    }, [filteredFilesData.activeFilters, filteredFilesData.activeSort, fileListDashboard]);

    useEffect(() => {
        if (!isFirstLoadingDocs && !isLoadingFileListDashboard) {
            const { activeFilters, activeSort } = filteredFilesData;
            (hasYonUserRole || hasViewerRole) &&
                dispatch(filterAndSortActions.saveFilterAndSortFilesValues(activeFilters, activeSort));
        }
    }, [filteredFilesData]);

    const userAccessFilterFields = filterDocumentsTabConfig.fields
        .filter((field) => !(field.param === DocumentFilterVariables.status && !hasDraftDocsAccess))
        .filter(
            (field) => !(field.param === DocumentFilterVariables.documentClass && !filesDocumentIntegrationEnabled)
        );

    const displayFlatList =
        filteredDocumentsData.activeFilters.documentName ||
        filteredDocumentsData.activeFilters.effectiveFrom ||
        filteredDocumentsData.activeFilters.effectiveTo ||
        (filteredDocumentsData.activeFilters.owner && filteredDocumentsData.activeFilters.owner.length > 0) ||
        (filteredDocumentsData.activeFilters.documentClass &&
            filteredDocumentsData.activeFilters.documentClass.length > 0) ||
        filteredDocumentsData.activeFilters.status !== IDocumentStatus.RELEASED;

    const filterVisibleAtTabIndex = filesDocumentIntegrationEnabled ? [0] : [0, 2];

    return (
        <DashboardCard
            isLoading={isLoadingDocs || isFirstLoadingDocs || isLoadingTenantSettings}
            hasError={hasErrorDocs}
            primaryHeader={t('dashboard.cards.documents.title')}
            secondaryHeader={
                <DocumentsCardSecondaryHeader
                    documentsActiveTab={documentsActiveTab}
                    filesCount={filesCount}
                    filteredDocsCount={filteredDocsCount}
                    filteredDocumentsData={filteredDocumentsData}
                />
            }
            icon={<DocumentsIcon size="large" />}
            filterVisibleAtTabIndex={filterVisibleAtTabIndex}
            tabs={[
                {
                    component: displayFlatList ? (
                        <YonderList
                            variant="docs"
                            items={filteredDocumentsData.documents}
                            divider="inset"
                            useVirtualList
                            isLoading={isLoadingDocs || isFirstLoadingDocs}
                            dataTestId="dashboardLibraryCardTabDocsList"
                        />
                    ) : (
                        <FoldersList
                            docs={filteredDocumentsData.documents}
                            folders={folders}
                            activeSort={filteredDocumentsData.activeSort}
                        />
                    ),
                    dataTestId: 'dashboardLibraryCardTabDocs',
                    label: t('dashboard.cards.documents.tabs.all'),
                    chips: {
                        filterAvailable: filtersActive,
                        filterType: filterDocumentsTabConfig.type,
                        filterValues: filteredDocumentsData.activeFilters,
                    },
                },
                {
                    component: <LastUsedList dataTestId="dashboardLibraryCardTabLastUsedList" />,
                    dataTestId: 'dashboardLibraryCardTabLastUsed',
                    label: t('dashboard.cards.documents.tabs.recent'),
                },
                (hasYonUserRole || hasViewerRole) && !isLoadingTenantSettings
                    ? !filesDocumentIntegrationEnabled
                        ? {
                              component: (
                                  <YonderList
                                      variant="file-drop-item"
                                      items={filteredFilesData.files}
                                      divider="inset"
                                      dataTestId="dashboardLibraryCardTabLastFilesList"
                                      isLoading={isLoadingFileListDashboard}
                                  />
                              ),
                              dataTestId: 'dashboardLibraryCardTabLastFiles',
                              label: t('dashboard.cards.documents.tabs.fileDrop'),
                              chips: {
                                  filterAvailable: true,
                                  filterType: filterFilesTabConfig.type,
                                  filterValues: filteredFilesData.activeFilters,
                              },
                          }
                        : null
                    : null,
            ]}
            filterOptions={FilterUtils.getDocumentCardFilterOptions({
                documentsActiveTab,
                filteredDocumentsData: filteredDocumentsData,
                filteredFilesData: filteredFilesData,
                t,
                userAccessFilterFields,
            })}
        />
    );
};
