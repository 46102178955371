import React from 'react';
import { DialogTitle } from '@material-ui/core';
import { PeopleAltOutlined, LocalOfferOutlined } from '@material-ui/icons';
import { palette } from '@yonder-mind/ui-core';

const SELECT_HEADER_ICON_STYLE = {
    color: palette.iconBlue,
    verticalAlign: 'text-top',
    marginRight: '0.5em',
    width: '1.8em',
    height: '1.8em',
};

export const MetadataDialogTitle: React.FC<{ title: string; subTitle: string; type: 'tags' | 'users' }> = ({
    title,
    subTitle,
    type,
}) => {
    return (
        <DialogTitle className="metadata-dialog-title">
            {type === 'users' ? (
                <PeopleAltOutlined style={SELECT_HEADER_ICON_STYLE} />
            ) : (
                <LocalOfferOutlined style={SELECT_HEADER_ICON_STYLE} />
            )}
            <div className="dialog-title-text">
                {title}
                <div className="number-of-users-selected"> {subTitle}</div>
            </div>
        </DialogTitle>
    );
};
