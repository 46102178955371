import { call, put, takeLatest } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';
import { IApiImportJobLock } from '@yonder-mind/ui-core';
import * as actions from './import-job-actions';
import { IWebDatabaseApi } from '../../interfaces';

function* requestImportJobLockByContextVersionOid(
    api: IWebDatabaseApi,
    action: ActionType<typeof actions.importJobLockRequested>
) {
    const contextVersionOid = action.payload;

    try {
        const importJobLockData: IApiImportJobLock = yield call(
            [api, api.getImportJobLockByContextVersionOid],
            contextVersionOid
        );
        yield put(actions.importJobLockReceived(contextVersionOid, importJobLockData));
    } catch (error) {
        yield put(actions.importJobLockError(contextVersionOid, error));
    }
}

export function* importSagas(api: IWebDatabaseApi) {
    yield takeLatest(getType(actions.importJobLockRequested), requestImportJobLockByContextVersionOid, api);
}
