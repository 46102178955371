import { combineStrings, URLParams } from '@yonder-mind/ui-core';
import { WORKFLOW_WEBSOCKET_PATH } from '../config';

export class ApiWorkflowConstants {
    /// Revision

    static getRevisions(filter?: { [filter: string]: string | undefined }): URL {
        const params = URLParams.fromObject(filter || {});
        return ApiWorkflowConstants.relativeUrl(combineStrings(['/revision', params.toQuery()], '?'));
    }

    static getNewRevisionForm(contextType: string, contextSubType: string): URL {
        const url = ApiWorkflowConstants.relativeUrl('/revision/new');
        url.searchParams.set('contextType', contextType);
        url.searchParams.set('contextSubType', contextSubType);
        return url;
    }

    static startNewRevision(contextType: string): URL {
        const url = ApiWorkflowConstants.relativeUrl('/revision');
        url.searchParams.set('contextType', contextType);
        return url;
    }

    static getRevisionHistory(documentId: string): URL {
        return ApiWorkflowConstants.relativeUrl(`/revision/${documentId}/history`);
    }

    static getRevisionHistoric(documentId: string, state = 'FINISHED'): URL {
        const url = ApiWorkflowConstants.relativeUrl('/revision/historic');
        url.searchParams.set('contextVersionId', documentId);
        url.searchParams.set('state', state);
        return url;
    }

    /// Change Request

    static getChangeRequests(filter?: { [filter: string]: string | undefined }): URL {
        const params = URLParams.fromObject(filter || {});
        return ApiWorkflowConstants.relativeUrl(combineStrings(['/cr', params.toQuery()], '?'));
    }

    static getNewChangeRequestForm(contextType: string, contextSubType: string, workflowId: string): URL {
        const url = ApiWorkflowConstants.relativeUrl('/cr/new');
        url.searchParams.set('contextType', contextType);
        url.searchParams.set('contextSubType', contextSubType);
        url.searchParams.set('workflowId', workflowId);
        return url;
    }

    static startNewChangeRequest(contextType: string, workflowId: string): URL {
        const url = ApiWorkflowConstants.relativeUrl('/cr');
        url.searchParams.set('contextType', contextType);
        url.searchParams.set('workflowId', workflowId);
        return url;
    }

    static getChangeRequestHistories(filter?: { [filter: string]: string | undefined }): URL {
        const params = URLParams.fromObject(filter || {});
        return ApiWorkflowConstants.relativeUrl(combineStrings(['/cr/historic', params.toQuery()], '?'));
    }

    /// Task

    static getMyTasks(): URL {
        return ApiWorkflowConstants.relativeUrl(`/mytasks/all`);
    }

    static getMyTasksOfDocument(documentId: string): URL {
        const url = ApiWorkflowConstants.relativeUrl(`/mydocumenttasks`);
        url.searchParams.set('documentId', documentId);

        return url;
    }

    static completeTask(taskId: string): URL {
        return ApiWorkflowConstants.relativeUrl(`/mytasks/${taskId}`);
    }

    /// Process

    // Common

    static getActivityOfProcess(processInstanceId: string): URL {
        return ApiWorkflowConstants.relativeUrl(`/workflow/${processInstanceId}`);
    }

    static getHistoryOfProcess(processInstanceId: string): URL {
        return ApiWorkflowConstants.relativeUrl(`/workflow/${processInstanceId}/history`);
    }

    static getMyTasksOfProcess(processInstanceId: string): URL {
        return ApiWorkflowConstants.relativeUrl(`/workflow/${processInstanceId}/mytasks`);
    }

    // Comment

    static canCommentOnProcess(processInstanceId: string): URL {
        return ApiWorkflowConstants.relativeUrl(`/workflow/${processInstanceId}/cancomment`);
    }

    static getCommentsOfProcess(processInstanceId: string): URL {
        return ApiWorkflowConstants.relativeUrl(`/workflow/${processInstanceId}/comment`);
    }

    static getCommentsOfCurrentActivity(processInstanceId: string): URL {
        return ApiWorkflowConstants.relativeUrl(`/workflow/${processInstanceId}/comment/currentactivity`);
    }

    static commentOnProcess(processInstanceId: string): URL {
        return ApiWorkflowConstants.relativeUrl(`/workflow/${processInstanceId}/comment`);
    }

    // Attachment

    static getAttachmentsOfProcess(processInstanceId: string): URL {
        return ApiWorkflowConstants.relativeUrl(`/workflow/${processInstanceId}/attachments`);
    }

    static uploadAttachmentOnProcess(processInstanceId: string): URL {
        return ApiWorkflowConstants.relativeUrl(`/workflow/${processInstanceId}/attachment`);
    }

    static downloadAttachment(attachmentId: string): URL {
        return ApiWorkflowConstants.relativeUrl(`/workflow/attachment/${attachmentId}/data`);
    }

    static deleteAttachment(attachmentId: string): URL {
        return ApiWorkflowConstants.relativeUrl(`/workflow/attachment/${attachmentId}`);
    }

    // Notification

    static getCommentNotificationStatusOfProcess(processInstanceId: string): URL {
        return ApiWorkflowConstants.relativeUrl(`/workflow/${processInstanceId}/getCommentNotificationStatus`);
    }

    static enabledNotificationsOfProcess(processInstanceId: string): URL {
        return ApiWorkflowConstants.relativeUrl(`/workflow/${processInstanceId}/enableCommentNotifications`);
    }

    static disabledNotificationsOfProcess(processInstanceId: string): URL {
        return ApiWorkflowConstants.relativeUrl(`/workflow/${processInstanceId}/disableCommentNotifications`);
    }

    // Variables

    static getVariablesOfProcess(processInstanceId: string): URL {
        return ApiWorkflowConstants.relativeUrl(`/workflow/${processInstanceId}/variables`);
    }

    static getVariablesOfProcessGrouped(processInstanceId: string): URL {
        return ApiWorkflowConstants.relativeUrl(`/workflow/${processInstanceId}/variablesByStatus`);
    }

    static setVariablesOfProcess(processInstanceId: string): URL {
        return ApiWorkflowConstants.relativeUrl(`/workflow/${processInstanceId}/variables`);
    }

    /// Websocket
    static websocketUrl(token: string): URL {
        return new URL(`${WORKFLOW_WEBSOCKET_PATH}?access_token=${token}`, window.location.origin);
    }

    /// Export
    static getAllChangeRequestsByDocumentVersionId(contextVersionId: string): URL {
        const url = ApiWorkflowConstants.relativeUrl('/export/cr');
        url.searchParams.set('contextVersionId', contextVersionId);
        return url;
    }

    static getRevisionsByDocumentVersionId(contextVersionId: string): URL {
        const url = ApiWorkflowConstants.relativeUrl('/export/revision');
        url.searchParams.set('contextVersionId', contextVersionId);
        return url;
    }

    static getActiveChangeRequests({ contextOid, moduleOid }: { contextOid: string; moduleOid?: string }): URL {
        return ApiWorkflowConstants.relativeUrl(
            `/v2/change-requests/active?contextId=${contextOid}${moduleOid ? '&moduleId=' + moduleOid : ''}`
        );
    }

    static getFinishedChangeRequests({
        contextVersionOid,
        moduleOid,
    }: {
        contextVersionOid: string;
        moduleOid?: string;
    }): URL {
        return ApiWorkflowConstants.relativeUrl(
            `/v2/change-requests?finishedOnly=true&contextVersionToEditId=${contextVersionOid}${
                moduleOid ? '&moduleId=' + moduleOid : ''
            }`
        );
    }

    static getFinishedChangeRequestsForDraft({ contextVersionOid }: { contextVersionOid: string }): URL {
        return ApiWorkflowConstants.relativeUrl(
            `/v2/change-requests?finishedOnly=true&contextVersionToEditId=${contextVersionOid}`
        );
    }

    static getFinishedChangeRequestsForReleased({
        contextVersionOid,
        moduleOid,
    }: {
        contextVersionOid: string;
        moduleOid?: string;
    }): URL {
        return ApiWorkflowConstants.relativeUrl(
            `/v2/change-requests?finishedOnly=true&contextVersionId=${contextVersionOid}${
                moduleOid ? '&moduleId=' + moduleOid : ''
            }`
        );
    }

    static getChangeRequest({ changeRequestId }: { changeRequestId?: string }): URL {
        return ApiWorkflowConstants.relativeUrl(`/cr/${changeRequestId}`);
    }

    /// Common
    private static relativeUrl(relative: string): URL {
        return new URL(`/workflow/api${relative}`, window.location.origin);
    }
}
