import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
    ContextVersionUtils,
    docsSelector,
    IApiContextVersion,
    IApplicationStore,
    IWorkflowActivity,
    IWorkflowRevisionHistoric,
    WorkflowUtils,
} from '@yonder-mind/ui-core';
import { useWorkflow } from '../../../context';
import { IFormValues } from '../../../interfaces';
import { WorkflowForm } from '../../FormStructure';
import { WorkflowSidebar } from '../WorkflowSidebar';
import { WorkflowTasks } from '../WorkflowTasks';
import { useRouteMatch } from 'react-router';
import { useSelector } from 'react-redux';

interface IProps {
    title: string;
    subTitle?: React.ReactNode;
    document: IApiContextVersion;
    activity?: IWorkflowActivity | IWorkflowRevisionHistoric;
}

export const RevisionWorkflowSidebar: React.FC<IProps> = ({ title, subTitle, document, activity }) => {
    const { t } = useTranslation();

    const { actions, startForm } = useWorkflow('revision');

    const { params: routerParams } = useRouteMatch<{ documentOid: string }>();
    const { documentOid } = routerParams;
    const contextVersion = useSelector((state: IApplicationStore) => docsSelector.getDocument(state, documentOid));
    const contextVersions = useSelector((state: IApplicationStore) => docsSelector.getVersions(state, documentOid));

    const [showNewRevisionForm, setNewRevisionFormVisibility] = React.useState(false);

    const isNewestVersionSelected = () =>
        ContextVersionUtils.isNewestVersionSelectedBuggyOne(documentOid, contextVersions || []);

    React.useEffect(() => {
        actions.requestNewRevisionForm(document.type, document.subType);
    }, []);

    const closeNewRevision = () => {
        setNewRevisionFormVisibility(false);
    };

    const handleSubmitNewChangeRequest = (values: IFormValues) => {
        setNewRevisionFormVisibility(false);
        actions.addNewRevisionRequest(document.type, values);
    };

    const canCreateNewRevision =
        startForm && (!activity || WorkflowUtils.isCompleted(activity)) && isNewestVersionSelected();

    const getFields = () => {
        return startForm.filter((field) => field.id !== 'TEMPORARY_EFFECTIVE_TO');
    };

    const getInjectedValues = () => {
        const isSubTypeFieldAvailable =
            getFields().find((field) => field.id === 'DOC_SUB_TYPE_ROLE_SUFFIX') !== undefined;
        const isTempRevisionFieldAvailable =
            getFields().find((field) => field.id === 'IS_TEMPORARY_REVISION') !== undefined;
        if (isTempRevisionFieldAvailable) {
            if (isSubTypeFieldAvailable) {
                return {
                    CONTEXT_VERSION_ID: document.oid,
                    CONTEXT_ID: document.contextOid,
                    DOCUMENT_ID: document.oid,
                    DOC_SUB_TYPE_ROLE_SUFFIX: document.subType,
                    IS_TEMPORARY_REVISION: 'false',
                };
            } else {
                return {
                    CONTEXT_VERSION_ID: document.oid,
                    CONTEXT_ID: document.contextOid,
                    DOCUMENT_ID: document.oid,
                    IS_TEMPORARY_REVISION: 'false',
                };
            }
        }
        if (isSubTypeFieldAvailable) {
            return {
                CONTEXT_VERSION_ID: document.oid,
                CONTEXT_ID: document.contextOid,
                DOCUMENT_ID: document.oid,
                DOC_SUB_TYPE_ROLE_SUFFIX: document.subType,
            };
        } else {
            return {
                CONTEXT_VERSION_ID: document.oid,
                CONTEXT_ID: document.contextOid,
                DOCUMENT_ID: document.oid,
            };
        }
    };

    return (
        <>
            <WorkflowSidebar
                title={title}
                subTitle={subTitle}
                activity={activity}
                tasks={
                    !canCreateNewRevision && (
                        <WorkflowTasks
                            contextVersion={contextVersion}
                            variant="revision"
                            activity={activity}
                            isDisabled={false}
                        />
                    )
                }
            />
            <WorkflowForm
                open={showNewRevisionForm}
                injectedFields={getInjectedValues()}
                fields={getFields()}
                title={t('workflow.revision.create.title')}
                onCancel={closeNewRevision}
                onSubmit={handleSubmitNewChangeRequest}
            />
        </>
    );
};
