import { ActionType, getType } from 'typesafe-actions';
import { ComplianceReportsStore, ComplianceReportsFilterConfig } from '../../interfaces';
import { complianceReportsActions as actions } from './index';

type ComplianceReportsActions = ActionType<typeof actions>;

export const initialFilterConfig: ComplianceReportsFilterConfig = {
    selectedDocumentIds: [],
    selectedUserIds: [],
    selectedRoleIds: [],
    selectedTagIds: [],
    status: 'PRESENT',
};

const initialState: ComplianceReportsStore = {
    selectedDropdown: 'none',
    tagDomains: [],
    filterConfig: initialFilterConfig,
    isLoadingTags: false,
    documents: [],
    isLoadingDocuments: false,
    isLoadingRoles: false,
    roles: [],
    selectableDocuments: [],
    isLoadingSelectableDocuments: false,
    appliedFilterConfig: initialFilterConfig,
    changesByContextVersion: {},
    isLoadingChanges: false,
    isLoadingUsersByChange: false,
    usersByChange: {},
    dateConfig: {
        effectiveAt: '',
        allEffectiveInFuture: null,
    },
    presets: [],
    isLoadingPresets: false,
    isSavingPreset: false,
    isDeletingPreset: false,

    isExportingCsv: false,
};

export default function complianceReportsReducer(
    state: ComplianceReportsStore = initialState,
    action: ComplianceReportsActions
): ComplianceReportsStore {
    switch (action.type) {
        case getType(actions.setSelectedDropdown):
            return {
                ...state,
                selectedDropdown: action.payload.selectedDropdown,
            };
        case getType(actions.clearFilterConfig):
            return {
                ...state,
                filterConfig: {
                    ...initialFilterConfig,
                    status: action.payload,
                },
                appliedFilterConfig: {
                    ...initialFilterConfig,
                    status: action.payload,
                },
                documents: [],
                changesByContextVersion: {},
            };
        case getType(actions.clearChangesFilterConfig):
            return {
                ...state,
                filterConfig: {
                    ...state.filterConfig,
                    status: action.payload,
                    selectedUserIds: [],
                },
                appliedFilterConfig: {
                    ...state.appliedFilterConfig,
                    selectedUserIds: [],
                },
            };
        case getType(actions.updateFilterConfigStatus):
            return {
                ...state,
                filterConfig: {
                    ...state.filterConfig,
                    status: action.payload,
                },
            };
        case getType(actions.updateFilterConfigSelectedDocumentIds):
            return {
                ...state,
                filterConfig: {
                    ...state.filterConfig,
                    selectedDocumentIds: action.payload.selectedDocumentIds,
                },
            };
        case getType(actions.updateFilterConfigSelectedUserIds):
            return {
                ...state,
                filterConfig: {
                    ...state.filterConfig,
                    selectedUserIds: action.payload.selectedUserIds,
                },
            };
        case getType(actions.loadCompliancePreset):
            return {
                ...state,
                isLoadingDocuments: true,
                filterConfig: action.payload.config,
                appliedFilterConfig: action.payload.config,
                dateConfig: { ...state.dateConfig, effectiveAt: action.payload.date },
            };
        case getType(actions.updateFilterConfigSelectedRoleIds):
            return {
                ...state,
                filterConfig: {
                    ...state.filterConfig,
                    selectedRoleIds: action.payload.selectedRoleIds,
                },
            };
        case getType(actions.updateFilterConfigSelectedTagIds):
            return {
                ...state,
                filterConfig: {
                    ...state.filterConfig,
                    selectedTagIds: action.payload.selectedTagIds,
                },
            };

        case getType(actions.complianceReportsTagsRequested):
            return {
                ...state,
                isLoadingTags: true,
            };
        case getType(actions.complianceReportsTagsReceived):
            return {
                ...state,
                tagDomains: action.payload.tagDomains,
                isLoadingTags: false,
            };
        case getType(actions.complianceReportsTagsError):
            return {
                ...state,
                isLoadingTags: false,
            };

        case getType(actions.complianceReportsSelectableDocumentsRequested):
            return {
                ...state,
                selectableDocuments: [],
                isLoadingSelectableDocuments: true,
            };
        case getType(actions.complianceReportsSelectableDocumentsReceived):
            return {
                ...state,
                selectableDocuments: action.payload,
                isLoadingSelectableDocuments: false,
            };
        case getType(actions.complianceReportsSelectableDocumentsError):
            return {
                ...state,
                isLoadingSelectableDocuments: false,
            };
        case getType(actions.complianceReportsRolesRequested):
            return {
                ...state,
                isLoadingRoles: true,
            };
        case getType(actions.complianceReportsRolesReceived):
            return {
                ...state,
                roles: action.payload,
                isLoadingRoles: false,
            };
        case getType(actions.complianceReportsRolesError):
            return {
                ...state,
                isLoadingRoles: false,
            };
        case getType(actions.complianceDocumentsRequested):
            return {
                ...state,
                usersByChange: {},
                isLoadingDocuments: true,
            };
        case getType(actions.complianceDocumentsReceived):
            return {
                ...state,
                documents: action.payload.documents,
                appliedFilterConfig: action.payload.config,
                dateConfig: {
                    ...state.dateConfig,
                    effectiveAt: action.payload.date,
                },
                isLoadingDocuments: false,
            };
        case getType(actions.clearComplianceDocuments): {
            return {
                ...state,
                documents: [],
            };
        }
        case getType(actions.complianceDocumentsError):
            return {
                ...state,
                isLoadingDocuments: false,
            };

        case getType(actions.complianceChangesRequested):
            return {
                ...state,
                isLoadingChanges: true,
                usersByChange: {},
            };
        case getType(actions.complianceChangesReceived):
            return {
                ...state,
                changesByContextVersion: {
                    ...state.changesByContextVersion,
                    [action.payload.contextVersionOid]: action.payload.changes,
                },
                isLoadingChanges: false,
            };
        case getType(actions.complianceChangesError):
            return {
                ...state,
                isLoadingChanges: false,
            };

        case getType(actions.complianceUsersRequested):
            return {
                ...state,
                isLoadingUsersByChange: true,
            };
        case getType(actions.complianceUsersReceived):
            return {
                ...state,
                usersByChange: {
                    ...state.usersByChange,
                    [action.payload.oid]: action.payload.users,
                },
                isLoadingUsersByChange: false,
            };
        case getType(actions.complianceUsersError):
            return {
                ...state,
                isLoadingUsersByChange: false,
            };

        case getType(actions.setDateToGetComplianceFilterDocuments):
            return {
                ...state,

                dateConfig: action.payload,
            };
        case getType(actions.compliancePresetsRequested):
            return {
                ...state,
                isLoadingPresets: true,
            };

        case getType(actions.compliancePresetsReceived):
            return {
                ...state,
                isLoadingPresets: false,
                presets: action.payload,
            };

        case getType(actions.compliancePresetsError):
            return {
                ...state,
                isLoadingPresets: false,
                presets: [],
            };

        case getType(actions.saveCompliancePresetsRequested):
            return {
                ...state,
                isSavingPreset: true,
            };
        case getType(actions.saveCompliancePresetsSuccess):
            return {
                ...state,
                isSavingPreset: false,
                presets: [...state.presets].concat(action.payload),
            };
        case getType(actions.deleteCompliancePresetRequested):
            return {
                ...state,
                isDeletingPreset: true,
            };
        case getType(actions.deleteCompliancePresetsSuccess):
            return {
                ...state,
                isDeletingPreset: false,
                presets: [...state.presets].filter((preset) => preset.oid !== action.payload),
            };
        case getType(actions.exportComplianceCsvRequested):
            return {
                ...state,
                isExportingCsv: true,
            };
        case getType(actions.exportComplianceCsvSuccess):
            return {
                ...state,
                isExportingCsv: false,
            };
        case getType(actions.exportComplianceCsvError):
            return {
                ...state,
                isExportingCsv: false,
            };

        default:
            return state;
    }
}
