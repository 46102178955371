import React from 'react';
import { FileTypeEnum, MemoEditor, YonderButton, NoteAltIcon, CloseButton } from '@yonder-mind/ui-core';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Chip,
    Grid,
    Typography,
} from '@material-ui/core';
import { DEFAULT_END_DATE, DEFAULT_START_DATE } from '../utils/metadataUtils';
import * as uuid from 'uuid';
import { FileDropTableItem } from '../domain/types';
import { useTranslation } from 'react-i18next';

interface IProps {
    open: boolean;
    onClose: () => void;
    importFile: (file: FileDropTableItem, fileKey: string) => void;
    maxFileSize: number;
}

export const MemoDialog: React.FC<IProps> = (props) => {
    const [memoContent, setMemoContent] = React.useState('');
    const [memoFileTitle, setMemoFileTitle] = React.useState('');
    const [memoFileLengthInMb, setMemoFileLengthInMb] = React.useState(0);

    const { t } = useTranslation();

    const { open, onClose, importFile, maxFileSize } = props;

    const changeContent = (content: string) => {
        setMemoContent(content);
    };

    const clearMemo = () => {
        setMemoContent('');
        setMemoFileTitle('');
    };

    const saveMemoFile = () => {
        const memoFile = new File([new Blob([memoContent])], `${memoFileTitle}.html`, { type: 'text/html' });
        const fileKey = uuid.v4();
        importFile(
            {
                file: memoFile,
                name: memoFileTitle,
                endDate: DEFAULT_END_DATE,
                startDate: DEFAULT_START_DATE.unix(),
                type: FileTypeEnum.html,
                key: fileKey,
                isDurationOpen: true,
                isMemo: true,
            },
            fileKey
        );
        clearMemo();
        onClose();
    };

    const closeMemoFile = () => {
        clearMemo();
        onClose();
    };

    React.useEffect(() => {
        const memoLength = new TextEncoder().encode(memoContent).length;
        setMemoFileLengthInMb(memoLength / 1024 / 1024);
    }, [memoContent]);

    return (
        <>
            <Dialog
                className="memo-dialog"
                PaperProps={{ className: 'memo-dialog-paper' }}
                open={open}
                onClose={() => onClose()}
                fullWidth={true}
                maxWidth="md"
            >
                <DialogTitle className="memo-dialog-title">
                    <Grid container={true} alignItems="center">
                        <Grid className="icon">
                            <NoteAltIcon />
                        </Grid>
                        <Grid className="title">
                            <Typography variant="h6">{t('fileDrop.memoDialog.title')}</Typography>
                            <Typography variant="subtitle2">
                                <Chip label={`${memoFileLengthInMb.toFixed(2)}/${maxFileSize} MB`} />
                            </Typography>
                        </Grid>
                        <div className="grow" />
                        <CloseButton onClick={() => onClose()} size="small" />
                    </Grid>
                </DialogTitle>
                <DialogContent className="memo-dialog-content">
                    <div className="memo-title-wrapper">
                        <TextField
                            id="outlined-basic"
                            variant="standard"
                            placeholder={t('fileDrop.memoDialog.memoTitleLabel')}
                            fullWidth
                            onChange={(e) => setMemoFileTitle(e.target.value)}
                        />
                    </div>
                    <div className="memo-editor-wrapper">
                        <MemoEditor
                            content={memoContent}
                            onChange={changeContent}
                            placeholder={t('fileDrop.memoDialog.memoEditorLabel')}
                        />
                    </div>
                </DialogContent>
                <DialogActions className="memo-dialog-actions">
                    <YonderButton onClick={closeMemoFile} variant="text">
                        {t('fileDrop.memoDialog.cancel')}
                    </YonderButton>
                    <YonderButton
                        onClick={() => saveMemoFile()}
                        variant="contained"
                        color="primary"
                        disabled={memoContent.length === 0 || memoFileTitle.length === 0}
                    >
                        {t('fileDrop.memoDialog.create')}
                    </YonderButton>
                </DialogActions>
            </Dialog>
        </>
    );
};
