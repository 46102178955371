import { HighlyPerformantTableColumn } from '../HighlyPerformantTable/HighlyPerformantTableInterfaces';

export const HistoryLogTableColumnConfiguration: HighlyPerformantTableColumn[] = [
    {
        label: 'historyLog.table.date',
        dataKey: 'date',
        width: 200,
    },
    {
        label: 'historyLog.table.title',
        dataKey: 'title',
        displayInGlobalView: true,
        width: 300,
    },
    {
        label: 'historyLog.table.revision',
        dataKey: 'revision',
        displayInGlobalView: true,
        width: 150,
    },
    {
        label: 'historyLog.table.user',
        dataKey: 'user',
        width: 250,
    },
    {
        label: 'historyLog.table.action',
        dataKey: 'action',
        width: 250,
    },
    {
        label: 'historyLog.table.details',
        dataKey: 'actionDetails',
    },
];
