import { createAction } from 'typesafe-actions';
import {
    IApiImportJobLock,
    IWorkflowActivity,
    IWorkflowFormField,
    IWorkflowHistoricActivity,
    IWorkflowChangeRequestV2,
    IWorkflowChangeRequestV1,
} from '@yonder-mind/ui-core';
import { IApiError, IFormValues, IWorkflowCrEditContent, IWorkflowCrEditTitle } from '../../../interfaces';

export const REQUEST_CONTEXT_VERSION_IMPORT_JOB_HAS_CHANGES = 'REQUEST CONTEXT VERSION JOB HAS CHANGES';
export const contextVersionImportJobHasChangesRequested = createAction(
    REQUEST_CONTEXT_VERSION_IMPORT_JOB_HAS_CHANGES,
    (contextVersionOid: string) => ({ contextVersionOid })
)();

export const CONTEXT_VERSION_IMPORT_JOB_HAS_CHANGES_RECEIVED = 'CONTEXT VERSION JOB HAS CHANGES RECEIVED';
export const contextVersionImportJobHasChangesReceived = createAction(
    CONTEXT_VERSION_IMPORT_JOB_HAS_CHANGES_RECEIVED,
    (hasChanges: boolean) => ({
        hasChanges,
    })
)();

// Form fields to add a new change request

export const REQUEST_START_NEW_CHANGE_REQUEST_FORM = 'REQUEST START NEW CHANGE REQUEST FORM FIELDS';
export const newChangeRequestFormFieldsRequested = createAction(
    REQUEST_START_NEW_CHANGE_REQUEST_FORM,
    (contextType: string, contextSubType: string, workflowId: string) => ({ contextType, contextSubType, workflowId })
)();

export const START_NEW_CHANGE_REQUEST_FORM_RECEIVED = 'START NEW CHANGE REQUEST FORM FIELDS RECEIVED';
export const newChangeRequestFormFieldsReceived = createAction(
    START_NEW_CHANGE_REQUEST_FORM_RECEIVED,
    (workflowId: string, fields: IWorkflowFormField[] | null) => ({ workflowId, fields })
)();

export const calculatedDiffRequested = createAction(
    'CALCULATED DIFF REQUESTED',
    (previousContent: string, currentContent: string) => ({
        previousContent,
        currentContent,
    })
)();

export const calculatedDiffClear = createAction('CALCULATED DIFF CLEAR', () => null)();

export const calculatedDiffReceived = createAction('CALCULATED DIFF RECEIVED', (contentDiff: string) => ({
    contentDiff,
}))();

// Add a new change request

export const ADD_CHANGE_REQUEST = 'ADD CHANGE REQUEST';
export const addChangeRequest = createAction(
    ADD_CHANGE_REQUEST,
    (payload: {
        contextType: string;
        workflowId: string;
        contextOid: string;
        contextVersionOid: string;
        previousContent: string;
        data: IFormValues;
        moduleOid: string;
        isDraft: boolean;
    }) => payload
)();

export const ADD_CHANGE_REQUEST_COMPLETED = 'ADD CHANGE REQUEST COMPLETED';
export const addChangeRequestCompleted = createAction(
    ADD_CHANGE_REQUEST_COMPLETED,
    (contextVersionOid: string, changeRequest: IWorkflowActivity) => ({ changeRequest, contextVersionOid })
)();

// New change request received

export const NEW_CHANGE_REQUEST_RECEIVED = 'NEW CHANGE REQUEST RECEIVED';
export const receivedNewChangeRequest = createAction(
    NEW_CHANGE_REQUEST_RECEIVED,
    (changeRequest: IWorkflowActivity) => ({ changeRequest })
)();

// Update change request variables

export const CHANGE_REQUEST_VARIABLE_UPDATES_RECEIVED = 'CHANGE REQUEST VARIABLE UPDATES RECEIVED';
export const changeRequestVariableUpdatesReceived = createAction(
    CHANGE_REQUEST_VARIABLE_UPDATES_RECEIVED,
    (processInstanceId: string, updates: { [id: string]: string }) => ({ processInstanceId, updates })
)();

// Update change request

export const CHANGE_REQUEST_UPDATES_RECEIVED = 'CHANGE REQUEST UPDATE RECEIVED';
export const changeRequestUpdatesReceived = createAction(
    CHANGE_REQUEST_UPDATES_RECEIVED,
    (processInstanceId: string, activity: IWorkflowActivity | IWorkflowHistoricActivity) => ({
        activity,
        processInstanceId,
    })
)();

// User Change Requests

export const REQUEST_ALL_CHANGE_REQUESTS_OF_USER = 'REQUEST ALL CHANGE REQUESTS OF USER';
export const allChangeRequestsOfUserRequested = createAction(
    REQUEST_ALL_CHANGE_REQUESTS_OF_USER,
    (userName: string) => ({ userName })
)();

export const ALL_CHANGE_REQUESTS_OF_USER_RECEIVED = 'ALL CHANGE REQUESTS OF USER RECEIVED';
export const allChangeRequestsOfUserReceived = createAction(
    ALL_CHANGE_REQUESTS_OF_USER_RECEIVED,
    (changeRequests: IWorkflowActivity[]) => ({ changeRequests })
)();

export const START_EDITING_CHANGE_REQUEST_CONTENT = 'START EDITING CHANGE REQUEST CONTENT';
export const startEditingChangeRequestContent = createAction(
    START_EDITING_CHANGE_REQUEST_CONTENT,
    (processInstanceId: string, contextVersionOid: string, moduleVersionOid: string) => ({
        processInstanceId,
        contextVersionOid,
        moduleVersionOid,
    })
)();

export const PEEK_EDITING_CHANGE_REQUEST_CONTENT = 'PEEK EDITING CHANGE REQUEST CONTENT';
export const peekEditingChangeRequestContent = createAction(
    PEEK_EDITING_CHANGE_REQUEST_CONTENT,
    (processInstanceId: string, contextVersionOid: string, moduleVersionOid: string) => ({
        processInstanceId,
        contextVersionOid,
        moduleVersionOid,
    })
)();

export const EDIT_CHANGE_REQUEST_CONTENT_RECEIVED = 'EDIT CHANGE REQUEST CONTENT RECEIVED';
export const editChangeRequestContentReceived = createAction(
    EDIT_CHANGE_REQUEST_CONTENT_RECEIVED,
    (processInstanceId: string, content: IWorkflowCrEditContent) => ({ processInstanceId, content })
)();

export const EDIT_CHANGE_REQUEST_CONTENT_ERROR = 'EDIT CHANGE REQUEST CONTENT ERROR';
export const editChangeRequestContentError = createAction(
    EDIT_CHANGE_REQUEST_CONTENT_ERROR,
    (error: IApiError) => error
)();

export const SAVE_CHANGE_REQUEST_CONTENT = 'SAVE CHANGE REQUEST CONTENT';
export const saveChangeRequestContent = createAction(
    SAVE_CHANGE_REQUEST_CONTENT,
    (
        processInstanceId: string,
        contextVersionOid: string,
        moduleVersionOid: string,
        content: IWorkflowCrEditContent
    ) => ({ processInstanceId, contextVersionOid, moduleVersionOid, content })
)();

export const PEEK_EDITING_CHANGE_REQUEST_TITLE = 'PEEK EDITING CHANGE REQUEST TITLE';
export const peekEditingChangeRequestTitle = createAction(
    PEEK_EDITING_CHANGE_REQUEST_TITLE,
    (processInstanceId: string, contextVersionOid: string, moduleOid: string) => ({
        processInstanceId,
        contextVersionOid,
        moduleOid,
    })
)();

export const PEEK_CHANGE_REQUEST_TITLE_RECEIVED = 'PEEK EDIT CHANGE REQUEST TITLE RECEIVED';
export const peekEditingChangeRequestTitleReceived = createAction(
    PEEK_CHANGE_REQUEST_TITLE_RECEIVED,
    (processInstanceId: string, title: IWorkflowCrEditTitle) => ({ processInstanceId, title })
)();

export const START_EDITING_CHANGE_REQUEST_TITLE = 'START EDITING CHANGE REQUEST TITLE';
export const startEditingChangeRequestTitle = createAction(
    START_EDITING_CHANGE_REQUEST_TITLE,
    (processInstanceId: string, contextVersionOid: string, moduleOid: string) => ({
        processInstanceId,
        contextVersionOid,
        moduleOid,
    })
)();

export const EDIT_CHANGE_REQUEST_TITLE_RECEIVED = 'EDIT CHANGE REQUEST TITLE RECEIVED';
export const editChangeRequestTitleReceived = createAction(
    EDIT_CHANGE_REQUEST_TITLE_RECEIVED,
    (processInstanceId: string, title: IWorkflowCrEditTitle) => ({ processInstanceId, title })
)();

export const SAVE_CHANGE_REQUEST_TITLE = 'SAVE CHANGE REQUEST TITLE';
export const saveChangeRequestTitle = createAction(
    SAVE_CHANGE_REQUEST_TITLE,
    (processInstanceId: string, contextVersionOid: string, moduleOid: string, title: IWorkflowCrEditTitle) => ({
        processInstanceId,
        contextVersionOid,
        moduleOid,
        title,
    })
)();

export const END_EDITING_CHANGE_REQUEST = 'END EDITING CHANGE REQUEST';
export const endEditingChangeRequest = createAction(END_EDITING_CHANGE_REQUEST, (processInstanceId: string) => ({
    processInstanceId,
}))();

export const REFRESH_CHANGE_REQUEST_EDIT_LOCK_REQUESTED = 'REFRESH CHANGE REQUEST EDIT LOCK REQUESTED';
export const refreshChangeRequestEditLockRequested = createAction(
    REFRESH_CHANGE_REQUEST_EDIT_LOCK_REQUESTED,
    (processInstanceId: string, contextVersionOid: string) => ({ processInstanceId, contextVersionOid })
)();

export const CHANGE_REQUEST_EDIT_LOCK_RECEIVED = 'CHANGE REQUEST EDIT LOCK RECEIVED';
export const changeRequestEditLockReceived = createAction(
    CHANGE_REQUEST_EDIT_LOCK_RECEIVED,
    (processInstanceId: string, importJobLock: IApiImportJobLock) => ({ processInstanceId, importJobLock })
)();

export const CANCEL_EDIT_CONTENT_REQUESTED = 'CANCEL EDIT CONTENT REQUESTED';
export const cancelEditCRContentRequested = createAction(
    CANCEL_EDIT_CONTENT_REQUESTED,
    (processInstanceId: string, contextVersionOid: string, moduleVersionOid: string) => ({
        processInstanceId,
        contextVersionOid,
        moduleVersionOid,
    })
)();

const CANCEL_EDIT_CONTENT_SUCCESS = 'CANCEL EDIT CONTENT SUCCESS';
export const cancelEditCRContentReceived = createAction(CANCEL_EDIT_CONTENT_SUCCESS, (processInstanceId: string) => ({
    processInstanceId,
}))();

export const CANCEL_EDIT_TITLE_REQUESTED = 'CANCEL EDIT TITLE REQUESTED';
export const cancelEditCRTitleRequested = createAction(
    CANCEL_EDIT_TITLE_REQUESTED,
    (processInstanceId: string, contextVersionOid: string, moduleOid: string) => ({
        processInstanceId,
        contextVersionOid,
        moduleOid,
    })
)();

const CANCEL_EDIT_TITLE_SUCCESS = 'CANCEL EDIT TITLE SUCCESS';
export const cancelEditCRTitleReceived = createAction(CANCEL_EDIT_TITLE_SUCCESS, (processInstanceId: string) => ({
    processInstanceId,
}))();

export const API_ERROR_RECEIVED = 'API ERROR RECEIVED';
export const apiErrorReceived = createAction(API_ERROR_RECEIVED, (error: IApiError) => ({ error }))();

export const API_ERROR_RESET = 'API ERROR RESET';
export const apiErrorReset = createAction(API_ERROR_RESET, (errors: IApiError[]) => ({ errors }))();

export const SAVE_DRAFT_ERROR_RECEIVED = 'SAVE DRAFT ERROR RECEIVED';
export const saveDraftErrorReceived = createAction(SAVE_DRAFT_ERROR_RECEIVED, (error: boolean) => ({ error }))();

export const CLOSE_DRAFT_ERROR_SNACK = 'CLOSE DRAFT ERROR SNACK';
export const closeDraftErrorSnack = createAction(CLOSE_DRAFT_ERROR_SNACK, (error: boolean) => ({ error }))();

export const CHANGE_REQUEST_CONTENT_SAVED = 'CHANGE REQUEST CONTENT SAVED';
export const crContentSaved = createAction(CHANGE_REQUEST_CONTENT_SAVED, (payload: boolean) => payload)();

export const RESET_DRAFT_ERRORS = 'RESET DRAFT ERRORS';
export const resetDraftErrors = createAction(RESET_DRAFT_ERRORS)();

export const EDIT_DRAFT_SUCCESS_REQUESTED = 'EDIT DRAFT SUCCESS REQUESTED';
export const editDraftSuccessRequested = createAction(EDIT_DRAFT_SUCCESS_REQUESTED, (payload: boolean) => payload)();

export const getChangeRequestsForReleasedDoc = createAction(
    'GET CHANGE REQUESTS FOR RELEASED DOC',
    (payload: { contextOid: string; contextVersionOid: string }) => payload
)();

export const getChangeRequestsForDraftDoc = createAction(
    'GET CHANGE REQUESTS FOR DRAFT DOC',
    (payload: { contextOid: string; contextVersionOid: string }) => payload
)();

export const getChangeRequestsError = createAction('GET CHANGE REQUESTS ERROR', (payload: string) => payload)();

export const getChangeRequestsSuccess = createAction(
    'GET CHANGE REQUESTS SUCCESS',
    (payload: IWorkflowChangeRequestV2[]) => payload
)();

export const getChangeRequest = createAction('GET CHANGE REQUEST', (payload: { changeRequestId: string }) => payload)();

export const getChangeRequestSuccess = createAction(
    'GET CHANGE REQUEST SUCCESS',
    (payload: IWorkflowChangeRequestV1) => payload
)();

export const updateChangeRequestProcess = createAction(
    'UPDATE CHANGE REQUEST PROCESS',
    (payload: { changeRequestId: string }) => payload
)();

export const getChangeRequestError = createAction('GET CHANGE REQUEST ERROR', (payload?: string) => payload)();

export const getFinishedChangeRequests = createAction(
    'GET FINISHED CHANGE REQUESTS',
    (contextVersionOid: string) => contextVersionOid
)();
export const getFinishedChangeRequestsSuccess = createAction(
    'GET FINISHED CHANGE REQUESTS SUCCESS',
    (payload: IWorkflowChangeRequestV2[]) => payload
)();

export const getFinishedChangeRequestsError = createAction(
    'GET FINISHED CHANGE REQUESTS ERROR',
    (payload: string) => payload
)();

export const getChangeRequestsForWfSidebar = createAction(
    'GET CHANGE REQUEST FOR WF SIDEBAR',
    (payload: { contextOid: string; contextVersionOid: string; isDraft: boolean }) => payload
)();

export const getChangeRequestsForModule = createAction(
    'GET CHANGE REQUEST FOR MODULE',
    (payload: { contextOid: string; moduleOid: string; contextVersionOid: string; isDraft: boolean }) => payload
)();

export const getChangeRequestsForModuleSuccess = createAction(
    'GET CHANGE REQUEST FOR MODULE SUCCESS',
    (payload: IWorkflowChangeRequestV2[]) => payload
)();

export const getChangeRequestsForModuleError = createAction(
    'GET CHANGE REQUEST FOR MODULE ERROR',
    (payload?: string) => payload
)();
