import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { select } from 'redux-saga/effects';
import { useSnackbar } from 'notistack';
import { useDropzone } from 'react-dropzone';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import utc from 'dayjs/plugin/utc';
import * as uuid from 'uuid';
import isEqual from 'lodash/isEqual';
import {
    changesActions,
    fileDropActions,
    filesActions,
    FileTypeEnum,
    folderActions,
    FolderStructureUtils,
    Spinner,
    useSelectorUiCore,
} from '@yonder-mind/ui-core';
import { FileDropMetadata, FileDropTableItem } from './domain/types';
import {
    checkFileContextChanges,
    checkFileRevisionChanges,
    DEFAULT_START_DATE,
    getApiMetadataFromFile,
    getContextMetadataFromSelectedFile,
    getFileDropTableItemFromApiMetadata,
    getFileDropTableItemFromApiMetadataNew,
    getMetadataFromTableItem,
    getRevisionMetadataFromSelectedFile,
    OFFSET_DATE_TIME_MAX,
} from './utils/metadataUtils';
import { WidgetCard } from '../../components/WidgetCard/WidgetCard';
import { FileDropTable } from './FileDropTable/FileDropTable';
import { validateFileDrop } from './utils/useFileDropZone';
import { KebabMenuProps } from './FileDropTable/TableKebabMenu';
import { RenameFileDialog } from './FileDropTable/RenameFileDialog';
import { DeleteFileDialog } from './FileDropTable/DeleteFileDialog';
import { NoItems } from '../../components';
import { MetadataTabs } from './MetadataTabs/MetadataTabs';
import { NewRevisionNotAllowedDialog } from './FileDropTable/NewRevisionNotAllowedDialog';

dayjs.extend(utc);
dayjs.extend(isSameOrAfter);

export const FileDropLibrary: React.FC<{
    setUpdatedFile: (updatedFile: FileDropTableItem) => void;
    updatedFile: FileDropTableItem;
    visible: boolean;
}> = ({ setUpdatedFile, visible, updatedFile }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const filesDocumentIntegrationEnabled = useSelectorUiCore(
        (state) => state.tenantSettings.tenantSettings.filesDocumentIntegrationEnabled
    );
    const fileListAPI = useSelectorUiCore((state) => state.fileDrop.fileList);
    const newFileList = useSelectorUiCore((state) => state.files.fileList);
    const isLoadingFiles = useSelectorUiCore((state) => {
        return filesDocumentIntegrationEnabled ? state.files.isLoadingFileList : state.fileDrop.isLoadingFileList;
    });
    const tenantSettings = useSelectorUiCore((State) => State.tenantSettings.tenantSettings);
    const folders = useSelectorUiCore((state) => state.folder.unprocessedFolders);
    const assignedFilesToFolders = useSelectorUiCore((state) => state.files.assignedFilesToFolders);
    const hasNotificationSettingsChanges = useSelectorUiCore((state) => state.files.hasNotificationSettingsChanges);
    const notificationSettings = useSelectorUiCore((state) => state.files.notificationSettings);

    const [droppedFile, setDroppedFile] = useState<File>(null);
    const [editMode, setEditMode] = useState(false);
    const [selectedFile, setSelectedFile] = useState<FileDropTableItem>(null);
    const [selectedFileKey, setSelectedFileKey] = React.useState<string>('');
    const [renameFileDialogOpen, setRenameFileDialogOpen] = useState(false);
    const [deleteFileDialogOpen, setDeleteFileDialogOpen] = useState(false);
    const [newRevisionNotAllowedDialogOpen, setNewRevisionNotAllowedDialogOpen] = useState(false);

    const fileList = useMemo(() => {
        if (filesDocumentIntegrationEnabled) {
            return newFileList.map((file) => getFileDropTableItemFromApiMetadataNew(file));
        } else {
            return fileListAPI.map((file) => getFileDropTableItemFromApiMetadata(file));
        }
    }, [fileListAPI, newFileList]);

    const setSelectedFileMetadata = useCallback(
        (metadata: FileDropMetadata) => {
            setSelectedFile({ ...selectedFile, ...metadata });
        },
        [selectedFile, selectedFileKey]
    );

    useEffect(() => {
        setSelectedFile(fileList.find((file) => file.key === selectedFileKey));
        setEditMode(false);
    }, [selectedFileKey, fileList]);

    useEffect(() => {
        if (!visible) {
            cancelEditMode();
        }
    }, [visible]);

    const cancelEditMode = () => {
        setSelectedFileKey('');
    };

    const currentMetadata = useMemo(
        () => (selectedFile ? getMetadataFromTableItem(selectedFile) : ({} as FileDropMetadata)),
        [selectedFile, select]
    );

    const successNotification = () => {
        enqueueSnackbar(`${selectedFile.name}: ${t('fileDrop.success.fileMetadataUpdated')}`, {
            variant: 'success',
            preventDuplicate: true,
        });
        cancelEditMode();
    };

    const errorNotification = () => {
        enqueueSnackbar(`${selectedFile.name}: ${t('fileDrop.error.edit')}`, {
            variant: 'warning',
            preventDuplicate: true,
        });
        cancelEditMode();
    };

    const onSave = useCallback(() => {
        if (filesDocumentIntegrationEnabled) {
            const isFileContextChanged = selectedFile && checkFileContextChanges(selectedFile, newFileList);
            const isFileRevisionChanged = selectedFile && checkFileRevisionChanges(selectedFile, newFileList);
            if (isFileRevisionChanged && !isFileContextChanged) {
                const updateRevisionMetadata = getRevisionMetadataFromSelectedFile(selectedFile);
                dispatch(
                    filesActions.updateRevisionMetadataRequested({
                        updateRevisionMetadata,
                        successNotification,
                        errorNotification,
                    })
                );
            }
            if (isFileContextChanged) {
                const updateContextMetadata = getContextMetadataFromSelectedFile(selectedFile);
                const updateRevisionMetadata = isFileRevisionChanged
                    ? getRevisionMetadataFromSelectedFile(selectedFile)
                    : null;
                dispatch(
                    filesActions.updateContextMetadataRequested({
                        updateContextMetadata,
                        updateRevisionMetadata,
                        successNotification,
                        errorNotification,
                    })
                );
            }
            if (hasFolderAssignmentChanged()) {
                const contextAssignment = {
                    contextOid: currentMetadata.contextOid,
                    folderOids: assignedFilesToFolders[currentMetadata.contextOid],
                };
                dispatch(
                    folderActions.updateFolderEntriesRequested({
                        structureOid: folders.oid,
                        contextAssignment,
                        successNotification,
                        errorNotification,
                    })
                );
                dispatch(filesActions.clearAssignedFilesToFolders());
            }
            if (hasNotificationSettingsChanges) {
                dispatch(
                    changesActions.updateChange(
                        'context',
                        currentMetadata.revisionOid,
                        notificationSettings[currentMetadata.revisionOid],
                        successNotification,
                        errorNotification
                    )
                );
                dispatch(filesActions.clearHasNotificationSettingsChanges());
            }
            cancelEditMode();
        } else {
            const apiMetadata = getApiMetadataFromFile(selectedFile);
            dispatch(
                fileDropActions.updateFileMetadata({
                    fileId: selectedFile.key,
                    metadata: apiMetadata,
                    callback: () => {
                        enqueueSnackbar(`${selectedFile.name}: ${t('fileDrop.success.fileMetadataUpdated')}`, {
                            variant: 'success',
                        });
                        cancelEditMode();
                    },
                })
            );
        }
    }, [dispatch, selectedFile, assignedFilesToFolders, hasNotificationSettingsChanges, notificationSettings]);

    const hasFolderAssignmentChanged = useCallback(() => {
        const originalAssignedFolders = FolderStructureUtils.getAssignedFolders(
            currentMetadata.contextOid,
            folders?.root?.children
        );
        const currentAssignedFolders = assignedFilesToFolders[currentMetadata.contextOid];
        return !isEqual(originalAssignedFolders, currentAssignedFolders);
    }, [currentMetadata.contextOid, folders, assignedFilesToFolders]);

    const onDrop = (acceptedFiles: File[]) => {
        const file = acceptedFiles[0];
        if (validateFileDrop({ enqueueSnackbar, file, t, tenantSettings })) {
            setDroppedFile(file);
        } else {
            setUpdatedFile(null);
            setDroppedFile(null);
        }
    };

    useEffect(() => {
        if (droppedFile && updatedFile) {
            const fileKey = uuid.v4();
            setUpdatedFile({ ...updatedFile, key: fileKey, file: droppedFile, type: droppedFile.type as FileTypeEnum });
            setDroppedFile(null);
        }
    }, [droppedFile, updatedFile]);

    const getFileType = (): { [key: string]: string[] } => {
        return currentMetadata.fileType ? { [currentMetadata.fileType]: [] } : null;
    };

    const { open, getInputProps } = useDropzone({
        onDrop,
        multiple: false,
        onFileDialogCancel: () => setUpdatedFile(null),
        accept: getFileType(),
    });

    const openRenameModal = () => {
        setRenameFileDialogOpen(true);
    };

    const renameSelectedFile = (newFileName: string) => {
        const apiMetadata = getApiMetadataFromFile(selectedFile);
        dispatch(
            fileDropActions.updateFileMetadata({
                fileId: selectedFile.key,
                metadata: {
                    ...apiMetadata,
                    fileName: newFileName,
                },
                callback: () => {
                    enqueueSnackbar(`${newFileName}: ${t('fileDrop.success.fileMetadataUpdated')}`, {
                        variant: 'success',
                    });
                    cancelEditMode();
                    setRenameFileDialogOpen(false);
                },
            })
        );
    };

    const updateFile = () => {
        const startDate = dayjs.unix(selectedFile.startDate);
        const endDate = selectedFile.endDate && dayjs.unix(selectedFile.endDate);
        const diffBetweenStartAndEndDate = endDate && Math.ceil(endDate.diff(startDate, 'day', true));
        const newEndDate = endDate
            ? DEFAULT_START_DATE.add(diffBetweenStartAndEndDate, 'day').startOf('day').utc(true).unix()
            : null;
        setUpdatedFile({
            ...selectedFile,
            startDate: DEFAULT_START_DATE.startOf('day').add(1, 'second').utc(true).unix(),
            endDate: newEndDate,
            file: null,
            key: null,
            type: null,
            fileIdToUpdate: selectedFile.key,
        });
        open();
    };

    const createNewRevision = () => {
        if (!selectedFile.isDurationOpen) {
            setNewRevisionNotAllowedDialogOpen(true);
        } else {
            const newStartDate = dayjs.unix(selectedFile.startDate).isSameOrAfter(dayjs(), 'day')
                ? dayjs.unix(selectedFile.startDate).add(1, 'day').unix()
                : dayjs().utc(true).unix();
            const endDate = selectedFile.endDate < OFFSET_DATE_TIME_MAX && dayjs.unix(selectedFile.endDate);
            const diffBetweenStartAndEndDate = endDate && Math.ceil(endDate.diff(newStartDate, 'day', true));
            const newEndDate = endDate
                ? DEFAULT_START_DATE.add(diffBetweenStartAndEndDate, 'day').startOf('day').utc(true).unix()
                : OFFSET_DATE_TIME_MAX;
            setUpdatedFile({
                ...selectedFile,
                startDate: newStartDate,
                endDate: newEndDate,
                file: null,
                key: null,
                type: null,
                fileIdToUpdate: selectedFile.key,
            });
            open();
        }
    };

    const kebabMenuProps: KebabMenuProps = {
        editMetadata: () => setEditMode(true),
        remove: () => setDeleteFileDialogOpen(true),
        rename: filesDocumentIntegrationEnabled ? null : () => openRenameModal(),
        updateFile: filesDocumentIntegrationEnabled ? createNewRevision : updateFile,
        getInputProps,
    };

    const noDataComponent = () => {
        return isLoadingFiles ? (
            <div className="filedrop-spinner-wrapper">
                <Spinner />
            </div>
        ) : (
            <NoItems className="file-drop-no-files-selected" message={t('fileDrop.filesNotUploaded')} />
        );
    };

    const handleDeleteFile = () => {
        if (filesDocumentIntegrationEnabled) {
            dispatch(
                filesActions.deleteFileRequested({
                    fileContextOid: selectedFileKey,
                    successNotification: () => {
                        enqueueSnackbar(`${selectedFile.name}: ${t('fileDrop.success.delete')}`, {
                            variant: 'success',
                            preventDuplicate: true,
                        });
                    },
                    errorNotification: () => {
                        enqueueSnackbar(`${selectedFile.name}: ${t('fileDrop.error.delete')}`, {
                            variant: 'warning',
                            preventDuplicate: true,
                        });
                    },
                })
            );
        } else {
            dispatch(fileDropActions.deleteFile({ fileId: selectedFileKey }));
        }
        setDeleteFileDialogOpen(false);
    };

    return (
        visible && (
            <div className="file-drop-body">
                <WidgetCard className="file-drop-table-card">
                    <FileDropTable
                        fileList={fileList}
                        selectedFileKeys={[selectedFileKey]}
                        setSelectedFileKeys={(keys) => {
                            setSelectedFileKey(keys[0]);
                        }}
                        disableMultiSelect
                        kebabMenuProps={kebabMenuProps}
                        withUploadDate={true}
                        className="library-table"
                        noDataComponent={noDataComponent()}
                    />
                </WidgetCard>
                <WidgetCard key={'panel-' + selectedFile?.key} className="file-drop-metadata-card">
                    <MetadataTabs
                        type="library"
                        currentMetadata={currentMetadata}
                        selectedFileKeys={
                            selectedFileKey && Object.keys(currentMetadata).length ? [selectedFileKey] : []
                        }
                        setMetadata={setSelectedFileMetadata}
                        onPublish={onSave}
                        disabled={!editMode}
                        cancelEditMode={cancelEditMode}
                    />
                </WidgetCard>
                <RenameFileDialog
                    open={renameFileDialogOpen}
                    onClose={() => setRenameFileDialogOpen(false)}
                    onOk={renameSelectedFile}
                    fileName={selectedFile?.name}
                />
                <DeleteFileDialog
                    open={deleteFileDialogOpen}
                    onClose={() => setDeleteFileDialogOpen(false)}
                    onDelete={handleDeleteFile}
                    fileName={selectedFile?.name}
                />
                <NewRevisionNotAllowedDialog
                    open={newRevisionNotAllowedDialogOpen}
                    onClose={() => setNewRevisionNotAllowedDialogOpen(false)}
                    fileName={selectedFile?.name}
                />
            </div>
        )
    );
};
