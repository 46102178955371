import { IApiTagDomain, IApiTagTreeNode, TagDomain, TagTreeNode } from '@yonder-mind/ui-core';

const mapToTagTreeNode = (apiTagTreeNode: IApiTagTreeNode, parentOid?: string): TagTreeNode => {
    const tagTreeNode: TagTreeNode = {
        oid: apiTagTreeNode.oid,
        names: apiTagTreeNode.names,
        parentOid,
        children: apiTagTreeNode.children.map((child) => mapToTagTreeNode(child, apiTagTreeNode.oid)),
    };
    return tagTreeNode;
};

export const mapToTagDomain = (apiTagDomain: IApiTagDomain): TagDomain => {
    const tagDomain: TagDomain = {
        oid: apiTagDomain.oid,
        names: apiTagDomain.names,
        icon: apiTagDomain.filterIcon,
        hideInContent: apiTagDomain.hideInContent,
        hideInFilters: apiTagDomain.hideInFilters,
        showInFileDrop: apiTagDomain.showInFileDrop,
        children: apiTagDomain.children.map((child) => mapToTagTreeNode(child)),
    };
    return tagDomain;
};
