import * as React from 'react';
import { NotificationState } from '../../../../enums';
import { NotificationStatusButton } from './NotificationStatusButton';

interface IProps {
    notificationStatus: NotificationState;
    changeNotificationStatus: (status: NotificationState) => any;
}

export const DiscussionHeader: React.FC<IProps> = ({ ...notficationStatusButtonProps }) => (
    <div className="discussion-header">
        <NotificationStatusButton className="discussion-notification-state" {...notficationStatusButtonProps} />
    </div>
);
