import { useTranslation } from 'react-i18next';
import { Box, Button, Typography, Chip } from '@material-ui/core';
import { DateUtils, FormSubmissionType, ManageableFormSubmission } from '@yonder-mind/ui-core';

interface ManageSubmissionHeaderProps {
    formSubmission: ManageableFormSubmission;
    formSubmissionType: FormSubmissionType;
    onStatusChange: (statusOid: string) => void;
}

export const ManageSubmissionHeader: React.FC<ManageSubmissionHeaderProps> = ({
    formSubmission,
    formSubmissionType,
    onStatusChange,
}) => {
    const { t } = useTranslation();

    const getCurrentStatus = () => {
        return formSubmissionType.statuses.find((status) => status.oid === formSubmission.statusOid);
    };

    return (
        <Box data-testid="manage-submission-header" component="div">
            <Box component="div" mb={2} display="flex" justifyContent="space-between" alignItems="center">
                <Box component="div" className="manageable-submission__header">
                    <Typography data-testid={'manage-submission-title'} variant="h5" className="title">
                        {formSubmission.summary}
                    </Typography>
                    <p data-testid="manage-submission-date" className="details">
                        {t('form.badge.details', {
                            date: DateUtils.ISOToFormattedDateWithoutTime(formSubmission.submittedAt) || '',
                            user: formSubmission.userId || '',
                        })}
                    </p>
                </Box>
                <Box
                    className="submission-status"
                    component="div"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Chip
                        data-testid="manage-submission-status"
                        className={`submission-badge color-${getCurrentStatus().color}`}
                        label={getCurrentStatus().name?.toUpperCase()}
                        size="medium"
                        variant={'outlined'}
                    />
                </Box>
            </Box>
            <Box component="div" justifyContent="stretch" alignItems="center">
                <Box component="div">
                    <div className={'manageable-submission__header__status-buttons'}>
                        {formSubmissionType?.statuses
                            .filter((status) => status.oid !== formSubmission.statusOid)
                            .map((status) => {
                                return (
                                    <Button
                                        data-testid={`manage-submission-status-${status.oid}`}
                                        key={status.oid}
                                        size="small"
                                        variant="contained"
                                        onClick={() => onStatusChange(status.oid)}
                                    >
                                        {status.name}
                                    </Button>
                                );
                            })}
                    </div>
                </Box>
            </Box>
        </Box>
    );
};
