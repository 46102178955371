import { Rest, FormSubmissionType, FormSubmission, FormApi, ManageableFormSubmission } from '@yonder-mind/ui-core';
import { FormConstants } from './FormConstants';

export class FormRestApi implements FormApi {
    async getFormSubmissionType(formSubmissionTypeOid: string): Promise<FormSubmissionType> {
        const response = await Rest.getRequest(FormConstants.getFormSubmissionType(formSubmissionTypeOid).toString());
        return response.json();
    }

    async getAllFormSubmissionTypes(): Promise<FormSubmissionType[]> {
        const response = await Rest.getRequest(FormConstants.getAllFormSubmissionTypes().toString());
        return response.json();
    }

    async getAllFormSubmissions(): Promise<FormSubmission[]> {
        const response = await Rest.getRequest(FormConstants.getAllFormSubmissions().toString());
        return response.json();
    }

    async getManageableFormSubmissions(): Promise<ManageableFormSubmission[]> {
        const response = await Rest.getRequest(FormConstants.getManageableFormSubmissions().toString());
        return response.json();
    }

    async getManageableFormSubmission(submissionOid: string): Promise<ManageableFormSubmission> {
        const response = await Rest.getRequest(FormConstants.getManageableFormSubmission(submissionOid).toString());
        return response.json();
    }

    async updateManageableFormSubmissionStatus(
        submissionOid: string,
        newStatusOid: string
    ): Promise<ManageableFormSubmission> {
        const response = await Rest.postRequest(
            FormConstants.updateManageableFormSubmissionStatus(submissionOid, newStatusOid).toString()
        );
        return response.json();
    }

    async getFormSubmissions(formVersionOid: string): Promise<FormSubmission[]> {
        const response = await Rest.getRequest(FormConstants.getFormSubmissions(formVersionOid).toString());
        return response.json();
    }

    async getFormSubmission(formSubmissionOid: string): Promise<FormSubmission> {
        const response = await Rest.getRequest(FormConstants.getFormSubmission(formSubmissionOid).toString());
        return response.json();
    }

    async saveFormSubmission(formSubmission: FormSubmission): Promise<FormSubmission> {
        const response = await Rest.postRequest(FormConstants.saveFormSubmission().toString(), formSubmission);
        return response.json();
    }

    async submitFormSubmission(formSubmission: FormSubmission): Promise<FormSubmission> {
        const response = await Rest.postRequest(
            FormConstants.submitFormSubmission(formSubmission.oid).toString(),
            formSubmission
        );
        return response.json();
    }

    async updateFormSubmission(formSubmission: FormSubmission): Promise<FormSubmission> {
        const response = await Rest.putRequest(
            FormConstants.updateFormSubmission(formSubmission.oid).toString(),
            formSubmission
        );
        return response.json();
    }
}
