import { createAction } from 'typesafe-actions';
import {
    IWorkflowActivity,
    IWorkflowFormField,
    IWorkflowHistoricActivity,
    IWorkflowRevisionHistoric,
} from '@yonder-mind/ui-core';
import { IApiError } from '../../../interfaces';

// All revisions

export const revisionsByContextOidRequested = createAction(
    'REQUEST REVISIONS BY CONTEXT OID',
    (contextId: string, documentId?: string) => ({ contextId, documentId })
)();
export const revisionsByContextOidReceived = createAction(
    'REVISIONS BY CONTEXT OID RECEIVED',
    (contextOid: string, workflowActivity: IWorkflowActivity[]) => ({ contextOid, workflowActivity })
)();

// All revisions of a particular document

export const revisionHistoryOfDocumentRequested = createAction(
    'REQUEST REVISION HISTORY OF DOCUMENT',
    (contextVersionOid: string) => contextVersionOid
)();
export const revisionHistoryOfDocumentReceived = createAction(
    'REVISION HISTORY OF DOCUMENT RECEIVED',
    (contextVersionOid: string, history: IWorkflowHistoricActivity[]) => ({ contextVersionOid, history })
)();

export const revisionHistoricRequested = createAction(
    'REQUEST REVISION HISTORIC',
    (documentId: string, state?: 'FINISHED' | 'ALL' | 'UNFINISHED') => ({ documentId, state })
)();
export const revisionHistoricReceived = createAction(
    'REVISION HISTORIC RECEIVED',
    (documentId: string, historic: IWorkflowRevisionHistoric) => ({ documentId, historic })
)();

// Form fields to start a new revision

export const newRevisionFormFieldsRequested = createAction(
    'REQUEST START NEW REVISION FORM FIELDS',
    (contextType: string, contextSubType: string) => ({
        contextType,
        contextSubType,
    })
)();

export const newRevisionFormFieldsReceived = createAction(
    'START NEW REVISION FORM FIELD RECEIVED',
    (fields: IWorkflowFormField[]) => fields
)();

// Form fields to start a new revision

export const startNewRevisionRequested = createAction(
    'REQUEST START NEW REVISION',
    (contextType: string, data: any) => ({
        contextType,
        data,
    })
)();

export const startNewRevisionConfirmationReceived = createAction(
    'START NEW REVISION CONFIRMATION RECEIVED',
    (revision: IWorkflowActivity[]) => revision
)();

// Received new revision

export const receivedNewRevision = createAction(
    'NEW REVISION RECEIVED',
    (contextOid: string, revision: IWorkflowActivity) => ({
        contextOid,
        revision,
    })
)();

// Update revision

export const revisionUpdatesReceived = createAction(
    'REVISION UPDATE RECEIVED',
    (contextOid: string, processInstanceId: string, activity: IWorkflowActivity | null) => ({
        contextOid,
        activity,
        processInstanceId,
    })
)();
export const apiErrorReceived = createAction('API ERROR RECEIVED', (error: IApiError) => ({ error }))();
export const apiErrorReset = createAction('API ERROR RESET', (errors: IApiError[]) => ({ errors }))();
