import React, { useMemo, useState } from 'react';
import { Table, TableContainer } from '@material-ui/core';
import { DocumentsTableBody } from './DocumentsTableBody';
import { DocumentsTableHead, SortingConfiguration } from './DocumentsTableHead';
import { ComplianceTableItem } from '../domain/types';
import { NoItems } from '../../../components';
import { useTranslation } from 'react-i18next';
import { SearchBar, Spinner } from '@yonder-mind/ui-core';
import isEqual from 'lodash/isEqual';
import { initialFilterConfig, useSelectorUiWeb } from '../../../store';

interface IProps {
    documentsList: ComplianceTableItem[];
    isLoading: boolean;
    printRef: React.MutableRefObject<any>;
}

export const DocumentsTable: React.FC<IProps> = (props) => {
    const [searchTerm, setSearchTerm] = useState('');

    const { t } = useTranslation();

    const { documentsList, isLoading, printRef } = props;
    const [sortingConfiguration, setSortingConfiguration] = useState<SortingConfiguration>({
        order: 'asc',
        orderBy: 'name',
    });

    const hasFiltersApplied = useSelectorUiWeb(
        (state) =>
            !isEqual(
                { ...state.complianceReports.appliedFilterConfig, status: '', date: '' },
                { ...initialFilterConfig, status: '', date: '' }
            )
    );

    const filteredDocumentsList = useMemo(
        () =>
            documentsList.filter(
                (doc) =>
                    doc.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    doc.revision.toLowerCase().includes(searchTerm.toLowerCase())
            ),
        [documentsList, searchTerm]
    );

    const onSearch = (searchValue: string) => {
        setSearchTerm(searchValue);
    };

    const onSearchCleared = () => {
        setSearchTerm('');
    };

    return (
        <div className="compliance-table-wrapper">
            {isLoading ? (
                <div className="compliance-filter-no-items-wrapper">
                    <div className="compliance-no-filter-applied">
                        <Spinner />
                    </div>
                </div>
            ) : documentsList.length ? (
                <>
                    <SearchBar
                        className={`item-selector-search-bar ${filteredDocumentsList.length === 0 ? 'error' : ''}`}
                        onSearchRequested={onSearch}
                        onSearchCleared={onSearchCleared}
                        onInputChanged={onSearch}
                        placeholder={t('complianceReports.documentsTable.searchPlaceholder')}
                    />
                    {filteredDocumentsList.length === 0 ? (
                        <div className="compliance-filter-no-items-wrapper">
                            <p>{t('complianceReports.noResults')}</p>
                        </div>
                    ) : (
                        <TableContainer ref={printRef} className="compliance-documents-table-container print-container">
                            <Table size="small" stickyHeader className="compliance-documents-table">
                                <DocumentsTableHead
                                    sortingConfiguration={sortingConfiguration}
                                    setSortingConfiguration={setSortingConfiguration}
                                />
                                <DocumentsTableBody
                                    documentsList={filteredDocumentsList}
                                    sortingConfiguration={sortingConfiguration}
                                />
                            </Table>
                        </TableContainer>
                    )}
                </>
            ) : (
                <div className="compliance-filter-no-items-wrapper">
                    <NoItems
                        className="compliance-no-filter-applied"
                        message={
                            hasFiltersApplied
                                ? t('complianceReports.noResults')
                                : t('complianceReports.noFilterSelected')
                        }
                    />
                </div>
            )}
        </div>
    );
};
