import { ActionType, getType } from 'typesafe-actions';
import { IPdfExportStore } from '../../interfaces';
import { pdfExportActions as actions } from './index';

export type PdfExportActions = ActionType<typeof actions>;

const initialState: IPdfExportStore = {
    changeRequests: [],
    revisions: [],
    hasError: false,
    isLoadingChangeRequests: false,
    isLoadingRevisions: false,
};

export default function pdfExportReducer(
    state: IPdfExportStore = initialState,
    action: PdfExportActions
): IPdfExportStore {
    switch (action.type) {
        case getType(actions.requestChangeRequestsForDocumentVersion):
            return {
                ...state,
                isLoadingChangeRequests: true,
            };
        case getType(actions.changeRequestsForDocumentVersionReceived):
            return {
                ...state,
                isLoadingChangeRequests: false,
                changeRequests: action.payload.content,
            };
        case getType(actions.requestRevisions):
            return {
                ...state,
                isLoadingRevisions: true,
            };
        case getType(actions.revisionsReceived):
            return {
                ...state,
                isLoadingRevisions: false,
                revisions: action.payload.content,
            };
        case getType(actions.error):
            return {
                ...state,
                hasError: true,
                isLoadingRevisions: false,
                isLoadingChangeRequests: false,
            };
        default:
            return state;
    }
}
