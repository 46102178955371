import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SnackbarKey, useSnackbar } from 'notistack';
import isEmpty from 'lodash/isEmpty';
import { formActions, FormSubmissionTableItem, SnackBarCloseButton, Spinner, useHistory } from '@yonder-mind/ui-core';
import { IWebApplicationStore } from '../../interfaces';
import { SubmissionsTable } from '../../components/Form/SubmissionsTable';
import { mapFormSubmissionList } from '../../components/Form/utils/FormUtils';

const managerColumnsToShow = ['status', 'submittedAt', 'submittedBy', 'formVersionTitle', 'summary'];

export const ManageSubmissionsTableBoard: React.FC = () => {
    const [formSubmissionList, setFormSubmissionList] = useState<FormSubmissionTableItem[]>([]);
    const dispatch = useDispatch();
    const { pushUrl } = useHistory();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        dispatch(
            formActions.manageableFormSubmissionsRequested(() =>
                enqueueSnackbar(t('form.messages.error.generalError'), {
                    action: (key: SnackbarKey) => <SnackBarCloseButton snackbarKey={key} />,
                    variant: 'error',
                    persist: true,
                    preventDuplicate: true,
                })
            )
        );
        dispatch(
            formActions.allFormSubmissionTypesRequested(() =>
                enqueueSnackbar(t('form.messages.error.generalError'), {
                    action: (key: SnackbarKey) => <SnackBarCloseButton snackbarKey={key} />,
                    variant: 'error',
                    persist: true,
                    preventDuplicate: true,
                })
            )
        );
    }, []);

    const { manageableFormSubmissions, isLoading, formSubmissionTypes } = useSelector((state: IWebApplicationStore) => {
        return {
            ...state.form,
        };
    });

    const openSubmission = (oid: string, formVersionOid: string) => {
        pushUrl(`/manage-submission/${formVersionOid}/${oid}`, [{ key: 'sidebar-tool', value: 'form-navigation' }]);
    };

    useEffect(() => {
        if (manageableFormSubmissions && !isEmpty(formSubmissionTypes)) {
            setFormSubmissionList(mapFormSubmissionList(manageableFormSubmissions, formSubmissionTypes));
        }
    }, [manageableFormSubmissions, formSubmissionTypes]);

    return (
        <>
            <div className={`manage-submissions`}>
                <div className="manage-submissions-wrapper">
                    <div className="manage-submissions-wrapper-content">
                        <div className="submissions-table-wrapper">
                            {isLoading ? (
                                <div className="submissions-filter-no-items-wrapper">
                                    <div className="submissions-no-filter-applied">
                                        <Spinner />
                                    </div>
                                </div>
                            ) : (
                                <>
                                    {formSubmissionList.length === 0 ? (
                                        <div className="submissions-filter-no-items-wrapper">
                                            <p>{t('form.submissionTable.noResults')}</p>
                                        </div>
                                    ) : (
                                        <SubmissionsTable
                                            formSubmissionsList={formSubmissionList}
                                            columnsToShow={managerColumnsToShow}
                                            openSubmission={openSubmission}
                                        />
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
