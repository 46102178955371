// Workaround introduced to fix the issue of editor height not adjusting when the
// Froala toolbar size changes. Froala using z-index in the toolbar which is
// preventing us from using flex and flex-grow to adjust the height of the editor

export const adjustEditorHeight = () => {
    const toolbarHeight = window.document.querySelector('.fr-toolbar')?.clientHeight;
    const wrapper = window.document.querySelector('.fr-wrapper') as HTMLElement;
    if (toolbarHeight) {
        wrapper.style.height = `calc(100% - ${toolbarHeight}px`;
    }
};

export const adjustEditorHeightOnResizeListenerHandler = () => {
    window.addEventListener('resize', () => {
        adjustEditorHeight();
    });
    return () => {
        window.removeEventListener('resize', () => {
            adjustEditorHeight();
        });
    };
};
