import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    HistoryLogElement,
    HistoryLogResponse,
    HistoryLogsFetchRequestProperties,
} from '../../interfaces/historyLog/historyLogApi';

export interface HistoryLogStore {
    historyLogElements: HistoryLogElement[];
    isLoading: boolean;
    isExporting: boolean;
    currentPage: number;
    pageSize: number;
    totalCount: number;
}

const initialState: HistoryLogStore = {
    historyLogElements: [],
    isLoading: false,
    isExporting: false,
    currentPage: 0,
    pageSize: 1000,
    totalCount: 0,
};

const historyLogSlice = createSlice({
    name: 'historyLog',
    initialState,
    reducers: {
        setCurrentPage(state, action: PayloadAction<number>) {
            state.currentPage = action.payload;
        },
        historyLogsRequested(state, _action: PayloadAction<HistoryLogsFetchRequestProperties>) {
            state.isLoading = true;
        },
        historyLogsReceived(state, action: PayloadAction<HistoryLogResponse>) {
            state.historyLogElements = [...state.historyLogElements, ...action.payload.elements];
            state.currentPage = action.payload.page;
            state.pageSize = action.payload.pageSize;
            state.totalCount = action.payload.totalCount;
            state.isLoading = false;
        },
        clearHistoryLogs(state) {
            state.historyLogElements = initialState.historyLogElements;
            state.isLoading = initialState.isLoading;
            state.currentPage = initialState.currentPage;
            state.pageSize = initialState.pageSize;
            state.totalCount = initialState.totalCount;
        },
        exportHistoryLogsRequested(state, _action: PayloadAction<HistoryLogsFetchRequestProperties>) {
            state.isExporting = true;
        },
        exportHistoryLogsReceived(state) {
            state.isExporting = false;
        },
    },
});

export const historyLogActions = historyLogSlice.actions;
export default historyLogSlice.reducer;
