import React, { useState } from 'react';
import { InputLabel, MenuItem, Select } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

interface SelectElementProps {
    id: string;
    label: string;
    selectedOid: string;
    handleChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
    options: { name: string; oid: string }[];
    fullwidth?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        select: {
            margin: theme.spacing(1, 0, 0),
            width: '100%',
        },
    })
);

export const SelectElement: React.FC<SelectElementProps> = ({
    id,
    label,
    selectedOid,
    handleChange,
    options,
    fullwidth,
}) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    return (
        <>
            <InputLabel id={id}>{label}</InputLabel>
            <Select
                className={classes.select}
                labelId={id}
                data-testid={id}
                open={open}
                fullWidth={fullwidth}
                variant="outlined"
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                value={selectedOid}
                onChange={handleChange}
            >
                {options.map(({ name, oid }) => {
                    return [
                        <MenuItem key={oid} value={oid}>
                            {name}
                        </MenuItem>,
                    ];
                })}
            </Select>
        </>
    );
};
