import React from 'react';
import { ListChildComponentProps } from 'react-window';
import { TableCell, TableRow } from '@material-ui/core';
import { HighlyPerformantTableColumn, ListItemData } from './HighlyPerformantTableInterfaces';
import { HIGHLY_PERFORMANT_ROW_HEIGHT } from './HighlyPerformantTable';

export const HighlyPerformantTableRow: React.FC<ListChildComponentProps<ListItemData<unknown>>> = ({
    index,
    style,
    data: { columns, items, classes },
}) => {
    const item = items[index];

    const getStyle = (column: HighlyPerformantTableColumn) => ({
        flexBasis: column.width || 0,
        height: HIGHLY_PERFORMANT_ROW_HEIGHT,
        ...(column.width && { width: column.width }),
    });

    return (
        <TableRow style={style} key={index} component="div" className={classes.row}>
            {columns.map((column, colIndex) => {
                return (
                    <TableCell
                        className={`highly-performant-table-cell ${classes.cell}${
                            column.width ? '' : ` highly-performant-table-cell-expanded ${classes.expandingCell}`
                        }`}
                        key={`${index}${colIndex}`}
                        variant="body"
                        component="div"
                        align={'left'}
                        style={getStyle(column)}
                    >
                        <span className={classes.cellSpan}>{item[column.dataKey]}</span>
                    </TableCell>
                );
            })}
        </TableRow>
    );
};
