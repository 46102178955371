// @ts-nocheck
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { isDebug } from './constants';
import { i18nDefaultLanguage, i18nResources } from './languages';

export const initI18next = () => {
    return i18next.use(initReactI18next).init({
        debug: isDebug(),
        defaultNS: 'yonderMind',
        fallbackLng: i18nDefaultLanguage,
        interpolation: {
            escapeValue: false, // not needed for react!!
        },
        ns: ['yonderMind'],
        react: {
            useSuspense: true,
        },
        resources: i18nResources,
    });
};

export const i18n = i18next;
