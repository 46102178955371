import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import {
    DashboardCard,
    filesActions,
    FileTypeEnum,
    IApiContextVersionMetadata,
    type IDocumentNotification,
    IQueryParam,
    NotificationListIcon,
    notificationsActions,
    NotificationsCardSecondaryHeader,
    NotificationType,
    useHistory,
    YonderList,
} from '@yonder-mind/ui-core';
import { IWebApplicationStore } from '../../../interfaces';

export const NotificationsCard = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { pushUrl } = useHistory();

    const notificationsData = useSelector((state: IWebApplicationStore) => state.notifications.notifications);
    const hasErrorNotifications = useSelector((state: IWebApplicationStore) => state.notifications.hasError);
    const isLoadingNotifications = useSelector((state: IWebApplicationStore) => state.notifications.isLoading);
    const contextVersionMetadata = useSelector(
        (state: IWebApplicationStore) => state.docs.currentContextVersionsMetadata
    );

    const getContextOid = (notification: IDocumentNotification) => {
        return getContextVersion(notification)?.contextOid || '';
    };

    const getContextVersion = (notification: IDocumentNotification): IApiContextVersionMetadata | null => {
        return (
            contextVersionMetadata.find(
                (contextVersion: IApiContextVersionMetadata) => contextVersion.oid === notification.contextVersionOid
            ) || null
        );
    };

    useEffect(() => {
        dispatch(notificationsActions.notificationsRequested());
    }, []);

    const handleOpenNotification = (notification: IDocumentNotification) => {
        if (
            notification.notificationType === NotificationType.NEW_FILE ||
            notification.notificationType === NotificationType.NEW_FILE_REVISION
        ) {
            openFileNotification(notification);
        } else {
            openNotification(notification);
        }
    };

    const openNotification = (notification: IDocumentNotification) => {
        pushUrl(`/doc/${notification.contextVersionOid}`, [
            {
                key: IQueryParam.SIDEBAR_TOOL,
                value: 'revision',
            },
            {
                key: IQueryParam.SIDEBAR_TOOL_TAB,
                value: 'revision.tasks',
            },
        ]);
    };

    const openFileNotification = (notification: IDocumentNotification) => {
        const contextOid = getContextOid(notification);
        if (!notification.contextVersionOid) return;
        if (getContextVersion(notification)?.fileMetadata?.mimeType === FileTypeEnum.html) {
            pushUrl(`/memo/${contextOid}`, [
                {
                    key: IQueryParam.NOTIFICATION_OID,
                    value: notification.oid,
                },
            ]);
        } else {
            const payload = notification.readAt
                ? { contextOid: contextOid }
                : { contextOid: contextOid, notificationOid: notification.oid };
            dispatch(filesActions.fileContentRequested(payload));
        }
        if (!notification.readAt) {
            refreshNotificationsWithDelay();
        }
    };

    const refreshNotificationsWithDelay = () => {
        setTimeout(() => {
            dispatch(notificationsActions.notificationsRequested());
        }, 1000);
    };

    const deleteNotification = (notificationId: string) => {
        dispatch(notificationsActions.deleteNotificationRequested(notificationId));
    };

    const isEffective = (item: IDocumentNotification) => dayjs().unix() - dayjs(item.validFrom).unix() > 0;

    const sortByValidFrom = (a: IDocumentNotification, b: IDocumentNotification) => {
        return dayjs(a.validFrom).unix() - dayjs(b.validFrom).unix();
    };

    const sortNotificationsData = (notifications: IDocumentNotification[]) => {
        const notificationsWithOverdueOpenTasks = notifications
            .filter((notification) => notification.openTasks > 0 && isEffective(notification))
            .sort((a, b) => sortByValidFrom(a, b));
        const notificationsWithOpenTasks = notifications
            .filter((notification) => notification.openTasks > 0 && !isEffective(notification))
            .sort((a, b) => sortByValidFrom(a, b))
            .reverse();
        const notificationsDone = notifications
            .filter((notification) => notification.openTasks === 0)
            .sort((a, b) => sortByValidFrom(a, b))
            .reverse();
        const notificationsWithoutTasks = notifications
            .filter((notification) => !notification.openTasks && notification.openTasks !== 0)
            .sort((a, b) => sortByValidFrom(a, b))
            .reverse();
        const notificationsWithoutTasksUnread = notificationsWithoutTasks.filter(
            (notification) => !notification.readAt
        );
        const notificationsWithoutTasksRead = notificationsWithoutTasks.filter((notification) => notification.readAt);

        return [
            ...notificationsWithOverdueOpenTasks,
            ...notificationsWithOpenTasks,
            ...notificationsWithoutTasksUnread,
            ...notificationsWithoutTasksRead,
            ...notificationsDone,
        ];
    };

    return (
        <DashboardCard
            isLoading={isLoadingNotifications}
            hasError={hasErrorNotifications}
            primaryHeader={t('dashboard.cards.notifications.title')}
            secondaryHeader={
                <NotificationsCardSecondaryHeader
                    title={t('dashboard.cards.notifications.tabs.documents')}
                    counter={notificationsData.length}
                />
            }
            icon={<NotificationListIcon />}
            tabs={[
                {
                    component: (
                        <YonderList
                            useVirtualList
                            variant="notifications"
                            items={sortNotificationsData(notificationsData)}
                            onClick={(notification) => handleOpenNotification(notification)}
                            onDelete={(notificationId) => deleteNotification(notificationId)}
                            dataTestId="dashboardNotificationsCardTabDocsList"
                        />
                    ),
                    dataTestId: 'dashboardNotificationsCardTabDocs',
                    label: t('dashboard.cards.notifications.tabs.documents'),
                },
            ]}
        />
    );
};
