import { DashboardCard, Flashcards, YonderList } from '@yonder-mind/ui-core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IWebApplicationStore } from '../../../interfaces';

export const FlashcardsCard = () => {
    const { t } = useTranslation();
    const [tabVisible] = useState(false);
    const flashcardSets = useSelector((state: IWebApplicationStore) => state.flashcards.flashcardSets);
    const hasErrorFlashcardSets = useSelector((state: IWebApplicationStore) => state.flashcards.hasError);
    const isLoadingFlashcardSets = useSelector((state: IWebApplicationStore) => state.flashcards.isLoading);

    return (
        <DashboardCard
            isLoading={isLoadingFlashcardSets}
            hasError={hasErrorFlashcardSets}
            primaryHeader={t('dashboard.cards.flashcards.title')}
            secondaryHeader={t('dashboard.cards.flashcards.subtitle')}
            icon={<Flashcards size="large" />}
            tabs={[
                {
                    component: (
                        <YonderList
                            useVirtualList
                            variant="flash-card-sets"
                            items={flashcardSets}
                            divider="inset"
                            dataTestId="dashboardFlashcardsCardTabSetsList"
                        />
                    ),
                    dataTestId: 'dashboardFlashcardsCardTabSets',
                    label: t('dashboard.cards.flashcards.tabs.sets'),
                },
                tabVisible
                    ? {
                          component: (
                              <YonderList
                                  useVirtualList
                                  variant="flash-card-sets"
                                  items={[]}
                                  divider="inset"
                                  dataTestId="dashboardFlashcardsCardTabTodayList"
                              />
                          ),
                          dataTestId: 'dashboardFlashcardsCardTabToday',
                          label: t('dashboard.cards.flashcards.tabs.today'),
                      }
                    : null,
                tabVisible
                    ? {
                          component: (
                              <YonderList
                                  useVirtualList
                                  variant="flash-card-sets"
                                  items={[]}
                                  divider="inset"
                                  dataTestId="dashboardFlashcardsCardTabOverdueList"
                              />
                          ),
                          dataTestId: 'dashboardFlashcardsCardTabOverdue',
                          label: t('dashboard.cards.flashcards.tabs.overdue'),
                      }
                    : null,
            ]}
        />
    );
};
