import React from 'react';
import { useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { TextField, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { fileDropActions, filesActions, useSelectorUiCore, YonderButton } from '@yonder-mind/ui-core';
import { FileDropMetadata } from '../../domain/types';
import { validateFileDrop } from '../../utils/useFileDropZone';
import { MetadataDateSelector } from './MetadataDateSelector';
import { MetadataUsersTagsSelector } from './MetadataUsersTagsSelector';
import { OFFSET_DATE_TIME_MAX } from '../../utils/metadataUtils';

export type MetadataPanelType = 'library' | 'import';
interface IProps {
    type: MetadataPanelType;
    selectedFileKeys: string[];
    setMetadata: (metadata: FileDropMetadata) => void;
    currentMetadata: FileDropMetadata;
    setNewValidation: (validation: MetadataValidation) => void;
    disabled?: boolean;
    visible: boolean;
}

export type MetadataValidation = {
    name?: string;
    dateFrom?: string;
    dateTo?: string;
    isEqualToCurrentMetadata?: boolean;
};

export const MetadataPanel: React.FC<IProps> = ({
    selectedFileKeys,
    setMetadata,
    currentMetadata,
    setNewValidation,
    disabled,
    type,
    visible,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const tenantSettings = useSelectorUiCore((state) => state.tenantSettings.tenantSettings);

    const onDrop = (acceptedFiles: File[]) => {
        const file = acceptedFiles[0];
        if (validateFileDrop({ enqueueSnackbar, file, t, tenantSettings })) {
            const formData = new FormData();
            formData.set('file', file);
            if (tenantSettings.filesDocumentIntegrationEnabled) {
                const updateRevisionMetadata = {
                    fileContextOid: currentMetadata.contextOid,
                    fileRevisionOid: currentMetadata.revisionOid,
                    effectiveFrom: currentMetadata.startDate,
                    effectiveTo: currentMetadata.endDate ? currentMetadata.endDate : OFFSET_DATE_TIME_MAX,
                    file: file,
                };
                dispatch(
                    filesActions.updateRevisionMetadataRequested({
                        updateRevisionMetadata,
                        successNotification: displaySuccessMessage,
                        errorNotification: displayErrorMessage,
                    })
                );
            } else {
                dispatch(
                    fileDropActions.replaceFileContent({
                        fileFormData: {
                            file: formData,
                            key: selectedFileKeys[0],
                            fileIdToUpdate: selectedFileKeys[0],
                        },
                        displaySuccessMessage,
                        displayErrorMessage,
                    })
                );
            }
        }
    };

    const displaySuccessMessage = () => {
        enqueueSnackbar(`${t('fileDrop.success.replaceContent')}`, {
            variant: 'success',
        });
    };

    const displayErrorMessage = () => {
        enqueueSnackbar(`${t('fileDrop.error.replaceContent')}`, { variant: 'error' });
    };

    const { open } = useDropzone({
        onDrop,
        multiple: false,
        onFileDialogCancel: () => {},
    });

    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name: string = event.target.value;
        setMetadata({ ...currentMetadata, name });
        setNewValidation({ name: getFileNameErrorMessage(name) });
    };

    const getFileNameErrorMessage = (fileName: string) => {
        if (!fileName?.trim()) return t('fileDrop.metadataPanel.fileName.error');
        return '';
    };

    return (
        visible && (
            <>
                <div className="metadata-validity">
                    {tenantSettings.filesDocumentIntegrationEnabled && (
                        <div>
                            {selectedFileKeys.length <= 1 && (
                                <div>
                                    <Typography variant="h6" className="validity-title">
                                        {t('fileDrop.metadataPanel.fileName.label')}
                                    </Typography>
                                    <div className="metadata-file-name">
                                        <TextField
                                            data-testid="metadata-file-name"
                                            variant="outlined"
                                            disabled={disabled}
                                            placeholder={t('fileDrop.metadataPanel.fileName.placeholder')}
                                            value={currentMetadata?.name || ''}
                                            error={!!getFileNameErrorMessage(currentMetadata.name)}
                                            helperText={getFileNameErrorMessage(currentMetadata.name)}
                                            fullWidth
                                            onChange={onNameChange}
                                            inputProps={{ maxLength: 60 }}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    <Typography variant="h6" className="validity-title">
                        {t('fileDrop.metadataPanel.datepicker.validityRange')}
                    </Typography>
                    <Typography variant="subtitle2" className="validity-description">
                        {t('fileDrop.metadataPanel.datepicker.helpText')}
                    </Typography>
                    <div className="validity-range">
                        <MetadataDateSelector
                            key={selectedFileKeys.toString() + 'date-selector'}
                            disabled={disabled}
                            currentMetadata={currentMetadata}
                            setMetadata={setMetadata}
                            setValidation={setNewValidation}
                            type={type}
                        />
                    </div>
                </div>
                <MetadataUsersTagsSelector
                    disabled={disabled}
                    setMetadata={setMetadata}
                    currentMetadata={currentMetadata}
                />
                <div className="metadata-footer">
                    {!tenantSettings.filesDocumentIntegrationEnabled && !disabled && (
                        <YonderButton onClick={open} variant="outlined">
                            {t('fileDrop.metadataPanel.footer.replaceFile')}
                        </YonderButton>
                    )}
                </div>
            </>
        )
    );
};
