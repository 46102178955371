import * as ReactDOM from 'react-dom';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    AddModule,
    combineStrings,
    EditContent,
    EditTitle,
    IReaderTool,
    IReaderToolTab,
    IWorkflowFormField,
    IWorkflowType,
    RemoveModule,
    SpeedDialButton,
    useReaderTools,
    WorkflowUtils,
    IWorkflowChangeRequestV2,
} from '@yonder-mind/ui-core';
import { useWorkflow } from '../../../../context';

interface IProps {
    changeRequests: Array<IWorkflowChangeRequestV2>;
    setNewCRType: (type: IWorkflowType) => void;
    setNewCRFormFields: (formFields: IWorkflowFormField[] | undefined) => void;
    shouldDisableButton: boolean;
    refetchOnOpen: () => void;
}

export const ChangeRequestButton: React.FC<IProps> = ({
    changeRequests,
    setNewCRType,
    setNewCRFormFields,
    shouldDisableButton,
    refetchOnOpen,
}) => {
    const crWorkflow = useWorkflow('cr');
    const { t } = useTranslation();
    const { activeToolsTab } = useReaderTools();

    const [visible, setVisible] = React.useState(false);

    React.useEffect(() => {
        setVisible(activeToolsTab[IReaderTool.MODULE_VIEW] === IReaderToolTab.MODULE_VIEW__CHANGE_REQUESTS);
    }, [activeToolsTab]);

    const actions = [
        {
            key: IWorkflowType.DELETE_MODULE,
            icon: <RemoveModule />,
            name: t('reader.moduleView.tools.changeRequest.actions.remove'),
        },
        {
            key: IWorkflowType.ADD_MODULE,
            icon: <AddModule />,
            name: t('reader.moduleView.tools.changeRequest.actions.add'),
        },
        {
            key: IWorkflowType.EDIT_TITLE,
            icon: <EditTitle />,
            name: t('reader.moduleView.tools.changeRequest.actions.editTitle'),
        },
        {
            key: IWorkflowType.EDIT_CONTENT,
            icon: <EditContent />,
            name: t('reader.moduleView.tools.changeRequest.actions.editContent'),
        },
    ]
        .filter((action) => crWorkflow.startForm[action.key])
        .map((action) => ({
            ...action,
            disabled:
                action.key !== IWorkflowType.EDIT_CONTENT &&
                action.key !== IWorkflowType.ADD_MODULE &&
                !WorkflowUtils.canStartNewCRV2(action.key, changeRequests),
            action: () => {
                setNewCRType(action.key);
                setNewCRFormFields(crWorkflow.startForm[action.key]);
            },
        }));

    return ReactDOM.createPortal(
        <SpeedDialButton
            className={combineStrings(['change-request--button', visible ? undefined : 'hidden'])}
            actions={actions}
            refetchOnOpen={refetchOnOpen}
            isDisabled={shouldDisableButton}
        />,
        document.getElementById('root')
    );
};
