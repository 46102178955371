// @ts-nocheck
import React from 'react';

import { Text, View } from '@react-pdf/renderer';
import { Table, TableHeader, TableCell, DataTableCell, TableBody } from '@david.kucsai/react-pdf-table';
import { ChangesTableItem } from '../domain/types';
import { pdfStyles } from './styles';
import { useTranslation } from 'react-i18next';
import { getProgressPercentages, getPercentageString } from '../DocumentsTable/ProgressBar';

type PdfChangesTableProps = {
    changes: ChangesTableItem[];
    reportListKey?: { role: string; oid: string };
};

export const PdfChangesTable: React.FC<PdfChangesTableProps> = ({ changes, reportListKey }) => {
    const { t } = useTranslation();

    return (
        <View style={pdfStyles.documentsTable}>
            <Text style={pdfStyles.titleText}>{t('complianceReports.changesTable.heading')}</Text>
            <Table data={changes}>
                <TableHeader>
                    <TableCell weighting={0.3}>
                        <View style={pdfStyles.changesHeaderTableCell}>
                            <Text>{t('complianceReports.changesTable.chapter')}</Text>
                        </View>
                    </TableCell>
                    <TableCell weighting={0.2}>
                        <View style={pdfStyles.changesHeaderTableCell}>
                            <Text>{t('complianceReports.changesTable.title')}</Text>
                        </View>
                    </TableCell>
                    <TableCell weighting={0.15}>
                        <View style={pdfStyles.changesHeaderTableCell}>
                            <Text>{t('complianceReports.changesTable.type')}</Text>
                        </View>
                    </TableCell>
                    <TableCell weighting={0.075}>
                        <View style={pdfStyles.changesHeaderTableCell}>
                            <Text>OK</Text>
                        </View>
                    </TableCell>
                    <TableCell weighting={0.075}>
                        <View style={pdfStyles.changesHeaderTableCell}>
                            <Text>NOK</Text>
                        </View>
                    </TableCell>
                    <TableCell weighting={0.2}>
                        <View style={pdfStyles.changesHeaderTableCell}>
                            <Text>{t('complianceReports.changesTable.assignedRole')}</Text>
                        </View>
                    </TableCell>
                </TableHeader>
                <TableBody>
                    <DataTableCell
                        weighting={0.3}
                        getContent={(r: ChangesTableItem) => {
                            const cellStyle =
                                reportListKey.role === r.role && reportListKey.changeOid === r.changeOid
                                    ? pdfStyles.selectedChangeTableCel
                                    : pdfStyles.tableCell;
                            return (
                                <View style={cellStyle}>
                                    <Text>{r.chapter}</Text>
                                </View>
                            );
                        }}
                    />
                    <DataTableCell
                        weighting={0.2}
                        getContent={(r: ChangesTableItem) => {
                            const cellStyle =
                                reportListKey.role === r.role && reportListKey.changeOid === r.changeOid
                                    ? pdfStyles.selectedChangeTableCel
                                    : pdfStyles.tableCell;
                            return (
                                <View style={cellStyle}>
                                    <Text>{r.title}</Text>
                                </View>
                            );
                        }}
                    />
                    <DataTableCell
                        weighting={0.15}
                        getContent={(r: ChangesTableItem) => {
                            const cellStyle =
                                reportListKey.role === r.role && reportListKey.changeOid === r.changeOid
                                    ? pdfStyles.selectedChangeTableCel
                                    : pdfStyles.tableCell;
                            return (
                                <View style={cellStyle}>
                                    <Text>
                                        {r.ackTotalCount
                                            ? t('complianceReports.documentsTable.readAndAck')
                                            : t('complianceReports.documentsTable.read')}
                                    </Text>
                                </View>
                            );
                        }}
                    />
                    <DataTableCell
                        weighting={0.075}
                        getContent={(r: ChangesTableItem) => {
                            const cellStyle =
                                reportListKey.role === r.role && reportListKey.changeOid === r.changeOid
                                    ? pdfStyles.selectedChangeTableCel
                                    : pdfStyles.tableCell;
                            return (
                                <View style={cellStyle}>
                                    <Text>
                                        {r.ackTotalCount
                                            ? getPercentageString(
                                                  getProgressPercentages(r.ackTotalCount, r.ackDoneCount)
                                                      .positiveBarPercentage
                                              )
                                            : getPercentageString(
                                                  getProgressPercentages(r.readTotalCount, r.readDoneCount)
                                                      .positiveBarPercentage
                                              )}
                                    </Text>
                                </View>
                            );
                        }}
                    />
                    <DataTableCell
                        weighting={0.075}
                        getContent={(r: ChangesTableItem) => {
                            const cellStyle =
                                reportListKey.role === r.role && reportListKey.changeOid === r.changeOid
                                    ? pdfStyles.selectedChangeTableCel
                                    : pdfStyles.tableCell;
                            return (
                                <View style={cellStyle}>
                                    <Text>
                                        {r.ackTotalCount
                                            ? getProgressPercentages(
                                                  r.ackTotalCount,
                                                  r.ackDoneCount
                                              ).negativeBarPercentage.toFixed(0) + '%'
                                            : getProgressPercentages(
                                                  r.readTotalCount,
                                                  r.readDoneCount
                                              ).negativeBarPercentage.toFixed(0) + '%'}
                                    </Text>
                                </View>
                            );
                        }}
                    />
                    <DataTableCell
                        weighting={0.2}
                        getContent={(r: ChangesTableItem) => {
                            const cellStyle =
                                reportListKey.role === r.role && reportListKey.changeOid === r.changeOid
                                    ? pdfStyles.selectedChangeTableCel
                                    : pdfStyles.tableCell;
                            return (
                                <View style={cellStyle}>
                                    <Text>{r.role}</Text>
                                </View>
                            );
                        }}
                    />
                </TableBody>
            </Table>
        </View>
    );
};
