import { createAction } from 'typesafe-actions';
import { IAggregatedWorkflowInformation } from '../../interfaces';

export const REQUEST_CHANGE_REQUESTS_FOR_DOCUMENT_VERSION = 'REQUEST CHANGE REQUESTS FOR DOCUMENT VERSION';
export const requestChangeRequestsForDocumentVersion = createAction(
    REQUEST_CHANGE_REQUESTS_FOR_DOCUMENT_VERSION,
    (documentVersionOid: string) => ({ documentVersionOid })
)();

export const CHANGE_REQUESTS_FOR_DOCUMENT_VERSION_RECEIVED = 'CHANGE REQUESTS FOR DOCUMENT VERSION RECEIVED';
export const changeRequestsForDocumentVersionReceived = createAction(
    CHANGE_REQUESTS_FOR_DOCUMENT_VERSION_RECEIVED,
    (payload: { content: IAggregatedWorkflowInformation[] }) => payload
)();

export const REQUEST_REVISIONS_FOR_DOCUMENT_VERSION = 'REQUEST REVISIONS FOR DOCUMENT VERSION';
export const requestRevisions = createAction(REQUEST_REVISIONS_FOR_DOCUMENT_VERSION, (documentVersionOid: string) => ({
    documentVersionOid,
}))();

export const REVISIONS_RECEIVED = 'REVISIONS FOR DOCUMENT VERSION RECEIVED';
export const revisionsReceived = createAction(
    REVISIONS_RECEIVED,
    (payload: { content: IAggregatedWorkflowInformation[] }) => payload
)();

export const PDF_EXPORT_ERROR = 'PDF EXPORT ERROR';
export const error = createAction(PDF_EXPORT_ERROR, (err: any) => ({ err }))();
