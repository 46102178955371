import React, { useState } from 'react';
import {
    ContainedButton,
    Dropdown,
    OutlinedButton,
    PrimaryButton,
    ResetButton,
    SecondaryButton,
    SubmitButton,
    TextButton,
} from './stories/YonderButton.story';
import { Typography } from '@material-ui/core';

export const YonderButtons: React.FC = () => {
    const [expanded, setExpanded] = useState(false);
    return (
        <div>
            <Typography variant={'h4'} onClick={() => setExpanded(!expanded)}>
                Yonder button variants
            </Typography>

            {expanded && (
                <>
                    <div className={'story'}>
                        <SubmitButton />
                    </div>

                    <div className={'story'}>
                        <ResetButton />
                    </div>

                    <div className={'story'}>
                        <TextButton />
                    </div>

                    <div className={'story'}>
                        <OutlinedButton />
                    </div>

                    <div className={'story'}>
                        <ContainedButton />
                    </div>

                    <div className={'story'}>
                        <PrimaryButton />
                    </div>

                    <div className={'story'}>
                        <SecondaryButton />
                    </div>

                    <div className={'story'}>
                        <Dropdown />
                    </div>
                </>
            )}
        </div>
    );
};
