import { ApiConstants } from './ApiConstants';
import { ApiComplianceReportsFilterConfig } from '../interfaces';

export class ApiComplianceReportsConstants {
    static getAllTagDomainsForComplianceReports(): URL {
        return ApiConstants.relativeUrl('/tag-domains');
    }
    static getAllRolesDomainsForComplianceReports(): URL {
        return ApiConstants.relativeUrl('/roles/can-receive-change-tasks');
    }

    static getFilterDocuments(status: string, effectiveAt?: string): URL {
        const url = ApiConstants.relativeUrl('/compliance-reports/documents-metadata');
        url.searchParams.append('status', status);
        effectiveAt && url.searchParams.append('effectiveAt', effectiveAt);
        return url;
    }

    static getComplianceReportsDocuments(config: ApiComplianceReportsFilterConfig): URL {
        const url = ApiConstants.relativeUrl('/compliance-reports/documents');
        url.searchParams.append('contextVersionOids', config.selectedDocumentIds.toString());
        url.searchParams.append('roles', config.selectedRoleIds.toString());
        url.searchParams.append('tagOids', config.selectedTagIds.toString());
        url.searchParams.append('users', config.selectedUserIds.toString());
        url.searchParams.append('status', config.status);
        config.date && url.searchParams.append('date', config.date);
        return url;
    }

    static getComplianceReportsChanges(contextVersionOid: string, config: ApiComplianceReportsFilterConfig): URL {
        const url = ApiConstants.relativeUrl('/compliance-reports/changes');
        url.searchParams.append('contextVersionOid', contextVersionOid);
        url.searchParams.append('roles', config.selectedRoleIds.toString());
        url.searchParams.append('tagOids', config.selectedTagIds.toString());
        url.searchParams.append('users', config.selectedUserIds.toString());
        url.searchParams.append('status', config.status);
        config.date && url.searchParams.append('date', config.date.toString());
        return url;
    }

    static getComplianceReportsUsers(changeOid: string, role: string, tagOids?: string[], users?: string[]): URL {
        const url = ApiConstants.relativeUrl('/compliance-reports/user-status');

        url.searchParams.append('changeOid', changeOid);
        url.searchParams.append('role', role);
        tagOids.length && url.searchParams.append('tagOids', tagOids.toString());
        users.length && url.searchParams.append('users', users.toString());

        return url;
    }

    static getComplianceReportsFileUserStatuses(
        fileRevisionOid: string,
        role?: string,
        tagOids?: string[],
        users?: string[]
    ): URL {
        const url = ApiConstants.relativeUrl('/compliance-reports/file-user-status');

        url.searchParams.append('fileRevisionOid', fileRevisionOid);
        role && url.searchParams.append('role', role);
        tagOids.length && url.searchParams.append('tagOids', tagOids.toString());
        users.length && url.searchParams.append('users', users.toString());

        return url;
    }

    static getComplianceReportsPresets(userOid: string): URL {
        const url = ApiConstants.relativeUrl(`/compliance-reports/presets?userId=${userOid}`);
        return url;
    }

    static deleteComplianceReportsPreset(oid: string): URL {
        const url = ApiConstants.relativeUrl(`/compliance-reports/presets/${oid}`);
        return url;
    }

    static saveComplianceReportsPreset(): URL {
        const url = ApiConstants.relativeUrl(`/compliance-reports/presets`);
        return url;
    }

    static exportComplianceCsv(
        contextVersionOids: string[],
        status: string,
        role: string[],
        date?: string,
        tagOids?: string[],
        users?: string[]
    ): URL {
        const url = ApiConstants.relativeUrl('/compliance-reports/export-csv');
        url.searchParams.append('contextVersionOids', contextVersionOids.toString());
        url.searchParams.append('status', status);
        url.searchParams.append('role', role.toString());
        date && url.searchParams.append('date', date);
        tagOids && url.searchParams.append('tagOids', tagOids.toString());
        users && url.searchParams.append('users', users.toString());
        return url;
    }
}
