import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { Spinner } from '@yonder-mind/ui-core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PDFDownloadLink } from '@react-pdf/renderer';

enum ExportType {
    PDF = 'PDF',
    CSV = 'CSV',
}

interface KebabMenuProps {
    onCSV: () => void;
    disabled?: boolean;
    pdfDocument?: React.ReactElement;
    fileName: string;
    disabledCsv?: boolean;
    isExportingCsv?: boolean;
}

export const ExportKebabMenu: React.FC<KebabMenuProps> = (props) => {
    const { onCSV, disabled, disabledCsv, isExportingCsv, fileName, pdfDocument } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleKebabClick = (event: React.MouseEvent) => {
        setAnchorEl(event.currentTarget);
    };

    const handleKebabClose = () => {
        setAnchorEl(null);
    };

    const { t } = useTranslation();

    const getSpinnerAndMessage = (exportType: ExportType) => {
        return (
            <div className="compliance-export-spinner">
                <Spinner message={t('complianceReports.export.message', { type: exportType })} />
            </div>
        );
    };

    return isExportingCsv ? (
        getSpinnerAndMessage(ExportType.CSV)
    ) : (
        <div>
            <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleKebabClose}>
                <MenuItem disabled={disabled}>
                    <PDFDownloadLink
                        document={pdfDocument}
                        fileName={fileName + '.pdf'}
                        style={{ textDecoration: 'none', color: 'inherit' }}
                    >
                        {({ loading }) =>
                            loading ? getSpinnerAndMessage(ExportType.PDF) : t('complianceReports.export.print')
                        }
                    </PDFDownloadLink>
                </MenuItem>
                <MenuItem
                    disabled={disabledCsv}
                    onClick={() => {
                        handleKebabClose();
                        onCSV();
                    }}
                >
                    {t('complianceReports.export.csv')}
                </MenuItem>
            </Menu>
            <IconButton onClick={handleKebabClick} className="compliance-kebab-menu-icon">
                <MoreVert />
            </IconButton>
        </div>
    );
};
