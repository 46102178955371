import { ApiConstants } from './ApiConstants';

export class ApiHighlightsConstants {
    static getHighlights(contextVersionOid: string): URL {
        return ApiConstants.relativeUrl(`/highlights?contextVersionOid=${contextVersionOid}`);
    }

    static postHighlight(): URL {
        return ApiConstants.relativeUrl('/highlights');
    }

    static highlightOid(oid?: string): URL {
        return ApiConstants.relativeUrl('/highlights/' + oid);
    }
}
