import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import { SnackbarKey, useSnackbar } from 'notistack';
import { Model } from 'survey-core';
import 'survey-core/defaultV2.css';
import {
    formActions,
    FormContent,
    FormSubmissionType,
    IApplicationStore,
    ManageableFormSubmission,
    ManageSubmissionTools,
    SnackBarCloseButton,
    Spinner,
    useSideBar,
} from '@yonder-mind/ui-core';
import { SurveyIntegration } from './SurveyIntegration';
import { updateQuestions } from '../utils/FormUtils';
import { ManageSubmissionHeader } from './ManageSubmissionHeader';

enum MessageType {
    SUCCESS = 'success',
    ERROR = 'error',
}

const SPINNER_STYLE = { width: '2em', height: '2em' };

interface ManageableSubmissionDetailsProps {
    moduleVersionOid: string;
    formContent: FormContent;
    formSubmissionOid: string;
}

export const ManageSubmissionDetails: React.FC<ManageableSubmissionDetailsProps> = ({
    moduleVersionOid,
    formContent,
    formSubmissionOid,
}) => {
    const { isExpanded } = useSideBar();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (formSubmissionOid) {
            dispatch(
                formActions.manageableFormSubmissionRequested(formSubmissionOid, () =>
                    createSnackbar(MessageType.ERROR, t('form.messages.error.generalError'))
                )
            );
        }
    }, [formSubmissionOid]);

    const { formSubmission, formSubmissionTypes, survey, isLoading } = useSelector(
        (
            state: IApplicationStore
        ): {
            formSubmission: ManageableFormSubmission;
            formSubmissionTypes: { [key: string]: FormSubmissionType };
            survey: Model;
            isLoading: boolean;
        } => {
            return {
                formSubmission: state.form?.manageableFormSubmissions[formSubmissionOid],
                formSubmissionTypes: state.form.formSubmissionTypes,
                survey: state.form.survey,
                isLoading: state.form.isLoading,
            };
        }
    );

    const { formSubmissionTypeOid } = useSelector(
        (
            state: IApplicationStore
        ): {
            formSubmissionTypeOid: string;
        } => {
            return {
                formSubmissionTypeOid:
                    state.docs.documentCache[formSubmission?.formVersionOid]?.contextVersion?.formSubmissionTypeOid,
            };
        }
    );

    useEffect(() => {
        formSubmissionTypeOid &&
            dispatch(
                formActions.formSubmissionTypeRequested(formSubmissionTypeOid, () =>
                    enqueueSnackbar(t('form.messages.error.generalError'), {
                        action: (key: SnackbarKey) => <SnackBarCloseButton snackbarKey={key} />,
                        variant: 'error',
                        persist: true,
                        preventDuplicate: true,
                    })
                )
            );
    }, [formSubmissionTypeOid]);

    useEffect(() => {
        dispatch(formActions.setQuestions(updateQuestions(survey, null)));
    }, [formSubmission, survey]);

    const createSnackbar = (messageType: MessageType, message: string) => {
        return messageType === MessageType.SUCCESS
            ? enqueueSnackbar(message, {
                  variant: 'success',
              })
            : enqueueSnackbar(message, {
                  action: (key) => <SnackBarCloseButton snackbarKey={key} />,
                  variant: 'error',
                  persist: true,
                  preventDuplicate: true,
              });
    };

    const onStatusChange = (statusOid: string) => {
        dispatch(
            formActions.updateManageableFormSubmissionStatusRequested(formSubmissionOid, statusOid, () =>
                createSnackbar(MessageType.ERROR, t('form.messages.error.statusChanged'))
            )
        );
    };

    return (
        <>
            <ManageSubmissionTools />
            <div className={`form-submission${isExpanded ? ' tools-open' : ''}`}>
                {isLoading || isEmpty(formContent) || !formSubmissionTypes[formSubmissionTypeOid] ? (
                    <div className={'form-submission__spinner'}>
                        <Spinner style={SPINNER_STYLE} />
                    </div>
                ) : (
                    <div>
                        <ManageSubmissionHeader
                            formSubmission={formSubmission}
                            formSubmissionType={formSubmissionTypes[formSubmissionTypeOid]}
                            onStatusChange={onStatusChange}
                        />
                        <SurveyIntegration
                            moduleVersionOid={moduleVersionOid}
                            formContent={formContent}
                            formSubmission={formSubmission}
                        />
                    </div>
                )}
            </div>
        </>
    );
};
