import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
    AddModule,
    combineStrings,
    EditContent,
    EditTitle,
    IListItemProps,
    IQueryParam,
    IWorkflowActivity,
    IWorkflowHistoricActivity,
    RemoveModule,
    useHistory,
    WorkflowUtils,
    YonderListItem,
} from '@yonder-mind/ui-core';
import { CRName } from '../../../../config';

type Variant = 'change-requests';

export interface IChangeRequestListItemProps
    extends IListItemProps<Variant, IWorkflowActivity | IWorkflowHistoricActivity> {
    dataTestId: string;
}

export const ChangeRequestListItem: React.FC<IChangeRequestListItemProps> = ({
    index,
    item,
    icon,
    onClick,
    ...listItemProps
}) => {
    const { t } = useTranslation();
    const { pushUrl } = useHistory();

    const importance: string = item.variables.SEVERITY && item.variables.SEVERITY;
    const label = `${item.variables.CHANGE_REQUEST_LABEL}${importance ? ` - ${importance}` : ''}`;

    const summary = item.variables.CHANGE_REQUEST_SUMMARY || 'Summary'; // TODO: Replace

    const description = item.variables.CHANGE_REQUEST;

    const userHasTasks = item.userHasTasks;

    const status = WorkflowUtils.getStatus(item, t);

    const handleClick = () => {
        if (onClick) {
            onClick(item, index);
        }

        let documentId = WorkflowUtils.getContextVersionOid(item.variables);
        if (WorkflowUtils.isCompleted(item) || WorkflowUtils.isObsolete(item)) {
            documentId = WorkflowUtils.getContextVersionToEditOid(item.variables);
        }
        if (documentId) {
            pushUrl(`/workflow/cr/${documentId}`, [
                {
                    key: IQueryParam.CHANGE_REQUEST,
                    value: item.processInstanceId,
                },
            ]);
        }
    };

    const getWorkflowIcon = () => {
        switch (item.activityName || item.processDefinitionName) {
            case CRName.EDIT_CONTENT:
                return <EditContent />;
            case CRName.EDIT_TITLE:
                return <EditTitle />;
            case CRName.ADD_MODULE:
                return <AddModule />;
            case CRName.DELETE_MODULE:
                return <RemoveModule />;
            default:
                return icon;
        }
    };

    return (
        <YonderListItem
            {...listItemProps}
            className="change-request__list-item"
            icon={getWorkflowIcon()}
            primary={summary}
            secondary={description}
            tertiary={label}
            meta={status}
            metaClassName={combineStrings([userHasTasks ? 'user-has-tasks' : undefined])}
            onClick={handleClick}
            button={true}
            crLayout={true}
        />
    );
};
