import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import {
    docsActions,
    Highlight,
    HighlightListItem,
    HighlightProvider,
    highlightsActions,
    HighlightTransferState,
    Layout,
    useSelectorUiCore,
} from '@yonder-mind/ui-core';
import { TransferHighlightsContent } from './TransferHighlightsContent';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router';

export interface UrlParam {
    contextVersionOid: string;
}

export const TransferHighlights: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [openTabActive, setOpenTabActive] = useState(true);
    const { path, url, params } = useRouteMatch<UrlParam>();
    const history = useHistory();
    const [selectedHighlight, setSelectedHighlight] = useState<{
        moduleOid: string | null;
        moduleVersionOid: string | null;
        highlightOid: string | null;
    }>({
        moduleOid: null,
        moduleVersionOid: null,
        highlightOid: null,
    });

    const openHighlights = useSelectorUiCore((state) =>
        (state?.highlights?.highlights[params.contextVersionOid] ?? []).filter(
            (highlight: Highlight) => highlight.transferState === HighlightTransferState.OPEN
        )
    );
    const closedHighlights = useSelectorUiCore((state) =>
        (state?.highlights?.highlights[params.contextVersionOid] ?? []).filter(
            (highlight: Highlight) => highlight.transferState !== HighlightTransferState.OPEN
        )
    );

    useEffect(() => {
        dispatch(highlightsActions.setVisibility(true));
        dispatch(docsActions.documentRequested({ documentId: params.contextVersionOid, filters: [] }));
        dispatch(highlightsActions.getHighlightsForContextVersion(params.contextVersionOid));
    }, [params.contextVersionOid]);

    const cacheDocument = useSelectorUiCore((state) => state.docs.documentCache[params.contextVersionOid]);

    if (!cacheDocument) {
        return null;
    }

    const onClick = (moduleOid: string, moduleVersionOid: string, highlightOid: string) => {
        setSelectedHighlight({
            moduleOid,
            moduleVersionOid,
            highlightOid,
        });
        history.replace({ pathname: `${url}/${moduleOid}/${moduleVersionOid}/${highlightOid}` });
    };

    const handleAfterTransfer = (transferHighlight: Highlight) => {
        const highlights = openHighlights.filter((h) => h.oid !== transferHighlight.oid);

        if (highlights.length > 0) {
            const { moduleOid, moduleVersionOid, oid } = highlights[0];

            setSelectedHighlight({
                moduleOid: moduleOid,
                moduleVersionOid: moduleVersionOid,
                highlightOid: oid,
            });
            return history.replace({
                pathname: `${url}/${moduleOid}/${moduleVersionOid}/${oid}`,
            });
        }

        return history.replace({
            pathname: url,
        });
    };

    return (
        <HighlightProvider contextVersionOid={cacheDocument?.contextVersion.oid}>
            <Layout title={t('transferHighlights.title')} variant="transfer-highlights">
                <div className="transfer-highlights-sidebar">
                    <div className="title-wrapper">
                        <Typography variant="h4" className="sidebar-title">
                            {t('transferHighlights.transferHighlight')}
                        </Typography>
                    </div>
                    <div className="transfer-highlights-wrapper">
                        <div className="tasks-tabs">
                            <div
                                className={`tasks-button ${openTabActive ? 'active' : ''} open`}
                                onClick={() => setOpenTabActive(true)}
                            >
                                <div>{t('reader.drawer.revision.openTasks')}</div>
                                <div
                                    className={`tasks-counter ${openTabActive ? 'active' : ''}`}
                                    data-testid="openHighlights"
                                >
                                    {openHighlights?.length ?? 0}
                                </div>
                            </div>
                            <div
                                className={`tasks-button ${!openTabActive ? 'active' : ''} done`}
                                onClick={() => setOpenTabActive(false)}
                            >
                                <div>{t('reader.drawer.revision.doneTasks')}</div>
                                <div
                                    className={`tasks-counter ${!openTabActive ? 'active' : ''}`}
                                    data-testid="doneHighlights"
                                >
                                    {closedHighlights?.length ?? 0}
                                </div>
                            </div>
                        </div>
                        <div className="tasks-list">
                            <ul>
                                {openTabActive &&
                                    openHighlights?.map((highlight) => (
                                        <HighlightListItem
                                            selected={highlight.oid === selectedHighlight.highlightOid}
                                            highlight={highlight}
                                            key={highlight.oid}
                                            onClick={() =>
                                                onClick(highlight.moduleOid, highlight.moduleVersionOid, highlight.oid)
                                            }
                                        />
                                    ))}
                                {!openTabActive &&
                                    closedHighlights?.map((highlight) => (
                                        <HighlightListItem
                                            selected={highlight.oid === selectedHighlight.highlightOid}
                                            highlight={highlight}
                                            key={highlight.oid}
                                            onClick={() =>
                                                onClick(highlight.moduleOid, highlight.moduleVersionOid, highlight.oid)
                                            }
                                        />
                                    ))}
                            </ul>
                        </div>
                    </div>
                </div>
                <Switch>
                    <Route exact path={path}>
                        <div className="transfer-highlights-content">
                            <div className="transfer-highlights-content-select-highlight">
                                <p>{t('transferHighlights.selectOpenHighlight')}</p>
                            </div>
                        </div>
                    </Route>
                    <Route
                        path={`${path}/:moduleOid/:moduleVersionOid/:highlightOid`}
                        render={(renderProps) => (
                            <TransferHighlightsContent
                                {...renderProps}
                                document={cacheDocument.contextVersion}
                                openHighlights={openHighlights}
                                closedHighlights={closedHighlights}
                                onTransferSuccess={(transferHighlight: Highlight) =>
                                    handleAfterTransfer(transferHighlight)
                                }
                                onTransferReject={(transferHighlight: Highlight) =>
                                    handleAfterTransfer(transferHighlight)
                                }
                            />
                        )}
                    />
                </Switch>
            </Layout>
        </HighlightProvider>
    );
};
