import { NotificationsApi, IDocumentNotification, Rest } from '@yonder-mind/ui-core';
import { ApiNotificationsConstants } from '../utils/ApiNotificationsContants';

export class RestNotificationsApi implements NotificationsApi {
    async getNotifications(): Promise<IDocumentNotification[]> {
        const response = await Rest.getRequest(ApiNotificationsConstants.getNotifications().toString());
        return response.json();
    }

    async deleteNotification(notificationId: string): Promise<any> {
        await Rest.deleteRequest(ApiNotificationsConstants.deleteNotification(notificationId).toString());
    }

    async readNotification(notificationId: string): Promise<any> {
        await Rest.postRequest(ApiNotificationsConstants.readNotification(notificationId).toString());
    }
}
