import Cookies from 'universal-cookie';
import { AuthUtils, IAuthConfiguration, IAuthState, IAuthApi } from '@yonder-mind/ui-core';

const cookies = new Cookies();

const cookiesKey = {
    accessToken: 'kc-access',
};
const maxCookieChunkSize = 4000;
const maxAccessTokenSize = 8100;

// TODO: add tests for this in ui-web
export class RestAuthApi implements IAuthApi {
    async loadConfiguration(): Promise<IAuthConfiguration> {
        const { hostname, origin } = window.location;
        // For testing web version on the iPad
        const realm = hostname === '192.168.2.1' ? 'localhost' : hostname.split('.')[0];

        return Promise.resolve({
            clientId: 'yondermind-web',
            issuer: `${origin}/auth/realms`,
            realm: realm === 'localhost' ? 'yondermind-dev' : `yondermind-${realm}`,
            redirectUrl: origin,
            scope: 'openid profile',
            baseUrl: window.location.origin,
        });
    }

    async loadAuthState(): Promise<IAuthState | undefined> {
        const storageKey = await this.getStorageKey();

        const authStateString = window.localStorage.getItem(storageKey);
        if (authStateString) {
            const authState = JSON.parse(authStateString);
            await this.storeAuthState(authState);

            return Promise.resolve(authState);
        }

        return Promise.resolve(undefined);
    }

    async storeAuthState(authState: IAuthState): Promise<void> {
        const storageKey = await this.getStorageKey();
        window.localStorage.setItem(storageKey, JSON.stringify(authState));

        if (authState.tokens.accessToken.length < maxAccessTokenSize) {
            const splitCookie = authState.tokens.accessToken.match(new RegExp('.{1,' + maxCookieChunkSize + '}', 'g'));
            cookies.set(cookiesKey.accessToken, splitCookie[0], { path: '/' });
            for (let idx = 1; idx < splitCookie.length; ++idx) {
                cookies.set(cookiesKey.accessToken + '_' + idx, splitCookie[idx], { path: '/' });
            }
        } else {
            cookies.set(cookiesKey.accessToken, authState.tokens.accessToken, { path: '/' });
        }

        return Promise.resolve();
    }

    async logout(): Promise<void> {
        const authState = await this.loadAuthState();
        if (authState === undefined) {
            return Promise.reject('User is not logged in');
        }

        const conf = await this.loadConfiguration();
        const issuer: string = AuthUtils.decodeAccessToken(authState.tokens.accessToken).iss;

        await this.clear();

        window.location.href = `${issuer}/protocol/openid-connect/logout?redirect_uri=${conf.redirectUrl}`;

        return Promise.resolve();
    }

    private async getStorageKey(): Promise<string> {
        const configuration = await this.loadConfiguration();

        return Promise.resolve(`${configuration.realm}.auth-state`);
    }

    private async clear(): Promise<void> {
        const storageKey = await this.getStorageKey();
        const authStateString = window.localStorage.getItem(storageKey);
        cookies.remove(cookiesKey.accessToken);
        if (authStateString) {
            const authState = JSON.parse(authStateString);
            const splitCookie = authState.tokens.accessToken.match(new RegExp('.{1,' + maxCookieChunkSize + '}', 'g'));
            for (let idx = 1; idx < splitCookie.length; ++idx) {
                cookies.remove(cookiesKey.accessToken + '_' + idx);
            }
        }

        window.localStorage.removeItem(storageKey);

        return Promise.resolve();
    }
}
