import React from 'react';
import { DropDownDocumentSelector } from './DropDownDocumentSelector';
import { DropDownTagsSelector } from './DropDownTagsSelector';
import { DropDownUsersSelector } from './DropDownUsersSelector';
import { RolesSelector } from './DropDownRolesSelector';
import { complianceReportsActions, useSelectorUiWeb } from '../../../store';
import { ComplianceReportsFilterConfig, DropdownType } from '../../../interfaces';
import { useDispatch } from 'react-redux';
import { authSelector, YonderButton } from '@yonder-mind/ui-core';
import isEqual from 'lodash/isEqual';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router';
import { Close } from '@material-ui/icons';
import dayjs from 'dayjs';
import { Tooltip } from '@material-ui/core';

const filterHasData = (config: ComplianceReportsFilterConfig) =>
    config.selectedDocumentIds.length > 0 ||
    config.selectedRoleIds.length > 0 ||
    config.selectedTagIds.length > 0 ||
    config.selectedUserIds.length > 0;

export const FilterConfigSelector: React.FC<{ isChangesFilter?: boolean }> = ({ isChangesFilter }) => {
    const dispatch = useDispatch();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const { t } = useTranslation();

    const { contextVersionOid }: { contextVersionOid: string } = useParams();
    const { filterConfig, appliedFilterConfig, selectedDropdown, date } = useSelectorUiWeb((state) => {
        return {
            filterConfig: isChangesFilter
                ? { ...state.complianceReports.filterConfig, selectedDocumentIds: [contextVersionOid] }
                : state.complianceReports.filterConfig,
            appliedFilterConfig: state.complianceReports.appliedFilterConfig,
            selectedDropdown: state.complianceReports.selectedDropdown,
            date: state.complianceReports.dateConfig.effectiveAt,
        };
    });

    const hasComplianceShowUserRole = useSelectorUiWeb(authSelector.hasComplianceShowUserRole);

    const handleOnClick = (type: DropdownType) => {
        dispatch(complianceReportsActions.setSelectedDropdown(selectedDropdown === type ? 'none' : type));
        if (type === 'documents') {
            dispatch(
                complianceReportsActions.complianceReportsSelectableDocumentsRequested(
                    filterConfig.status,
                    filterConfig.status === 'PAST' ? dayjs(date).toISOString() : ''
                )
            );
        }
    };

    const isEqualToAppliedFilters = isEqual(filterConfig, appliedFilterConfig);

    const filterConfigHasData = filterHasData(filterConfig);
    const appliedFilterConfigHasData = filterHasData(appliedFilterConfig);

    const { status } = useSelectorUiWeb((state) => state.complianceReports.filterConfig);

    const clearFilters = () => {
        isChangesFilter
            ? dispatch(complianceReportsActions.clearChangesFilterConfig(status))
            : dispatch(complianceReportsActions.clearFilterConfig(status));
    };

    const applyFilters = () => {
        queryParams.delete('selectedRole');
        dispatch(complianceReportsActions.complianceDocumentsRequested({ ...filterConfig, date }));
    };

    return (
        <div className={`filter-config-selector`}>
            <DropDownDocumentSelector
                disabled={isChangesFilter}
                selectedDropdown={selectedDropdown}
                handleOnClick={handleOnClick}
            />
            <DropDownTagsSelector
                disabled={isChangesFilter}
                selectedDropdown={selectedDropdown}
                handleOnClick={handleOnClick}
            />
            {hasComplianceShowUserRole ? (
                <DropDownUsersSelector selectedDropdown={selectedDropdown} handleOnClick={handleOnClick} />
            ) : (
                <Tooltip title={t('complianceReports.filter.noAccess')} enterDelay={600} leaveDelay={600}>
                    <div>
                        <DropDownUsersSelector
                            disabled
                            selectedDropdown={selectedDropdown}
                            handleOnClick={handleOnClick}
                        />
                    </div>
                </Tooltip>
            )}
            <RolesSelector selectedDropdown={selectedDropdown} handleOnClick={handleOnClick} />
            <YonderButton
                variant="outlined"
                onClick={() => applyFilters()}
                disabled={isEqualToAppliedFilters && filterConfigHasData}
                color="primary"
                className="apply-button"
            >
                {t('complianceReports.filter.applyFilter')}
            </YonderButton>
            <YonderButton
                onClick={() => clearFilters()}
                variant="text"
                className="clear-button"
                color="primary"
                disabled={isEqualToAppliedFilters && !appliedFilterConfigHasData}
            >
                <Close /> {t('complianceReports.filter.clearFilter')}
            </YonderButton>
        </div>
    );
};
