import { Rest } from '@yonder-mind/ui-core';
import { IPdfExportApi, IAggregatedWorkflowInformation } from '../interfaces';
import { ApiWorkflowConstants } from '../utils';

export class PdfExportApi implements IPdfExportApi {
    async getAllChangeRequestsByDocumentVersionId(
        contextVersionOid: string
    ): Promise<IAggregatedWorkflowInformation[]> {
        const response = await Rest.getRequest(
            ApiWorkflowConstants.getAllChangeRequestsByDocumentVersionId(contextVersionOid).toString()
        );
        return response.json();
    }

    async getRevisionsByDocumentVersionId(contextVersionOid: string): Promise<IAggregatedWorkflowInformation[]> {
        const response = await Rest.getRequest(
            ApiWorkflowConstants.getRevisionsByDocumentVersionId(contextVersionOid).toString()
        );
        return response.json();
    }
}
