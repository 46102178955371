import { TokenResponse } from '@openid/appauth';
import * as SockJS from 'sockjs-client';
import { Client as StompClient, IFrame } from '@stomp/stompjs';
import { WORKFLOW_WEBSOCKET_PATH } from '../../config';
import { eventChannel } from 'redux-saga';

export function getStompClient(tokens: TokenResponse): Promise<StompClient | Error> {
    return new Promise((resolve, reject) => {
        const brokerURL = new URL(WORKFLOW_WEBSOCKET_PATH, window.location.origin).toString();

        const stompClient: StompClient = new StompClient({
            brokerURL,
            connectHeaders: {
                Authorization: `${tokens.tokenType} ${tokens.accessToken}`,
                credentials: 'same-origin',
            },

            onConnect: () => resolve(stompClient),
            onStompError: (frame: IFrame) => {
                console.error('An error occurred in the stomp client.', frame);
                reject(Error(frame.body));
            },
            onWebSocketError: (event: Event) => {
                console.error('An error occurred in the websocket', event);
                reject(Error(event.type));
            },
            webSocketFactory: () => new SockJS.default(brokerURL),
        });

        stompClient.activate();
    });
}

export function subscribe(stompClient: StompClient, topic: string) {
    return eventChannel((emit) => {
        const { unsubscribe } = stompClient.subscribe(topic, (message) => emit(message));
        return () => {
            unsubscribe();
        };
    });
}
