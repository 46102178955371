// @ts-nocheck
export const APP_TITLE = 'Yonder Reader';
export const CUSTOM_URL_SCHEME = '{CUSTOM_URL_SCHEME}';

Object.defineProperty(global, 'debug', {
    value: process.env.NODE_ENV === 'development',
    writable: process.env.NODE_ENV === 'development',
});

export const isDebug = () => global && global.debug;

export const workflowRoleMap = ['wf-manager', 'wf-contributor', 'camunda-admin'];

export const dateFormat = 'DD.MM.YYYY';
export const timeFormat = 'HH:mm';
export const dayFormat = 'ddd';
export const dateTimeFormat = `${dateFormat} ${timeFormat}`;
export const dayDateFormat = `${dayFormat} ${dateFormat}`;
export const dayDateTimeFormat = `${dayDateFormat} ${timeFormat}`;

export const ExternalKey = {
    UNDEFINED: {
        externalId: 'undefined',
        source: 'undefined',
    },
};

export const DEFAULT_FILTER_ICON = 'AIRPLANE';
