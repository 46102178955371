import {
    IWorkflowActivity,
    IWorkflowComment,
    IWorkflowFormField,
    IWorkflowHistoricActivity,
    IWorkflowTaskInfo,
    IWorkflowRevisionHistoric,
    IWorkflowAttachment,
    Rest,
    IWorkflowChangeRequestV2,
} from '@yonder-mind/ui-core';
import { IFormValues, IWorkflowApi } from '../interfaces';
import { ApiConstants, ApiWorkflowConstants } from '../utils';

export class RestWorkflowApi implements IWorkflowApi {
    async getChangeRequest({ changeRequestId }: { changeRequestId: string }): Promise<IWorkflowActivity> {
        const response = await Rest.getRequest(ApiWorkflowConstants.getChangeRequest({ changeRequestId }).toString());
        return response.json();
    }
    async getChangeRequestsForModule({
        contextOid,
        contextVersionOid,
        moduleOid,
        isDraft,
    }: {
        contextOid: string;
        contextVersionOid: string;
        moduleOid: string;
        isDraft: boolean;
    }): Promise<IWorkflowChangeRequestV2[]> {
        const activeCRs: IWorkflowChangeRequestV2[] = await (
            await Rest.getRequest(ApiWorkflowConstants.getActiveChangeRequests({ contextOid, moduleOid }).toString())
        ).json();

        let finishedNotReleasedCRs: IWorkflowChangeRequestV2[] = [];
        if (!isDraft) {
            finishedNotReleasedCRs = await (
                await Rest.getRequest(
                    ApiWorkflowConstants.getFinishedChangeRequestsForReleased({
                        contextVersionOid,
                        moduleOid,
                    }).toString()
                )
            ).json();
        }

        const finishedCRs: IWorkflowChangeRequestV2[] = await (
            await Rest.getRequest(
                ApiWorkflowConstants.getFinishedChangeRequests({ contextVersionOid, moduleOid }).toString()
            )
        ).json();

        return [...activeCRs, ...finishedCRs, ...finishedNotReleasedCRs];
    }

    async getChangeRequestsForReleasedDoc({
        contextOid,
        contextVersionOid,
    }: {
        contextOid: string;
        contextVersionOid: string;
    }): Promise<IWorkflowChangeRequestV2[]> {
        const activeCRs: IWorkflowChangeRequestV2[] = await (
            await Rest.getRequest(ApiWorkflowConstants.getActiveChangeRequests({ contextOid }).toString())
        ).json();

        const finishedCRs: IWorkflowChangeRequestV2[] = await (
            await Rest.getRequest(
                ApiWorkflowConstants.getFinishedChangeRequestsForReleased({ contextVersionOid }).toString()
            )
        ).json();

        return [...finishedCRs, ...activeCRs];
    }

    async getChangeRequestsForDraftDoc({
        contextOid,
        contextVersionOid,
    }: {
        contextOid: string;
        contextVersionOid: string;
    }): Promise<IWorkflowChangeRequestV2[]> {
        const activeCRs: IWorkflowChangeRequestV2[] = await (
            await Rest.getRequest(ApiWorkflowConstants.getActiveChangeRequests({ contextOid }).toString())
        ).json();

        const finishedCRs: IWorkflowChangeRequestV2[] = await (
            await Rest.getRequest(
                ApiWorkflowConstants.getFinishedChangeRequestsForDraft({ contextVersionOid }).toString()
            )
        ).json();

        return [...finishedCRs, ...activeCRs];
    }

    async getFinishedChangeRequests({
        contextVersionOid,
    }: {
        contextVersionOid?: string;
    }): Promise<IWorkflowChangeRequestV2[]> {
        const response = await Rest.getRequest(
            ApiWorkflowConstants.getFinishedChangeRequests({ contextVersionOid }).toString()
        );
        return response.json();
    }

    async getChangeRequests(filters?: { [filter: string]: string | undefined }): Promise<IWorkflowActivity[]> {
        const response = await Rest.getRequest(ApiWorkflowConstants.getChangeRequests(filters).toString());
        return response.json();
    }

    async getChangeRequestHistories(filters?: {
        [filter: string]: string | undefined;
    }): Promise<IWorkflowHistoricActivity[]> {
        const response = await Rest.getRequest(ApiWorkflowConstants.getChangeRequestHistories(filters).toString());
        return response.json();
    }

    async getNewChangeRequestForm(
        contextType: string,
        contextSubType: string,
        workflowId: string
    ): Promise<IWorkflowFormField[] | null> {
        const response = await Rest.getRequest(
            ApiWorkflowConstants.getNewChangeRequestForm(contextType, contextSubType, workflowId).toString()
        );
        return response.json();
    }

    async startNewChangeRequest(
        contextType: string,
        workflowId: string,
        data: IFormValues
    ): Promise<IWorkflowActivity> {
        const response = await Rest.postRequest(
            ApiWorkflowConstants.startNewChangeRequest(contextType, workflowId).toString(),
            data
        );
        return response.json();
    }

    async calculateDiff(previousContent: string, currentContent: string): Promise<{ diffContent: string }> {
        const response = await Rest.postRequest(ApiConstants.calculateDiff().toString(), {
            previousContent,
            currentContent,
        });
        return response.json();
    }

    async getMyTasksOfDocument(documentOid: string): Promise<IWorkflowTaskInfo> {
        const response = await Rest.getRequest(ApiWorkflowConstants.getMyTasksOfDocument(documentOid).toString());
        return response.json();
    }

    async completeTask(taskId: string, data: IFormValues): Promise<void> {
        await Rest.postRequest(ApiWorkflowConstants.completeTask(taskId).toString(), data);
    }

    async getMyTasks(): Promise<IWorkflowTaskInfo[]> {
        const response = await Rest.getRequest(ApiWorkflowConstants.getMyTasks().toString());
        return response.json();
    }

    async getRevisions(filters?: { [filter: string]: string | undefined }): Promise<IWorkflowActivity[]> {
        const response = await Rest.getRequest(ApiWorkflowConstants.getRevisions(filters).toString());
        return response.json();
    }

    async getRevisionHistory(documentId: string): Promise<IWorkflowHistoricActivity[]> {
        const response = await Rest.getRequest(ApiWorkflowConstants.getRevisionHistory(documentId).toString());
        return response.json();
    }

    async getRevisionHistoric(documentId: string, state = 'FINISHED'): Promise<IWorkflowRevisionHistoric[]> {
        const response = await Rest.getRequest(ApiWorkflowConstants.getRevisionHistoric(documentId, state).toString());
        return response.json();
    }

    async getNewRevisionForm(contextType: string, contextSubType: string): Promise<IWorkflowFormField[]> {
        const response = await Rest.getRequest(
            ApiWorkflowConstants.getNewRevisionForm(contextType, contextSubType).toString()
        );
        return response.json();
    }

    async startNewRevision(contextType: string, revision: any): Promise<IWorkflowActivity> {
        const response = await Rest.postRequest(
            ApiWorkflowConstants.startNewRevision(contextType).toString(),
            revision
        );
        return response.json();
    }

    async canCommentOnProcess(processInstanceId: string): Promise<boolean> {
        const response = await Rest.getRequest(ApiWorkflowConstants.canCommentOnProcess(processInstanceId).toString());
        return response.json();
    }

    async getCommentsOfProcess(processInstanceId: string): Promise<IWorkflowComment[]> {
        const response = await Rest.getRequest(ApiWorkflowConstants.getCommentsOfProcess(processInstanceId).toString());
        return response.json();
    }

    async getCommentsOfCurrentActivityOfProcess(processInstanceId: string): Promise<IWorkflowComment[]> {
        const response = await Rest.getRequest(
            ApiWorkflowConstants.getCommentsOfCurrentActivity(processInstanceId).toString()
        );
        return response.json();
    }

    async commentOnProcess(processInstanceId: string, message: string): Promise<IWorkflowComment> {
        const response = await Rest.postRequest(
            ApiWorkflowConstants.commentOnProcess(processInstanceId).toString(),
            message,
            false // Message fix ("\"message\"")
        );
        return response.json();
    }

    async getAttachmentsOfProcess(processInstanceId: string): Promise<IWorkflowAttachment[]> {
        const response = await Rest.getRequest(
            ApiWorkflowConstants.getAttachmentsOfProcess(processInstanceId).toString()
        );
        return response.json();
    }

    async uploadAttachment(processInstanceId: string, attachment: File): Promise<void> {
        const formData = new FormData();
        formData.append('file', attachment, attachment.name);
        await Rest.uploadRequest(
            ApiWorkflowConstants.uploadAttachmentOnProcess(processInstanceId).toString(),
            formData,
            'POST'
        );
    }

    async deleteAttachment(attachmentId: string): Promise<void> {
        await Rest.deleteRequest(ApiWorkflowConstants.deleteAttachment(attachmentId).toString());
    }

    async getProcessActivity(processInstanceId: string): Promise<IWorkflowActivity | null> {
        const response = await Rest.getRequest(ApiWorkflowConstants.getActivityOfProcess(processInstanceId).toString());
        return response.json();
    }

    async getProcessHistories(processInstanceId: string): Promise<IWorkflowHistoricActivity[]> {
        const response = await Rest.getRequest(ApiWorkflowConstants.getHistoryOfProcess(processInstanceId).toString());
        return response.json();
    }

    async getProcessTasks(processInstanceId: string): Promise<IWorkflowTaskInfo | null> {
        const response = await Rest.getRequest(ApiWorkflowConstants.getMyTasksOfProcess(processInstanceId).toString());
        return response.json();
    }

    async getProcessVariables(processInstanceId: string): Promise<any> {
        const response = await Rest.getRequest(
            ApiWorkflowConstants.getVariablesOfProcess(processInstanceId).toString()
        );
        return response.json();
    }

    async getProcessVariablesGrouped(processInstanceId: string): Promise<any> {
        const response = await Rest.getRequest(
            ApiWorkflowConstants.getVariablesOfProcessGrouped(processInstanceId).toString()
        );
        return response.json();
    }

    async updateVariablesOfProcess(processInstanceId: string, variables: any): Promise<any> {
        await Rest.putRequest(ApiWorkflowConstants.setVariablesOfProcess(processInstanceId).toString(), variables);
    }

    async enabledCommentNotifications(processInstanceId: string): Promise<any> {
        await Rest.putRequest(ApiWorkflowConstants.enabledNotificationsOfProcess(processInstanceId).toString());
    }

    async disabledCommentNotifications(processInstanceId: string): Promise<any> {
        await Rest.putRequest(ApiWorkflowConstants.disabledNotificationsOfProcess(processInstanceId).toString());
    }

    async getCommentNotificationStatus(processInstanceId: string): Promise<boolean> {
        const response = await Rest.getRequest(
            ApiWorkflowConstants.getCommentNotificationStatusOfProcess(processInstanceId).toString()
        );
        return response.json();
    }
}
