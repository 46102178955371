import React from 'react';
import { TableBody, TableCell, TableRow } from '@material-ui/core';
import { ComplianceTableItem } from '../domain/types';
import { SortingConfiguration } from './DocumentsTableHead';
import { ProgressBar } from './ProgressBar';
import { DateUtils, DocumentClass, useHistory, useQuery } from '@yonder-mind/ui-core';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useSelectorUiWeb } from '../../../store';

interface IProps {
    documentsList: ComplianceTableItem[];
    sortingConfiguration: SortingConfiguration;
}

export const DocumentsTableBody: React.FC<IProps> = (props) => {
    const { documentsList, sortingConfiguration } = props;

    const { pushUrl } = useHistory();
    const { t } = useTranslation();

    const query = useQuery();
    const preset = query.get('preset');

    const { status, selectedUserIds, selectedTagIds, selectedRoleIds } = useSelectorUiWeb(
        (state) => state.complianceReports.filterConfig
    );

    const date = useSelectorUiWeb((state) => state.complianceReports.dateConfig.effectiveAt);

    const sortComparator = (a: ComplianceTableItem, b: ComplianceTableItem) => {
        const { order, orderBy } = sortingConfiguration;
        if (orderBy === 'name') {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
                return order === 'asc' ? -1 : 1;
            }
            return order === 'asc' ? 1 : -1;
        }
        if (orderBy === 'effectiveFrom') {
            if (dayjs(a.effectiveFrom).millisecond() < dayjs(b.effectiveFrom).millisecond()) {
                return order === 'asc' ? -1 : 1;
            }
            if (dayjs(a.effectiveFrom).millisecond() > dayjs(b.effectiveFrom).millisecond()) {
                return order === 'asc' ? 1 : -1;
            }
        }
        if (orderBy === 'effectiveTo') {
            if (dayjs(a.effectiveTo).millisecond() < dayjs(b.effectiveTo).millisecond()) {
                return order === 'asc' ? -1 : 1;
            }
            if (dayjs(a.effectiveTo).millisecond() > dayjs(b.effectiveTo).millisecond()) {
                return order === 'asc' ? 1 : -1;
            }
        }
        if (a[orderBy] < b[orderBy]) {
            return order === 'asc' ? -1 : 1;
        }
        if (a[orderBy] > b[orderBy]) {
            return order === 'asc' ? 1 : -1;
        }
        return 0;
    };

    const openDocument = (id: string) => {
        pushUrl('/compliance-reports/doc/' + id, [
            { key: 'status', value: status },
            { key: 'date', value: date },
            { key: 'userIds', value: selectedUserIds.toString() },
            { key: 'tagIds', value: selectedTagIds.toString() },
            { key: 'roleIds', value: selectedRoleIds.toString() },
            { key: 'preset', value: preset },
        ]);
    };

    // TODO extract common tableCells between SingleDocumentTable and this component

    return (
        <TableBody>
            {documentsList.sort(sortComparator).map((item) => {
                return (
                    <TableRow
                        key={'table-item' + item.contextVersionOid}
                        onClick={() => openDocument(item.contextVersionOid)}
                        className="document-row"
                    >
                        <TableCell>{item.name}</TableCell>
                        <TableCell>{item.documentClass === DocumentClass.DOCUMENT ? item.revision : '—'}</TableCell>
                        <TableCell size="small">
                            <ProgressBar
                                currentAmount={item.readDoneCount}
                                totalAmount={item.readTotalCount}
                                futureUse={status === 'FUTURE'}
                            />
                        </TableCell>
                        <TableCell size="small">
                            <ProgressBar
                                currentAmount={item.ackDoneCount}
                                totalAmount={item.ackTotalCount}
                                futureUse={status === 'FUTURE'}
                            />
                        </TableCell>
                        <TableCell>{DateUtils.ISOToFormattedDateWithoutTime(item.effectiveFrom)}</TableCell>
                        <TableCell>
                            {item.effectiveTo
                                ? DateUtils.ISOToFormattedDateWithoutTime(item.effectiveTo)
                                : t('complianceReports.documentsTable.effectiveToOpenEnded')}
                        </TableCell>
                    </TableRow>
                );
            })}
        </TableBody>
    );
};
