import { IWebApplicationStore, IAggregatedWorkflowInformation } from '../../interfaces';

export interface IPdfExportSelector {
    getChangeRequests: (state: IWebApplicationStore) => IAggregatedWorkflowInformation[];
    getRevisions: (state: IWebApplicationStore) => IAggregatedWorkflowInformation[];
}

const getChangeRequests = (state: IWebApplicationStore) => state.pdfExport.changeRequests;

const getRevisions = (state: IWebApplicationStore) => state.pdfExport.revisions;

export const pdfExportSelector: IPdfExportSelector = {
    getChangeRequests,
    getRevisions,
};
