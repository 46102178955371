import { IAuthApi, IAppSettingsApi, ISelectedFontSize, ISelectedLanguage, LanguageUtils } from '@yonder-mind/ui-core';

export class AppSettingsApi implements IAppSettingsApi {
    private authUtilsApi: IAuthApi;

    constructor(authUtilsApi: IAuthApi) {
        this.authUtilsApi = authUtilsApi;
    }

    async getAppSettingsFont(): Promise<ISelectedFontSize> {
        const storageKey = await this.getStorageKey();

        const storedItems = window.localStorage.getItem(storageKey);

        if (!storedItems) {
            return Promise.resolve({
                fontSize: 'default',
                isSaved: false,
                hasError: false,
            });
        }

        const items: ISelectedFontSize = JSON.parse(storedItems);

        await this.storeAppSettingsFont(items);

        return Promise.resolve(items);
    }

    async saveAppSettingsFont(item: ISelectedFontSize): Promise<ISelectedFontSize> {
        const statusStorage: ISelectedFontSize = {
            fontSize: item.fontSize,
            isSaved: true,
            hasError: false,
        };

        await this.storeAppSettingsFont(statusStorage);

        return Promise.resolve(statusStorage);
    }

    private async getStorageKey(): Promise<string> {
        const configuration = await this.authUtilsApi.loadConfiguration();

        return Promise.resolve(`${configuration.realm}.appSettings.fontSettings`);
    }

    private async storeAppSettingsFont(items: ISelectedFontSize): Promise<void> {
        const storageKey = await this.getStorageKey();

        window.localStorage.setItem(storageKey, JSON.stringify(items));
    }

    //Language selection
    async getDefaultLanguage(): Promise<ISelectedLanguage> {
        const storageKey = await this.getDefaultLanguageStorageKey();

        const storedLanguage = window.localStorage.getItem(storageKey);
        if (!storedLanguage) {
            return Promise.resolve({
                defaultLanguage: LanguageUtils.getUserLanguage(),
                isSaved: false,
                hasError: false,
            });
        }

        const languageSetup: ISelectedLanguage = JSON.parse(storedLanguage);
        await this.storeDefaultLanguage(languageSetup);

        return Promise.resolve(languageSetup);
    }

    async saveDefaultLanguage(item: ISelectedLanguage): Promise<ISelectedLanguage> {
        const selectedLanguageStorage: ISelectedLanguage = item;

        await this.storeDefaultLanguage(selectedLanguageStorage);

        return Promise.resolve(selectedLanguageStorage);
    }

    private async getDefaultLanguageStorageKey(): Promise<string> {
        const configuration = await this.authUtilsApi.loadConfiguration();

        return Promise.resolve(`${configuration.realm}.appSettings.defaultLanguage`);
    }

    private async storeDefaultLanguage(item: ISelectedLanguage): Promise<void> {
        const storageKey = await this.getDefaultLanguageStorageKey();

        window.localStorage.setItem(storageKey, JSON.stringify(item));
    }

    // Store module diffing settings
    async getDiffingSettings(): Promise<boolean> {
        const storageKey = await this.getDiffingSettingsStorageKey();

        const storedItems = window.localStorage.getItem(storageKey);
        if (!storedItems) {
            return Promise.resolve(true);
        }

        const items: boolean = JSON.parse(storedItems);
        await this.storeDiffingSettings(items);

        return Promise.resolve(items);
    }

    async saveDiffingSettings(item: boolean): Promise<boolean> {
        const statusStorage: boolean = item;

        await this.storeDiffingSettings(statusStorage);

        return Promise.resolve(statusStorage);
    }

    private async getDiffingSettingsStorageKey(): Promise<string> {
        const configuration = await this.authUtilsApi.loadConfiguration();

        return Promise.resolve(`${configuration.realm}.appSettings.moduleDiffing`);
    }

    private async storeDiffingSettings(items: boolean): Promise<void> {
        const storageKey = await this.getDiffingSettingsStorageKey();

        window.localStorage.setItem(storageKey, JSON.stringify(items));
    }
}
