import { call, put, takeLatest } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';
import { userActions, type UserApi } from '@yonder-mind/ui-core';

function* uploadAvatar(api: UserApi, action: ActionType<typeof userActions.uploadAvatar>) {
    try {
        yield call([api, api.uploadUserAvatar], action.payload.avatar);
        yield put(userActions.avatarUploaded());
    } catch (error) {
        yield put(userActions.uploadAvatarError(error));
    }
}

export default function* userSaga(api: UserApi) {
    yield takeLatest(getType(userActions.uploadAvatar), uploadAvatar, api);
}
