import React from 'react';
import { YonderButtons } from './YonderButtons';
import { Typography } from '@material-ui/core';

export const Storybooklet: React.FC = () => {
    return (
        <div className="storybooklet-page">
            <div className="storybooklet-page-title">
                <Typography variant={'h3'}>Storybooklet</Typography>
                <Typography variant={'h6'}>Click on titles to expand.</Typography>
            </div>
            <YonderButtons />
        </div>
    );
};
