import React, { useEffect, useRef } from 'react';
import { authSelector, Layout, useQuery, userActions } from '@yonder-mind/ui-core';
import { useTranslation } from 'react-i18next';
import StatusSidebar from './StatusSidebar/StatusSidebar';
import { useParams } from 'react-router';
import PresentCompliance from './Present/PresentCompliance';
import FutureCompliance from './Future/FutureCompliance';
import PastCompliance from './Past/PastCompliance';

import { ExportKebabMenu } from './ExportKebabMenu/ExportKebabMenu';
import { complianceReportsActions, useSelectorUiWeb } from '../../store';
import { Preset } from './domain/types';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { useSnackbar } from 'notistack';

import { Document, Font, Page, View } from '@react-pdf/renderer';
import { PdfDocumentsTable } from './Pdf/PdfDocumentsTable';
import { pdfStyles } from './Pdf/styles';
import { PdfHeader, PdfHeaderPropsSelector } from './Pdf/PdfHeader';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { hyphenationCallback } from './Pdf/utils';

dayjs.extend(utc);

Font.registerHyphenationCallback(hyphenationCallback);
export interface UrlParam {
    status: string;
}

export const ComplianceReports: React.FC = () => {
    const { enqueueSnackbar } = useSnackbar();

    const dispatch = useDispatch();

    const query = useQuery();
    const preventClear = query.get('preventClear');

    const { t } = useTranslation();

    const params: UrlParam = useParams();
    const printRef = useRef(null);

    const preset = query.get('preset');
    const presets = useSelectorUiWeb((state) => state.complianceReports.presets);
    const isExportingCsv = useSelectorUiWeb((state) => state.complianceReports.isExportingCsv);
    const hasComplianceViewRole = useSelector(authSelector.hasComplianceViewRole);

    const currentPreset = presets && presets.find((item: Preset) => item.oid === preset);

    const { hasDocuments, currentFilterConfig, documents, username } = useSelectorUiWeb((state) => {
        return {
            hasDocuments: state.complianceReports.documents.length !== 0,
            currentFilterConfig: {
                ...state.complianceReports.appliedFilterConfig,
                date: state.complianceReports.dateConfig.effectiveAt,
            },
            username: state.user.userSettings.username,
            documents: state.complianceReports.documents,
        };
    });

    useEffect(() => {
        if (username === '') {
            dispatch(userActions.userSettingsRequested());
        }
    }, [username]);

    const pdfHeaderProps = useSelectorUiWeb((state) => PdfHeaderPropsSelector(state));

    const DocumentsPdf = () => (
        <Document>
            <Page size="A4" style={pdfStyles.page}>
                <View wrap={false} fixed style={{ height: 30 }} />
                <PdfHeader {...pdfHeaderProps} />
                <PdfDocumentsTable documents={documents} />
                <View wrap={false} fixed style={{ height: 60 }} />
            </Page>
        </Document>
    );

    const currentPresetFilterConfig = currentPreset && {
        selectedDocumentIds: currentPreset.contextVersionOids,
        selectedRoleIds: currentPreset.roles,
        selectedTagIds: currentPreset.tagOids,
        selectedUserIds: currentPreset.users,
        status: currentPreset.status,
        date: currentPreset.date || '',
    };

    const handleCsv = () => {
        const { date, selectedDocumentIds, selectedUserIds, selectedRoleIds, selectedTagIds, status } =
            currentFilterConfig;
        dispatch(
            complianceReportsActions.exportComplianceCsvRequested({
                contextVersionOids: selectedDocumentIds,
                status: status,
                role: selectedRoleIds,
                date: date,
                tagOids: selectedTagIds,
                users: selectedUserIds,
                errorAction: () =>
                    enqueueSnackbar(`${t('complianceReports.export.csvError')}`, {
                        variant: 'error',
                    }),
            })
        );
    };

    React.useEffect(() => {
        if (isEqual(currentPresetFilterConfig, currentFilterConfig) || preventClear) {
            return;
        }

        if (currentPreset && preset?.length > 0) {
            const { date, selectedDocumentIds, selectedRoleIds, selectedTagIds, selectedUserIds, status } =
                currentPresetFilterConfig;

            dispatch(
                complianceReportsActions.loadCompliancePreset(
                    { selectedDocumentIds, selectedRoleIds, selectedTagIds, selectedUserIds, status },
                    date
                )
            );
        } else {
            dispatch(complianceReportsActions.clearComplianceDocuments());
            dispatch(complianceReportsActions.clearFilterConfig(params.status.toUpperCase()));
        }
    }, [currentPreset, params.status]);

    const renderLayout = () => {
        if (params.status) {
            switch (params.status) {
                case 'present':
                    return <PresentCompliance printRef={printRef} currentPreset={currentPreset} />;
                case 'future':
                    return <FutureCompliance printRef={printRef} currentPreset={currentPreset} />;
                case 'past':
                    return <PastCompliance printRef={printRef} currentPreset={currentPreset} />;
                default:
                    return <PresentCompliance printRef={printRef} currentPreset={currentPreset} />;
            }
        }
        return <PresentCompliance printRef={printRef} />;
    };

    const fileName = `${
        currentPreset?.name || (currentFilterConfig.date ? currentFilterConfig.date : dayjs().utc(true).toISOString())
    }_${t('complianceReports.sidebar.' + currentFilterConfig.status.toLowerCase())}`;

    return hasComplianceViewRole ? (
        <div>
            <Layout
                title={t('complianceReports.title')}
                variant="compliance-reports"
                navbarProps={{
                    rightActionButtons: [
                        <ExportKebabMenu
                            pdfDocument={<DocumentsPdf />}
                            disabled={!hasDocuments}
                            key="export-kebab"
                            fileName={fileName}
                            onCSV={handleCsv}
                            disabledCsv={!hasDocuments}
                            isExportingCsv={isExportingCsv}
                        />,
                    ],
                }}
            >
                <div className="compliance-wrapper">
                    <StatusSidebar />
                    <div className="compliance-content">{renderLayout()}</div>
                </div>
            </Layout>
        </div>
    ) : (
        <Layout title={t('complianceReports.title')} variant="compliance-reports">
            <div>{t('fileDrop.metadataPanel.notFound')}</div>
        </Layout>
    );
};
