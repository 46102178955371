import React, { useEffect, useState } from 'react';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { ApiStructure } from '@yonder-mind/ui-core';
import { Checkbox, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

interface FileFolderAssignmentProps {
    selectedFolders: string[];
    setSelectedFolders: (folderOid: string) => void;
    folders: ApiStructure[];
    visible: boolean;
    disabled: boolean;
}

const useStyles = makeStyles(() =>
    createStyles({
        folderItem: {
            display: 'flex',
            alignItems: 'center',
        },
    })
);

export const FileFolderAssignment: React.FC<FileFolderAssignmentProps> = ({
    selectedFolders,
    setSelectedFolders,
    visible,
    disabled,
    folders,
}) => {
    const classes = useStyles();
    const [expandedFolders, setExpandedFolders] = useState<string[]>([]);

    const getParentsOidsWithChildren = (folders: ApiStructure[]) => {
        const result: string[] = [];
        folders.forEach((folder) => {
            result.push(folder.oid);
            if (folder.children) {
                result.push(...getParentsOidsWithChildren(folder.children));
            }
        });
        return result;
    };

    useEffect(() => {
        if (folders.length > 0) {
            setExpandedFolders(getParentsOidsWithChildren(folders));
        }
    }, [folders]);

    const handleFolderSelect = (event: React.MouseEvent<HTMLButtonElement>, folderOid: string) => {
        event.stopPropagation();
        setSelectedFolders(folderOid);
    };

    const handleExpandClick = (event: React.MouseEvent<HTMLLIElement>, folderOid: string) => {
        if (expandedFolders.includes(folderOid)) {
            setExpandedFolders(expandedFolders.filter((oid) => oid !== folderOid));
        } else {
            setExpandedFolders([...expandedFolders, folderOid]);
        }
        event.stopPropagation();
    };

    const renderTree = (folder: ApiStructure) => (
        <TreeItem
            key={folder.oid}
            nodeId={folder.oid}
            onClick={(event) => handleExpandClick(event, folder.oid)}
            label={
                <div className={classes.folderItem} data-testid="folderRow">
                    <Checkbox
                        checked={selectedFolders?.indexOf(folder.oid) !== -1}
                        color="primary"
                        tabIndex={-1}
                        disabled={disabled}
                        disableRipple
                        onClick={(event) => handleFolderSelect(event, folder.oid)}
                        data-testid="folderCheckbox"
                    />
                    {folder.name}
                </div>
            }
        >
            {Array.isArray(folder.children) ? folder.children.map((folder) => renderTree(folder)) : null}
        </TreeItem>
    );

    return (
        visible && (
            <>
                <Typography variant="h6">{'Assign the selected file to one or more folders'}</Typography>
                <div className="file-folder-assignment">
                    <TreeView
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        expanded={expandedFolders}
                        defaultExpandIcon={<ChevronRightIcon />}
                        multiSelect
                        data-testid="treeview"
                    >
                        {folders ? folders.map((folder) => renderTree(folder)) : null}
                    </TreeView>
                </div>
            </>
        )
    );
};
