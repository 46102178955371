import { combineReducers } from 'redux';
import { IWorkflowStore } from '../../interfaces';
import changeRequestReducer from './change-request/cr-reducer';
import revisionReducer from './revision/revision-reducer';
import processReducer from './process/process-reducer';

export const workflowReducer = combineReducers<{ [key in keyof IWorkflowStore]: any }>({
    changeRequest: changeRequestReducer,
    revision: revisionReducer,
    process: processReducer,
});

export default workflowReducer;
