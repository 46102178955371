import { ActionType, getType } from 'typesafe-actions';
import { IRevisionStore } from '../../../interfaces/store/workflow';
import * as actions from './revision-actions';

export type RevisionActions = ActionType<typeof actions>;

const initialState: IRevisionStore = {
    revisionsByContextOid: {},
    documentRevisionHistories: {},
    revisionHistorics: {},
    allowedToStartRevisionWorkflow: false,
    apiErrors: [],
    isLoading: false,
};

export default function revisionReducer(state: IRevisionStore = initialState, action: RevisionActions): IRevisionStore {
    switch (action.type) {
        case getType(actions.revisionsByContextOidRequested):
            return { ...state, isLoading: true };
        case getType(actions.revisionsByContextOidReceived):
            return {
                ...state,
                revisionsByContextOid: {
                    ...state.revisionsByContextOid,
                    [action.payload.contextOid]: action.payload.workflowActivity,
                },
                isLoading: false,
            };
        case getType(actions.revisionHistoryOfDocumentReceived):
            return {
                ...state,
                documentRevisionHistories: {
                    ...state.documentRevisionHistories,
                    [action.payload.contextVersionOid]: action.payload.history,
                },
            };

        case getType(actions.revisionHistoricReceived):
            return {
                ...state,
                revisionHistorics: {
                    ...state.revisionHistorics,
                    [action.payload.documentId]: action.payload.historic,
                },
            };

        case getType(actions.revisionUpdatesReceived):
            return {
                ...state,
                revisionsByContextOid: {
                    ...state.revisionsByContextOid,
                    [action.payload.contextOid]: [
                        ...state.revisionsByContextOid[action.payload.contextOid].filter(
                            (revision) => revision.processInstanceId !== action.payload.processInstanceId
                        ),
                        ...(action.payload.activity ? [action.payload.activity] : []),
                    ],
                },
            };

        case getType(actions.receivedNewRevision):
            return {
                ...state,
                revisionsByContextOid: {
                    ...state.revisionsByContextOid,
                    [action.payload.contextOid]: [
                        ...state.revisionsByContextOid[action.payload.contextOid].filter(
                            (revision) => revision.id !== action.payload.revision.id
                        ),
                        action.payload.revision,
                    ],
                },
            };

        case getType(actions.newRevisionFormFieldsReceived):
            return {
                ...state,
                startingFormFields: action.payload,
                allowedToStartRevisionWorkflow: true,
            };
        case getType(actions.apiErrorReceived):
            return { ...state, apiErrors: [...state.apiErrors, action.payload.error] };
        case getType(actions.apiErrorReset):
            return { ...state, apiErrors: action.payload.errors };

        default:
            return state;
    }
}
