import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import { docsActions, Layout, SideBarProvider, routerSelectors, IApplicationStore } from '@yonder-mind/ui-core';
import { ManageSubmissionDetails } from '../../components/Form/SubmissionDetails/ManageSubmissionDetails';

export interface UrlParam {
    documentOid: string;
    formSubmissionOid?: string;
}

export const ManageSubmission: React.FC = () => {
    const dispatch = useDispatch();
    const { params } = useRouteMatch<UrlParam>();
    const fetchFormContent = () => {
        dispatch(docsActions.contentByModuleVersionOidRequested(moduleVersionOid));
    };

    const { form, formContent, moduleVersionOid, sidebarTool, sidebarToolTab } = useSelector(
        (state: IApplicationStore) => {
            const { sidebarTool, sidebarToolTab } = routerSelectors.queryParams(state);
            return {
                form: state.docs.documentCache[params.documentOid]?.contextVersion,
                moduleVersionOid:
                    state.docs.documentCache[params.documentOid]?.contextVersion?.outline[0]?.moduleVersionOid,
                formContent: state.docs.formContent,
                sidebarTool,
                sidebarToolTab,
            };
        }
    );

    const debounceContentLoad = useCallback(debounce(fetchFormContent, 300), [moduleVersionOid]);

    useEffect(() => {
        params.documentOid &&
            dispatch(
                docsActions.documentRequested({
                    documentId: params.documentOid,
                    filters: [],
                })
            );
    }, [params.documentOid]);

    useEffect(() => {
        if (!isEmpty(form) && isEmpty(formContent[moduleVersionOid])) {
            debounceContentLoad();
        }
    }, [form]);

    return (
        <SideBarProvider tool={sidebarTool} toolTab={sidebarToolTab} extraTools={[]}>
            <Layout
                title={form?.title}
                variant="manage-submission"
                sidebarTool={sidebarTool}
                sidebarToolTab={sidebarToolTab}
            >
                <ManageSubmissionDetails
                    moduleVersionOid={moduleVersionOid}
                    formSubmissionOid={params.formSubmissionOid}
                    formContent={formContent}
                />
            </Layout>
        </SideBarProvider>
    );
};
