import { takeLatest, call, put } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';
import { IAggregatedWorkflowInformation, IPdfExportApi } from '../../interfaces';
import * as actions from './pdfexport-actions';

function* requestChangeRequestsForDocumentVersion(
    api: IPdfExportApi,
    action: ActionType<typeof actions.requestChangeRequestsForDocumentVersion>
) {
    try {
        const content: IAggregatedWorkflowInformation[] = yield call(
            [api, api.getAllChangeRequestsByDocumentVersionId],
            action.payload.documentVersionOid
        );
        yield put(actions.changeRequestsForDocumentVersionReceived({ content }));
    } catch (error) {
        yield put(actions.error(error));
    }
}

function* requestRevisions(api: IPdfExportApi, action: ActionType<typeof actions.requestRevisions>) {
    try {
        const content: IAggregatedWorkflowInformation[] = yield call(
            [api, api.getRevisionsByDocumentVersionId],
            action.payload.documentVersionOid
        );

        yield put(actions.revisionsReceived({ content }));
    } catch (error) {
        yield put(actions.error(error));
    }
}

export default function* pdfExportSagas(api: IPdfExportApi) {
    yield takeLatest(
        getType(actions.requestChangeRequestsForDocumentVersion),
        requestChangeRequestsForDocumentVersion,
        api
    );
    yield takeLatest(getType(actions.requestRevisions), requestRevisions, api);
}
