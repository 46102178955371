import React from 'react';
import { Divider, List, ListItem, ListItemText } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { IApplicationStore, IUser } from '@yonder-mind/ui-core';
import { useSelector } from 'react-redux';
import { FileDropMetadata } from '../../domain/types';
import { NoItems } from '../../../../components';
import { useTranslation } from 'react-i18next';

export const MetadataUsersList: React.FC<{
    setMetadata: (metadata: FileDropMetadata) => void;
    selectedUsers: string[];
    disabled?: boolean;
}> = ({ setMetadata, selectedUsers, disabled }) => {
    const usersList = useSelector((state: IApplicationStore) => state.fileDrop.usersList);

    const { t } = useTranslation();

    const onDelete = (username: string) => {
        setMetadata({ users: selectedUsers.filter((item: string) => item !== username) });
    };

    return (
        <>
            {usersList && selectedUsers && selectedUsers.length > 0 ? (
                <List component="div" disablePadding>
                    {usersList
                        .filter((user: IUser) => selectedUsers.includes(user.username))
                        .map((user: IUser, index: number) => {
                            return (
                                <div key={index}>
                                    <ListItem className="list-item">
                                        <ListItemText
                                            className={`text-layer-${index}`}
                                            primary={`${user.firstName} ${user.lastName}`}
                                        />
                                        {!disabled && (
                                            <div onClick={() => onDelete(user.username)} className="remove-item">
                                                <Delete />
                                            </div>
                                        )}
                                    </ListItem>
                                    <Divider />
                                </div>
                            );
                        })}
                </List>
            ) : (
                <NoItems
                    className="metadata-no-users-selected"
                    message={t('fileDrop.metadataPanel.users.noUsersSelected')}
                />
            )}
        </>
    );
};
