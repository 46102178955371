import React, { useState } from 'react';
import { HistoryLog } from '../../components/HistoryLog/HistoryLog';
import { authSelector, ListItem } from '@yonder-mind/ui-core';
import { GlobalHistoryLogFilterConfiguration } from './GlobalHistoryLogFilterConfiguration';
import { useSelector } from 'react-redux';

interface GlobalHistoryLogProps {}

export const GlobalHistoryLog: React.FC<GlobalHistoryLogProps> = () => {
    const hasHistoryViewRole = useSelector(authSelector.hasHistoryViewRole);
    const [selectedDocumentItem, setSelectedDocumentItem] = useState<ListItem>(null);

    return (
        hasHistoryViewRole && (
            <div className="global-history-log" data-testid="globalHistoryLog">
                <GlobalHistoryLogFilterConfiguration
                    selectedDocumentItem={selectedDocumentItem}
                    setSelectedDocumentItem={setSelectedDocumentItem}
                />
                <HistoryLog isGlobalView={true} contextOid={selectedDocumentItem?.id} minWidth={1350} />
            </div>
        )
    );
};
