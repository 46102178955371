import {
    IApiContextVersionChange,
    IApiContextVersionChangeContent,
    IApiImportJobLock,
    IApiModuleVersionChange,
    IApiModuleVersionChangeContent,
    Rest,
} from '@yonder-mind/ui-core';
import { ApiConstants } from '../utils';
import { IWebDatabaseApi, IWorkflowCrEditContent, IWorkflowCrEditTitle } from '../interfaces';

export class RestWebDbApi implements IWebDatabaseApi {
    async addContextVersionChange(
        contextVersionOid: string,
        change: IApiContextVersionChangeContent
    ): Promise<IApiContextVersionChange> {
        const response = await Rest.postRequest(
            ApiConstants.createContextVersionChanges(contextVersionOid).toString(),
            change
        );
        return response.json();
    }

    async addModuleVersionChange(
        moduleVersionOid: string,
        change: IApiModuleVersionChangeContent
    ): Promise<IApiModuleVersionChange> {
        const response = await Rest.postRequest(
            ApiConstants.createModuleVersionChanges(moduleVersionOid).toString(),
            change
        );
        return response.json();
    }

    async getContextVersionChanges(contextVersionOid: string, revisionId: string): Promise<IApiContextVersionChange[]> {
        const response = await Rest.getRequest(
            ApiConstants.getContextVersionChanges(contextVersionOid, revisionId).toString()
        );
        return response.json();
    }

    async getContextVersionImportJobHasChanges(contextVersionOid: string): Promise<boolean> {
        const response = await Rest.getRequest(
            ApiConstants.getContextVersionImportJobHasChanges(contextVersionOid).toString()
        );
        return response.json();
    }

    async getModuleVersionChanges(
        moduleVersionOid: string,
        changeRequestId: string
    ): Promise<IApiModuleVersionChange[]> {
        const response = await Rest.getRequest(
            ApiConstants.getModuleVersionChanges(moduleVersionOid, changeRequestId).toString()
        );
        return response.json();
    }

    async updateContextVersionChange(
        contextVersionOid: string,
        changeOid: string,
        change: IApiContextVersionChangeContent
    ): Promise<IApiContextVersionChange> {
        const response = await Rest.putRequest(
            ApiConstants.updateContextVersionChanges(contextVersionOid, changeOid).toString(),
            change
        );
        return response.json();
    }

    async updateModuleVersionChange(
        moduleVersionOid: string,
        changeOid: string,
        change: IApiModuleVersionChangeContent
    ): Promise<IApiModuleVersionChange> {
        const response = await Rest.putRequest(
            ApiConstants.updateModuleVersionChanges(moduleVersionOid, changeOid).toString(),
            change
        );
        return response.json();
    }

    async deleteContextVersionChange(contextVersionOid: string, changeOid: string): Promise<void> {
        await Rest.deleteRequest(ApiConstants.updateContextVersionChanges(contextVersionOid, changeOid).toString());
    }

    async deleteModuleVersionChange(moduleVersionOid: string, changeOid: string): Promise<void> {
        await Rest.deleteRequest(ApiConstants.updateModuleVersionChanges(moduleVersionOid, changeOid).toString());
    }

    async getCRContent(contextVersionOid: string, moduleVersionOid: string): Promise<IWorkflowCrEditContent> {
        const response = await Rest.getRequest(
            ApiConstants.startEditingCRContent(contextVersionOid, moduleVersionOid).toString()
        );
        return response.json();
    }

    async peekGetCRContent(contextVersionOid: string, moduleVersionOid: string): Promise<IWorkflowCrEditContent> {
        const response = await Rest.getRequest(
            ApiConstants.peekEditingCRContent(contextVersionOid, moduleVersionOid).toString()
        );
        return response.json();
    }

    async saveCRContent(
        contextVersionOid: string,
        moduleVersionOid: string,
        content: IWorkflowCrEditContent
    ): Promise<IWorkflowCrEditContent> {
        const response = await Rest.postRequest(
            ApiConstants.startEditingCRContent(contextVersionOid, moduleVersionOid).toString(),
            content
        );

        return response.json();
    }

    async peekCRTitle(contextVersionOid: string, moduleOid: string): Promise<IWorkflowCrEditTitle> {
        const response = await Rest.getRequest(
            ApiConstants.peekEditingCRTitle(contextVersionOid, moduleOid).toString()
        );
        return response.json();
    }

    async getCRTitle(contextVersionOid: string, moduleOid: string): Promise<IWorkflowCrEditTitle> {
        const response = await Rest.getRequest(
            ApiConstants.startEditingCRTitle(contextVersionOid, moduleOid).toString()
        );
        return response.json();
    }

    async saveCRTitle(contextVersionOid: string, moduleOid: string, title: IWorkflowCrEditTitle): Promise<void> {
        await Rest.postRequest(ApiConstants.startEditingCRTitle(contextVersionOid, moduleOid).toString(), title);
    }

    async refreshLock(contextVersionOid: string): Promise<IApiImportJobLock> {
        const response = await Rest.getRequest(ApiConstants.refreshLock(contextVersionOid).toString());
        return response.json();
    }

    async cancelEditCRContent(contextVersionOid: string, moduleVersionOid: string): Promise<IApiImportJobLock> {
        const response = await Rest.postRequest(
            ApiConstants.cancelEditingCRContent(contextVersionOid, moduleVersionOid).toString(),
            null
        );
        return response.json();
    }

    async cancelEditCRTitle(contextVersionOid: string, moduleOid: string): Promise<IApiImportJobLock> {
        const response = await Rest.postRequest(
            ApiConstants.cancelEditingCRTitle(contextVersionOid, moduleOid).toString(),
            null
        );
        return response.json();
    }

    async getImportJobLockByContextVersionOid(contextVersionOid: string): Promise<IApiImportJobLock> {
        const response = await Rest.getRequest(
            ApiConstants.getImportJobLockByContextVersionOid(contextVersionOid).toString()
        );
        return response.json();
    }
}
