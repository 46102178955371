import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Grid, TextField, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { SnackbarKey, useSnackbar } from 'notistack';
import {
    compareRevisionsActions,
    CompareRevisionsStatus,
    CompareRevisionsTools,
    docsActions,
    DocumentClass,
    IApiContextVersionMetadata,
    SnackBarCloseButton,
    Spinner,
    useSideBar,
} from '@yonder-mind/ui-core';
import { IWebApplicationStore } from '../../interfaces';
import { SelectElement } from './SelectElement';
import { CompareRevisionsGenerateButton } from './CompareRevisionsGenerateButton';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        input: {
            margin: theme.spacing(1, 0, 0),
        },
        form: {
            margin: theme.spacing(3, 0, 0),
        },
    })
);

export const CompareRevisions: React.FC = () => {
    const { isExpanded } = useSideBar();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    const [selectedDocument, setSelectedDocument] = useState<string>('');
    const [revisions, setRevisions] = useState<IApiContextVersionMetadata[]>([]);

    const [oldRevision, setOldRevision] = useState<string>('');
    const [newRevision, setNewRevision] = useState<string>('');
    const [compareRevisionsLabel, setCompareRevisionsLabel] = useState<string>('');
    const [wasActiveLastTime, setWasActiveLastTime] = useState<boolean>(false);

    const {
        contextTitleBasicInfo,
        contextVersionsMetadataByContext,
        compareRevisionsJobId,
        isActiveCompareRevisionsJobsLoading,
        activeCompareRevisionsJobs,
        currentCompareRevisionsJob,
        hasCompareRevisionsJobRunning,
        isCurrentCompareRevisionsProgressing,
        compareRevisionsResults,
    } = useSelector((state: IWebApplicationStore) => {
        return {
            contextTitleBasicInfo: state.docs.contextTitleBasicInfo
                .filter((contextInfo) => contextInfo.documentClass === DocumentClass.DOCUMENT)
                .sort((a, b) => a.title.localeCompare(b.title)),
            contextVersionsMetadataByContext: state.docs.contextVersionsMetadataByContext,
            activeCompareRevisionsJobs: state.compareRevisions.activeCompareRevisionsJobs,
            isActiveCompareRevisionsJobsLoading: state.compareRevisions.isActiveCompareRevisionsJobsLoading,
            compareRevisionsJobId: state.compareRevisions.currentCompareRevisionsJobId,
            currentCompareRevisionsJob: state.compareRevisions.currentCompareRevisionsJob,
            hasCompareRevisionsJobRunning: state.compareRevisions.activeCompareRevisionsJobs.some(
                (job) => job.status !== CompareRevisionsStatus.FAILED && CompareRevisionsStatus.FINISHED
            ),
            isCurrentCompareRevisionsProgressing:
                state.compareRevisions.currentCompareRevisionsJob &&
                state.compareRevisions.currentCompareRevisionsJob?.status !== CompareRevisionsStatus.FINISHED &&
                state.compareRevisions.currentCompareRevisionsJob?.status !== CompareRevisionsStatus.FAILED,
            compareRevisionsResults: state.compareRevisions.compareRevisionsResults,
        };
    });

    useEffect(() => {
        dispatch(docsActions.contextTitleBasicInfoRequested({ minRevisions: 2 }));
        dispatch(
            compareRevisionsActions.compareRevisionsResultsRequested({ errorNotification: createErrorNotification })
        );
        dispatch(
            compareRevisionsActions.activeCompareRevisionsJobsRequested({ errorNotification: createErrorNotification })
        );
        return () => {
            dispatch(compareRevisionsActions.resetCurrentCompareRevisionsJob());
            dispatch(docsActions.clearContextTitleBasicInfo());
        };
    }, []);

    useEffect(() => {
        if (contextVersionsMetadataByContext && contextVersionsMetadataByContext[selectedDocument]) {
            updateRevisions(selectedDocument);
        }
    }, [contextVersionsMetadataByContext]);

    useEffect(() => {
        if (!hasCompareRevisionsJobRunning && wasActiveLastTime) {
            setWasActiveLastTime(false);
            dispatch(
                compareRevisionsActions.compareRevisionsResultsRequested({ errorNotification: createErrorNotification })
            );
        }

        hasCompareRevisionsJobRunning && setWasActiveLastTime(true);

        const pollActiveCompareRevisionsJobs =
            hasCompareRevisionsJobRunning &&
            setInterval(
                () =>
                    dispatch(
                        compareRevisionsActions.activeCompareRevisionsJobsRequested({
                            errorNotification: createErrorNotification,
                        })
                    ),
                5000
            );
        return () => {
            clearInterval(pollActiveCompareRevisionsJobs);
        };
    }, [activeCompareRevisionsJobs]);

    useEffect(() => {
        if (compareRevisionsJobId) {
            dispatch(
                compareRevisionsActions.compareRevisionsJobRequested({
                    compareRevisionsJobId: compareRevisionsJobId,
                    errorNotification: createErrorNotification,
                })
            );
        }
    }, [compareRevisionsJobId]);

    useEffect(() => {
        const pollCurrentCompareRevisionsJob =
            isCurrentCompareRevisionsProgressing &&
            setInterval(
                () =>
                    dispatch(
                        compareRevisionsActions.compareRevisionsJobRequested({
                            compareRevisionsJobId: compareRevisionsJobId,
                            errorNotification: createErrorNotification,
                        })
                    ),
                1000
            );
        return () => {
            clearInterval(pollCurrentCompareRevisionsJob);
        };
    }, [currentCompareRevisionsJob]);

    useEffect(() => {
        if (currentCompareRevisionsJob?.status === CompareRevisionsStatus.FINISHED) {
            enqueueSnackbar(t('revisionDiffer.messages.success'), {
                variant: 'success',
                preventDuplicate: true,
            });
            dispatch(
                compareRevisionsActions.compareRevisionsResultsRequested({ errorNotification: createErrorNotification })
            );
            dispatch(
                compareRevisionsActions.activeCompareRevisionsJobsRequested({
                    errorNotification: createErrorNotification,
                })
            );
            window.open(
                `${window.location.origin}/api/compare-revisions/${currentCompareRevisionsJob?.resultOid}/html`,
                '_blank',
                'noreferrer'
            );
        }
        if (currentCompareRevisionsJob?.status === CompareRevisionsStatus.FAILED) {
            enqueueSnackbar(t('revisionDiffer.messages.generalError'), {
                action: (key: SnackbarKey) => <SnackBarCloseButton snackbarKey={key} />,
                variant: 'error',
                persist: true,
                preventDuplicate: true,
            });
        }
    }, [currentCompareRevisionsJob]);

    const startCompareRevisions = () => {
        dispatch(
            compareRevisionsActions.compareRevisionsRequested({
                contextVersionOid1: oldRevision,
                contextVersionOid2: newRevision,
                name: encodeURIComponent(compareRevisionsLabel),
                errorNotification: createErrorNotification,
            })
        );
    };

    const handleSelectedDocument = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedDocument(event.target.value as string);
        if (!contextVersionsMetadataByContext[event.target.value as string]) {
            dispatch(docsActions.documentVersionsMetadataRequested(event.target.value as string));
        } else {
            updateRevisions(event.target.value as string);
        }
    };

    const updateRevisions = (selectedDocument: string) => {
        const contextRevisions = contextVersionsMetadataByContext[selectedDocument];
        setRevisions(contextRevisions);
        const oldRevision = contextRevisions[contextRevisions.length - 2]?.oid;
        const newRevision = contextRevisions[contextRevisions.length - 1]?.oid;
        setOldRevision('');
        setNewRevision('');
        if (contextRevisions.length > 1) {
            setOldRevision(oldRevision);
            setNewRevision(newRevision);
        }
        updateCompareRevisionsLabel(selectedDocument, oldRevision, newRevision);
    };

    const updateCompareRevisionsLabel = (selectedDocument: string, oldRevision?: string, newRevision?: string) => {
        setCompareRevisionsLabel(
            `${findRevisionName(selectedDocument, oldRevision) || ' '} ${t('revisionDiffer.name.versus')} ${
                findRevisionName(selectedDocument, newRevision) || ' '
            }`
        );
    };

    const findRevisionName = (selectedDocument: string, oid: string) => {
        return contextVersionsMetadataByContext[selectedDocument].find((contextVersion) => contextVersion.oid === oid)
            ?.name;
    };

    const handleOldRevision = (event: React.ChangeEvent<{ value: unknown }>) => {
        setOldRevision(event.target.value as string);
        updateCompareRevisionsLabel(selectedDocument, event.target.value as string, newRevision);
    };

    const handleNewRevision = (event: React.ChangeEvent<{ value: unknown }>) => {
        setNewRevision(event.target.value as string);
        updateCompareRevisionsLabel(selectedDocument, oldRevision, event.target.value as string);
    };

    const createErrorNotification = (statusText?: string) => {
        const errorText = statusText
            ? t('revisionDiffer.messages.backendError', { statusText })
            : t('revisionDiffer.messages.generalError');
        enqueueSnackbar(errorText, {
            action: (key: SnackbarKey) => <SnackBarCloseButton snackbarKey={key} />,
            variant: 'error',
            persist: true,
            preventDuplicate: true,
        });
    };

    const areFormFieldsFilled = Boolean(selectedDocument && oldRevision && newRevision && compareRevisionsLabel);

    return (
        <>
            <CompareRevisionsTools />
            <div className={`compare-revisions${isExpanded ? ' tools-open' : ''}`}>
                <div className="compare-revisions-wrapper">
                    <div className="compare-revisions-wrapper-content">
                        <div>
                            {!contextTitleBasicInfo?.length ? (
                                <div className="compare-revisions-loading-wrapper">
                                    <div className="compare-revisions-loading">
                                        <Spinner />
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <Box component="div" mb={2} alignItems="center">
                                        <Box component="div" mb={2} alignItems="center">
                                            <Typography
                                                data-testid={'compare-revisions-subtitle'}
                                                variant="h5"
                                                className="title"
                                            >
                                                {t('revisionDiffer.subtitle')}
                                            </Typography>
                                        </Box>
                                        <Box component="div" mb={2} alignItems="center">
                                            <form className={classes.form} noValidate>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12}>
                                                        <SelectElement
                                                            id="compare-revisions-document-label"
                                                            label={t('revisionDiffer.documentSelection.title')}
                                                            selectedOid={selectedDocument}
                                                            handleChange={handleSelectedDocument}
                                                            options={contextTitleBasicInfo.map((contextInfo) => ({
                                                                oid: contextInfo.contextOid,
                                                                name: contextInfo.title,
                                                            }))}
                                                            fullwidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography
                                                            data-testid={'compare-revisions-selection-subtitle'}
                                                            variant="h6"
                                                            className="title"
                                                        >
                                                            {t('revisionDiffer.revisionSelection.title')}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} lg={6}>
                                                        <SelectElement
                                                            id="compare-revisions-old-revision-label"
                                                            label={t('revisionDiffer.revisionSelection.old')}
                                                            selectedOid={oldRevision}
                                                            handleChange={handleOldRevision}
                                                            options={revisions}
                                                            fullwidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} lg={6}>
                                                        <SelectElement
                                                            id="compare-revisions-new-revision-label"
                                                            label={t('revisionDiffer.revisionSelection.new')}
                                                            selectedOid={newRevision}
                                                            handleChange={handleNewRevision}
                                                            options={revisions}
                                                            fullwidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            className={classes.input}
                                                            label={t('revisionDiffer.name.title')}
                                                            value={compareRevisionsLabel}
                                                            variant="outlined"
                                                            data-testid="compare-revisions-name"
                                                            onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                                                                setCompareRevisionsLabel(event.target.value as string)
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <CompareRevisionsGenerateButton
                                                    hasCompareRevisionsJobRunning={hasCompareRevisionsJobRunning}
                                                    isCurrentCompareRevisionsProgressing={
                                                        isCurrentCompareRevisionsProgressing
                                                    }
                                                    isActiveCompareRevisionsJobsLoading={
                                                        isActiveCompareRevisionsJobsLoading
                                                    }
                                                    areFormFieldsFilled={areFormFieldsFilled}
                                                    currentCompareRevisionsJob={currentCompareRevisionsJob}
                                                    compareRevisionsResults={compareRevisionsResults}
                                                    startCompareRevisions={startCompareRevisions}
                                                />
                                            </form>
                                        </Box>
                                    </Box>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
